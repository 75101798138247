import { useAtomValue } from 'jotai';
import { userConfigsAtom } from '@/atoms/queries/userConfigs';
import { upsertUserConfigAtom } from '@/atoms/mutations/userConfigs/userConfigs';
import { UserConfigPutMutationParams } from '@/types/userConfigs';

export const useUpsertUserConfig = () => {
  const userConfigs = useAtomValue(userConfigsAtom);
  const { mutateAsync: upsertUserConfig } = useAtomValue(upsertUserConfigAtom);

  const handleUpsertUserConfig = async (...mutationParams: UserConfigPutMutationParams[]) => {
    const { configIds, isSuccess } = await upsertUserConfig(mutationParams);
    if (isSuccess) {
      await userConfigs.refetch();
    }
    return { configIds, isSuccess };
  };

  return handleUpsertUserConfig;
};
