import { metricKeyAtom } from '@/atoms/metric';
import { teamIdAtom } from '@/atoms/team/team';
import { METRIC_KEY } from '@/consts/searchKeys';
import { useSetTeamDataDebouncedFilterParams } from '@/hooks/apiFilterParams/useSetTeamDataDebounceFilterParams';
import TeamPreview from '@/pages/team/preview/TeamPreview';
import { useSetAtom } from 'jotai';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

export const TeamAggsPreview = () => {
  useSetTeamDataDebouncedFilterParams();
  const [searchParams] = useSearchParams();
  const metricKey = searchParams.get(METRIC_KEY);
  const teamId = searchParams.get('teamId');

  const setMetricKey = useSetAtom(metricKeyAtom);
  const setTeamId = useSetAtom(teamIdAtom);

  useEffect(() => {
    if (metricKey && teamId) {
      setMetricKey(metricKey);
      setTeamId(Number(teamId));
    }
  }, [metricKey, teamId]);

  if (!teamId || !metricKey) return null;

  return <TeamPreview />;
};
