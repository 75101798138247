import { atomWithSuspenseQuery } from 'jotai-tanstack-query';
import { atom } from 'jotai';
import { loadable, unwrap } from 'jotai/utils';
import { Search } from '@statsbomb/parachute-types';
import { fetchClientAtom } from './client';
import { searchDebouncedValueAtom } from '../search/search';
import { unwrappedGenderAtom } from './userConfigs';

const rawSearchQuery = atomWithSuspenseQuery(get => {
  const queryKey = ['search', get(searchDebouncedValueAtom), get(unwrappedGenderAtom)] as const;
  const queryFn = async ({ queryKey: [, debouncedValue, gender] }: { queryKey: typeof queryKey }) => {
    if (debouncedValue === '' || !gender) return {};

    const { fetch } = get(fetchClientAtom);
    return (await fetch(`/search?q=${debouncedValue}&gender=${gender}`)) as Promise<Search>;
  };
  return { queryKey, queryFn };
});

const searchQueryResultsAtom = atom(async get => {
  const results = await get(rawSearchQuery);
  return results.data;
});

/**
 * Both unwrapped and loadable are being used in the Search component as unwrapped does not hold a loading state.
 * loadable is only being used to show the loading state of the search query. This keeps the current search
 * component from being empty when the search query is loading and working the same as current IQ.
 */
export const unwrappedSearchQueryResult = unwrap(searchQueryResultsAtom, prev => prev ?? {});
export const loadableSearchQueryResult = loadable(searchQueryResultsAtom);
