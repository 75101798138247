import { atomWithSuspenseQuery } from 'jotai-tanstack-query';
import { Cycle } from '@statsbomb/parachute-types';
import { fetchClientAtom } from '@/atoms/queries/client';

export const rawCyclesAtom = atomWithSuspenseQuery(get => ({
  queryKey: ['cycles'],
  queryFn: async () => {
    const { fetch } = get(fetchClientAtom);
    return (await fetch(`/cycles`)) as Promise<Cycle[]>;
  },
}));
