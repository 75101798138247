import { useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';
import {
  selectedEndDateAtom,
  selectedGameRangeAtom,
  selectedPeriodRangeAtom,
  selectedStartDateAtom,
} from '@/atoms/filters/highLevel';
import { GameType } from '@/types/filters';
import { selectedGameTypeAtom } from '@/atoms/filters/gameMetrics';
import { selectedCompetitionsAtom } from '@/atoms/filters/highLevel/competitions';
import { selectedTeamsAtom } from '@/atoms/filters/highLevel/teams';
import { selectedSeasonsAtom } from '@/atoms/filters/highLevel/seasons';

export const useSelectedFiltersDescription = () => {
  const { t } = useTranslation(['gameModal', 'games', 'filters']);

  const selectedCompetitions = useAtomValue(selectedCompetitionsAtom);
  const selectedTeams = useAtomValue(selectedTeamsAtom);
  const selectedSeasons = useAtomValue(selectedSeasonsAtom);
  const selectedStartDate = useAtomValue(selectedStartDateAtom);
  const selectedEndDate = useAtomValue(selectedEndDateAtom);
  const { key: selectedGameRangeKey } = useAtomValue(selectedGameRangeAtom);
  const selectedPeriodRange = useAtomValue(selectedPeriodRangeAtom);

  const selectedGameType = useAtomValue(selectedGameTypeAtom);
  const gameTypeFilter =
    selectedGameType.value === GameType.HOME_AWAY
      ? []
      : [
          {
            ...selectedGameType,
            label: t('gamesText', {
              ns: 'games',
              text: t(selectedGameType.translation.key, { ns: selectedGameType.translation.ns }),
            }),
          },
        ];

  const periodRange =
    selectedGameRangeKey === 'period' && selectedPeriodRange.translation.key !== 'allGames'
      ? [
          {
            label: t(selectedPeriodRange.translation.key, {
              ns: selectedPeriodRange.translation.ns,
              count: selectedPeriodRange.translation.count,
            }),
          },
        ]
      : [];
  const startDate = selectedGameRangeKey === 'dateRange' && selectedStartDate ? selectedStartDate : '';
  const endDate = selectedGameRangeKey === 'dateRange' && selectedEndDate ? selectedEndDate : '';
  const dateRange =
    selectedGameRangeKey === 'dateRange' && (startDate || endDate)
      ? [
          {
            label: `
            ${startDate && !endDate ? ` ${t('dateFrom', { ns: 'filters' })} ${startDate}` : ``}
            ${startDate && endDate ? `${startDate} ${t('dateTo', { ns: 'filters' })} ${endDate}` : ``}
            ${endDate && !startDate ? ` ${t('dateUntil', { ns: 'filters' })} ${endDate}` : ``}
            `.trim(), // removes some weird whitespace issues
          },
        ]
      : [];

  const selectedFilters = [
    ...selectedTeams,
    ...selectedCompetitions,
    ...selectedSeasons,
    ...dateRange,
    ...periodRange,
    ...gameTypeFilter,
  ];

  return selectedFilters.map(({ label }) => label).join(', ');
};
