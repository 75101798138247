import { fetchClientAtom } from '@/atoms/queries/client';
import { useAtomValue, useSetAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { toastDataAtom } from '@/atoms/toast';
import { csvDownloadDataAtom } from '@/atoms/exporting';
import { createLinkAndDownload } from './Export.helper';
import { MenuItemWithIcon } from '../menu/MenuItemWithIcon';

/**
 * This BOM needs to be prepended to the CSV file to get Excel to render non-ascii characters in a utf-8 encoded file
 * correctly
 */
const UTF_8_BYTE_ORDER_MARKER = new Uint8Array([0xef, 0xbb, 0xbf]);

const addLanguageQueryParam = (url: string, language: string) =>
  url.includes('?') ? `${url}&lang=${language}` : `${url}?lang=${language}`;

export const ExportCsvMenuItem = () => {
  const { url, fileName } = useAtomValue(csvDownloadDataAtom);
  const {
    t,
    i18n: { language },
  } = useTranslation('csv');
  const { fetch } = useAtomValue(fetchClientAtom);
  const setToastData = useSetAtom(toastDataAtom);

  const handleCsvExport = async () => {
    setToastData('exportCsvStarted', true);

    // Because we do not enable the button if the url is undefined, we know that if we get here this url must be a string
    const response = await fetch(addLanguageQueryParam(url as string, language), {
      headers: { accept: 'text/csv' },
    });

    try {
      if (response.status !== 200) {
        throw new Error('Invalid status');
      }

      // The response from the server already includes the BOM but sadly this isn't correctly added to the Blob, so we
      // need to strip it off and add again using the following code, otherwise you end up with weird (visible)
      // characters in your CSV.
      const csvString = (await response.text()).replace(UTF_8_BYTE_ORDER_MARKER, '');
      const csvStringBlob = new Blob([UTF_8_BYTE_ORDER_MARKER, csvString], {
        type: response.headers.get('content-type'),
      });
      const dataUrl = URL.createObjectURL(csvStringBlob);
      createLinkAndDownload(dataUrl, fileName);
      setToastData('exportCsvCompleted', true);
    } catch (e) {
      setToastData('exportCsvCompleted', false);
    }
  };

  return <MenuItemWithIcon onClick={handleCsvExport} icon="Download" text={t('export')} disabled={!fileName || !url} />;
};
