export const PITCH_ORIENTATION = ['HORIZONTAL', 'VERTICAL', 'VERTICAL_REVERSE', 'HORIZONTAL_REVERSE'] as const;
export const PITCH_CROP = [
  'FULL_PITCH',
  'HOME_BOX',
  'HOME_THIRD',
  'HOME_HALF',
  'OPPOSITION_BOX',
  'OPPOSITION_THIRD',
  'OPPOSITION_HALF',
  'CENTRE_THIRD',
] as const;
export const EVENT_MARKERS_LIMIT = 1500;
