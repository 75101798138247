import { UserConfigPutMutationParams } from '@/types/userConfigs';
import { useState } from 'react';
import { useSetAtom } from 'jotai';
import { toastDataAtom } from '@/atoms/toast';
import { useUpsertUserConfig } from './useUpsertUserConfig';

export const useOptimisticUserConfigUpdate = <T,>(savedValue: T, toastName: string) => {
  const upsertUserConfig = useUpsertUserConfig();
  const setToastData = useSetAtom(toastDataAtom);
  const [localValue, setLocalValue] = useState<T>(savedValue);

  const updateUserConfigOptimistically = async (mutationParams: UserConfigPutMutationParams, newValue: T) => {
    // Only call API if we have a new value, different value
    if (newValue !== localValue) {
      setLocalValue(newValue);
      const { isSuccess } = await upsertUserConfig(mutationParams);

      // If API call fails revert local state and set error state
      if (!isSuccess) {
        setLocalValue(savedValue);
      }

      setToastData(toastName, isSuccess);
    }
  };

  return { updateUserConfigOptimistically, localValue };
};
