import { Typography } from '@statsbomb/kitbag-components';
import { useTranslation } from 'react-i18next';

export const PreviewTableState = ({ isLoading, hasData }: { isLoading: boolean; hasData: boolean }) => {
  const { t } = useTranslation(['general', 'status']);

  return (
    <>
      {!isLoading && !hasData && (
        <Typography variant="bodyMedium" className="text-center py-4" data-testid="preview-table-no-data">
          {t('general.noData.title', { ns: 'status' })}
        </Typography>
      )}
      {isLoading && (
        <Typography variant="bodyMedium" className="text-center py-4" data-testid="preview-table-loading">
          {t('loading', { ns: 'general' })}
        </Typography>
      )}
    </>
  );
};
