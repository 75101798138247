/* istanbul ignore file */
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

export const Head = () => {
  const {
    i18n: { language },
  } = useTranslation();

  return (
    <Helmet htmlAttributes={{ lang: language, translate: 'no' }}>
      <meta name="google" content="notranslate" />
    </Helmet>
  );
};
