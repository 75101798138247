import { teamEventMetricFiltersAtom, teamEventsForMetricCountAtom } from '@/atoms/queries/team/teamEventData';
import { unwrappedSelectedTeamNameAtom } from '@/atoms/team/info';
import { teamMetricDataAtom } from '@/atoms/team/metric';
import { EventDataControls } from '@/components/controls/EventsDataControls';
import { PageTitle } from '@/components/head/PageTitle';
import { TeamLayoutWithFilters } from '@/components/layout/team/TeamLayoutWithFilters';
import { MetricKeyDropdown } from '@/components/metric/MetricKeyDropdown';
import { VisDisplaySection } from '@/components/section/VisDisplaySection';
import { TeamVisEventsTable } from '@/components/team/vis/TeamVisEventsTable';
import { EventCountInfoBar } from '@/components/visualisations/EventCountInfoBar';
import { PitchVisualisation } from '@/components/visualisations/PitchVisualisation/PitchVisualisation';
import { teamPitchVisComponents } from '@/consts/visualisationComponents';
import { useEntityPageTitle } from '@/hooks/useEntityPageTitle';
import { useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';
import { teamMetricEventsUrl } from '@/query/url';
import { metricKeyAtom } from '@/atoms/metric';
import { teamIdAtom } from '@/atoms/team/team';
import { useTableColumns } from '@/hooks/useTableColumns';
import { useCsvDownload } from '@/hooks/exporting/useCsvDownload';

export const TeamVisPage = () => {
  useTableColumns(['competition', 'season', 'game', 'team', 'player', 'event']);
  const { t } = useTranslation('navigation');
  const teamName = useAtomValue(unwrappedSelectedTeamNameAtom);
  const pageTitle = useEntityPageTitle(t('secondary.pitchVis'), teamName);
  const metricKey = useAtomValue(metricKeyAtom);
  const teamId = useAtomValue(teamIdAtom);
  const teamEventMetricFilters = useAtomValue(teamEventMetricFiltersAtom);
  useCsvDownload(pageTitle, teamMetricEventsUrl(teamId, metricKey, teamEventMetricFilters));
  const metricKeyDropdownComponent = <MetricKeyDropdown metricDataAtom={teamMetricDataAtom} />;

  return (
    <>
      <PageTitle>{pageTitle}</PageTitle>
      <TeamLayoutWithFilters>
        <>
          <EventDataControls metricKeyDropdownComponent={metricKeyDropdownComponent} />
          <VisDisplaySection
            EventsTableComponent={TeamVisEventsTable}
            visualisationComponent={
              <PitchVisualisation
                visualisations={teamPitchVisComponents}
                headerComponent={metricKeyDropdownComponent}
                footerComponent={<EventCountInfoBar eventCountAtom={teamEventsForMetricCountAtom} />}
              />
            }
          />
        </>
      </TeamLayoutWithFilters>
    </>
  );
};
