import { KeysToCamelCase } from '@/types/generic';
import { NestedObject } from '@/types/object';
import { isString } from 'lodash/fp';

const isNestedObject = (val: NestedObject[string]): val is NestedObject =>
  !Array.isArray(val) && typeof val === 'object' && val !== null;

export const snakeToCamel = <T>(value: T) =>
  isString(value) ? value.replace(/_([a-z0-9])/gi, (_, letter) => letter.toUpperCase()) : value;
export const kebabToCamel = (str: string) => str.replace(/-([a-z0-9])/gi, (_, letter) => letter.toUpperCase());

export const camelToSnakeCase = (str: string) =>
  str
    .split(/(?=[A-Z]\d*|90)/)
    .join('_')
    .toLowerCase();

// Convert object keys from snake to camel case. Works with nested objects / arrays
export const objSnakeToCamel = <T extends NestedObject>(record: T): KeysToCamelCase<T> =>
  Object.entries(record).reduce((newObj, [key, value]) => {
    const camelKey = snakeToCamel(key);
    if (Array.isArray(value)) {
      return {
        ...newObj,
        [camelKey]: value.map(v => (isNestedObject(v) ? objSnakeToCamel(v) : v)),
      };
    }
    return {
      ...newObj,
      [camelKey]: isNestedObject(value) ? objSnakeToCamel(value) : value,
    };
  }, {} as KeysToCamelCase<T>);

export const objValsSnakeToCamel = <T extends NestedObject>(record: T): T =>
  Object.entries(record).reduce((newObj, [key, value]) => {
    if (Array.isArray(value)) {
      return {
        ...newObj,
        [key]: value.map(v => (isNestedObject(v) ? objValsSnakeToCamel(v) : snakeToCamel(v))),
      };
    }
    return {
      ...newObj,
      [key]: isNestedObject(value) ? objValsSnakeToCamel(value) : snakeToCamel(value),
    };
  }, {} as T);
