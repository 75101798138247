import { selectedBodyPartsAtom } from '@/atoms/eventData';
import { BODY_PARTS } from '@/consts/bodyParts';
import { useTranslation } from 'react-i18next';
import { snakeToCamel } from '@/utils/queries';
import { CheckboxGroup } from './CheckboxGroup';

export const BodyPartCheckboxGroup = () => {
  const { t } = useTranslation('events');
  const header = t('keys.event.attributes.bodyPart');

  const options = BODY_PARTS.map(value => ({
    label: t(`fields.event.attributes.bodyPart.${snakeToCamel(value)}`),
    value,
  }));

  return <CheckboxGroup options={options} selectedValuesAtom={selectedBodyPartsAtom} header={header} />;
};
