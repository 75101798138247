import { EVENT_MARKERS_LIMIT } from '@/consts/visualisations';
import { useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';
import { PromiseAtom } from '@/types/atom';
import { Suspense } from 'react';
import { InfoBar } from '../feedback/InfoBar';

const EventCountInfoBarBase = ({ eventCountAtom }: { eventCountAtom: PromiseAtom<number> }) => {
  const { t } = useTranslation('dataVis');
  const eventCount = useAtomValue(eventCountAtom);

  /* hide info bar if has been filtered down below limit */
  if (eventCount <= EVENT_MARKERS_LIMIT || !eventCount) return null;

  const mainText = t('infoBar', { limit: EVENT_MARKERS_LIMIT, total: eventCount });

  return <InfoBar testId="event-count-info-bar" mainText={mainText} />;
};

export const EventCountInfoBar = ({ eventCountAtom }: { eventCountAtom: PromiseAtom<number> }) => (
  <Suspense fallback={<div className="-mt-4" data-testid="event-count-loading" />}>
    <EventCountInfoBarBase eventCountAtom={eventCountAtom} />
  </Suspense>
);
