import { useTranslation } from 'react-i18next';
import { PREFERRED_FOOT } from '@/consts/preferredFoot';
import { selectedPreferredFootAtom } from '@/atoms/filters/dataLocker/preferredFoot';
import { CheckboxGroup } from './CheckboxGroup';

export const PreferredFootCheckboxGroup = () => {
  const { t } = useTranslation('events');
  const header = t('keys.player.preferredFoot');

  const options = PREFERRED_FOOT.map(value => ({
    label: t(`fields.player.preferredFoot.${value.toLowerCase()}`),
    value,
  }));

  return <CheckboxGroup options={options} selectedValuesAtom={selectedPreferredFootAtom} header={header} />;
};
