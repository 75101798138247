import { filtersToPersistAtom } from '@/atoms/filters/persistedFilters';
import { isSaveFilterModalOpenAtom, filterSetNameAtom } from '@/atoms/filters/userFilters';
import { filterSetsAtom } from '@/atoms/queries/userConfigs';
import { useUpsertFilterSetConfig } from '@/hooks/useUpsertFilterSetConfig';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { GenericSaveModal } from '../GenericSaveModal/GenericSaveModal';

interface SaveFilterModalProps {
  filterSetIdToRename?: string;
}

/**
 * This modal is used for:
 * - Saving a brand new filter set (which will create a new row in the user config table)
 * - Saving changes to an existing filter set under a new name (which will create a new row in the user config table)
 * - Renaming an existing filter set (which will overwrite the name of the existing filter set with the new name)
 */
const SaveFilterModalWithData = ({ filterSetIdToRename }: SaveFilterModalProps) => {
  const { t } = useTranslation(['general', 'filters']);
  const [filterSetName, setFilterSetName] = useAtom(filterSetNameAtom);
  const setIsSaveModalOpen = useSetAtom(isSaveFilterModalOpenAtom);

  // If we're renaming, grab the existing filter config for the id of the config being renamed.
  // Otherwise use the filters as they are currently set up on the page.
  const filterSets = useAtomValue(filterSetsAtom);
  const filtersToPersist = useAtomValue(filtersToPersistAtom);
  const filtersToSave = filterSetIdToRename
    ? filterSets.find(({ configId }) => configId === filterSetIdToRename)?.definition.filters
    : filtersToPersist;

  const upsertFilterSetConfig = useUpsertFilterSetConfig();

  // Prevent duplicated filter set names.
  // If we're renaming, we're allowed to set the name to be the same as the filter set that we're renaming.
  const otherFilterSetNames = filterSets
    .filter(({ configId }) => configId !== filterSetIdToRename)
    .map(({ definition }) => definition.name);

  const handleConfirm = async () => {
    await upsertFilterSetConfig(filterSetName.trim(), filtersToSave, filterSetIdToRename);
    setFilterSetName('');
    setIsSaveModalOpen(false);
  };

  const handleCancel = () => {
    setFilterSetName('');
    setIsSaveModalOpen(false);
  };

  const action = filterSetIdToRename ? 'rename' : 'save';

  return (
    <GenericSaveModal
      id="save-filter-set-title"
      title={t('saveFilterModalTitle', {
        action: t(action, { ns: 'general' }),
        ns: 'filters',
      })}
      onConfirm={handleConfirm}
      onCancel={handleCancel}
      confirmLabel={t('save')}
      value={filterSetName}
      onChange={setFilterSetName}
      existingConfigNames={otherFilterSetNames}
    />
  );
};

export const SaveFilterModal = ({ filterSetIdToRename }: SaveFilterModalProps) => {
  const isSaveModalOpen = useAtomValue(isSaveFilterModalOpenAtom);

  return (
    isSaveModalOpen && (
      <Suspense>
        <SaveFilterModalWithData filterSetIdToRename={filterSetIdToRename} />
      </Suspense>
    )
  );
};
