import { useSetAtom } from 'jotai';
import { toastDataAtom } from '@/atoms/toast';
import { useUpsertUserConfig } from './useUpsertUserConfig';
import { useNavigateToFilterSetId } from './useNavigateToFilterSetId';

/**
 * This hook is used by the SaveFilterModal, and also the menu option
 * to update the saved filters of an existing filter set.
 */
export const useUpsertFilterSetConfig = () => {
  const upsertUserConfig = useUpsertUserConfig();
  const navigateToFilterSetId = useNavigateToFilterSetId();
  const setToastData = useSetAtom(toastDataAtom);

  const upsertFilterSetConfig = async (
    filterSetName?: string,
    filtersToSave?: Record<string, any>,
    filterSetConfigId?: string,
  ) => {
    const configDefinition = {
      name: filterSetName,
      filters: filtersToSave,
    };

    const { configIds, isSuccess } = await upsertUserConfig({
      configId: filterSetConfigId,
      configType: 'filters',
      configDefinition,
    });

    setToastData('saveFilters', isSuccess, filterSetName);

    const configId = configIds?.[0];
    // If saving worked, and we weren't updating an existing config, navigate
    // to the new config.
    if (isSuccess && !filterSetConfigId && configId) {
      navigateToFilterSetId(configId);
    }
  };

  return upsertFilterSetConfig;
};
