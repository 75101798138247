import { useTranslation } from 'react-i18next';
import { EventNameDropdown } from '../dropdowns/EventNameDropdown';
import { Accordion } from '../accordion/Accordion';
import { OutcomeDropdown } from '../dropdowns/OutcomeDropdown';

export const EventTypeFilters = () => {
  const { t } = useTranslation('filters');

  return (
    <Accordion title={t('typeFilter.title')} isDefaultExpanded>
      <EventNameDropdown />
      <OutcomeDropdown />
    </Accordion>
  );
};
