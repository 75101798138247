import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Tabs } from '@statsbomb/kitbag-components';
import { useTranslation } from 'react-i18next';
import { Translation } from '@/types/translations';
import { useEffect, useState } from 'react';
import { isSettingsPanelOpenAtom } from '@/atoms/sidebar';
import { useSetAtom } from 'jotai';

interface Tab {
  displayText?: 'right' | 'left';
  icon?: string;
  isDisabled?: boolean;
  label?: string;
  value: string;
}

export const PageSubNav = ({ items }: { items: { translation: Translation; route: string }[] }) => {
  const { t } = useTranslation('navigation');
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { pathname } = useLocation();
  const setIsSettingsPanelOpen = useSetAtom(isSettingsPanelOpenAtom);

  const hasSearchParams = searchParams.size > 0;
  const [activeTab, setActiveTab] = useState<string>();

  useEffect(() => {
    const defaultTab = items.find(item => pathname.includes(item.route))?.route || items[0].route;
    setActiveTab(defaultTab);
  }, [pathname]);

  const tabs = items.map(item => ({
    label: t(item.translation.key),
    value: item.route,
  }));

  return (
    <Tabs
      tabs={tabs}
      onTabChange={(_: React.MouseEvent<HTMLButtonElement, MouseEvent>, tab: Tab) => {
        const navigatePath = hasSearchParams ? `${tab.value}?${searchParams}` : tab.value;
        setIsSettingsPanelOpen(false);
        setActiveTab(tab.value);
        navigate(navigatePath);
      }}
      activeTab={activeTab}
    />
  );
};
