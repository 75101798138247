import { Suspense, useEffect } from 'react';
import { useAtom } from 'jotai';
import { Dropdown } from '@statsbomb/kitbag-components';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Option } from '@/types/generic';
import { useUpdateQueryString } from '@/hooks/useUpdateQueryString';
import { convertToDropdownOption } from '@/utils/object';
import { RadarTemplate } from '@/types/radar';
import { radarTemplateIdAtom } from '@/atoms/radar';

const RadarTemplateDropdownBase = ({
  label = '',
  isLoading = false,
  value,
  onChange = () => {},
  options,
}: {
  label?: string;
  isLoading?: boolean;
  value?: Option | null;
  onChange?: (option: Option) => void;
  options?: Option[];
}) => (
  <Dropdown
    id="radar-templates-dropdown"
    labelPosition="none"
    variant="tertiary"
    onChange={onChange}
    label={label}
    options={options}
    value={value}
    isLoading={isLoading}
  />
);

const RadarTemplateDropdownWithData = ({
  templates = [],
  label = '',
}: {
  templates?: RadarTemplate[];
  label?: string;
}) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { t } = useTranslation('radar');

  const radarTemplateParam = searchParams.get('radarTemplate');

  const [selectedRadarTemplateId, setSelectedRadarIdAtom] = useAtom(radarTemplateIdAtom);
  const selectedRadarTemplate = templates.find(template => template.id === selectedRadarTemplateId);

  useEffect(() => {
    if (radarTemplateParam) {
      setSelectedRadarIdAtom(Number(radarTemplateParam));
    }
  }, [radarTemplateParam, setSelectedRadarIdAtom]);

  const createQueryString = useUpdateQueryString(searchParams);

  return (
    <RadarTemplateDropdownBase
      label={label}
      options={templates.map(({ labelKey, id }) => convertToDropdownOption(t(`templates.${labelKey}`), id))}
      value={
        selectedRadarTemplate
          ? convertToDropdownOption(t(`templates.${selectedRadarTemplate.labelKey}`), selectedRadarTemplate.id)
          : null
      }
      onChange={(option: Option) => {
        setSelectedRadarIdAtom(Number(option.value));
        navigate(`${pathname}?${createQueryString([{ key: 'radarTemplate', value: String(option.value) }])}`, {
          replace: true,
        });
      }}
    />
  );
};

const RadarTemplateDropdown = ({ templates, label }: { templates: RadarTemplate[]; label: string }) => (
  <Suspense fallback={<RadarTemplateDropdownBase label={label} isLoading />}>
    <RadarTemplateDropdownWithData templates={templates} label={label} />
  </Suspense>
);

export default RadarTemplateDropdown;
