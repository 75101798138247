import { teamMetricDataAtom } from '@/atoms/team/metric';
import { teamIdAtom } from '@/atoms/team/team';
import { TEAM_RADAR_TEMPLATES } from '@/consts/radar';
import { Nullable } from '@/types/generic';
import { NestedObject } from '@/types/object';
import { convertFilterParamsToString, hasEmptyEqFilters } from '@/utils/api';
import { atom } from 'jotai';
import { atomWithSuspenseQuery } from 'jotai-tanstack-query';
import { loadable, unwrap } from 'jotai/utils';
import { atomWithDebounce } from '@/atoms/debounce';
import { fetchClientAtom } from '../client';
import { metricDistributionsAtom, metricsAtom } from '../metric';
import { radarDataAtom } from '../radar';

export const teamRadarFilterDebounceObject = atomWithDebounce<Nullable<NestedObject>>(null);
const { debouncedValueAtom: teamRadarFilterAtom } = teamRadarFilterDebounceObject;

const rawTeamRadarDataAtom = atomWithSuspenseQuery(get => {
  const queryKey = ['teamRadarData', get(teamIdAtom), get(teamRadarFilterAtom)] as const;

  const queryFn = async ({ queryKey: [, teamId, filterParams] }: { queryKey: typeof queryKey }) => {
    if (!teamId || hasEmptyEqFilters(filterParams)) return {};
    const { fetch } = get(fetchClientAtom);
    return (await fetch(
      `/team/${teamId}/normalised-aggregates${convertFilterParamsToString(filterParams)}`,
    )) as Promise<Record<string, number>>;
  };

  return { queryKey, queryFn };
});

export const loadableTeamRadarDataAtom = loadable(
  radarDataAtom(rawTeamRadarDataAtom, teamMetricDataAtom, TEAM_RADAR_TEMPLATES),
);

export const teamMetricsAtom = atom(async get => (await get(metricsAtom)).filter(({ team }) => team));

export const teamMetricDistributionsAtom = atom(async get =>
  (await get(metricDistributionsAtom)).filter(({ team }) => team),
);

export const teamAggsMinutesPlayedAtom = atom(async get => {
  const rawTeamRadarData = (await get(rawTeamRadarDataAtom)).data;
  return rawTeamRadarData.minutes_played || 0;
});

export const unwrappedTeamAggsMinutesPlayedAtom = unwrap(teamAggsMinutesPlayedAtom, prev => prev || 0);
