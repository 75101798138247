import { ReactNode, useState, Children } from 'react';
import { ButtonWithMenu } from '@statsbomb/kitbag-components';
import { useTranslation } from 'react-i18next';

export const MoreOptionsMenu = ({
  children,
  placement = 'bottom-end',
  title,
  ...props
}: {
  children: ReactNode;
  placement?: string;
  title?: string;
  testId?: string;
  disabled?: boolean;
  size?: string;
}) => {
  const { t } = useTranslation('general');
  const [isOpen, setIsOpen] = useState(false);

  const menuTitle = title || t('optionsMenu');

  return (
    <ButtonWithMenu
      icon="More"
      isOpen={isOpen}
      onClick={() => setIsOpen(true)}
      onOutsideClick={() => setIsOpen(false)}
      placement={placement}
      title={menuTitle}
      variant="ghost"
      {...props}
    >
      {Children.map(children, menuItem => (
        <span onClick={() => setIsOpen(false)} role="presentation">
          {menuItem}
        </span>
      ))}
    </ButtonWithMenu>
  );
};

export const MoreOptionsMenuFallback = () => (
  <MoreOptionsMenu disabled>
    <span />
  </MoreOptionsMenu>
);
