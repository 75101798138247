import { hasVideoLicenceAtom } from '@/atoms/user';
import { useAtomValue } from 'jotai';
import { ReactNode, Suspense } from 'react';
import { Navigate } from 'react-router-dom';
import { Loading } from '../translations/Generic';

const ProtectedVideoRouteWrapper = ({ component }: { component: ReactNode }) => {
  const hasVideoLicence = useAtomValue(hasVideoLicenceAtom);

  if (!hasVideoLicence) return <Navigate to="/" replace />;

  return component;
};

export const ProtectedVideoRoute = ({ component }: { component: ReactNode }) => (
  <Suspense fallback={<Loading />}>
    <ProtectedVideoRouteWrapper component={component} />
  </Suspense>
);
