import { Nullable } from '@/types/generic';
import * as Sentry from '@sentry/react';

export const initialHeapSetup = (userId: string, email: Nullable<string | undefined>) => {
  window.heap.identify(userId);
  window.heap.addUserProperties({ email });
};

export const initialSentrySetup = (userId: string, email: Nullable<string | undefined>) => {
  Sentry.setUser({ email: email ?? undefined, id: userId });
};
