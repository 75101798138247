import { useAtom } from 'jotai';
import { isSettingsPanelOpenAtom } from '@/atoms/sidebar';
import { ButtonIcon } from '@statsbomb/kitbag-components';
import { useTranslation } from 'react-i18next';

export const SettingsButton = () => {
  const { t } = useTranslation('visualisation');
  const [isSettingsPanelOpen, setIsSettingsPanelOpen] = useAtom(isSettingsPanelOpenAtom);

  return (
    <span data-testid="settings-button">
      <ButtonIcon
        icon="DisplaySettings"
        title={t('displaySettings')}
        onClick={() => setIsSettingsPanelOpen(!isSettingsPanelOpen)}
        variant="ghost"
      />
    </span>
  );
};
