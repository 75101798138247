import { Typography } from '@statsbomb/kitbag-components';
import { useTranslation } from 'react-i18next';

export const GeneralErrorBoundaryFallback = () => {
  const { t } = useTranslation('general');
  return (
    <Typography data-testid="fallback-error" variant="bodyRegular">
      {t('error')}
    </Typography>
  );
};
