import { EXPORT_TYPE } from '@/consts/exporting';

export type ExportType = keyof typeof EXPORT_TYPE;

export enum ExportContent {
  VIS = 'vis',
  TABLE = 'table',
  VIS_TABLE = 'visTable',
}

export type ExportModalData = {
  visName: string;
  secondaryTitle: string;
  customWidth?: number;
  content?: ExportContent;
};

export type ExportModalProps = Omit<ExportModalData, 'content'>;
