import { selectedBodyPartsAtom, selectedKeeperActionsAtom, selectedPassHeightAtom } from '@/atoms/eventData';
import { maxAgeSelectedAtom, minAgeSelectedAtom } from '@/atoms/filters/dataLocker/ageRange';
import { maxHeightSelectedAtom, minHeightSelectedAtom } from '@/atoms/filters/dataLocker/heightRange';
import { maxMinutesSelectedAtom, minMinutesSelectedAtom } from '@/atoms/filters/dataLocker/minMinutes';
import { selectedPreferredFootAtom } from '@/atoms/filters/dataLocker/preferredFoot';
import { selectedEventNamesAtom, selectedOutcomesAtom } from '@/atoms/filters/events';
import { selectedGameTypeAtom } from '@/atoms/filters/gameMetrics';
import {
  deselectedGamesAtom,
  selectedEndDateAtom,
  selectedGameRangeAtom,
  selectedPeriodRangeAtom,
  selectedStartDateAtom,
} from '@/atoms/filters/highLevel';
import { selectedCompetitionIdsAtom } from '@/atoms/filters/highLevel/competitions';
import { selectedPlayerIdsAtom } from '@/atoms/filters/highLevel/players';
import { selectedPositionsAtom } from '@/atoms/filters/highLevel/positions';
import { selectedSeasonIdsAtom } from '@/atoms/filters/highLevel/seasons';
import { selectedTeamIdsAtom } from '@/atoms/filters/highLevel/teams';
import { selectedPitchFilterEndZonesAtom, selectedPitchFilterStartZonesAtom } from '@/atoms/filters/pitchFilter';
import { metricKeyAtom } from '@/atoms/metric';
import { widgetGenderAtom } from '@/atoms/queries/userConfigs';
import { radarTemplateIdAtom } from '@/atoms/radar';
import {
  isStartPlacementAtom,
  pitchCropAtom,
  pitchOrientationReportWidgetAtom,
  showArrowsAtom,
} from '@/atoms/visualisation';
import { PITCH_CROP, PITCH_ORIENTATION } from '@/consts/visualisations';
import {
  ConfigDisplaySettingAtomHydrateCallbacks,
  ConfigDisplaySettingAtoms,
  ConfigEventFilterAtomHydrateCallbacks,
  ConfigEventFilterAtoms,
  ConfigFilterAtomHydrateCallbacks,
  ConfigFilterAtoms,
  ConfigGameFilterAtomHydrateCallbacks,
  ConfigGameFilterAtoms,
  ConfigPlayerFilterAtomHydrateCallbacks,
  ConfigPlayerFilterAtoms,
} from '@/types/userConfigs';
import { PitchCrop, PitchOrientation } from '@/types/visualisation';
import { convertFiltersToOption } from '@/utils/array';
import { CustomShape } from '@statsbomb/kitbag-datavis';
import { Gender } from '@statsbomb/parachute-types';
import { gameTypeOptions } from './gameMetrics';
import { GAME_RANGE_FILTER_OPTIONS, PERIOD_RANGE_OPTIONS } from './gameRange';

const gameFilterAtoms: ConfigGameFilterAtoms = {
  periodRange: selectedPeriodRangeAtom,
  gameRange: selectedGameRangeAtom,
  startDate: selectedStartDateAtom,
  endDate: selectedEndDateAtom,
  gameType: selectedGameTypeAtom,
  deselectedGameIds: deselectedGamesAtom,
};

const eventFilterAtoms: ConfigEventFilterAtoms = {
  eventNames: selectedEventNamesAtom,
  outcomes: selectedOutcomesAtom,
  bodyParts: selectedBodyPartsAtom,
  passHeights: selectedPassHeightAtom,
  keeperActions: selectedKeeperActionsAtom,
  pitchStartZones: selectedPitchFilterStartZonesAtom,
  pitchEndZones: selectedPitchFilterEndZonesAtom,
};

const playerFilterAtoms: ConfigPlayerFilterAtoms = {
  minAge: minAgeSelectedAtom,
  maxAge: maxAgeSelectedAtom,
  minHeight: minHeightSelectedAtom,
  maxHeight: maxHeightSelectedAtom,
  minMinutes: minMinutesSelectedAtom,
  maxMinutes: maxMinutesSelectedAtom,
  preferredFoot: selectedPreferredFootAtom,
};

/**
 * This is a function as making it a variable results in this moaning about trying
 * to access selectedCompetitionIdsAtom before initialisation and I cannot work out why!
 */
export const filterAtomsToHydrate = (): ConfigFilterAtoms => ({
  competitionIds: selectedCompetitionIdsAtom,
  seasonIds: selectedSeasonIdsAtom,
  teamIds: selectedTeamIdsAtom,
  playerIds: selectedPlayerIdsAtom,

  positions: selectedPositionsAtom,

  metric: metricKeyAtom,
  gender: widgetGenderAtom,

  ...gameFilterAtoms,
  ...eventFilterAtoms,
  ...playerFilterAtoms,
});

const gameFilterAtomHydrateCallbacks: ConfigGameFilterAtomHydrateCallbacks = {
  periodRange: ({ value }: { value: number }) => PERIOD_RANGE_OPTIONS.find(option => option.value === value)!,
  gameRange: (value: string) =>
    GAME_RANGE_FILTER_OPTIONS.find(option => option.key === value) || GAME_RANGE_FILTER_OPTIONS[0],
  startDate: (value: string) => value,
  endDate: (value: string) => value,
  gameType: (value: string) => gameTypeOptions.find(option => option.value === value)!,
  deselectedGameIds: (value: number[]) => convertFiltersToOption(value),
};

const eventFilterAtomHydrateCallbacks: ConfigEventFilterAtomHydrateCallbacks = {
  eventNames: (value: string[]) => value,
  outcomes: (value: string[]) => value,
  bodyParts: (value: string[]) => value,
  passHeights: (value: string[]) => value,
  keeperActions: (value: string[]) => value,
  pitchStartZones: (value: CustomShape[]) => value,
  pitchEndZones: (value: CustomShape[]) => value,
};

const playerFilterAtomHydrateCallbacks: ConfigPlayerFilterAtomHydrateCallbacks = {
  minAge: (value: number) => value,
  maxAge: (value: number) => value,
  minHeight: (value: number) => value,
  maxHeight: (value: number) => value,
  minMinutes: (value: number) => value,
  maxMinutes: (value: number) => value,
  preferredFoot: (value: string[]) => value,
};

export const filterAtomsToHydrateCallback: ConfigFilterAtomHydrateCallbacks = {
  competitionIds: (value: number[]) => value,
  seasonIds: (value: number[] | null) => value,
  teamIds: (value: number[]) => value,
  playerIds: (value: number[]) => value,

  positions: (value: string[]) => value,

  metric: (value: string) => value,
  gender: (value: Gender) => value,

  ...gameFilterAtomHydrateCallbacks,
  ...eventFilterAtomHydrateCallbacks,
  ...playerFilterAtomHydrateCallbacks,
};

/**
 * This one doesn't technically need to be a function, but it felt more consistent
 * to make it one since I had to change the filter atom equivalent thing a function
 */
export const displayAtomsToHydrate = (): ConfigDisplaySettingAtoms => ({
  radarTemplateId: radarTemplateIdAtom,
  pitchOrientation: pitchOrientationReportWidgetAtom,
  pitchCrop: pitchCropAtom,
  showArrows: showArrowsAtom,
  isStartPlacement: isStartPlacementAtom,
});

export const displayAtomsToHydrateCallback: ConfigDisplaySettingAtomHydrateCallbacks = {
  radarTemplateId: (value: number) => value,
  pitchOrientation: (value: PitchOrientation) => PITCH_ORIENTATION.find(orientation => orientation === value)!,
  pitchCrop: (value: PitchCrop) => PITCH_CROP.find(crop => crop === value)!,
  showArrows: (value: boolean) => value,
  isStartPlacement: (value: boolean) => value,
};
