import { toastDataAtom } from '@/atoms/toast';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { widgetDataToDeleteAtom } from '@/atoms/report/widget';
import { useParams } from 'react-router-dom';
import { unwrappedReportWidgetsToRenderAtom } from '@/atoms/report/reportSaving';
import { patchUserConfigAtom } from '@/atoms/mutations/userConfigs/userConfigs';
import { GenericDeleteModal } from '../GenericDeleteModal/GenericDeleteModal';

const DeleteWidgetModalWithData = () => {
  const { t } = useTranslation('widget');
  const { reportId } = useParams();

  const { mutateAsync: patchUserConfig } = useAtomValue(patchUserConfigAtom);
  const setToastData = useSetAtom(toastDataAtom);
  const reportWidgets = useAtomValue(unwrappedReportWidgetsToRenderAtom);
  const [{ widgetId, widgetName }, setWidgetDataToDelete] = useAtom(widgetDataToDeleteAtom);

  const resetAtoms = () => {
    setWidgetDataToDelete({
      widgetId: null,
      widgetName: '',
    });
  };

  const deleteWidget = async () => {
    const updatedReport = reportWidgets.filter(widget => widget.widgetId !== widgetId);

    /* you can't open this without being on a report
    as it requires clicking on a widget menu to open */
    /* istanbul ignore else */
    if (reportId) {
      const { isSuccess } = await patchUserConfig([
        { configId: reportId, configDefinition: { widgets: updatedReport } },
      ]);

      setToastData('deleteWidget', isSuccess, widgetName);
      resetAtoms();
    }
  };

  return (
    <GenericDeleteModal
      id="delete-widget-modal"
      itemToDelete={widgetName}
      itemType={t('widget')}
      onConfirm={deleteWidget}
      onCancel={resetAtoms}
    />
  );
};

export const DeleteWidgetModal = () => {
  const { widgetId } = useAtomValue(widgetDataToDeleteAtom);
  return widgetId && <DeleteWidgetModalWithData />;
};
