import { useTranslation } from 'react-i18next';
import { Accordion } from '../accordion/Accordion';
import { AgeRangeFilter } from './AgeRangeFilter';
import { MinutesPlayedFilter } from './MinutesPlayedFilter';
import { HeightRangeFilter } from './HeightRangeFilter';
import { PreferredFootCheckboxGroup } from '../checkboxGroups/PreferredFootCheckboxGroup';
import { PitchPositionFilter } from './PitchPositionFilter';

export const PlayerAggsGeneralFilters = () => {
  const { t } = useTranslation('entity');

  return (
    <Accordion title={t('player.one')} isDefaultExpanded>
      <div className="flex flex-col gap-y-4">
        <PitchPositionFilter />
        <AgeRangeFilter />
        <HeightRangeFilter />
        <MinutesPlayedFilter />
        <PreferredFootCheckboxGroup />
      </div>
    </Accordion>
  );
};
