import { NestedObject } from '@/types/object';
import { Entity } from './entity';
import { ItemsPerPage } from './table';

export interface BaseOption<T> {
  label: string;
  value: T;
  optional?: {};
}

export type Option = BaseOption<number>;
export type StringOption = BaseOption<string>;
export type ItemsPerPageOption = BaseOption<ItemsPerPage>;

export type SBID = string | number;

export type Nullable<T> = T | null;
export type ValueOf<T> = T[keyof T];

export interface LinkObject extends Record<string, string | number> {
  id: SBID;
  label: string;
}

export const isLinkObject = (value: NestedObject[string]): value is LinkObject => {
  const linkObject = value as LinkObject;

  return linkObject?.id !== undefined && linkObject?.label !== undefined;
};

export type NonUndefined<T> = T extends undefined ? never : T;

type CamelCase<S extends string> = S extends `${infer P1}_${infer P2}${infer P3}`
  ? `${Lowercase<P1>}${Uppercase<P2>}${CamelCase<P3>}`
  : Lowercase<S>;

/**
 * Adding a check for arrays as when KeysToCamelCase is used on arrays it
 * treats the array as an object. This means typescript does not know it
 * can use the methods/properties that belong to arrays (map, filter, some, etc.).
 *
 * KeysToCamelCase takes a generic value and checks if it is an array through extending.
 * If it is an array, it then recursively goes through the values of the array and
 * runs the KeysToCamelCase on R (the values of the array).
 */
export type KeysToCamelCase<T> = {
  [K in keyof T as CamelCase<string & K>]: T[K] extends Array<infer R>
    ? Array<KeysToCamelCase<R>>
    : T[K] extends {}
    ? KeysToCamelCase<T[K]>
    : T[K];
};

export type Section = Entity | 'dataLocker';
