import { playerPositionsOptionsAtom } from '@/atoms/queries/players';
import { filterAtomDefaults } from '@/consts/defaults/filterAtomDefaults';
import { HighLevelFilterMapAtom, PositionOption } from '@/types/filters';
import { Position } from '@/types/player';
import { convertFiltersToOption } from '@/utils/array';
import { getOptionsAtomForSection } from '@/utils/atoms/highLevelFilters';
import { atom } from 'jotai';
import { unwrap } from 'jotai/utils';

/* list of all positions as required by the data locker pitch position selector */
const dataLockerPositionsOptionsAtom = atom(async () => convertFiltersToOption(Object.values(Position)));

const positionSectionMapAtom = atom<HighLevelFilterMapAtom<PositionOption[]>>(() => ({
  player: playerPositionsOptionsAtom,
  dataLocker: dataLockerPositionsOptionsAtom,
}));

export const positionOptionsAtom = atom(getOptionsAtomForSection(positionSectionMapAtom));

export const selectedPositionsAtom = atom(filterAtomDefaults.positions);
export const selectedPositionsFilterParamsAtom = atom(get => {
  const selectedPositions = get(selectedPositionsAtom);
  const selectedPositionsValues = selectedPositions.reduce((acc, id) => {
    const splitId = id.split(',');
    if (splitId.length > 1) {
      return [...acc, ...splitId];
    }

    return [...acc, id];
  }, [] as string[]);

  return selectedPositionsValues;
});

const unwrappedPositionOptions = unwrap(positionOptionsAtom, prev => prev || []);

export const selectedPositionOptionsAtom = atom(get => {
  const positionOptions = get(unwrappedPositionOptions);
  const selectedPositions = get(selectedPositionsAtom);

  return positionOptions.filter(({ value }) => selectedPositions.includes(value));
});

export const unwrappedSelectedPositionOptionsAtom = unwrap(selectedPositionOptionsAtom, prev => prev || []);
