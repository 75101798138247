import { ReactNode } from 'react';

import { PlayerHighLevelFilters } from '@/components/player/filters/PlayerHighLevelFilters';
import { LayoutWithFilters } from '../LayoutWithFilters';

export const PlayerLayoutWithFilters = ({
  children,
  hasNoBgContent = false,
}: {
  children: ReactNode;
  hasNoBgContent?: boolean;
}) => (
  <LayoutWithFilters hasNoBgContent={hasNoBgContent} filters={<PlayerHighLevelFilters />}>
    {children}
  </LayoutWithFilters>
);
