import { ButtonIcon } from '@statsbomb/kitbag-components';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

export const ExpandVideoButton = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { t } = useTranslation(['video', 'general']);
  const btnLabel = t('expandVideoPlayer', { player: t('player') });

  return (
    <div className="flex justify-end flex-grow">
      <ButtonIcon
        variant="ghost"
        onClick={() => {
          navigate(`..?${searchParams}`);
        }}
        icon="DoubleChevronRight"
        title={btnLabel}
      >
        {btnLabel}
      </ButtonIcon>
    </div>
  );
};
