export const BODY_PARTS = [
  'left_foot',
  'right_foot',
  'head',
  'chest',
  'left_hand',
  'right_hand',
  'keeper_arm',
  'both_hands',
  'other',
];
