import { useSuccessFailColouring } from '@/hooks/useSuccessFailColouring';
import { ColouredArrowMarkers } from '@statsbomb/kitbag-datavis';
import { useTranslation } from 'react-i18next';

export const EventMapArrowMarkerLegend = () => {
  const { t } = useTranslation('visualisation');
  const successFailColouring = useSuccessFailColouring();

  return (
    <ColouredArrowMarkers
      title={t('markerColouring.passSuccess')}
      arrowMarkers={[
        { label: t('passSuccess.success'), strokeColour: successFailColouring.success, arrowHead: 'EQUILATERAL' },
        { label: t('passSuccess.fail'), strokeColour: successFailColouring.fail, arrowHead: 'EQUILATERAL' },
        { label: t('passSuccess.neutral'), strokeColour: successFailColouring.neutral, arrowHead: 'EQUILATERAL' },
      ]}
    />
  );
};
