import { PeriodRange } from '@/types/game';
import { GameRangeFilterOption } from '@/types/filters';

export const GAME_RANGE_FILTER_OPTIONS: GameRangeFilterOption[] = [
  { key: 'period', translation: { key: 'period', ns: 'filters' } },
  { key: 'dateRange', translation: { key: 'dateRange', ns: 'filters' } },
];

export const PERIOD_RANGE_OPTIONS: PeriodRange[] = [
  // A valid number that is greater than the maximum number of games that we're ever likely to have.
  { translation: { key: 'allGames', ns: 'filters' }, value: Number.MAX_SAFE_INTEGER },
  { translation: { key: 'lastXGames', count: 5, ns: 'filters' }, value: 5 },
  { translation: { key: 'lastXGames', count: 10, ns: 'filters' }, value: 10 },
  { translation: { key: 'lastXGames', count: 20, ns: 'filters' }, value: 20 },
];
