import { selectedOutcomesAtom } from '@/atoms/filters/events';
import { eventOutcomesAtom } from '@/atoms/queries/eventData';
import { StringOption } from '@/types/generic';
import { convertOptionsToValues, sortAscArrayOptions } from '@/utils/array';
import { snakeToCamel } from '@/utils/queries';
import { Dropdown } from '@statsbomb/kitbag-components';
import { useAtom, useAtomValue } from 'jotai';
import { Suspense, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const OutcomeDropdownBase = ({
  eventOutcomes = [],
  isLoading = false,
}: {
  eventOutcomes?: string[];
  isLoading?: boolean;
}) => {
  const { t } = useTranslation(['events', 'filters', 'general']);
  const [selectedOutcomes, setSelectedOutcomes] = useAtom(selectedOutcomesAtom);

  const translatedOptions = sortAscArrayOptions(
    eventOutcomes.map(key => ({ value: key, label: t(`fields.event.attributes.outcome.${snakeToCamel(key)}`) })),
  );

  useEffect(() => {
    if (!isLoading) {
      const newSelectedOutcomes = selectedOutcomes.filter(outcome => eventOutcomes.includes(outcome));
      setSelectedOutcomes(newSelectedOutcomes);
    }
  }, [JSON.stringify(eventOutcomes)]);

  return (
    <Dropdown
      isLoading={isLoading}
      id="outcome-dropdown"
      labelPosition="top"
      label={t('typeFilter.outcomeSelector', { ns: 'filters' })}
      options={translatedOptions}
      isMulti
      value={translatedOptions.filter(option => selectedOutcomes.includes(option.value))}
      onChange={(outcomes: StringOption[]) => setSelectedOutcomes(convertOptionsToValues(outcomes))}
      placeholder={t('dropdownPlaceholder', { ns: 'filters' })}
      selectedChipText={t('selectedChipText', { ns: 'general' })}
      noOptionsText={t('noOptions', { ns: 'general' })}
    />
  );
};

export const OutcomeDropdownWithData = () => {
  const eventOutcomes = useAtomValue(eventOutcomesAtom);

  return <OutcomeDropdownBase eventOutcomes={eventOutcomes} />;
};

export const OutcomeDropdown = () => (
  <Suspense fallback={<OutcomeDropdownBase isLoading />}>
    <OutcomeDropdownWithData />
  </Suspense>
);
