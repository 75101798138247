import { ReactNode } from 'react';

export const SectionTopBar = ({ children }: { children: ReactNode }) => (
  <header
    className="bg-canvas-tertiary-main mb-4 -mx-4 px-4 border-canvas-primary-main
      border-b border-opacity-50 py-2 relative z-10 -mt-4 md:h-12 
      [&:has(+.top-bar-hr)]:border-0 content-center"
  >
    <div className="flex items-center gap-2 flex-wrap">{children}</div>
  </header>
);
