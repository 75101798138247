import { hasVideoLicenceAtom } from '@/atoms/user';
import { useAtomValue } from 'jotai';
import { ReactNode, Suspense } from 'react';

const HasVideoAccessWrapper = ({ children }: { children: ReactNode }) => {
  const hasVideoLicence = useAtomValue(hasVideoLicenceAtom);

  if (!hasVideoLicence) return null;

  return children;
};

export const HasVideoAccess = ({ children }: { children: ReactNode }) => (
  <Suspense>
    <HasVideoAccessWrapper>{children}</HasVideoAccessWrapper>
  </Suspense>
);
