import { selectedGameTypeAtom } from '@/atoms/filters/gameMetrics';
import { gameTypeOptions } from '@/consts/gameMetrics';
import { Dropdown } from '@statsbomb/kitbag-components';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { GameType } from '@/types/filters';
import { getTranslatedOption, getTranslatedOptions } from '@/utils/translations';
import { BaseOption } from '@/types/generic';

export const GameTypeFilter = () => {
  const { t } = useTranslation('filters');
  const [selectedGameType, setSelectedGameType] = useAtom(selectedGameTypeAtom);

  return (
    <Dropdown
      value={getTranslatedOption(selectedGameType)}
      id="game-type-multi-select"
      onChange={(gameType: BaseOption<GameType>) =>
        setSelectedGameType(gameTypeOptions.find(option => option.value === gameType.value)!)
      }
      label={t('gameType')}
      isSearchable={false}
      options={getTranslatedOptions(gameTypeOptions)}
    />
  );
};
