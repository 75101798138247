import { Table, Typography } from '@statsbomb/kitbag-components';
import { useAtomValue } from 'jotai';
import { useDecimalPlaces } from '@/hooks/useDecimalPlaces';
import { snakeToCamel } from '@/utils/queries';
import { useTranslation } from 'react-i18next';
import { Link, useSearchParams } from 'react-router-dom';
import { Spoke } from '@statsbomb/kitbag-datavis';
import { renderedRadarDataAtom } from '@/atoms/radar';
import { useUpdateQueryString } from '@/hooks/useUpdateQueryString';
import { METRIC_KEY } from '@/consts/searchKeys';
import { useHasEmptyRadar } from '@/hooks/useHasEmptyRadar';
import { TableCell } from '../../tables/TableCell';
import { Loading } from '../../translations/Generic';

const MetricName = ({ metricKey }: { metricKey: string }) => {
  const { t } = useTranslation('metrics');
  const [searchParams] = useSearchParams();
  const createQueryString = useUpdateQueryString(searchParams);

  return (
    <Typography
      as={Link}
      variant="linkMedium"
      to={`./preview?${createQueryString([{ key: METRIC_KEY, value: metricKey }])}`}
    >
      {t(`${snakeToCamel(metricKey)}.name`)}
    </Typography>
  );
};

export const RadarTable = () => {
  const renderedRadarData = useAtomValue(renderedRadarDataAtom);
  const formatNumberDecimals = useDecimalPlaces();
  const hasEmptyRadar = useHasEmptyRadar();

  if (!renderedRadarData.length) return <Loading />;

  return (
    <Table>
      <Table.Head>
        <Table.Row>
          <Table.HeaderCell size="regular" colSpan="2" className="w-[40%]" />
          <Table.HeaderCell size="regular">Value</Table.HeaderCell>
        </Table.Row>
      </Table.Head>
      <Table.Body>
        {renderedRadarData.map(({ key, value, decimalPlaces }, index) => (
          <Table.Row key={key}>
            <TableCell>
              <div className="py-2 w-[20px]">
                <Spoke numberOfSpokes={renderedRadarData.length} spokeIndex={index} />
              </div>
            </TableCell>
            <TableCell textAlign="right">
              <MetricName metricKey={key} />
            </TableCell>
            <TableCell>{!hasEmptyRadar ? formatNumberDecimals(decimalPlaces, value) : '-'}</TableCell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};
