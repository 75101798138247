import { Grid } from '@statsbomb/kitbag-components';
import { useTranslation } from 'react-i18next';
import { TableDataControls } from '@/components/controls/TableDataControls';
import { LayoutWithFilters } from '@/components/layout/LayoutWithFilters';
import { PlayerAggsTableWithPagination } from '@/components/tables/PlayerAggsTable';
import { PlayerAggsFilters } from '@/components/dataLocker/PlayerAggsFilters';
import { TableScopeProvider } from '@/components/tables/TableScopeProvider';
import { PageTitle } from '@/components/head/PageTitle';
import { playerAggsFilterParamsAtom } from '@/atoms/queries/player/playerAggData';
import { useAtomValue } from 'jotai';
import { playersNormalisedAggregatesUrl } from '@/query/url';
import { useTableColumns } from '@/hooks/useTableColumns';
import { ScopeProvider } from 'jotai-scope';
import { tableColumnEntitiesAtom } from '@/atoms/queries/tableColumns';
import { useCsvDownload } from '@/hooks/exporting/useCsvDownload';
import { playerDataDefaultColumnsAtom, playerDataVisibleColumnsAtom } from '@/atoms/dataLocker/player/table';

const TableWithControls = ({ pageTitle }: { pageTitle: string }) => {
  const { t } = useTranslation(['navigation']);
  useTableColumns(['player', 'player_metric']);
  const playerAggsFilterParams = useAtomValue(playerAggsFilterParamsAtom);
  useCsvDownload(pageTitle, playersNormalisedAggregatesUrl(playerAggsFilterParams));

  return (
    <>
      <TableDataControls
        visibleColumnsAtom={playerDataVisibleColumnsAtom}
        defaultColumnsAtom={playerDataDefaultColumnsAtom}
        heading={t(`viewing.table`, { ns: 'visualisation' })}
      />
      <TableScopeProvider>
        <PlayerAggsTableWithPagination />
      </TableScopeProvider>
    </>
  );
};

export const PlayerAggsPage = () => {
  const { t } = useTranslation(['navigation']);
  const pageTitle = t('pageTitles.dataLocker', {
    entity: t('player.one', { ns: 'entity' }),
  });

  return (
    <ScopeProvider atoms={[tableColumnEntitiesAtom]}>
      <PageTitle>{pageTitle}</PageTitle>
      <LayoutWithFilters filters={<PlayerAggsFilters />}>
        <Grid>
          <Grid item>
            <TableWithControls pageTitle={pageTitle} />
          </Grid>
        </Grid>
      </LayoutWithFilters>
    </ScopeProvider>
  );
};
