import { useAtomValue } from 'jotai';
import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { UserDetails } from '@statsbomb/parachute-types';
import { ErrorBoundary } from '@sentry/react';
import { Card, CardBody } from '@statsbomb/kitbag-components';
import { Loading } from '@/components/translations/Generic';
import { AppShell } from '@/components/layout/AppShell';
import { EmptyTopBar } from '@/components/topBar/TopBar';
import { EmptySideNav } from '@/components/navigation/SideNav';
import { isValidEntityUserAtom } from '@/atoms/user';
import { CardHeightWrapper } from './card/CardHeightWrapper';

export const ErrorPage = ({ errorType, userDetails }: { errorType: string; userDetails?: UserDetails | null }) => {
  const { t } = useTranslation('error');
  let errorMessage = t(`${errorType}.message`);

  if (userDetails && userDetails.entity.start_date)
    errorMessage = errorMessage.replaceAll('{{start_date}}', userDetails.entity.start_date);

  if (userDetails && userDetails.entity.end_date)
    errorMessage = errorMessage.replaceAll('{{end_date}}', userDetails.entity.end_date);

  return (
    <AppShell topBar={<EmptyTopBar />} sideNav={<EmptySideNav />}>
      <Card>
        <CardHeightWrapper>
          <CardBody statusDescription={errorMessage} statusTitle={t(`${errorType}.title`)} statusType="error" />
        </CardHeightWrapper>
      </Card>
    </AppShell>
  );
};

const ShowErrorWithData = ({ children }: { children: React.ReactNode }) => {
  const { valid, error, userDetails } = useAtomValue(isValidEntityUserAtom);
  return valid ? children : <ErrorPage errorType={error} userDetails={userDetails} />;
};

export const HandleInvalidEntityUser = ({ children }: { children: React.ReactNode }) => (
  <Suspense fallback={<Loading />}>
    {/* This is here so that if the error page itself errors the app runs as usual. e.g. if the user has logged out */}
    {/* It doesn't work without the fragment. */}
    {/* eslint-disable-next-line react/jsx-no-useless-fragment */}
    <ErrorBoundary fallback={<>{children}</>}>
      <ShowErrorWithData>{children}</ShowErrorWithData>
    </ErrorBoundary>
  </Suspense>
);
