import { defaultSeasonsAtom } from '@/atoms/filters/highLevel/seasons';
import { filterStateHistoryAtom, filtersToPersistAtom } from '@/atoms/filters/persistedFilters';
import { useAtomValue, useSetAtom } from 'jotai';
import { useLocation } from 'react-router-dom';
import { useDeepEffect } from './useDeepEffect';

export const useFilterStateHistory = () => {
  const setFilterStateHistory = useSetAtom(filterStateHistoryAtom);
  const filtersToPersist = useAtomValue(filtersToPersistAtom);
  const defaultSeasons = useAtomValue(defaultSeasonsAtom);
  const { pathname } = useLocation();

  useDeepEffect(() => {
    /* don't set anything until we've awaited the default season being set */
    if (!defaultSeasons) return;

    setFilterStateHistory(pathname);

    /*
      checking the stringified version of this
      so we don't have to check every single item in the object
    */
  }, [pathname, filtersToPersist]);
};
