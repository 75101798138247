import {
  competitionOptionsAtom,
  selectedCompetitionIdsAtom,
  selectedCompetitionsAtom,
} from '@/atoms/filters/highLevel/competitions';
import { FilterDropdown } from '@/components/dropdowns/FilterDropdown';
import { SelectorProps } from '@/types/filters';
import { Option } from '@/types/generic';
import { convertOptionsToValues } from '@/utils/array';
import { useAtom, useAtomValue } from 'jotai';
import { Suspense, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const CompetitionSelectorBase = ({ options = [], isLoading }: SelectorProps) => {
  const { t } = useTranslation(['filters', 'entity']);
  const selectedCompetitions = useAtomValue(selectedCompetitionsAtom);
  const [selectedCompetitionIds, setSelectedCompetitionIds] = useAtom(selectedCompetitionIdsAtom);
  const optionValues = options?.map(({ value }) => value);

  useEffect(() => {
    if (!isLoading && selectedCompetitionIds) {
      const newSelectedCompetitionIds = selectedCompetitionIds.filter(
        competitionId => optionValues?.includes(competitionId),
      );
      setSelectedCompetitionIds(newSelectedCompetitionIds);
    }
  }, [JSON.stringify(optionValues)]);

  return (
    <FilterDropdown
      id="competition-multi-select"
      label={t('competition.one', { ns: 'entity' })}
      selectedOption={selectedCompetitions}
      options={options}
      onChange={(options: Option[]) => setSelectedCompetitionIds(convertOptionsToValues(options))}
      placeholder={t('dropdownPlaceholder')}
      isLoading={isLoading}
    />
  );
};

const CompetitionSelectorWithData = () => {
  const competitionOptions = useAtomValue(competitionOptionsAtom);
  return <CompetitionSelectorBase options={competitionOptions} />;
};

export const CompetitionSelector = () => (
  <Suspense fallback={<CompetitionSelectorBase isLoading />}>
    <CompetitionSelectorWithData />
  </Suspense>
);
