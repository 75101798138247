import { PitchHeatmapLayer, PitchViz, Legend, GradientBar } from '@statsbomb/kitbag-datavis';
import { useAtomValue } from 'jotai';
import { VisEvent } from '@/types/event';
import { HeatMapProps } from '@/types/visualisation';
import { PITCH_HEIGHT, PITCH_WIDTH, SCALE_FACTOR } from '@/consts/HeatMap';
import { loadable } from 'jotai/utils';
import { useGetPitchRotation } from '@/hooks/useGetPitchRotation';
import { usePitchCrop } from '@/hooks/usePitchCrop';
import { showLegendAtom, showMarkerStartAtom } from '@/atoms/visualisation';
import { isNullish } from '@/utils/general';
import { useRect } from '@statsbomb/kitbag-components';
import { useTranslation } from 'react-i18next';
import { FallbackNoData } from './FallbackNoData';
import { FallbackLoading } from './FallbackLoading';

const HeatMapBase = ({ eventsForMetric = [] }: { eventsForMetric?: VisEvent[] }) => {
  const pitchRotation = useGetPitchRotation(false);
  const visibleArea = usePitchCrop(false);
  const showMarkerStart = useAtomValue(showMarkerStartAtom);
  const { t } = useTranslation('visualisation');
  const [legendRef, legendRect] = useRect<HTMLLegendElement>();
  const showLegend = useAtomValue(showLegendAtom);

  return (
    <figure className="h-full flex flex-col gap-2">
      <PitchViz
        className="flex-1"
        rotationName={pitchRotation}
        pitchFocusZoneName={visibleArea}
        underlay={
          <PitchHeatmapLayer
            data={eventsForMetric.map(({ startX, startY, endX, endY }) => {
              const x = showMarkerStart || isNullish(endX) ? startX : endX;
              const y = showMarkerStart || isNullish(endY) ? startY : endY;

              return {
                x,
                y,
              };
            })}
            width={PITCH_WIDTH}
            height={PITCH_HEIGHT}
            scaleFactor={SCALE_FACTOR}
          />
        }
      />
      {showLegend && (
        <Legend legendRef={legendRef}>
          <GradientBar
            colSpan={2}
            title={t('legend.heatMap.title')}
            minText={t('legend.heatMap.minText')}
            maxText={t('legend.heatMap.maxText')}
            legendWidth={legendRect.width}
          />
        </Legend>
      )}
    </figure>
  );
};

const HeatMapWithData = ({ pitchEventsAtom }: HeatMapProps) => {
  const { markerEvents, arrowEvents } = useAtomValue(pitchEventsAtom);
  const eventsForMetric = [...markerEvents, ...arrowEvents];

  if (eventsForMetric.length === 0) return <FallbackNoData />;

  return (
    <div className="h-full w-full" data-testid="heatmap-with-data">
      <HeatMapBase eventsForMetric={eventsForMetric} />
    </div>
  );
};

export const HeatMap = ({ pitchEventsAtom }: HeatMapProps) => {
  const loadablePitchEventsAtom = useAtomValue(loadable(pitchEventsAtom));
  return loadablePitchEventsAtom.state === 'loading' ? (
    <FallbackLoading />
  ) : (
    <HeatMapWithData pitchEventsAtom={pitchEventsAtom} />
  );
};
