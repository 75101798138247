import { EVENTS_DATA_LOCKER_DEFAULT_COLUMNS } from '@/consts/dataLocker/events';
import { atom } from 'jotai';
import { unwrap } from 'jotai/utils';
import { getReadWriteVisibleColumnsAtom } from '../utils';

export const eventsDataVisibleColumnsUserSelectedAtom = atom<string[] | undefined>(undefined);

export const eventsDataDefaultColumnsAtom = atom(async () => [...EVENTS_DATA_LOCKER_DEFAULT_COLUMNS]);

export const eventsDataVisibleColumnsAtom = getReadWriteVisibleColumnsAtom(
  eventsDataVisibleColumnsUserSelectedAtom,
  eventsDataDefaultColumnsAtom,
);

export const unwrappedEventsDataVisibleColumnsAtom = unwrap(eventsDataVisibleColumnsAtom, prev => prev || []);
