import { PLAYER_AGGS_FILTER_ATOMS } from '@/consts/dataLocker/player';
import { useSetDataLockerDebouncedFilterParams } from '@/hooks/apiFilterParams/useSetDataLockerDebouncedFilterParams';
import { PlayerAggsGeneralFilters } from '../filters/PlayerAggsGeneralFilters';
import { DataLockerHighLevelFilters } from './filters/DataLockerHighLevelFilters';
import { UserFilters } from '../filters/UserFilters/UserFilters';

export const PlayerAggsFilters = () => {
  useSetDataLockerDebouncedFilterParams(PLAYER_AGGS_FILTER_ATOMS);

  return (
    <div className="flex flex-col">
      <UserFilters />
      <DataLockerHighLevelFilters />
      <PlayerAggsGeneralFilters />
    </div>
  );
};
