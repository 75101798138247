import { teamEventMetricFiltersAtom } from '@/atoms/queries/team/teamEventData';
import { selectedTeamNameAtom } from '@/atoms/team/info';
import { teamIdAtom } from '@/atoms/team/team';
import { TeamNav } from '@/components/navigation/TeamNav';
import { TableScopeProvider } from '@/components/tables/TableScopeProvider';
import { EntityTopBar } from '@/components/topBar/EntityTopBar';
import { layoutScopeAtoms } from '@/consts/scope';
import { useAutoResetPagination } from '@/hooks/useAutoResetPagination';
import { useAutoResetSelectionLayer } from '@/hooks/useAutoResetSelectionLayer';
import { useSetMetricKey } from '@/hooks/useMetricKey';
import { useSetTeamId } from '@/hooks/useTeamId';
import { useAtomValue } from 'jotai';
import { ScopeProvider } from 'jotai-scope';
import { Outlet } from 'react-router-dom';
import { ContentState } from '@/components/contentState/ContentState';
import { StatusTranslationKey } from '@/types/status';
import { AppShell } from '../AppShell';
import { SectionWrapper } from '../SectionWrapper';

const TeamLayoutInner = () => {
  const teamEventMetricFilters = useAtomValue(teamEventMetricFiltersAtom);
  useAutoResetSelectionLayer(teamEventMetricFilters);
  useAutoResetPagination(teamEventMetricFilters);

  return (
    <SectionWrapper section="team">
      <Outlet />
    </SectionWrapper>
  );
};

const TeamLayoutWithData = () => {
  useSetTeamId();
  useSetMetricKey();
  const teamId = useAtomValue(teamIdAtom);

  return (
    <AppShell topBar={<EntityTopBar selectedEntityNameAtom={selectedTeamNameAtom} />} subnav={<TeamNav />}>
      <TableScopeProvider>
        {teamId ? <TeamLayoutInner /> : <ContentState status={StatusTranslationKey.LOADING} />}
      </TableScopeProvider>
    </AppShell>
  );
};

export const TeamLayout = () => (
  <ScopeProvider atoms={layoutScopeAtoms}>
    <TeamLayoutWithData />
  </ScopeProvider>
);
