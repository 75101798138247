import { seasonOptionsAtom, selectedSeasonsAtom } from '@/atoms/filters/highLevel/seasons';
import { Atom, useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';
import { GamesFilterType } from '@/hooks/useGetGamesByFilter';
import { FormattedGame } from '@/types/game';
import { Option } from '@/types/generic';
import { useGetFilteredOptions } from '@/hooks/useGetFilteredOptions';
import { RadarFilterSummaryItem } from './RadarFilterSummaryItem';

export const RadarFilterSeasonSummary = ({
  filteredGamesDataAtom,
  selectedGamesAtom,
}: {
  filteredGamesDataAtom: Atom<Promise<FormattedGame[]>>;
  selectedGamesAtom: Atom<Promise<Option[]>>;
}) => {
  const { t } = useTranslation('entity');
  const filteredCompetitionOptions = useGetFilteredOptions(
    seasonOptionsAtom,
    filteredGamesDataAtom,
    selectedGamesAtom,
    GamesFilterType.SEASON,
  );

  return (
    <RadarFilterSummaryItem
      label={t('season.one')}
      options={filteredCompetitionOptions}
      selectedOptions={useAtomValue(selectedSeasonsAtom)}
    />
  );
};
