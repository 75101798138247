import { useAtomValue, useSetAtom } from 'jotai';
import { isCreateReportModalOpenAtom } from '@/atoms/report/report';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useUpsertUserConfig } from '@/hooks/useUpsertUserConfig';
import { toastDataAtom } from '@/atoms/toast';
import { unwrappedReportConfigsAtom } from '@/atoms/queries/userConfigs';
import { GenericSaveModal } from '../GenericSaveModal/GenericSaveModal';

const CreateReportModalWithData = () => {
  const { t } = useTranslation(['report', 'general']);
  const setIsCreateReportModalOpen = useSetAtom(isCreateReportModalOpenAtom);
  const reports = useAtomValue(unwrappedReportConfigsAtom);
  const [reportName, setReportName] = useState('');
  const setToastData = useSetAtom(toastDataAtom);
  const upsertUserConfig = useUpsertUserConfig();

  const reportConfigNames = reports
    .filter(({ definition }) => definition.name)
    .map(({ definition }) => definition.name as string);

  return (
    <GenericSaveModal
      id="create-report-modal"
      title={t('createReportText')}
      onConfirm={async () => {
        const trimmedReportName = reportName.trim();
        const { isSuccess } = await upsertUserConfig({
          configType: 'dashboard',
          configDefinition: { name: trimmedReportName, widgets: [] },
        });

        setIsCreateReportModalOpen(false);
        setToastData('createReport', isSuccess, trimmedReportName);
      }}
      onCancel={() => setIsCreateReportModalOpen(false)}
      confirmLabel={t('create', { ns: 'general' })}
      value={reportName}
      onChange={setReportName}
      existingConfigNames={reportConfigNames}
    />
  );
};

export const CreateReportModal = () => {
  const isCreateReportModalOpen = useAtomValue(isCreateReportModalOpenAtom);
  return isCreateReportModalOpen && <CreateReportModalWithData />;
};
