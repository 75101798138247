import { playerPositionsAtom } from '@/atoms/queries/players';
import { FallbackLoading } from '@/components/visualisations/FallbackLoading';
import { PLAYED_90S_MINIMUM, PLAYED_90S_THRESHOLD } from '@/consts/player';
import { usePositionUIElements } from '@/hooks/usePositionUIElements';
import { PitchViz, PositionSelectionLayer } from '@statsbomb/kitbag-datavis';
import { useAtomValue } from 'jotai';
import { Suspense } from 'react';

const PlayerPositionsBase = ({ positions }: { positions: Record<string, number> }) => {
  const positionUIElements = usePositionUIElements();

  const playerPositions = positionUIElements.map(uiPosition => {
    const played90s = positions[uiPosition.key];

    return {
      ...uiPosition,
      value: played90s?.toFixed(1) || undefined,
      isMuted: !played90s,
      isSelected: played90s > PLAYED_90S_THRESHOLD,
    };
  });

  return <PitchViz overlay={<PositionSelectionLayer playerPositions={playerPositions} />} />;
};

const PlayerPositionsWithData = () => {
  const positions = useAtomValue(playerPositionsAtom);
  const positionsToDisplay = Object.fromEntries(
    Object.entries(positions as Record<string, number>).filter(([_, played90s]) => played90s > PLAYED_90S_MINIMUM),
  );

  return <PlayerPositionsBase positions={positionsToDisplay} />;
};

export const PlayerPositions = () => (
  <Suspense fallback={<FallbackLoading isPreview />}>
    <PlayerPositionsWithData />
  </Suspense>
);
