import { fetchClientAtom } from '@/atoms/queries/client';
import { Nullable, StringOption } from '@/types/generic';
import { NestedObject } from '@/types/object';
import { convertFilterParamsToString, hasEmptyEqFilters } from '@/utils/api';
import { createCompetitionOptionsAtom, createSeasonOptionsAtom } from '@/utils/atoms/cycles';
import { convertToDropdownOption, toObject } from '@/utils/object';
import { objSnakeToCamel } from '@/utils/queries';
import {
  CycleWithRels,
  GameWithRels,
  Player,
  PlayerWithRels,
  Position,
  TeamWithRels,
} from '@statsbomb/parachute-types';
import { t } from 'i18next';
import { atom } from 'jotai';
import { atomWithSuspenseQuery } from 'jotai-tanstack-query';
import { isSharingId } from '@/utils/array';
import { filterCyclesWithRelsByCompetition, filterCyclesBy } from '@/utils/filters';
import { unwrap } from 'jotai/utils';
import { sortCyclesBySeasonAndComp } from '@/utils/cycles';
import { formatPlayerName, positionPercentagesTo90sPlayed } from '@/utils/player';
import { competitionIdsToFilterByAtom } from '../filters/highLevel/competitions';
import { selectedTeamIdsAtom } from '../filters/highLevel/teams';
import { atomWithDebounce } from '../debounce';
import { playerIdAtom } from '../player/player';
import { unwrappedGenderAtom } from './userConfigs';
import { seasonIdsToFilterByAtom } from '../filters/highLevel/seasons';
import { rawCyclesAtom } from './cycles';
import { playerAggsMinutesPlayedAtom } from './player/playerAggData';

const rawPlayersAtom = atomWithSuspenseQuery(get => {
  const { fetch } = get(fetchClientAtom);
  const queryKey = ['playerInfo', get(unwrappedGenderAtom)] as const;

  const queryFn = async ({ queryKey: [, gender] }: { queryKey: typeof queryKey }) => {
    if (!gender) {
      return [];
    }

    return (await fetch(`/players?eq[gender]=${gender}`)) as Promise<PlayerWithRels[]>;
  };

  return { queryKey, queryFn };
});

export const playersAtom = atom(async get => {
  const rawPlayers = (await get(rawPlayersAtom)).data;
  const rawCycles = (await get(rawCyclesAtom)).data;
  const selectedCompetitionIds = get(competitionIdsToFilterByAtom);
  const selectedSeasonsIds = get(seasonIdsToFilterByAtom);
  const selectedTeamIds = get(selectedTeamIdsAtom);

  // filter the cycles by selected competitions and seasons to create an array of selected cycles
  const filteredCyclesByCompetition = filterCyclesBy(rawCycles, selectedCompetitionIds, 'competition_id');
  const selectedCycles = filterCyclesBy(filteredCyclesByCompetition, selectedSeasonsIds, 'season_id');
  const selectedCycleIds = selectedCycles.map(cycle => cycle.cycle_id);

  // filter players by selected teams and cycles
  const filteredPlayersByTeamsAndCycles =
    selectedTeamIds.length || selectedCycleIds.length
      ? rawPlayers.filter(player =>
          player.team_cycle_ids.some(
            teamCycleIds =>
              // ensure player played for selected team during selected seasons
              (selectedTeamIds.length ? selectedTeamIds.includes(teamCycleIds.team_id) : true) &&
              (selectedCycleIds.length ? isSharingId(teamCycleIds.cycle_ids, selectedCycleIds) : true),
          ),
        )
      : rawPlayers;

  return filteredPlayersByTeamsAndCycles;
});

export const playerName = (playerInfo: Player | PlayerWithRels) => {
  const { nickname, name } = objSnakeToCamel(playerInfo);
  return formatPlayerName(name, nickname);
};

export const playerOptionsAtom = atom(async get => {
  const players = await get(playersAtom);

  return players.map(playerInfo => {
    const { playerId, nationalityAreaCode } = objSnakeToCamel(playerInfo);
    return convertToDropdownOption(playerName(playerInfo), playerId, { countryCode: nationalityAreaCode });
  });
});

const playerFiltersAtom = atom(get => ({
  eq: {
    ...toObject('player_id', get(playerIdAtom)),
  },
}));

export const rawFilteredPlayerInfoAtom = atomWithSuspenseQuery(get => {
  const { fetch } = get(fetchClientAtom);
  const queryKey = ['filteredPlayerInfo', get(playerFiltersAtom)] as const;

  const queryFn = async ({ queryKey: [_, filterParams] }: { queryKey: typeof queryKey }) =>
    (await fetch(`/players${convertFilterParamsToString(filterParams)}`)) as Promise<Player[]>;

  return { queryKey, queryFn };
});

const rawPlayerGamesAtom = atomWithSuspenseQuery(get => {
  const { fetch } = get(fetchClientAtom);
  const queryKey = ['playerGames', get(playerIdAtom)] as const;

  const queryFn = async ({ queryKey: [_, playerId] }: { queryKey: typeof queryKey }) =>
    (await fetch(`/player/${playerId}/games`)) as Promise<GameWithRels[]>;

  return { queryKey, queryFn };
});

export const playerGamesAtom = atom(async get => {
  const playerGames = await get(rawPlayerGamesAtom);
  return Object.values(playerGames.data).map(objSnakeToCamel);
});

const rawPlayerCyclesAtom = atomWithSuspenseQuery(get => {
  const { fetch } = get(fetchClientAtom);
  const queryKey = ['playerCycles', get(playerIdAtom)] as const;

  const queryFn = async ({ queryKey: [_, playerId] }: { queryKey: typeof queryKey }) =>
    (await fetch(`/player/${playerId}/cycles`)) as Promise<CycleWithRels[]>;
  return { queryKey, queryFn };
});

const playerCyclesAtom = atom(async get => {
  const rawPlayerCycles = await get(rawPlayerCyclesAtom);
  return rawPlayerCycles.data.map(objSnakeToCamel);
});

export const playerCyclesOrderedAtom = atom(async get => {
  const playerCycles = await get(playerCyclesAtom);
  return sortCyclesBySeasonAndComp(playerCycles);
});

const filteredPlayerCyclesSeasonAtom = atom(async get => {
  const playerCycles = await get(playerCyclesAtom);
  const selectedCompetitionIds = get(competitionIdsToFilterByAtom);

  return filterCyclesWithRelsByCompetition(playerCycles, selectedCompetitionIds);
});

export const playerCompetitionOptionsAtom = createCompetitionOptionsAtom(playerCyclesAtom);

export const playerSeasonOptionsAtom = createSeasonOptionsAtom(filteredPlayerCyclesSeasonAtom);

const rawPlayerTeamsAtom = atomWithSuspenseQuery(get => {
  const queryKey = ['playerTeams', get(playerIdAtom)] as const;

  const queryFn = async ({ queryKey: [_, playerId] }: { queryKey: typeof queryKey }) => {
    const { fetch } = get(fetchClientAtom);
    return (await fetch(`/player/${playerId}/teams`)) as Promise<TeamWithRels[]>;
  };
  return { queryKey, queryFn };
});

export const playerTeamsAtom = atom(async get => {
  const playerCycles = (await get(rawPlayerCyclesAtom)).data;
  const rawPlayerTeams = (await get(rawPlayerTeamsAtom)).data;
  const selectedCompetitionIds = get(competitionIdsToFilterByAtom);
  const selectedSeasonsIds = get(seasonIdsToFilterByAtom);

  const cycles = playerCycles.map(({ cycle }) => cycle);

  // filter the cycles by selected competitions and seasons to create an array of "valid" cycles
  const filteredCyclesByCompetition = filterCyclesBy(cycles, selectedCompetitionIds, 'competition_id');
  const filteredCyclesBySeasons = filterCyclesBy(filteredCyclesByCompetition, selectedSeasonsIds, 'season_id');
  const validCycleIds = filteredCyclesBySeasons.map(cycle => cycle.cycle_id);

  // filter available teams by comparing the cycles they played in and the valid cycles ids
  const filteredTeamsByCycles =
    selectedCompetitionIds.length || selectedSeasonsIds.length
      ? rawPlayerTeams.filter(team => isSharingId(team.cycle_ids, validCycleIds))
      : rawPlayerTeams;

  return filteredTeamsByCycles;
});

export const playerTeamsOptionsAtom = atom(async get => {
  const playerTeams = await get(playerTeamsAtom);

  return playerTeams
    .map(objSnakeToCamel)
    .map(({ name, teamId, areaCode }) => convertToDropdownOption(name, teamId, { countryCode: areaCode }));
});

export const playerPositionsFilterDebounceObject = atomWithDebounce<Nullable<NestedObject>>(null);
const { debouncedValueAtom: playerPositionsFilterAtom } = playerPositionsFilterDebounceObject;

const rawPlayerPositionsAtom = atomWithSuspenseQuery(get => {
  const queryKey = ['playerPositions', get(playerIdAtom), get(playerPositionsFilterAtom)] as const;

  const queryFn = async ({ queryKey: [_, playerId, filterParams] }: { queryKey: typeof queryKey }) => {
    if (!playerId || hasEmptyEqFilters(filterParams)) return [];
    const { fetch } = get(fetchClientAtom);
    return (await fetch(`/player/${playerId}/positions${convertFilterParamsToString(filterParams)}`)) as Promise<
      Position[]
    >;
  };
  return { queryKey, queryFn };
});

export const playerPositionsOptionsAtom = atom(async get => {
  const rawPlayerPositions = await get(rawPlayerPositionsAtom);
  const playerPositions = rawPlayerPositions.data.map(objSnakeToCamel);

  const { positions, otherPositions } = playerPositions.reduce<{ positions: StringOption[]; otherPositions: string[] }>(
    (acc, { position, percentage }) => {
      if (percentage < 1) {
        return { ...acc, otherPositions: [...acc.otherPositions, position] };
      }

      return {
        ...acc,
        positions: [
          ...acc.positions,
          convertToDropdownOption(
            `${t(`fields.event.attributes.playerPosition.${position}`, { ns: 'events' })} - ${percentage.toFixed(2)}%`,
            position,
          ),
        ],
      };
    },
    { positions: [], otherPositions: [] },
  );

  const hasOtherPositions = otherPositions.length > 0;

  return [
    ...positions,
    /* only renders the "other" option if there are otherPositions */
    ...(hasOtherPositions
      ? [{ ...convertToDropdownOption(t('other', { ns: 'filters' }), otherPositions.join(',')), other: true }]
      : []),
  ];
});

export const unwrappedPlayerPositionsOptionsAtom = unwrap(playerPositionsOptionsAtom, prev => prev || []);

export const playerPositionsAtom = atom(async get => {
  const playerPositions = (await get(rawPlayerPositionsAtom)).data;
  const playerTotalMinutes = await get(playerAggsMinutesPlayedAtom);

  return positionPercentagesTo90sPlayed(playerPositions, playerTotalMinutes);
});
