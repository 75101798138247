import { EventMap } from '@/components/visualisations/EventMap';
import { VisualisationComponentProps } from '@/types/visualisation';
import { playerPitchEventsAtom, playerUnwrappedPitchEventsAtom } from '@/atoms/player/vis';
import { FallbackLoading } from '@/components/visualisations/FallbackLoading';
import { MetricKeyFallbackWrapper } from '@/components/metric/MetricKeyFallbackWrapper';

export const PlayerEventMap = ({ allowInteraction, isPreview }: VisualisationComponentProps) => (
  <MetricKeyFallbackWrapper>
    <EventMap
      fallback={<FallbackLoading isPreview={isPreview} />}
      allowInteraction={allowInteraction}
      pitchEventsAtom={playerPitchEventsAtom}
      unwrappedPitchEventsAtom={playerUnwrappedPitchEventsAtom}
      isPreview={isPreview}
    />
  </MetricKeyFallbackWrapper>
);
