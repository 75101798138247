import { ReactNode } from 'react';
import { Table } from '@statsbomb/kitbag-components';

interface TableCellProps {
  textAlign?: 'left' | 'right';
  size?: 'small' | 'regular' | 'large';
  children: ReactNode;
  className?: string;
}

export const TableCell = ({ textAlign = 'left', size = 'regular', className, children }: TableCellProps) => (
  <Table.DataCell
    className={className}
    // TODO (KC-78: Refactor table component)
    // TS-Ignoring as typescript complains about the $textAlign prop here even though it works
    // so there must be some issue on KC where the typescript type is not coming through correctly.
    // @ts-ignore
    $textAlign={textAlign}
    $size={size}
  >
    {children}
  </Table.DataCell>
);
