import { useAtomValue } from 'jotai';
import { sectionAtom } from '@/atoms/general';
import { selectedCompetitionsAtom } from '@/atoms/filters/highLevel/competitions';
import { selectedSeasonsAtom } from '@/atoms/filters/highLevel/seasons';
import { selectedTeamsAtom } from '@/atoms/filters/highLevel/teams';
import { selectedPlayersAtom } from '@/atoms/filters/highLevel/players';
import { useExportInfo } from './useExportInfo';

export const useExportVisInfo = () => {
  const section = useAtomValue(sectionAtom);
  const selectedCompetitionOptions = useAtomValue(selectedCompetitionsAtom);
  const selectedSeasonOptions = useAtomValue(selectedSeasonsAtom);
  const selectedTeamOptions = useAtomValue(selectedTeamsAtom);
  const selectedPlayerOptions = useAtomValue(selectedPlayersAtom);

  const competitionInfo = useExportInfo(selectedCompetitionOptions, 'Competitions', 2);
  const seasonInfo = useExportInfo(selectedSeasonOptions, 'Seasons', 3, true);
  const teamInfo = useExportInfo(selectedTeamOptions, 'Teams', 2);
  const playerInfo = useExportInfo(selectedPlayerOptions, 'Players', 2);
  const dataLockerInfo3 = [teamInfo, playerInfo].filter(Boolean).join(', ');

  const playerPitchInfo = [teamInfo, competitionInfo, seasonInfo];
  const teamPitchInfo = [competitionInfo, seasonInfo];
  const dataLockerPitchInfo = [competitionInfo, seasonInfo, dataLockerInfo3];

  const getInfo = () => {
    if (section === 'player') return playerPitchInfo;
    if (section === 'team') return teamPitchInfo;
    return dataLockerPitchInfo;
  };

  const info = getInfo().filter(Boolean);

  return { info1: info[0], info2: info[1], info3: info[2] };
};
