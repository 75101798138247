import { unwrappedSelectedPlayerNameAtom } from '@/atoms/player/info';
import { PageTitle } from '@/components/head/PageTitle';
import { PlayerLayoutWithFilters } from '@/components/layout/player/PlayerLayoutWithFilters';
import { PlayerRadar } from '@/components/player/radar/PlayerRadar';
import { useEntityPageTitle } from '@/hooks/useEntityPageTitle';
import { Grid } from '@statsbomb/kitbag-components';
import classNames from 'classnames';
import { useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

export const PlayerRadarPage = () => {
  const { pathname } = useLocation();
  const isPreview = pathname.includes('preview');
  const { t } = useTranslation('navigation');
  const playerName = useAtomValue(unwrappedSelectedPlayerNameAtom);
  const pageTitle = useEntityPageTitle(t('secondary.radar'), playerName);

  return (
    <>
      <PageTitle>{pageTitle}</PageTitle>
      <PlayerLayoutWithFilters>
        {/* TODO (PPC-294: override to centre content when on preview)  */}
        <div className={classNames({ '[&>div]:justify-center': isPreview })}>
          <Grid>
            <Grid item xs={12}>
              <PlayerRadar />
            </Grid>
          </Grid>
        </div>
      </PlayerLayoutWithFilters>
    </>
  );
};
