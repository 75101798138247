import { PromiseAtom } from '@/types/atom';
import { KeysToCamelCase, Option } from '@/types/generic';
import { CycleWithRels } from '@statsbomb/parachute-types';
import { atom } from 'jotai';
import { convertToDropdownOption } from '../object';

type EntityCyclesAtom = PromiseAtom<KeysToCamelCase<CycleWithRels>[]>;

export const createCompetitionOptionsAtom = (entityCyclesAtom: EntityCyclesAtom) =>
  atom(async get => {
    const entityCycles = await get(entityCyclesAtom);

    return Object.values(
      entityCycles.reduce(
        (acc, { competition: { name, competitionId, areaCode } }) => ({
          ...acc,
          [competitionId]: {
            ...convertToDropdownOption(name, competitionId, { countryCode: areaCode }),
          },
        }),
        {} as Record<number, Option>,
      ),
    );
  });

export const createSeasonOptionsAtom = (entityCyclesAtom: EntityCyclesAtom) =>
  atom(async get => {
    const entityCycles = await get(entityCyclesAtom);

    const sortedSeasonOptions = Object.values(
      entityCycles.reduce(
        (acc, { season: { name, seasonId } }) => ({
          ...acc,
          [seasonId]: convertToDropdownOption(name, seasonId),
        }),
        {} as Record<number, Option>,
      ),
    )
      .sort((a, b) => a.label.localeCompare(b.label))
      .reverse();

    return sortedSeasonOptions;
  });
