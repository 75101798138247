import { atom } from 'jotai';
import { atomWithSuspenseQuery } from 'jotai-tanstack-query';
import { Competition } from '@statsbomb/parachute-types';
import { fetchClientAtom } from '@/atoms/queries/client';
import { unwrappedGenderAtom } from './userConfigs';

const rawCompetitionsAtom = atomWithSuspenseQuery(get => {
  const queryKey = ['competitions', get(unwrappedGenderAtom)] as const;
  const queryFn = async ({ queryKey: [, gender] }: { queryKey: typeof queryKey }) => {
    const { fetch } = get(fetchClientAtom);
    if (!gender) {
      return [];
    }

    return (await fetch(`/competitions?eq[gender]=${gender}`)) as Promise<Competition[]>;
  };

  return { queryKey, queryFn };
});

export const competitionsAtom = atom(async get => (await get(rawCompetitionsAtom)).data);
