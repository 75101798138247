import { selectedEventNamesAtom } from '@/atoms/filters/events';
import { eventNamesAtom } from '@/atoms/queries/eventData';
import { StringOption } from '@/types/generic';
import { convertOptionsToValues } from '@/utils/array';
import { snakeToCamel } from '@/utils/queries';
import { Dropdown } from '@statsbomb/kitbag-components';
import { useAtom, useAtomValue } from 'jotai';
import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';

const EventNameDropdownBase = ({
  isLoading = false,
  eventOptions = [],
}: {
  isLoading?: boolean;
  eventOptions?: { label: string; options: { value: string; label: string }[] }[];
}) => {
  const { t } = useTranslation(['events', 'filters', 'general']);
  const [selectedEventNames, setSelectedEventNames] = useAtom(selectedEventNamesAtom);

  const translatedOptions = eventOptions.map(({ label, options }) => ({
    label: t(`fields.event.group.${snakeToCamel(label)}`),
    options: options.map(({ label, value }) => ({ label: t(`fields.event.type.${snakeToCamel(label)}`), value })),
  }));

  const selectedOptions = translatedOptions
    .flatMap(({ options }) => options)
    .filter(option => selectedEventNames.includes(option.value));

  return (
    <Dropdown
      isLoading={isLoading}
      id="event-name-dropdown"
      labelPosition="top"
      label={t('typeFilter.eventNameSelector', { ns: 'filters' })}
      options={translatedOptions}
      isMulti
      value={selectedOptions}
      onChange={(selectedEvents: StringOption[]) => setSelectedEventNames(convertOptionsToValues(selectedEvents))}
      placeholder={t('dropdownPlaceholder', { ns: 'filters' })}
      selectedChipText={t('selectedChipText', { ns: 'general' })}
      noOptionsText={t('noOptions', { ns: 'general' })}
    />
  );
};

export const EventNameDropdownWithData = () => {
  const eventOptions = useAtomValue(eventNamesAtom);
  return <EventNameDropdownBase eventOptions={eventOptions} />;
};

export const EventNameDropdown = () => (
  <Suspense fallback={<EventNameDropdownBase isLoading />}>
    <EventNameDropdownWithData />
  </Suspense>
);
