import { DATE_FORMAT } from '@/consts/date';
import dayjs from 'dayjs';

export const firstDateForAge = (age: number, fixedDate: string | undefined = undefined) =>
  dayjs(fixedDate).subtract(age, 'year').format(DATE_FORMAT);

export const lastDateForAge = (age: number, fixedDate: string | undefined = undefined) =>
  dayjs(fixedDate)
    .subtract(age + 1, 'year')
    .subtract(1, 'day')
    .format(DATE_FORMAT);
