import { atom } from 'jotai';
import env from '@/env';
import { getFetchClient } from '@/query/client';

// Storing as an object, as advised by the devs, otherwise jotai assumes it needs to store the *result* of the function
export const fetchClientAtom = atom<{ fetch: (input: RequestInfo, init?: RequestInit) => Promise<any> }>({
  fetch: (...args) => {
    /* istanbul ignore next */
    // eslint-disable-next-line no-console
    if (env.VITE_NODE_ENV !== 'test') console.warn('Fetch initiated before client was ready');

    // Reject here so that tanstack retries any failed queries, but not in tests as we won't actually have a token
    const fetch =
      env.VITE_NODE_ENV === 'test'
        ? getFetchClient(Promise.resolve('unset'))
        : /* istanbul ignore next */
          getFetchClient(Promise.reject(new Error('Bearer token not set')));

    return fetch(...args);
  },
});
