import { unwrappedRenderedRadarDataAtom } from '@/atoms/radar';
import { useDecimalPlaces } from '@/hooks/useDecimalPlaces';
import { useDeepEffect } from '@/hooks/useDeepEffect';
import { Nullable } from '@/types/generic';
import { Typography } from '@statsbomb/kitbag-components';
import { Atom, useAtomValue } from 'jotai';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const RadarFilterMinsSummary = ({ minutesAtom }: { minutesAtom: Atom<number> }) => {
  const { t } = useTranslation('filters');
  const [minsToDisplay, setMinsToDisplay] = useState<Nullable<number>>(null);
  const formatNumberDecimals = useDecimalPlaces();
  const renderedRadarData = useAtomValue(unwrappedRenderedRadarDataAtom);
  const minutes = useAtomValue(minutesAtom);
  const per90s = formatNumberDecimals(1, minutes / 90);

  useDeepEffect(() => {
    // Only update value when radar data changes, not when the user changes filters
    setMinsToDisplay(minutes);
  }, [renderedRadarData]);

  if (minsToDisplay === null) return null;

  return (
    // TODO (KC-360: Replace with Card component once available)
    <div className="bg-canvas-secondary-main px-4 py-2">
      <Typography variant="headingMedium" as="h2" className="pb-1">
        {t('summary.minutesPlayed')}
      </Typography>
      <Typography variant="bodyRegular" as="span">
        {t('summary.totalMinutes', { value: formatNumberDecimals(0, minutes) })} ({per90s} 90s)
      </Typography>
    </div>
  );
};
