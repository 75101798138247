import { useSetAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { ColumnControlsWrapper } from '@/components/controls/ColumnControlsWrapper';
import { MenuItemWithIcon } from '@/components/menu/MenuItemWithIcon';
import { MoreOptionsMenu } from '@/components/menu/MoreOptionsMenu/MoreOptionsMenu';
import { exportModalDataAtom } from '@/atoms/exporting';
import { ExportContent } from '@/types/exporting';
import { useTranslatedRadarTemplate } from '@/hooks/useTranslatedRadarTemplate';

export const RadarTableHeader = () => {
  const { t } = useTranslation(['visualisation', 'export']);
  const setExportModalData = useSetAtom(exportModalDataAtom);
  const secondaryTitle = useTranslatedRadarTemplate();

  return (
    <ColumnControlsWrapper heading={t(`viewing.table`, { ns: 'visualisation' })}>
      <MoreOptionsMenu>
        <MenuItemWithIcon
          icon="Download"
          text={t('export', { ns: 'export' })}
          onClick={() => {
            setExportModalData({
              visName: t('imageContentOptions.fileName.table', { ns: 'export' }),
              secondaryTitle,
              content: ExportContent.TABLE,
            });
          }}
        />
      </MoreOptionsMenu>
    </ColumnControlsWrapper>
  );
};
