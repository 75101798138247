import { TranslatedOption } from '@/types/translations';
import { t } from 'i18next';

export const getTranslatedOption = <T>({ translation, value }: TranslatedOption<T>) => ({
  label: t(translation.key, {
    ns: translation.ns,
    count: translation.count,
  }),
  value,
});

export const getTranslatedOptions = <T>(options: TranslatedOption<T>[]) =>
  options.map(option => getTranslatedOption(option));

export const getTranslationColumns = (id: string) => {
  const translationNs = id.includes('aggregates') ? 'metrics' : 'events';
  const translationKey =
    translationNs === 'events' ? `keys.${id}` : `${id.replace('aggregates.', '').replace('Per90', '')}.name`;

  return { translationNs, translationKey };
};
