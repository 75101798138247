import { filterUniqueByProperty } from '@/utils/filters';
import { BOX_PLUS_CHANNELS_GRID_ZONES, CustomShape } from '@statsbomb/kitbag-datavis';

export const toggleFilterZoneGroups = (zoneGroupIds: (string | number)[], selectedZones: CustomShape[]) => {
  const isZoneGroupFullySelected = zoneGroupIds.every(zoneItem =>
    selectedZones.map(selectedZone => selectedZone.id).includes(zoneItem),
  );

  const deselectZoneGroup = (selectedZones: CustomShape[]) =>
    selectedZones.filter(zoneItem => !zoneGroupIds.includes(zoneItem.id));

  const selectZoneGroup = () => {
    const selectedZoneGroupItems = BOX_PLUS_CHANNELS_GRID_ZONES.filter(zoneItem => zoneGroupIds.includes(zoneItem.id));

    return filterUniqueByProperty('id', [...selectedZones, ...selectedZoneGroupItems]);
  };

  if (isZoneGroupFullySelected) return deselectZoneGroup(selectedZones);
  return selectZoneGroup();
};
