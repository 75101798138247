import { isMobileFiltersPanelOpenAtom } from '@/atoms/sidebar';
import { ButtonIcon } from '@statsbomb/kitbag-components';
import { useSetAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

export const MobileFilterButton = () => {
  const { t } = useTranslation('general');
  const setIsMobileFiltersPanelOpen = useSetAtom(isMobileFiltersPanelOpenAtom);

  return (
    <div className="mobile-filter-button">
      <ButtonIcon size="small" displayText="left" icon="Filter" onClick={() => setIsMobileFiltersPanelOpen(true)}>
        {t('filters.other')}
      </ButtonIcon>
    </div>
  );
};
