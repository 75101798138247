import { VideoPlayer } from '@/components/video/VideoPlayer';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ButtonIcon } from '@statsbomb/kitbag-components';
import { MetricName } from '@/components/metric/MetricName';

export const VideoLayout = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();

  const { t } = useTranslation('video');
  const isPreview = pathname.includes('preview');
  const btnLabel = t('collapseVideoPlayer', { player: t('player') });

  return (
    <div>
      <span className="flex items-center justify-between w-full mb-4">
        <MetricName />
        <span className="ml-auto min-h-[32px]">
          {!isPreview && (
            <ButtonIcon
              title={btnLabel}
              icon="DoubleChevronLeft"
              variant="ghost"
              onClick={() => navigate(`preview?${searchParams}`)}
            >
              {btnLabel}
            </ButtonIcon>
          )}
        </span>
      </span>
      <VideoPlayer />
    </div>
  );
};
