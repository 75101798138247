import { playerIdAtom } from '@/atoms/player/player';
import { useSetAtom } from 'jotai';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

export const useSetPlayerId = () => {
  const { playerId } = useParams();
  if (!playerId) throw new Error('Missing param in URL: playerId');
  const setPlayerId = useSetAtom(playerIdAtom);
  useEffect(() => setPlayerId(Number(playerId)), [playerId]);
};
