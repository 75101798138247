import { HighLevelFilterMapAtom } from '@/types/filters';
import { getOptionsAtomForSection } from '@/utils/atoms/highLevelFilters';
import { atom } from 'jotai';
import { unwrap } from 'jotai/utils';
import { playerOptionsAtom as playerOptionsQueryAtom } from '@/atoms/queries/players';
import { Option } from '@/types/generic';
import { filterAtomDefaults } from '@/consts/defaults/filterAtomDefaults';

const playerSectionMapAtom = atom<HighLevelFilterMapAtom<Option[]>>(() => ({
  dataLocker: playerOptionsQueryAtom,
  team: atom(async () => []),
  player: atom(async () => []),
}));

export const playerOptionsAtom = atom(getOptionsAtomForSection(playerSectionMapAtom));

export const selectedPlayerIdsAtom = atom(filterAtomDefaults.playerIds);

export const unwrappedPlayerOptionsAtom = unwrap(playerOptionsAtom, prev => prev || []);
export const selectedPlayersAtom = atom(get => {
  const playerOptions = get(unwrappedPlayerOptionsAtom);
  const selectedPlayerIds = get(selectedPlayerIdsAtom);

  return playerOptions.filter(({ value }) => selectedPlayerIds.includes(value));
});
