/* istanbul ignore file */
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { NotFound } from '@/pages/NotFound';
import { TeamLayout } from '@/components/layout/team/TeamLayout';
import { TeamRadarPage } from '@/pages/team/radar/TeamRadarPage';
import { TeamVisPage } from '@/pages/team/vis/TeamVisPage';
import { TeamPreview } from '@/pages/team/preview/TeamPreview';
import { TeamVideoPage } from '@/pages/team/video/TeamVideoPage';
import { TeamVideoPreview } from '@/pages/team/video/TeamVideoPreview';
import { VideoPreview } from '@/components/video/VideoPreview';
import { ProtectedVideoRoute } from '@/components/routes/ProtectedVideoRoute';
import { PlayerAggsPreview } from '@/pages/dataLocker/aggregates/player/PlayerAggsPreview';
import { TeamSquadPage } from '@/pages/team/squad/TeamSquadPage';
import { TeamGamesPage } from '@/pages/team/games/TeamGamesPage';
import { GroupOnlyRoute } from '@/components/routes/GroupOnlyRoute';
import { TeamGamesVideoPage } from '@/pages/team/games/video/TeamGamesVideoPage';

export const TeamRoutes = () => (
  <Routes>
    <Route element={<TeamLayout />}>
      <Route path="radar" element={<TeamRadarPage />}>
        <Route path="preview" element={<TeamPreview />} />
      </Route>
      <Route path="vis" element={<TeamVisPage />}>
        <Route path="preview" element={<ProtectedVideoRoute component={<VideoPreview />} />} />
      </Route>
      <Route path="vis/video" element={<ProtectedVideoRoute component={<TeamVideoPage />} />}>
        <Route path="preview" element={<ProtectedVideoRoute component={<TeamVideoPreview />} />} />
      </Route>
      <Route path="squad" element={<TeamSquadPage />}>
        <Route path="preview" element={<PlayerAggsPreview />} />
      </Route>
      <Route path="matches" element={<TeamGamesPage />} />
      <Route
        path="matches/video"
        element={
          <GroupOnlyRoute group="statsbomb">
            <ProtectedVideoRoute component={<TeamGamesVideoPage />} />
          </GroupOnlyRoute>
        }
      />
      {/* TODO (PPC-281: not found page) */}
      <Route path="*" element={<NotFound />} />
    </Route>
  </Routes>
);
