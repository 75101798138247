import { competitionOptionsAtom } from '@/atoms/filters/highLevel/competitions';
import { unwrappedTeamSelectedGamesForQueryAtom } from '@/atoms/filters/team/teamGames';
import { pageAndOrderParamsAtom } from '@/atoms/general';
import { teamIdAtom } from '@/atoms/team/team';
import { fetchClientAtom } from '@/atoms/queries/client';
import { teamGamesAtom } from '@/atoms/queries/teams';
import { convertOptionsToValues } from '@/utils/array';
import { flattenAndAddPrefix } from '@/utils/object';
import { objSnakeToCamel } from '@/utils/queries';
import { TeamGameAggregate } from '@statsbomb/parachute-types';
import { atom } from 'jotai';
import { atomWithSuspenseQuery } from 'jotai-tanstack-query';

import { getSortedGamesToDisplay } from '@/utils/games';
import { teamGamesTotalAggregatesUrl } from '@/query/url';

const rawTeamGameAggsDataAtom = atomWithSuspenseQuery(get => {
  const queryKey = ['teamGameAggs', get(teamIdAtom)] as const;
  const queryFn = async ({ queryKey: [, teamId] }: { queryKey: typeof queryKey }) => {
    const url = teamGamesTotalAggregatesUrl(teamId, {
      limit: 'ALL',
    });
    if (!url) return [];
    const { fetch } = get(fetchClientAtom);

    const data: TeamGameAggregate[] = await fetch(url);

    return data.map(objSnakeToCamel);
  };
  return { queryKey, queryFn };
});

/* need to do joining here between teamGames and rawTeamGameAggsDataAtom as we don't have 
the aggregate data for all our games, so when using the lastXGames filter it didn't work as expected.
This joins them together so the filter works, and we display a message for when the aggregates haven't
been processed yet. */
export const teamGameAggsAtom = atom(async get => {
  const pageAndOrderParams = get(pageAndOrderParamsAtom);
  const teamGameAggs = (await get(rawTeamGameAggsDataAtom)).data;
  const competitionOptions = await get(competitionOptionsAtom);
  const unwrappedTeamGamesForQuery = convertOptionsToValues(get(unwrappedTeamSelectedGamesForQueryAtom));
  const teamGames = await get(teamGamesAtom);

  const gamesToDisplayWithAggs = teamGames
    .filter(({ game }) => unwrappedTeamGamesForQuery.includes(game.gameId))
    .map(game => {
      const gameAggs = teamGameAggs.find(gameAgg => gameAgg.game.gameId === game.game.gameId);
      const competition = competitionOptions.find(comp => game.cycle.competitionId === comp.value);
      const gameWithAggs = {
        ...game,
        aggregates: gameAggs?.aggregates,
        hasAggregates: !!gameAggs,
        competition: { name: competition?.label },
      };

      return flattenAndAddPrefix(objSnakeToCamel(gameWithAggs), '.', false);
    });

  const sortedGamesToDisplay = getSortedGamesToDisplay(gamesToDisplayWithAggs, pageAndOrderParams);
  return sortedGamesToDisplay.slice(pageAndOrderParams.offset, pageAndOrderParams.offset + pageAndOrderParams.limit);
});

export const teamGameAggsCountAtom = atom(async get => get(unwrappedTeamSelectedGamesForQueryAtom).length);
