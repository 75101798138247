import { teamPitchVisComponents } from '@/consts/visualisationComponents';
import { teamEventsForMetricCountAtom } from '@/atoms/queries/team/teamEventData';
import { EntityPreview } from '@/components/preview/EntityPreview';
import { TeamPreviewEvents } from '@/components/team/radar/preview/TeamPreviewEvents';
import { teamIdAtom } from '@/atoms/team/team';
import { useAtomValue } from 'jotai';

export const TeamPreview = () => {
  const teamId = useAtomValue(teamIdAtom);

  return (
    <EntityPreview
      eventsForMetricCountAtom={teamEventsForMetricCountAtom}
      visualisationComponents={teamPitchVisComponents}
      pathTo={`../../../team/${teamId}/vis`}
      entity="team"
    >
      <TeamPreviewEvents />
    </EntityPreview>
  );
};

export default TeamPreview;
