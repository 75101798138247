import { useTranslation } from 'react-i18next';
import { TEAM_RADAR_TEMPLATES } from '@/consts/radar';
import { RadarCard } from '@/components/radar/RadarCard';
import { FiftyFiftyColumnLayout } from '@/components/layout/FiftyFiftyColumnLayout';
import { OverviewHeader } from '@/components/section/OverviewHeader';
import { RadarExportModal } from '@/components/radar/RadarExportModal';
import { TeamRadarChart } from './TeamRadarChart';
import { TeamRadarTableGroup, TeamRadarTableSummary } from './TeamRadarTableGroup';

export const TeamRadar = () => {
  const { t } = useTranslation(['radar', 'entity']);
  const label = t('dropdownLabel', { entity: t('team.one', { ns: 'entity' }) });

  return (
    <>
      <FiftyFiftyColumnLayout header={<OverviewHeader />}>
        <RadarCard templates={TEAM_RADAR_TEMPLATES} label={label}>
          <TeamRadarChart id="main" />
        </RadarCard>
        <TeamRadarTableGroup />
      </FiftyFiftyColumnLayout>

      <RadarExportModal>
        <TeamRadarChart id="export" isInteractive={false} />
        <TeamRadarTableSummary />
      </RadarExportModal>
    </>
  );
};
