import { UserDetails } from '@statsbomb/parachute-types';

type ValidEntityUserResponse = { valid: true; error: null; userDetails: UserDetails };
type InvalidEntityUserResponse = { valid: false; error: string; userDetails: UserDetails | null };

const entityIsSuspended = (entity: UserDetails['entity']) => entity.suspended;

const entityHasNotStarted = (entity: UserDetails['entity']) =>
  entity.start_date && +new Date(entity.start_date) > Date.now();

const entityHasExpired = (entity: UserDetails['entity']) => entity.end_date && +new Date(entity.end_date) < Date.now();

const featuresHasNoData = (features: UserDetails['features']) => features.licensed_game_count === 0;

export const isValidEntityUser = (
  userDetails: UserDetails | null,
): ValidEntityUserResponse | InvalidEntityUserResponse => {
  if (!userDetails) return { valid: false, error: 'no_user', userDetails: null };

  const { entity, features } = userDetails;

  if (entityHasNotStarted(entity)) return { valid: false, error: 'not_started', userDetails };
  if (entityHasExpired(entity)) return { valid: false, error: 'expired', userDetails };
  if (entityIsSuspended(entity)) return { valid: false, error: 'suspended', userDetails };

  // this needs to be last, as if any of the other errors are true, this one will also be true
  if (featuresHasNoData(features)) return { valid: false, error: 'no_data', userDetails };

  return { valid: true, error: null, userDetails };
};
