import { atom } from 'jotai';
import { atomWithSuspenseQuery } from 'jotai-tanstack-query';
import { Metric, MetricDistribution } from '@statsbomb/parachute-types';
import { fetchClientAtom } from '@/atoms/queries/client';
import { objSnakeToCamel } from '@/utils/queries';

const rawMetricsAtom = atomWithSuspenseQuery(get => ({
  queryKey: ['metrics'],
  queryFn: async () => {
    const { fetch } = get(fetchClientAtom);

    return (await fetch(`/metrics`)) as Promise<Metric[]>;
  },
  refetchOnMount: false,
  refetchOnReconnect: false,
  refetchOnWindowFocus: false,
}));

export const metricsAtom = atom(async get => ((await get(rawMetricsAtom)).data || []).map(objSnakeToCamel));

const rawMetricDistributionsAtom = atomWithSuspenseQuery(get => ({
  queryKey: ['metricDistribution'],
  queryFn: async () => {
    const { fetch } = get(fetchClientAtom);

    return (await fetch(`/metric-distributions`)) as Promise<MetricDistribution[]>;
  },
  refetchOnMount: false,
  refetchOnReconnect: false,
  refetchOnWindowFocus: false,
}));

export const metricDistributionsAtom = atom(async get =>
  ((await get(rawMetricDistributionsAtom)).data || []).map(objSnakeToCamel),
);
