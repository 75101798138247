import { TEAM_AGGS_FILTER_ATOMS } from '@/consts/dataLocker/team';
import { useSetDataLockerDebouncedFilterParams } from '@/hooks/apiFilterParams/useSetDataLockerDebouncedFilterParams';
import { UserFilters } from '../filters/UserFilters/UserFilters';
import { DataLockerTeamHighLevelFilters } from './filters/DataLockerTeamHighLevelFilters';

export const TeamAggsFilters = () => {
  useSetDataLockerDebouncedFilterParams(TEAM_AGGS_FILTER_ATOMS);

  return (
    <div className="flex flex-col">
      <UserFilters />
      <DataLockerTeamHighLevelFilters />
    </div>
  );
};
