import { teamEventsForMetricAtom } from '@/atoms/queries/team/teamEventData';
import { PreviewEvents, PreviewEventsBase } from '@/components/tables/PreviewEvents';
import { useAtomValue } from 'jotai';
import { Suspense } from 'react';

const TeamPreviewEventsWithData = () => {
  const eventsForMetric = useAtomValue(teamEventsForMetricAtom);
  return <PreviewEvents eventsForMetric={eventsForMetric} />;
};

export const TeamPreviewEvents = () => (
  <Suspense fallback={<PreviewEventsBase isLoading />}>
    <TeamPreviewEventsWithData />
  </Suspense>
);
