import { Typography, ButtonIcon } from '@statsbomb/kitbag-components';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const VideoPlayerHeader = ({ navigationPath }: { navigationPath: string }) => {
  const { t } = useTranslation(['general', 'video']);
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  return (
    <span className="flex items-center justify-between w-full">
      <Typography as="span" variant="headingMedium">
        {t('player', { ns: 'video' })}
      </Typography>
      <span className="hidden md:block ml-auto">
        <ButtonIcon
          title={t('fullScreen')}
          icon="Start"
          variant="ghost"
          onClick={() => {
            navigate(`${navigationPath}/vis/video/preview?${searchParams}`, { relative: 'path' });
          }}
        />
      </span>
    </span>
  );
};
