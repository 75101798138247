import { Typography } from '@statsbomb/kitbag-components';
import { useTranslation } from 'react-i18next';

export const Loading = () => {
  const { t } = useTranslation('general');
  return (
    <Typography variant="bodyMedium" as="span" data-testid="loading">
      {t('loading')}
    </Typography>
  );
};
