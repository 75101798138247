import {
  filterGamesByCompetition,
  filterGamesByTeam,
  filterGamesByGameType,
  pipeFilterFunctions,
  filterGamesBySeason,
} from '@/utils/filters';
import { atom } from 'jotai';
import { convertToDropdownOption } from '@/utils/object';
import { playerCompetitionOptionsAtom, playerGamesAtom, playerSeasonOptionsAtom } from '@/atoms/queries/players';
import { FormattedGame, FormattedGameWithTeam } from '@/types/game';
import { loadable, unwrap } from 'jotai/utils';
import { getFilteredGames, getGamesBySeason, getSelectedGames } from '@/utils/atoms/game';
import { selectedGameTypeAtom } from '../gameMetrics';
import { deselectedGamesAtom } from '../highLevel';
import { competitionIdsToFilterByAtom } from '../highLevel/competitions';
import { selectedTeamIdsAtom } from '../highLevel/teams';
import { seasonIdsToFilterByAtom } from '../highLevel/seasons';

const playerGameFilterFunctionsAtom = atom(get => {
  const selectedGameType = get(selectedGameTypeAtom).value;
  const selectedCompetitionIds = get(competitionIdsToFilterByAtom);
  const selectedTeamIds = get(selectedTeamIdsAtom);
  const selectedSeasonIds = get(seasonIdsToFilterByAtom);

  return pipeFilterFunctions<FormattedGame>(
    (entityGame: FormattedGameWithTeam[]) => filterGamesByGameType(entityGame, selectedGameType),
    (entityGame: FormattedGameWithTeam[]) => filterGamesByCompetition(entityGame, selectedCompetitionIds),
    (entityGame: FormattedGameWithTeam[]) => filterGamesBySeason(entityGame, selectedSeasonIds),
    (entityGame: FormattedGameWithTeam[]) => filterGamesByTeam(entityGame, selectedTeamIds),
  );
});

export const filteredPlayerGamesAtom = atom(async get =>
  getFilteredGames(get, playerGamesAtom, playerGameFilterFunctionsAtom),
);

export const playerGamesBySeasonAtom = atom(get =>
  getGamesBySeason(get, playerSeasonOptionsAtom, playerCompetitionOptionsAtom, filteredPlayerGamesAtom),
);

const playerGamesOptionsAtom = atom(async get => {
  const playerGames = await get(filteredPlayerGamesAtom);
  return playerGames.map(({ game: { gameId, name } }) => convertToDropdownOption(name, gameId));
});

export const playerSelectedGamesAtom = atom(async get =>
  getSelectedGames(get, playerGamesOptionsAtom, deselectedGamesAtom),
);

const playerSelectedGamesForQueryAtom = atom(async get => {
  const userPlayerSelectedGames = await get(playerSelectedGamesAtom);
  return userPlayerSelectedGames.length > 0 ? userPlayerSelectedGames : get(playerGamesOptionsAtom);
});

export const loadablePlayerSelectedGamesForQueryAtom = loadable(playerSelectedGamesForQueryAtom);

export const unwrappedPlayerSelectedGamesForQueryAtom = unwrap(playerSelectedGamesForQueryAtom, prev => prev || []);
