import { atom } from 'jotai';
import { unwrap } from 'jotai/utils';
import {
  GOALKEEPER_MATCHES_DEFAULT_COLUMNS,
  PLAYER_MATCHES_DEFAULT_COLUMNS,
} from '@/components/tables/GameAggregatesTable.constants';
import { playerGameAggsPositionsAtom } from '@/atoms/queries/player/playerGameAggData';
import { Position } from '@/types/player';
import { getReadWriteVisibleColumnsAtom } from '../../utils';

export const playerMatchDataVisibleColumnsUserSelectedAtom = atom<string[] | undefined>(undefined);

export const playerMatchDataDefaultColumnsAtom = atom(async get => {
  const { data: playerGameAggsPositions } = await get(playerGameAggsPositionsAtom);

  // positions are already sorted in a descending order from most played position to least played position
  return [
    ...(playerGameAggsPositions[0]?.position === Position.GK
      ? GOALKEEPER_MATCHES_DEFAULT_COLUMNS
      : PLAYER_MATCHES_DEFAULT_COLUMNS),
  ];
});

export const playerMatchDataVisibleColumnsAtom = getReadWriteVisibleColumnsAtom(
  playerMatchDataVisibleColumnsUserSelectedAtom,
  playerMatchDataDefaultColumnsAtom,
);

export const unwrappedPlayerMatchDataVisibleColumnsAtom = unwrap(playerMatchDataVisibleColumnsAtom, prev => prev || []);
