import React, { useState, useEffect, useRef, ReactNode } from 'react';
import { useAtomValue, useSetAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import {
  Toggle,
  Dropdown,
  Typography,
  Modal,
  ModalHeader,
  ModalFooter,
  Button,
  ButtonIcon,
} from '@statsbomb/kitbag-components';
import { exportModalDataAtom } from '@/atoms/exporting';
import { useExportModalInfo } from '@/hooks/exporting/useExportModalInfo';
import { useExportVisInfo } from '@/hooks/exporting/useExportVisInfo';
import { ExportType } from '@/types/exporting';
import { DOWNLOAD_FORMAT_OPTIONS, EXPORT_TYPE, EXPORT_WIDTH, MODAL_ID, X_PADDING } from '@/consts/exporting';
import { useIsPage } from '@/hooks/useIsPage';
import ExportTemplate from './ExportTemplate';
import { exportVisual } from './Export.helper';
import { ExportContentDropdown } from './ExportContentDropdown';

const ExportModalWithData = ({ children }: { children: ReactNode }) => {
  const exportRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation(['export', 'general']);

  const isRadarPage = useIsPage('radar');
  const { title, secondaryTitle, fileName, customWidth } = useExportModalInfo();
  const { info1, info2, info3 } = useExportVisInfo();

  const setExportModalData = useSetAtom(exportModalDataAtom);
  const [isExporting, setIsExporting] = useState(false);
  const [exportType, setExportType] = useState(DOWNLOAD_FORMAT_OPTIONS[0]);
  const [withLogo, setWithLogo] = useState(true);

  const exportName = fileName || t('defaultFileName');

  // exporting blocks the UI thread, so it's important to ensure that isExporting
  // is set synchronously before running the export function
  useEffect(() => {
    if (isExporting) {
      /* timeout here allows us to wait for the loading button to render as disabled
      before continuing the download else we only see the button disabled briefly before the download
      finishes */
      setTimeout(async () => {
        await exportVisual(exportRef, EXPORT_TYPE[exportType.value as ExportType], exportName);

        setIsExporting(false);
        setExportModalData(null);
      }, 50);
    }
  }, [isExporting]);

  const width = (customWidth || EXPORT_WIDTH.DEFAULT) + X_PADDING;
  const widthInPx = `${width}px`;

  return (
    <Modal id={MODAL_ID} isOpen onEscape={() => setExportModalData(null)} testId={MODAL_ID}>
      <ModalHeader id={MODAL_ID} as="header" testId={`${MODAL_ID}-header`}>
        <div className="w-full flex items-center justify-between">
          <Typography variant="headingRegular">{t('title')}</Typography>
          <ButtonIcon
            icon="Close"
            variant="ghost"
            onClick={() => setExportModalData(null)}
            title={t('cancel', { ns: 'general' })}
          />
        </div>
      </ModalHeader>
      <div className="flex justify-center">
        <div className="max-h-[60vh] w-[700px] p-4 hidden md:flex justify-center overflow-hidden">
          <div className="border border-canvas-secondary-main max-h-full max-w-full overflow-auto">
            <ExportTemplate
              ref={exportRef}
              width={widthInPx}
              title={title}
              secondaryTitle={secondaryTitle}
              info1={info1}
              info2={info2}
              info3={info3}
              withLogo={withLogo}
            >
              {children}
            </ExportTemplate>
          </div>
        </div>
        <aside className="min-w-[240px] border-l border-canvas-secondary-main">
          <Typography variant="headingRegular" as="header" className="p-4 border-b border-canvas-secondary-main">
            {t('exportSettings')}
          </Typography>
          <div className="flex flex-col p-4 gap-4">
            <Typography as="span" variant="bodyMedium">
              {t('imageWidth', { width: `${width.toFixed()}px` })}
            </Typography>
            {isRadarPage && <ExportContentDropdown />}
            <Dropdown
              id="image-type-dropdown"
              options={DOWNLOAD_FORMAT_OPTIONS}
              label={t('imageType')}
              onChange={setExportType}
              value={exportType}
              isSearchable={false}
            />
            <Toggle
              id="export-logo-toggle"
              label={t('statsBombLogo', { ns: 'general' })}
              checked={withLogo}
              onChange={() => setWithLogo(!withLogo)}
            />
          </div>
        </aside>
      </div>
      <ModalFooter testId={`${MODAL_ID}-footer`}>
        <Button onClick={() => setExportModalData(null)} variant="ghost">
          {t('cancel', { ns: 'general' })}
        </Button>
        <Button onClick={() => setIsExporting(true)} variant="primary" isPending={isExporting}>
          {t('export')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

/**
 * An export modal with customization options
 * Takes an SVG and displays it within an export template
 */
export const ExportModal = ({ children }: { children: ReactNode }) => {
  const exportModalData = useAtomValue(exportModalDataAtom);
  return exportModalData !== null && <ExportModalWithData>{children}</ExportModalWithData>;
};
