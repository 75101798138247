import { unwrappedSelectedPlayerNameAtom } from '@/atoms/player/info';
import { PageTitle } from '@/components/head/PageTitle';
import { PlayerLayoutWithFilters } from '@/components/layout/player/PlayerLayoutWithFilters';
import { useEntityPageTitle } from '@/hooks/useEntityPageTitle';
import { Grid, Typography } from '@statsbomb/kitbag-components';
import { useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';
import { playerIdAtom } from '@/atoms/player/player';
import { playerDashboardWidgetsAtom } from '@/atoms/dashboardTemplate/dashboardTemplate';
import { SectionTopBar } from '@/components/sectionTopBar/SectionTopBar';
import { ContentState } from '@/components/contentState/ContentState';
import { StatusTranslationKey } from '@/types/status';
import { DashboardWidgets } from '@/components/dashboard/DashboardWidgets';

export const PlayerDashboardPage = () => {
  const { t } = useTranslation('navigation');
  const playerName = useAtomValue(unwrappedSelectedPlayerNameAtom);
  const playerId = useAtomValue(playerIdAtom);
  const playerDashboardWidgets = useAtomValue(playerDashboardWidgetsAtom);
  const heading = t('secondary.dashboard');
  const pageTitle = useEntityPageTitle(heading, playerName);

  return (
    <>
      <PageTitle>{pageTitle}</PageTitle>
      <PlayerLayoutWithFilters hasNoBgContent>
        <Grid>
          <Grid item>
            <div className="mx-4 mt-4">
              <SectionTopBar>
                <Typography variant="headingRegular">{heading}</Typography>
              </SectionTopBar>
            </div>
            {!playerId ? (
              <ContentState status={StatusTranslationKey.ERROR} />
            ) : (
              <DashboardWidgets entityId={playerId} widgets={playerDashboardWidgets} />
            )}
          </Grid>
        </Grid>
      </PlayerLayoutWithFilters>
    </>
  );
};
