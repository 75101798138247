import { selectedEventNamesAtom } from '@/atoms/filters/events';
import { sectionAtom } from '@/atoms/general';
import { metricKeyAtom } from '@/atoms/metric';
import { selectedVisualisationAtom } from '@/atoms/visualisation';
import { useExportInfo } from '@/hooks/exporting/useExportInfo';
import { snakeToCamel } from '@/utils/queries';
import { useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';
import { Suspense } from 'react';
import { SettingsButton } from '../button/SettingsButton';
import { VisualisationMenu } from '../visualisations/VisualisationMenu';
import { MoreOptionsMenuFallback } from '../menu/MoreOptionsMenu/MoreOptionsMenu';

const VisControlsFallback = () => (
  <>
    <SettingsButton />
    <MoreOptionsMenuFallback />
  </>
);

const VisControlsWithData = () => {
  const { t } = useTranslation(['visualisation', 'events', 'metrics']);
  const selectedVis = useAtomValue(selectedVisualisationAtom);
  const section = useAtomValue(sectionAtom);
  const selectedMetricKey = useAtomValue(metricKeyAtom);
  const entitySecondaryTitle = selectedMetricKey ? t(`${snakeToCamel(selectedMetricKey)}.name`, { ns: 'metrics' }) : '';
  const selectedEvents = useAtomValue(selectedEventNamesAtom);
  const translatedSelectedEvents = selectedEvents.map(key => ({
    label: t(`fields.event.type.${snakeToCamel(key)}`, 'events'),
    value: key,
  }));
  const dataLockerSecondaryTitle = useExportInfo(translatedSelectedEvents, 'Events', 3);

  const secondaryTitle = section === 'dataLocker' ? dataLockerSecondaryTitle : entitySecondaryTitle;

  return (
    <span className="flex" data-testid="vis-controls">
      <SettingsButton />
      <VisualisationMenu
        saveToReportProps={{
          visType: selectedVis.visualisationKey,
          dataType: 'event',
        }}
        exportModalProps={{
          visName: t(selectedVis.visualisationKey, { ns: 'visualisation' }),
          secondaryTitle,
        }}
      />
    </span>
  );
};

export const VisControls = () => (
  <Suspense fallback={<VisControlsFallback />}>
    <VisControlsWithData />
  </Suspense>
);
