import { EditColumnsButton } from '@/components/button/EditColumnsButton';
import { DefaultColumnsAtom, VisibleColumnsAtom } from '@/types/tableColumns';
import { ExportCsvMenuItem } from '../export/ExportCsvMenuItem';
import { MoreOptionsMenu } from '../menu/MoreOptionsMenu/MoreOptionsMenu';
import { SectionControlsWrapper } from './SectionControlsWrapper';

export const TableDataControls = ({
  visibleColumnsAtom,
  defaultColumnsAtom,
  heading,
}: {
  visibleColumnsAtom: VisibleColumnsAtom;
  defaultColumnsAtom: DefaultColumnsAtom;
  heading: string;
}) => (
  <SectionControlsWrapper heading={heading}>
    <EditColumnsButton visibleColumnsAtom={visibleColumnsAtom} defaultColumnsAtom={defaultColumnsAtom} />
    <MoreOptionsMenu>
      <ExportCsvMenuItem />
    </MoreOptionsMenu>
  </SectionControlsWrapper>
);
