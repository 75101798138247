import { useTranslation } from 'react-i18next';
import { useAtom } from 'jotai';
import { selectedEndDateAtom, selectedStartDateAtom } from '@/atoms/filters/highLevel';
import { DatePicker } from '../input/DatePicker';

export const DateRangeSelector = () => {
  const { t } = useTranslation('filters');
  const [selectedStartDate, setSelectedStartDate] = useAtom(selectedStartDateAtom);
  const [selectedEndDate, setSelectedEndDate] = useAtom(selectedEndDateAtom);

  return (
    <>
      <DatePicker
        label={t('startDate')}
        id="filter-start-date"
        value={selectedStartDate}
        onChange={setSelectedStartDate}
      />
      <DatePicker label={t('endDate')} id="filter-end-date" value={selectedEndDate} onChange={setSelectedEndDate} />
    </>
  );
};
