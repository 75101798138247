import { ExpandVideoButton } from '@/components/button/ExpandVideoButton';
import { CardHeader } from '@/components/card/Card';
import { DataLockerEventsTable } from '@/components/dataLocker/DataLockerEventsTable';
import { PreviewItemsLayout } from '@/components/layout/PreviewItemsLayout';
import { VisualisationComponent } from '@/components/visualisations/PitchVisualisation/VisualisationComponent';
import { dataLockerVisualisationComponents } from '../visComponents';

export const DataLockerVideoPreview = () => (
  <>
    <CardHeader>
      <ExpandVideoButton />
    </CardHeader>
    <PreviewItemsLayout>
      <VisualisationComponent allowInteraction visualisations={dataLockerVisualisationComponents} />
      <DataLockerEventsTable />
    </PreviewItemsLayout>
  </>
);
