import { selectedPlayerNameAtom } from '@/atoms/player/info';
import { playerIdAtom } from '@/atoms/player/player';
import { playerEventMetricFiltersAtom } from '@/atoms/queries/player/playerEventData';
import { PlayerNav } from '@/components/navigation/PlayerNav';
import { TableScopeProvider } from '@/components/tables/TableScopeProvider';
import { EntityTopBar } from '@/components/topBar/EntityTopBar';
import { layoutScopeAtoms } from '@/consts/scope';
import { usePlayerReferenceData } from '@/hooks/dataFetching/usePlayerReferenceData';
import { useAutoResetPagination } from '@/hooks/useAutoResetPagination';
import { useAutoResetSelectionLayer } from '@/hooks/useAutoResetSelectionLayer';
import { useSetMetricKey } from '@/hooks/useMetricKey';
import { useSetPlayerId } from '@/hooks/usePlayerId';
import { useAtomValue } from 'jotai';
import { ScopeProvider } from 'jotai-scope';
import { Outlet } from 'react-router-dom';
import { ContentState } from '@/components/contentState/ContentState';
import { StatusTranslationKey } from '@/types/status';
import { AppShell } from '../AppShell';
import { SectionWrapper } from '../SectionWrapper';

const PlayerLayoutInner = () => {
  usePlayerReferenceData();
  const playerEventMetricFilters = useAtomValue(playerEventMetricFiltersAtom);
  useAutoResetSelectionLayer(playerEventMetricFilters);
  useAutoResetPagination(playerEventMetricFilters);

  return (
    <SectionWrapper section="player">
      <Outlet />
    </SectionWrapper>
  );
};

const PlayerLayoutWithData = () => {
  useSetPlayerId();
  useSetMetricKey();
  const playerId = useAtomValue(playerIdAtom);

  return (
    <AppShell topBar={<EntityTopBar selectedEntityNameAtom={selectedPlayerNameAtom} />} subnav={<PlayerNav />}>
      <TableScopeProvider>
        {playerId ? <PlayerLayoutInner /> : <ContentState status={StatusTranslationKey.LOADING} />}
      </TableScopeProvider>
    </AppShell>
  );
};

export const PlayerLayout = () => (
  <ScopeProvider atoms={layoutScopeAtoms}>
    <PlayerLayoutWithData />
  </ScopeProvider>
);
