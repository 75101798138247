import { atom } from 'jotai';
import { unwrap } from 'jotai/utils';
import { atomWithSuspenseQuery } from 'jotai-tanstack-query';
import { CycleWithRels, GameWithRels, Team, TeamWithRels } from '@statsbomb/parachute-types';
import { fetchClientAtom } from '@/atoms/queries/client';
import { createOptionsAtom } from '@/utils/atoms/generic';
import { objSnakeToCamel } from '@/utils/queries';
import { createCompetitionOptionsAtom, createSeasonOptionsAtom } from '@/utils/atoms/cycles';
import { isSharingId } from '@/utils/array';
import { filterCyclesWithRelsByCompetition, filterCyclesBy } from '@/utils/filters';
import { sortCyclesBySeasonAndComp } from '@/utils/cycles';
import { teamIdAtom } from '../team/team';
import { unwrappedGenderAtom } from './userConfigs';
import { competitionIdsToFilterByAtom } from '../filters/highLevel/competitions';
import { rawCyclesAtom } from './cycles';
import { seasonIdsToFilterByAtom } from '../filters/highLevel/seasons';
import { unwrappedUserDefaultTeamIdAtom } from '../user';

const rawTeamsAtom = atomWithSuspenseQuery(get => {
  const { fetch } = get(fetchClientAtom);
  const queryKey = ['teams', get(unwrappedGenderAtom)];

  const queryFn = async ({ queryKey: [, gender] }: { queryKey: typeof queryKey }) => {
    if (!gender) {
      return [];
    }
    return (await fetch(`/teams?eq[gender]=${gender}`)) as Promise<TeamWithRels[]>;
  };

  return { queryKey, queryFn };
});

export const rawAllTeamsAtom = atomWithSuspenseQuery(get => ({
  queryKey: ['allTeams'],
  queryFn: async () => {
    const { fetch } = get(fetchClientAtom);
    return (await fetch(`/teams`)) as Promise<TeamWithRels[]>;
  },
}));

const allTeamsAtom = atom(async get => {
  const rawAllTeams = (await get(rawAllTeamsAtom)).data;
  return rawAllTeams;
});

export const teamsAtom = atom(async get => {
  const rawTeams = (await get(rawTeamsAtom)).data;
  const rawCycles = (await get(rawCyclesAtom)).data;
  const selectedCompetitionIds = get(competitionIdsToFilterByAtom);
  const selectedSeasonsIds = get(seasonIdsToFilterByAtom);

  // filter the cycles by selected competitions and seasons to create an array of "valid" cycles
  const filteredCyclesByCompetition = filterCyclesBy(rawCycles, selectedCompetitionIds, 'competition_id');
  const filteredCyclesBySeasons = filterCyclesBy(filteredCyclesByCompetition, selectedSeasonsIds, 'season_id');
  const validCycleIds = filteredCyclesBySeasons.map(cycle => cycle.cycle_id);

  // filter available teams by comparing the cycles they played in and the valid cycles ids
  const filteredTeamsByCycles =
    selectedCompetitionIds.length || selectedSeasonsIds.length
      ? rawTeams.filter(team => isSharingId(team.cycle_ids, validCycleIds))
      : rawTeams;

  return filteredTeamsByCycles;
});

export const teamsOptionsAtom = createOptionsAtom(teamsAtom, 'name', 'team_id', ['area_code']);
export const allTeamsOptionsAtom = createOptionsAtom(allTeamsAtom, 'name', 'team_id', ['area_code', 'gender']);

const rawTeamCyclesAtom = atomWithSuspenseQuery(get => {
  const { fetch } = get(fetchClientAtom);
  const queryKey = ['teamCycles', get(teamIdAtom)] as const;

  const queryFn = async ({ queryKey: [_, teamId] }: { queryKey: typeof queryKey }) =>
    (await fetch(`/team/${teamId}/cycles`)) as Promise<CycleWithRels[]>;
  return { queryKey, queryFn };
});

const teamCyclesAtom = atom(async get => {
  const rawTeamCycles = await get(rawTeamCyclesAtom);
  return rawTeamCycles.data.map(objSnakeToCamel);
});

export const teamCyclesOrderedAtom = atom(async get => {
  const teamCycles = await get(teamCyclesAtom);
  return sortCyclesBySeasonAndComp(teamCycles);
});

const filteredTeamCyclesSeasonAtom = atom(async get => {
  const teamCycles = await get(teamCyclesAtom);
  const selectedCompetitionIds = get(competitionIdsToFilterByAtom);

  return filterCyclesWithRelsByCompetition(teamCycles, selectedCompetitionIds);
});

export const teamCompetitionOptionsAtom = createCompetitionOptionsAtom(teamCyclesAtom);

export const teamSeasonOptionsAtom = createSeasonOptionsAtom(filteredTeamCyclesSeasonAtom);

const rawTeamGamesAtom = atomWithSuspenseQuery(get => {
  const { fetch } = get(fetchClientAtom);
  const queryKey = ['teamGames', get(teamIdAtom)] as const;

  const queryFn = async ({ queryKey: [_, teamId] }: { queryKey: typeof queryKey }) =>
    (await fetch(`/team/${teamId}/games`)) as Promise<GameWithRels[]>;

  return { queryKey, queryFn };
});

export const teamGamesAtom = atom(async get => {
  const teamGames = await get(rawTeamGamesAtom);
  const selectedTeamId = get(teamIdAtom);

  const formattedTeamGames = teamGames.data.map(objSnakeToCamel).map(game => ({
    ...game,
    team: { teamId: selectedTeamId },
  }));

  return formattedTeamGames;
});

export const rawSelectedTeamInfoAtom = atomWithSuspenseQuery(get => {
  const { fetch } = get(fetchClientAtom);
  const queryKey = ['selectedTeamInfo', get(teamIdAtom)] as const;

  const queryFn = async ({ queryKey: [_, teamId] }: { queryKey: typeof queryKey }) => {
    if (!teamId) return [];

    return (await fetch(`/teams?eq[team_id]=${teamId}`)) as Promise<Team[]>;
  };

  return { queryKey, queryFn };
});

export const selectedTeamInfoAtom = atom(async get => (await get(rawSelectedTeamInfoAtom)).data[0]);

export const unwrappedSelectedTeamInfoAtom = unwrap(selectedTeamInfoAtom, prev => prev || null);

const rawUserDefaultTeamCyclesAtom = atomWithSuspenseQuery(get => {
  const { fetch } = get(fetchClientAtom);
  const queryKey = ['userDefaultTeamCycles', get(unwrappedUserDefaultTeamIdAtom)] as const;

  const queryFn = async ({ queryKey: [_, teamId] }: { queryKey: typeof queryKey }) => {
    if (!teamId) return [];

    return (await fetch(`/team/${teamId}/cycles`)) as Promise<CycleWithRels[]>;
  };

  return { queryKey, queryFn };
});

export const userDefaultTeamCyclesOrderedAtom = atom(async get => {
  const rawUserDefaultTeamCycles = await get(rawUserDefaultTeamCyclesAtom);
  const userDefaultTeamCycles = rawUserDefaultTeamCycles.data.map(objSnakeToCamel);

  return sortCyclesBySeasonAndComp(userDefaultTeamCycles);
});
