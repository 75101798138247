import { QueryClient } from '@tanstack/query-core';
import env from '@/env';
import * as Sentry from '@sentry/react';

export const getQueryClient = () =>
  new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 3600000, // 1 hour
      },
    },
  });

export const getFetchClient = (authToken: Promise<string>) => async (input: RequestInfo, init?: RequestInit) => {
  const bearer = await authToken;

  // Wrapping the fetch within Sentry startSpan to allow Sentry to capture how long API calls take
  // https://docs.sentry.io/platforms/javascript/performance/instrumentation/custom-instrumentation/#start-span
  const result = await Sentry.startSpan({ op: 'fetch', name: `Fetch: ${input}` }, async span => {
    const response = await window.fetch(`${env.VITE_PARACHUTE_REST_API_ENDPOINT}${input}`, {
      ...init,
      headers: {
        ...init?.headers,
        Authorization: `Bearer ${bearer}`,
      },
    });

    if (!response.ok) {
      // eslint-disable-next-line no-console
      console.error(response.statusText);
      Sentry.captureException(new Error(`Fetch error: ${response.statusText}`), {
        data: {
          url: input,
          status: response.status,
        },
      });
    }
    span?.setStatus(response.ok ? 'ok' : 'unknown_error');

    // for requests that don't return a body, response.json() gives errors
    // this check is to confirm there is a request body and then parse it
    // else just return the response
    const contentType = response.headers.get('content-type');
    if (contentType && contentType.indexOf('application/json') !== -1) {
      const data = await response.json();
      return data;
    }
    return response;
  });

  return result;
};
