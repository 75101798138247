import { useGetPitchRotation } from '@/hooks/useGetPitchRotation';
import { usePitchCrop } from '@/hooks/usePitchCrop';
import { FallBackProps } from '@/types/visualisation';
import { PitchViz, FallbackLayer } from '@statsbomb/kitbag-datavis';
import { useTranslation } from 'react-i18next';

export const FallbackLoading = ({ isPreview = false }: FallBackProps) => {
  const { t } = useTranslation('general');
  const pitchRotation = useGetPitchRotation(isPreview);
  const visibleArea = usePitchCrop(isPreview);

  return (
    <PitchViz
      rotationName={pitchRotation}
      pitchFocusZoneName={visibleArea}
      overlay={
        <FallbackLayer rotationName={pitchRotation} pitchFocusZoneName={visibleArea} fallbackText={[t('loading')]} />
      }
    />
  );
};
