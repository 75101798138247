import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import enJSON from './locale/en.json';
import esJSON from './locale/es.json';
import deJSON from './locale/de.json';
import frJSON from './locale/fr.json';
import nlJSON from './locale/nl.json';
import { SUPPORTED_LOCALES } from './consts/i18n';

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    /* initReactI18next config */
    resources: {
      en: { ...enJSON },
      es: { ...esJSON },
      de: { ...deJSON },
      fr: { ...frJSON },
      nl: { ...nlJSON },
    },
    fallbackLng: SUPPORTED_LOCALES[0].code,
    supportedLngs: SUPPORTED_LOCALES.map(local => local.code),
    load: 'languageOnly',
    lowerCaseLng: true,
    nonExplicitSupportedLngs: true,

    /* LanguageDetector config */
    detection: {
      order: ['path', 'localStorage', 'navigator'],
      caches: ['localStorage'],
      lookupFromPathIndex: 0,
      // Uses only the language part of the locale - e.g. en-GB becomes en
      convertDetectedLanguage: lng => lng.split('-')[0],
    },
  });
