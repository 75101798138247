import { useTranslation } from 'react-i18next';
import { Button, Checkbox, Typography } from '@statsbomb/kitbag-components';
import { StringOption } from '@/types/generic';

export const CheckboxGroupBase = ({
  selectedValues,
  options,
  header,
  onChange,
  onChangeAll,
}: {
  selectedValues: string[];
  options: StringOption[];
  header: string;
  onChange: (value: string) => void;
  onChangeAll?: () => void;
}) => {
  const { t } = useTranslation('general');
  const isAllSelected = selectedValues.length === options.length;

  return (
    <div className="flex flex-col gap-2">
      {header && (
        <div className="flex justify-between items-center">
          <Typography variant="headingMedium">{`${header}`}</Typography>
          {onChangeAll && (
            <Button variant="secondary" size="small" onClick={onChangeAll}>
              {t(isAllSelected ? 'deselectAll' : 'selectAll')}
            </Button>
          )}
        </div>
      )}
      <div className="flex flex-col gap-2">
        {options.map(({ label, value }) => {
          const isChecked = selectedValues.includes(value);
          return (
            <Checkbox
              key={value}
              checked={isChecked}
              id={value}
              label={label}
              onChange={() => onChange(value)}
              size="small"
              withLabel
            />
          );
        })}
      </div>
    </div>
  );
};
