import { atom, SetStateAction } from 'jotai';

export const atomWithDebounce = <T>(initialValue: T, initialTimeoutDelay = 0) => {
  const timeoutDelayAtom = atom(initialTimeoutDelay);
  const prevTimeoutAtom = atom<NodeJS.Timeout | undefined>(undefined);

  // DO NOT EXPORT currentValueAtom as using this atom to set state can cause
  // inconsistent state between currentValueAtom and debouncedValueAtom
  const currentValueAtom = atom(initialValue);

  const debouncedValueAtom = atom(initialValue, (get, set, update: SetStateAction<T>) => {
    set(currentValueAtom, update);
    clearTimeout(get(prevTimeoutAtom));
    const timeoutDelay = get(timeoutDelayAtom);
    const nextTimeoutId = setTimeout(() => set(debouncedValueAtom, update), timeoutDelay);
    set(prevTimeoutAtom, nextTimeoutId);
  });

  return {
    currentValueAtom: atom(get => get(currentValueAtom)),
    debouncedValueAtom,
    prevTimeoutAtom,
    timeoutDelayAtom,
  };
};
