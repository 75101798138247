import { ReactNode } from 'react';
import { EventMapBaseProps, EventMapProps, UnwrappedPitchEventsAtom } from '@/types/visualisation';
import { EventArrowLayer, EventMarkerLayer, PitchSelectionLayer, PitchViz } from '@statsbomb/kitbag-datavis';
import { useAtom, useAtomValue } from 'jotai';
import { activeRectAtom, selectedVisEventsAtom } from '@/atoms/vis/selection';
import { useResetSelectionLayer } from '@/hooks/useResetSelectionLayer';
import { useNavigateToPreview } from '@/hooks/useNavigateToPreview';
import { useDragEndSelectEvents } from '@/hooks/useDragEndSelectEvents';
import { useCurrentlySelectedVideoId } from '@/hooks/useCurrentlySelectedVideoId';
import { MARKER_HIGHLIGHTED_COLOUR } from '@/consts/markerColours';
import { usePitchCrop } from '@/hooks/usePitchCrop';
import { Nullable } from '@/types/generic';
import { loadable } from 'jotai/utils';
import { useGetPitchRotation } from '@/hooks/useGetPitchRotation';
import { pitchMarkerColouringModeAtom, showMarkerStartAtom } from '@/atoms/visualisation';
import { isNullish } from '@/utils/general';
import { EventSuccessFail } from '@/types/event';
import { useSuccessFailColouring } from '@/hooks/useSuccessFailColouring';
import { FallbackNoData } from './FallbackNoData';
import { FallbackLoading } from './FallbackLoading';
import { EventMapLegend } from './EventMap/EventMapLegend';

const SelectionLayerWrapper = ({
  unwrappedPitchEventsAtom,
  children,
}: {
  unwrappedPitchEventsAtom: UnwrappedPitchEventsAtom;
  children: ReactNode;
}) => {
  const resetSelectionLayer = useResetSelectionLayer();
  const [activeRect, setActiveRect] = useAtom(activeRectAtom);

  const onDragEnd = useDragEndSelectEvents(unwrappedPitchEventsAtom);

  return (
    <PitchSelectionLayer
      onDragStart={resetSelectionLayer}
      onDragEnd={onDragEnd}
      selectionLayerAttributes={activeRect}
      setSelectionLayerAttributes={setActiveRect}
    >
      {children}
    </PitchSelectionLayer>
  );
};

const EventMapBase = ({
  selectedItemIds,
  markerEvents,
  arrowEvents,
  allEvents,
  onMarkerClick,
  allowInteraction,
  unwrappedPitchEventsAtom,
  isPreview = false,
}: EventMapBaseProps) => {
  const pitchMarkerColouringMode = useAtomValue(pitchMarkerColouringModeAtom);
  const pitchRotation = useGetPitchRotation(isPreview);
  const visibleArea = usePitchCrop(isPreview);
  const showMarkerStart = useAtomValue(showMarkerStartAtom);

  const currentlySelectedVideoId = useCurrentlySelectedVideoId();
  const successFailColouring = useSuccessFailColouring();

  const getMarkerColour = (videoEventId: string | undefined, currentlySelectedVideoId: Nullable<string>) =>
    videoEventId === currentlySelectedVideoId ? MARKER_HIGHLIGHTED_COLOUR : undefined;

  const currentVideoEventId = [allEvents.find(item => item.videoEventId === currentlySelectedVideoId)?.eventId];

  const getLayerColour = (videoEventId: string | undefined, successFail: EventSuccessFail) => {
    if (pitchMarkerColouringMode === 'default') return getMarkerColour(videoEventId, currentlySelectedVideoId);

    return successFailColouring[successFail];
  };

  const EventMapLayers = (
    <>
      <EventMarkerLayer
        onMarkerClick={onMarkerClick}
        markers={markerEvents?.map(({ eventId: id, startX, startY, endX, endY, successFail, videoEventId }) => {
          const x = showMarkerStart || isNullish(endX) ? startX : endX;
          const y = showMarkerStart || isNullish(endY) ? startY : endY;

          return {
            id,
            x,
            y,
            fill: getLayerColour(videoEventId, successFail),
            shapeName: 'CIRCLE',
          };
        })}
        selectedItemIds={selectedItemIds}
        highlightedItemIds={currentVideoEventId}
      />
      <EventArrowLayer
        onMarkerClick={onMarkerClick}
        arrows={arrowEvents?.map(({ eventId: id, startX, startY, endX, endY, successFail, videoEventId }) => ({
          id,
          startX,
          startY,
          endX,
          endY,
          stroke: getLayerColour(videoEventId, successFail),
          arrowHeadName: 'EQUILATERAL',
        }))}
        selectedItemIds={selectedItemIds}
        highlightedItemIds={currentVideoEventId}
      />
    </>
  );

  return (
    <figure className="h-full flex flex-col gap-2">
      <PitchViz
        className="flex-1"
        rotationName={pitchRotation}
        pitchFocusZoneName={visibleArea}
        overlay={
          allowInteraction ? (
            <SelectionLayerWrapper unwrappedPitchEventsAtom={unwrappedPitchEventsAtom}>
              {EventMapLayers}
            </SelectionLayerWrapper>
          ) : (
            EventMapLayers
          )
        }
      />
      <EventMapLegend />
    </figure>
  );
};

const EventMapWithData = ({
  pitchEventsAtom,
  allowInteraction,
  unwrappedPitchEventsAtom,
  isPreview,
}: EventMapProps) => {
  const [selectedVisEvents, setSelectedEvents] = useAtom(selectedVisEventsAtom);
  const { markerEvents, arrowEvents } = useAtomValue(pitchEventsAtom);
  const allEvents = [...markerEvents, ...arrowEvents];
  const resetSelectionLayer = useResetSelectionLayer();
  const navigateToPreview = useNavigateToPreview();

  if (allEvents.length === 0) return <FallbackNoData />;

  return (
    <EventMapBase
      {...{ arrowEvents, markerEvents, allEvents, unwrappedPitchEventsAtom, allowInteraction, isPreview }}
      onMarkerClick={
        allowInteraction
          ? (_, id) => {
              resetSelectionLayer();
              setSelectedEvents(allEvents.filter(({ eventId }) => eventId === id));

              navigateToPreview();
            }
          : undefined
      }
      selectedItemIds={selectedVisEvents?.map(({ eventId }) => eventId)}
    />
  );
};

export const EventMap = ({ fallback = <FallbackLoading />, ...props }: EventMapProps & { fallback?: ReactNode }) => {
  const loadablePitchEventsAtom = useAtomValue(loadable(props.pitchEventsAtom));
  return loadablePitchEventsAtom.state === 'loading' ? fallback : <EventMapWithData {...props} />;
};
