import { competitionIdsToFilterByAtom } from '@/atoms/filters/highLevel/competitions';
import { defaultSeasonsAtom } from '@/atoms/filters/highLevel/seasons';
import { DEFAULT_DEBOUNCE_MS } from '@/consts/api';
import { Atom, useAtomValue, useSetAtom } from 'jotai';
import { NestedObject } from '@/types/object';
import { FilterParamsDebounceObject } from '@/types/atom';
import { useDeepEffect } from '../useDeepEffect';

// Hook to set the filter params used in the API calls
// Pass arguments relevant to the data locker section required
// Uses atomWithDebounce with a timeout - if another call is fired before the timeout has
// expired the call is cancelled preventing excessive API calls being made on the back-end.
export const useSetDataLockerDebouncedFilterParams = <T>({
  filterParamsAtom,
  filterParamsDebounceObject,
}: {
  filterParamsAtom: Atom<NestedObject>;
  filterParamsDebounceObject: FilterParamsDebounceObject<T>;
}) => {
  const defaultSeasons = useAtomValue(defaultSeasonsAtom);
  const competitionsToQuery = useAtomValue(competitionIdsToFilterByAtom);
  const filterParams = useAtomValue(filterParamsAtom);
  const { debouncedValueAtom, timeoutDelayAtom } = filterParamsDebounceObject;
  const setFilterParamsDebounce = useSetAtom(debouncedValueAtom);
  const setFilterParamsTimeoutDelay = useSetAtom(timeoutDelayAtom);

  useDeepEffect(() => {
    // These checks are here to prevent queries running before season options and competition options have been loaded.
    if (!defaultSeasons || competitionsToQuery.length === 0) return;
    setFilterParamsDebounce(filterParams);
    // Ensure that the timeout delay is set (we initialise this to 0 so that we don't debounce
    // on initial page load)
    setFilterParamsTimeoutDelay(DEFAULT_DEBOUNCE_MS);
  }, [defaultSeasons, competitionsToQuery, filterParams]);
};
