import { Nullable, Option as OptionType } from '@/types/generic';
import { Checkbox, Dropdown, Typography } from '@statsbomb/kitbag-components';
import { MouseEventHandler, ReactNode, Ref, ButtonHTMLAttributes } from 'react';
import { FixedSizeList as List } from 'react-window';
import { useTranslation } from 'react-i18next';
import { FlagIcon } from '../icons/FlagIcon';
import { GenderIcon } from '../icons/GenderIcon';

interface OptionProps {
  data: {
    label: string;
    value: string;
    optional?: { countryCode?: string; gender?: string };
  };
  isFocused: boolean;
  innerRef: Ref<HTMLButtonElement>;
  innerProps: ButtonHTMLAttributes<HTMLButtonElement>;
}

interface FilterDropdownProps {
  selectedOption?: Nullable<OptionType[]>;
  id: string;
  onChange: (options: OptionType[]) => void;
  label: string;
  options?: OptionType[];
  placeholder?: string;
  isLoading?: boolean;
}

const REACT_SELECT_DEFAULT_HEIGHT = 22;

export const MenuList = ({
  children,
  height = REACT_SELECT_DEFAULT_HEIGHT,
  maxHeight,
}: {
  height?: number;
  maxHeight: number;
  children: ReactNode[];
}) => {
  const { t } = useTranslation('general');
  return children.length ? (
    <List
      height={Math.min(maxHeight, children.length * height)}
      width="100%"
      itemCount={children.length}
      itemSize={height}
    >
      {({ index, style }) => <div style={style}>{children[index]}</div>}
    </List>
  ) : (
    <List height={height} width="100%" itemCount={1} itemSize={height}>
      {({ style }) => (
        <div style={{ ...style, textAlign: 'center', fontSize: 12, paddingTop: 2 }}>{t('noOptions')}</div>
      )}
    </List>
  );
};

const DropdownFlag = ({ countryCode }: { countryCode?: string }) => (
  <span className="w-4 ml-auto">{countryCode && <FlagIcon countryCode={countryCode} size="small" />}</span>
);

const DropdownItem = ({
  innerRef,
  isFocused,
  onClick,
  value,
  children,
}: {
  onClick: MouseEventHandler<HTMLButtonElement> | undefined;
  isFocused: boolean;
  innerRef: React.Ref<HTMLButtonElement>;
  value: string;
  children: ReactNode;
}) => (
  <button
    type="button"
    ref={innerRef}
    className={`flex items-center w-full p-1 truncate gap-2 hover:bg-canvas-secondary-main ${
      isFocused && '!bg-canvas-secondary-main'
    }`}
    onClick={onClick}
    key={`option-${value}`}
  >
    {children}
  </button>
);

export const OptionWithFlagAndGender = ({
  data: { label, value, optional: { countryCode, gender } = {} },
  innerRef,
  isFocused,
  innerProps: { onClick },
}: OptionProps) => (
  <DropdownItem innerRef={innerRef} isFocused={isFocused} onClick={onClick} value={value}>
    <Typography as="span" className="truncate pr-6" variant="bodyMedium" title={label}>
      {label}
    </Typography>
    <span className="absolute right-6 scale-90">
      <GenderIcon gender={gender!} />
    </span>
    <DropdownFlag countryCode={countryCode} />
  </DropdownItem>
);

const MultiOptionWithFlag = ({
  data: { label, value, optional: { countryCode } = {} },
  isSelected,
  innerRef,
  isFocused,
  innerProps: { onClick },
}: OptionProps & { isSelected: boolean }) => (
  <DropdownItem innerRef={innerRef} isFocused={isFocused} onClick={onClick} value={value}>
    <Checkbox
      withLabel={false}
      label={label}
      id={`option-${value}`}
      checked={isSelected}
      onChange={onClick}
      size="small"
    />
    <Typography className="truncate" variant="bodyMedium" title={label}>
      {label}
    </Typography>
    <DropdownFlag countryCode={countryCode} />
  </DropdownItem>
);

export const FilterDropdown = ({
  selectedOption,
  id,
  onChange,
  label,
  options,
  placeholder,
  isLoading,
}: FilterDropdownProps) => {
  const { t } = useTranslation('general');

  return (
    <Dropdown
      components={{ MenuList, Option: MultiOptionWithFlag }}
      value={selectedOption}
      id={id}
      onChange={onChange}
      label={label}
      labelPosition="top"
      isSearchable
      isMulti
      options={options}
      placeholder={placeholder}
      isLoading={isLoading}
      selectedChipText={t('selectedChipText')}
    />
  );
};
