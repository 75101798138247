import { useShouldShowHiddenFeature } from '@/hooks/useShouldShowHiddenFeature';
import { PageSubNav } from './PageSubNav';

const playerNavItems = [
  {
    translation: { key: 'secondary.overview' },
    route: 'radar',
  },
  {
    translation: { key: 'secondary.pitchVis' },
    route: 'vis',
  },
  {
    translation: { key: 'secondary.games' },
    route: 'matches',
  },
];

export const PlayerNav = () => {
  /* TODO (PPC-1541: Add route to playerNavItems above, remove istanbul ignore below & delete restriction when ready to deploy) */
  const shouldShowFeature = useShouldShowHiddenFeature();

  /* istanbul ignore next */
  const navItems = shouldShowFeature
    ? [
        {
          translation: { key: 'secondary.dashboard' },
          route: 'dashboard',
        },
        ...playerNavItems,
      ]
    : playerNavItems;

  return <PageSubNav items={navItems} />;
};
