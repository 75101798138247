import { DefaultColumnsAtom } from '@/types/tableColumns';
import { atom, PrimitiveAtom } from 'jotai';

export const getReadWriteVisibleColumnsAtom = (
  visibleColumnsUserSelectedAtom: PrimitiveAtom<string[] | undefined>,
  defaultColumnsAtom: DefaultColumnsAtom,
) =>
  atom(
    async get => {
      const visibleColumnsUserSelected = get(visibleColumnsUserSelectedAtom);
      const defaultColumns = await get(defaultColumnsAtom);
      return visibleColumnsUserSelected || defaultColumns;
    },
    (_, set, value: string[]) => set(visibleColumnsUserSelectedAtom, value),
  );
