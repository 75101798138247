import { atom } from 'jotai';
import { EventFilter, EventMarker, EventTypeOutcomes, EventWithRels } from '@statsbomb/parachute-types';
import { atomWithSuspenseQuery } from 'jotai-tanstack-query';
import { keeperActionFilterParamsAtom, selectedBodyPartsAtom, selectedPassHeightAtom } from '@/atoms/eventData';
import { selectedEndDateAtom, selectedStartDateAtom } from '@/atoms/filters/highLevel';
import { fetchClientAtom } from '@/atoms/queries/client';
import { EVENT_MARKERS_LIMIT } from '@/consts/visualisations';
import { KeysToCamelCase, Nullable } from '@/types/generic';
import { NestedObject } from '@/types/object';
import { convertFilterParamsToString } from '@/utils/api';
import {
  convertMarkerDataIntoMarkers,
  convertMarkerDataIntoVideoDescriptions,
  processEventDataAtom,
} from '@/utils/atoms/eventData';
import { toObject } from '@/utils/object';
import { objSnakeToCamel } from '@/utils/queries';
import { eventsUrl } from '@/query/url';
import { atomWithDebounce } from '../debounce';
import { selectedEventNamesAtom, selectedOutcomesAtom } from '../filters/events';
import { competitionIdsToFilterByAtom } from '../filters/highLevel/competitions';
import { selectedPlayerIdsAtom } from '../filters/highLevel/players';
import { seasonIdsToFilterByAtom } from '../filters/highLevel/seasons';
import { selectedTeamIdsAtom } from '../filters/highLevel/teams';
import { selectedPitchFilterCoordsAtom } from '../filters/pitchFilter';
import { pageAndOrderParamsAtom } from '../general';

const nullChecks = { null: { 'event.player_id': false, 'event.team_id': false } };

export const eventDataFilterParamsAtom = atom(get => ({
  gte: toObject('game.date', get(selectedStartDateAtom)),
  lte: toObject('game.date', get(selectedEndDateAtom)),
  eq: {
    ...toObject('event.competition_id', get(competitionIdsToFilterByAtom)),
    ...toObject('event.player_id', get(selectedPlayerIdsAtom)),
    ...toObject('event.team_id', get(selectedTeamIdsAtom)),
    ...toObject('event.season_id', get(seasonIdsToFilterByAtom)),
    ...toObject('event.type', get(selectedEventNamesAtom)),
    ...toObject('event.attributes.outcome', get(selectedOutcomesAtom)),
    ...toObject('event.attributes.body_part', get(selectedBodyPartsAtom)),
    ...toObject('event.attributes.pass_height', get(selectedPassHeightAtom)),
    ...get(keeperActionFilterParamsAtom),
  },
  inside: get(selectedPitchFilterCoordsAtom),
  ...nullChecks,
}));

export const eventDataFilterParamsDebounceObject = atomWithDebounce<Nullable<NestedObject>>(null);
const { debouncedValueAtom: debouncedEventDataFilterParamsAtom } = eventDataFilterParamsDebounceObject;

const rawEventDataAtom = atomWithSuspenseQuery(get => {
  const queryKey = ['events', get(debouncedEventDataFilterParamsAtom), get(pageAndOrderParamsAtom)] as const;
  const queryFn = async ({ queryKey: [, filterParams, pageAndOrderParams] }: { queryKey: typeof queryKey }) => {
    if (!filterParams) return [];
    const { fetch } = get(fetchClientAtom);
    return (await fetch(eventsUrl({ ...pageAndOrderParams, ...filterParams }))) as Promise<EventWithRels[]>;
  };
  return { queryKey, queryFn };
});

export const eventDataAtom = atom(async get => processEventDataAtom(await get(rawEventDataAtom)));

const rawEventDataCountAtom = atomWithSuspenseQuery(get => {
  const queryKey = ['eventCounts', get(debouncedEventDataFilterParamsAtom)] as const;

  const queryFn = async ({ queryKey: [, filterParams] }: { queryKey: typeof queryKey }) => {
    if (!filterParams) return 0;
    const { fetch } = get(fetchClientAtom);
    return (await fetch(`/events/count${convertFilterParamsToString(filterParams)}`)) as Promise<number>;
  };

  return { queryKey, queryFn };
});

export const eventsDataCountAtom = atom(async get => (await get(rawEventDataCountAtom)).data);

const rawEventMarkersAtom = atomWithSuspenseQuery(get => {
  const queryKey = ['eventMarkers', get(debouncedEventDataFilterParamsAtom)] as const;

  const queryFn = async ({ queryKey: [, filterParams] }: { queryKey: typeof queryKey }) => {
    if (!filterParams) return [];
    const { fetch } = get(fetchClientAtom);
    return (await fetch(
      `/event-markers${convertFilterParamsToString({ ...filterParams, limit: EVENT_MARKERS_LIMIT })}`,
    )) as Promise<EventMarker[]>;
  };

  return { queryKey, queryFn };
});

export const eventMarkersAtom = atom(async get => {
  const rawEventMarkersData = await get(rawEventMarkersAtom);
  return convertMarkerDataIntoMarkers(rawEventMarkersData.data);
});

const rawEventTypeOutcomesAtom = atomWithSuspenseQuery(get => {
  const queryKey = ['eventTypeOutcomes'] as const;

  const queryFn = async () => {
    const { fetch } = get(fetchClientAtom);
    return (await fetch(`/event/type-outcomes${convertFilterParamsToString(nullChecks)}`)) as Promise<
      EventTypeOutcomes[]
    >;
  };

  return { queryKey, queryFn };
});

const rawGroupedEventTypesAtom = atomWithSuspenseQuery(get => {
  const queryKey = ['groupedEventTypes'] as const;

  const queryFn = async () => {
    const { fetch } = get(fetchClientAtom);
    return (await fetch(`/event-filters`)) as Promise<EventFilter[]>;
  };

  return { queryKey, queryFn };
});

const convertToGroupedOptions = (groupedEventTypes: KeysToCamelCase<EventFilter>[]) => {
  const options = groupedEventTypes.reduce(
    (
      acc: { label: string; options: { value: string; label: string; sortOrder: number }[] }[],
      { eventGroup, eventType, sortOrder },
    ) => {
      const group = acc.find(({ label }) => label === eventGroup);
      if (group) {
        group.options.push({ value: eventType, label: eventType, sortOrder });
      } else {
        acc.push({ label: eventGroup, options: [{ value: eventType, label: eventType, sortOrder }] });
      }
      return acc;
    },
    [],
  );

  return options.map(group => ({
    label: group.label,
    options: group.options.sort((a, b) => a.sortOrder - b.sortOrder).map(({ value, label }) => ({ value, label })),
  }));
};

export const eventNamesAtom = atom(async get => {
  const groupedEventNames = (await get(rawGroupedEventTypesAtom)).data.map(objSnakeToCamel);
  return convertToGroupedOptions(groupedEventNames);
});

export const eventOutcomesAtom = atom(async get => {
  const selectedTypes = get(selectedEventNamesAtom);
  const typeOutcomes = (await get(rawEventTypeOutcomesAtom)).data;
  const availableOutcomes = [
    ...new Set(
      (selectedTypes.length ? typeOutcomes.filter(({ type }) => selectedTypes.includes(type)) : typeOutcomes).flatMap(
        ({ outcomes }) => outcomes,
      ),
    ),
    // we don't want to support filtering by no_outcome yet as the API currently won't run this query properly
  ].filter(outcome => outcome !== 'no_outcome');
  return availableOutcomes;
});

export const eventVideoDescriptionsAtom = atom(async get => {
  const rawEventMarkersData = await get(rawEventMarkersAtom);
  return convertMarkerDataIntoVideoDescriptions(rawEventMarkersData.data);
});
