import { RadioButton, RadioGroup, Typography } from '@statsbomb/kitbag-components';
import { useAtom, useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';
import { isStartPlacementAtom, showArrowsAtom } from '@/atoms/visualisation';

export const PitchMarkerPlacementToggle = () => {
  const { t } = useTranslation('visualisation');
  const [isStartPlacement, setIsStartMarkerPlacement] = useAtom(isStartPlacementAtom);
  const showArrows = useAtomValue(showArrowsAtom);

  return (
    <RadioGroup groupId="pitch-marker-placement" groupTitle={t('markerPlacement.title')} size="regular">
      <Typography variant="bodySmall">{t('markerPlacement.description')}</Typography>
      <RadioButton
        checked={isStartPlacement}
        onChange={() => setIsStartMarkerPlacement(true)}
        id="marker-placement-start-option"
        label={t('markerPlacement.start')}
        value="true"
        size="small"
        disabled={showArrows}
      />
      <RadioButton
        checked={!isStartPlacement}
        onChange={() => setIsStartMarkerPlacement(false)}
        id="marker-placement-end-option"
        label={t('markerPlacement.end')}
        value="false"
        size="small"
        disabled={showArrows}
      />
    </RadioGroup>
  );
};
