import { deselectedGamesAtom } from '@/atoms/filters/highLevel';
import { selectedCompetitionIdsAtom } from '@/atoms/filters/highLevel/competitions';
import { selectedPlayerIdsAtom } from '@/atoms/filters/highLevel/players';
import { selectedPositionsAtom } from '@/atoms/filters/highLevel/positions';
import { selectedSeasonIdsAtom } from '@/atoms/filters/highLevel/seasons';
import { selectedTeamIdsAtom } from '@/atoms/filters/highLevel/teams';
import { sectionAtom } from '@/atoms/general';
import { playerIdAtom } from '@/atoms/player/player';
import { widgetGenderAtom } from '@/atoms/queries/userConfigs';
import { teamIdAtom } from '@/atoms/team/team';
import { ScopeProvider } from 'jotai-scope';
import React, { ReactNode } from 'react';

export const WidgetFiltersModalScopeProvider = ({ children }: { children: ReactNode }) => (
  <ScopeProvider
    atoms={[
      sectionAtom,
      selectedCompetitionIdsAtom,
      selectedSeasonIdsAtom,
      selectedTeamIdsAtom,
      selectedPlayerIdsAtom,
      selectedPositionsAtom,
      deselectedGamesAtom,
      playerIdAtom,
      teamIdAtom,
      widgetGenderAtom,
    ]}
  >
    {children}
  </ScopeProvider>
);
