import { Menu, MenuItem, MenuItemText, Icon, ButtonWithMenu } from '@statsbomb/kitbag-components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const SupportLinks = ({ isMobile }: { isMobile?: boolean }) => {
  const { t } = useTranslation('general');
  const [isOpen, setIsOpen] = useState(false);
  const menuItems = [
    { label: t('goToAssist'), link: 'https://statsbombhelp.zendesk.com/hc/' },
    { label: t('contactUs'), link: 'mailto:support@statsbomb.com' },
    { label: t('shareFeedback'), link: 'https://info.statsbomb.com/StatsBomb-Beta-Testing' },
    {
      label: t('termsAndConditions'),
      link: 'https://docs.google.com/document/d/e/2PACX-1vS8lFrLU7iLYmn5JF02DufykVas3T6mBfXSyr7NbUGnWzxg-rKLr0mN-sqFeascpqK7oY6dxb34-B46/pub',
    },
  ];

  return (
    <ButtonWithMenu
      isOpen={isOpen}
      placement="bottom-end"
      onClick={() => setIsOpen(true)}
      onOutsideClick={() => setIsOpen(false)}
      variant={isMobile ? 'ghost' : 'secondary'}
      title={t('support')}
      icon="Support"
      size={isMobile ? 'regular' : 'small'}
    >
      <Menu onOutsideClick={() => setIsOpen(false)}>
        {menuItems.map(item => (
          <MenuItem key={item.label}>
            <a
              className="h-full w-full flex items-center justify-between"
              href={item.link}
              target="_blank"
              rel="noreferrer"
            >
              <MenuItemText>{item.label}</MenuItemText>
              <Icon variant="ChevronRight" size="small" />
            </a>
          </MenuItem>
        ))}
      </Menu>
    </ButtonWithMenu>
  );
};
