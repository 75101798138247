import { atomsToHydrateAtom, setAtomsToHydrateAtom } from '@/atoms/hydration';
import { useAtom, useSetAtom } from 'jotai';
import { useEffect } from 'react';

export const useAtomsToHydrate = () => {
  const [atomsToHydrate, setAtomsToHydrate] = useAtom(atomsToHydrateAtom);
  const setAtoms = useSetAtom(setAtomsToHydrateAtom);

  /* set atoms and clear the atomsToHydrate afterwards */
  useEffect(() => {
    if (atomsToHydrate) {
      setAtoms(atomsToHydrate);
      setAtomsToHydrate(null);
    }
  }, [atomsToHydrate]);
};
