import { atom } from 'jotai';
import { atomWithSuspenseQuery } from 'jotai-tanstack-query';
import { Season } from '@statsbomb/parachute-types';
import { fetchClientAtom } from '@/atoms/queries/client';
import { competitionsAtom } from './competitions';
import { rawCyclesAtom } from './cycles';
import { competitionIdsToFilterByAtom } from '../filters/highLevel/competitions';

const rawSeasonsAtom = atomWithSuspenseQuery(get => ({
  queryKey: ['seasons'],
  queryFn: async () => {
    const { fetch } = get(fetchClientAtom);
    return (await fetch(`/seasons`)) as Promise<Season[]>;
  },
}));

export const seasonsAtom = atom(async get => {
  // use competitions and cycles to filter seasons by gender
  const [competitions, cycles, seasons] = await Promise.all([
    get(competitionsAtom),
    get(rawCyclesAtom),
    get(rawSeasonsAtom),
  ]);
  const selectedCompetitionIds = get(competitionIdsToFilterByAtom);

  // filter for the cycles that exist in the competitions
  // (At the time of writing we do this just to ensure we only get seasons
  // for the correct gender.
  // But this will just ensure that we only show seasons which are applicable
  // to the competitions which are available to us)
  const cyclesByAvailableCompetitions = cycles.data.filter(cycle =>
    competitions.some(competition => competition.competition_id === cycle.competition_id),
  );
  // then filter seasons by selected competitions, if applicable
  const cyclesBySelectedCompetition = selectedCompetitionIds.length
    ? cyclesByAvailableCompetitions.filter(cycle => selectedCompetitionIds.includes(cycle.competition_id))
    : cyclesByAvailableCompetitions;
  // then filter the seasons that exist in the filtered cycles result
  const seasonsByAvailableCycles = seasons.data.filter(season =>
    cyclesBySelectedCompetition.some(cycle => cycle.season_id === season.season_id),
  );

  return seasonsByAvailableCycles;
});
