import { useCallback } from 'react';

export const useUpdateQueryString = (searchParams: URLSearchParams) =>
  useCallback(
    (paramsToAdd: Array<{ key: string; value: string }>, paramsToDelete?: Array<string>) => {
      const params = new URLSearchParams(searchParams);

      paramsToDelete?.forEach(key => {
        params.delete(key);
      });

      paramsToAdd.forEach(({ key, value }) => {
        params.set(key, value);
      });

      return params.toString();
    },
    [searchParams],
  );
