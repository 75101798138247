import { useNavigate } from 'react-router-dom';
import { useKitbagAuth } from '@statsbomb/kitbag-auth';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Loading } from '../translations/Generic';

export const RedirectToLocalised = () => {
  const navigate = useNavigate();
  const { isLoading } = useKitbagAuth();
  const {
    i18n: { language },
  } = useTranslation();

  useEffect(() => {
    if (!isLoading) {
      navigate(`/${language}`);
    }
  }, [isLoading]);

  return <Loading />;
};
