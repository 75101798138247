import { TEAM_MATCHES_DEFAULT_COLUMNS } from '@/components/tables/GameAggregatesTable.constants';
import { atom } from 'jotai';
import { unwrap } from 'jotai/utils';
import { getReadWriteVisibleColumnsAtom } from '../../utils';

export const teamMatchDataVisibleColumnsUserSelectedAtom = atom<string[] | undefined>(undefined);

export const teamMatchDataDefaultColumnsAtom = atom(async () => [...TEAM_MATCHES_DEFAULT_COLUMNS]);

export const teamMatchDataVisibleColumnsAtom = getReadWriteVisibleColumnsAtom(
  teamMatchDataVisibleColumnsUserSelectedAtom,
  teamMatchDataDefaultColumnsAtom,
);

export const unwrappedTeamMatchDataVisibleColumnsAtom = unwrap(teamMatchDataVisibleColumnsAtom, prev => prev || []);
