import { Children, ReactNode } from 'react';
import { useAtomValue } from 'jotai';
import { exportContentAtom } from '@/atoms/exporting';
import { ExportContent } from '@/types/exporting';
import { ExportModal } from '../export/ExportModal';

export const RadarExportModal = ({ children }: { children: ReactNode }) => {
  const [radar, table] = Children.toArray(children);
  const exportContent = useAtomValue(exportContentAtom);

  return (
    <ExportModal>
      {(exportContent === ExportContent.VIS || exportContent === ExportContent.VIS_TABLE) && (
        <div className="mx-auto w-[500px] [&>article>div>div]:h-fit" data-testid="radar-export">
          {radar}
        </div>
      )}
      {(exportContent === ExportContent.TABLE || exportContent === ExportContent.VIS_TABLE) && (
        <div className="flex-1" data-testid="table-export">
          {table}
        </div>
      )}
    </ExportModal>
  );
};
