import { Link } from 'react-router-dom';
import { SideNavigationItem, SideNavigationMenuList } from '@statsbomb/kitbag-components';
import { NavigationItem, useNavigationItems } from '@/hooks/useNavigationItems';

export interface SideNavLinkProps {
  pathSection: string;
  isTouch: boolean;
  setIsNavOpen: (open: boolean) => void;
  setIsSettingsPanelOpen: (open: boolean) => void;
}

const SideNavLinks = ({
  navItems,
  pathSection,
  isTouch,
  setIsNavOpen,
  setIsSettingsPanelOpen,
}: { navItems: NavigationItem[] } & SideNavLinkProps) => (
  <SideNavigationMenuList>
    {navItems.map(({ icon, path, label }) => (
      <SideNavigationItem
        key={path}
        version={2}
        icon={icon}
        component={Link}
        componentProps={{
          to: path,
          onClick: () => {
            if (isTouch) setIsNavOpen(false);
            setIsSettingsPanelOpen(false);
          },
        }}
        isSelected={path.includes(pathSection)}
      >
        {label}
      </SideNavigationItem>
    ))}
  </SideNavigationMenuList>
);

export const MainSideNavLinks = (props: SideNavLinkProps) => {
  const { primary } = useNavigationItems();
  return <SideNavLinks navItems={primary} {...props} />;
};

export const EmptySideNavLinks = (props: SideNavLinkProps) => <SideNavLinks navItems={[]} {...props} />;
