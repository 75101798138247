import { createUnwrappedPitchEventsAtom, getMarkersToggleDisabled, getPitchMarkersEvents } from '@/utils/atoms/vis';
import { atom } from 'jotai';
import { playerEventMarkersForMetricAtom } from '../queries/player/playerEventData';

export const playerPitchEventsAtom = atom(async get => getPitchMarkersEvents(get, playerEventMarkersForMetricAtom));

export const playerMarkersToggleDisabledAtom = atom(async get =>
  getMarkersToggleDisabled(get, playerEventMarkersForMetricAtom),
);

export const playerUnwrappedPitchEventsAtom = createUnwrappedPitchEventsAtom(playerPitchEventsAtom);
