import { atom } from 'jotai';
import { EXPORT_WIDTH } from '@/consts/exporting';
import { ExportContent, ExportModalData } from '@/types/exporting';

export const exportModalDataAtom = atom<ExportModalData | null>(null);

// Derived atom for the export content dropdown
export const exportContentAtom = atom(
  get => get(exportModalDataAtom)?.content,
  (get, set, exportContent: ExportContent, visName: string) => {
    // When updating the content we want to update the vis name and custom width when as well
    const exportModalData = get(exportModalDataAtom);

    if (exportModalData) {
      const customWidth = exportContent === ExportContent.VIS_TABLE ? EXPORT_WIDTH.WIDE : EXPORT_WIDTH.DEFAULT;
      set(exportModalDataAtom, { ...exportModalData, customWidth, content: exportContent, visName });
    }
  },
);

export const csvDownloadDataAtom = atom({
  url: '',
  fileName: '',
});
