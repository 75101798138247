import { Atom, useAtomValue } from 'jotai';
import { Option } from '@/types/generic';
import { FormattedGame } from '@/types/game';
import { useGetGamesByFilter, GamesFilterType } from './useGetGamesByFilter';

export const useGetFilteredOptions = (
  optionsAtom: Atom<Promise<Option[]>>,
  filteredGamesDataAtom: Atom<Promise<FormattedGame[]>>,
  selectedGamesAtom: Atom<Promise<Option[]>>,
  gamesFilterType: GamesFilterType,
) => {
  const options = useAtomValue(optionsAtom);
  const filteredTeamIds = useGetGamesByFilter(filteredGamesDataAtom, selectedGamesAtom, gamesFilterType);

  return options.filter(option => filteredTeamIds.includes(option.value));
};
