import { OptionPromiseAtom } from '@/types/atom';
import { convertToDropdownOption } from '@/utils/object';
import { deselectedGamesAtom } from '@/atoms/filters/highLevel';
import { GamesBySeason } from '@/types/filters';
import { Nullable, Option } from '@/types/generic';
import { useTranslation } from 'react-i18next';
import { Typography, Grid, Checkbox, Menu, Button, ButtonIcon, ConfirmationDialog } from '@statsbomb/kitbag-components';
import { useAtomValue, useSetAtom } from 'jotai';
import { useState } from 'react';
import { filterUniqueByProperty } from '@/utils/filters';
import { useSelectedFiltersDescription } from '@/hooks/useSelectedFiltersDescription';
import { StatusTranslationKey } from '@/types/status';
import { InfoBar } from '../feedback/InfoBar';
import { MenuItemWithIcon } from '../menu/MenuItemWithIcon';
import { ContentState } from '../contentState/ContentState';

const GameModalInfoBar = () => {
  const { t } = useTranslation('gameModal');
  const filterText = useSelectedFiltersDescription();

  if (filterText === '') return null;

  return <InfoBar testId="game-modal-info-bar" mainText={t('infoBar')} additionalText={filterText} />;
};

const GameModalList = ({
  gamesBySeason,
  selectedGames,
  setSelectedGameIds,
}: {
  gamesBySeason: GamesBySeason[];
  selectedGames: Option[];
  setSelectedGameIds: Function;
}) => {
  const { t } = useTranslation(['games', 'general']);
  const [openMenuId, setOpenMenuId] = useState<Nullable<number>>(null);

  return (
    <div data-testid="games-list" className="w-full sm:max-w-[600px] flex flex-col gap-y-4">
      <GameModalInfoBar />
      {gamesBySeason.length === 0 ? (
        <ContentState status={StatusTranslationKey.NO_DATA} />
      ) : (
        <>
          <div className="flex justify-end gap-x-2">
            <Button
              variant="secondary"
              size="small"
              onClick={() => {
                const allGamesToSelect = gamesBySeason
                  .flatMap(({ games }) => games)
                  .map(({ game: { gameId, name } }) => convertToDropdownOption(name, gameId));

                setSelectedGameIds(allGamesToSelect);
              }}
            >
              {t('selectAll', { ns: 'general' })}
            </Button>
            <Button variant="secondary" size="small" onClick={() => setSelectedGameIds([])}>
              {t('deselectAll', { ns: 'general' })}
            </Button>
          </div>
          {gamesBySeason.map(season => (
            <div data-testid={`season-${season.seasonId}`} key={season.seasonId} className="flex flex-col gap-6 mb-6">
              <div className="relative flex items-center gap-4">
                <Typography variant="headingMedium">{season.seasonName}</Typography>
                <div className="flex grow">
                  <Typography variant="bodyMedium" className="opacity-sb-secondary">
                    {t('gamesText', { text: season.games.length })}
                  </Typography>
                </div>
                <div className="relative" data-testid={`season-${season.seasonId}-menu-btn`}>
                  <span title={t('toggleMenu', { ns: 'general' })}>
                    <ButtonIcon
                      variant="secondary"
                      size="small"
                      icon="More"
                      onClick={() => setOpenMenuId(season.seasonId)}
                    />
                  </span>
                  {openMenuId === season.seasonId && (
                    <div className="absolute mt-2 right-0 z-10 [&>ul]:relative [&>ul]:p-0 [&_:is(li,p)]:cursor-pointer">
                      <Menu onOutsideClick={() => setOpenMenuId(null)}>
                        <MenuItemWithIcon
                          text={t('selectSeason', { ns: 'general' })}
                          onClick={() => {
                            setOpenMenuId(null);
                            const seasonGamesToSelect: Option[] = season.games.map(({ game: { gameId, name } }) =>
                              convertToDropdownOption(name, gameId),
                            );

                            const updatedSelectedGames = filterUniqueByProperty('value', [
                              ...selectedGames,
                              ...seasonGamesToSelect,
                            ]);

                            setSelectedGameIds(updatedSelectedGames);
                          }}
                        />
                        <MenuItemWithIcon
                          text={t('deselectSeason', { ns: 'general' })}
                          onClick={() => {
                            setOpenMenuId(null);
                            const seasonGameIdsToDeselect = season.games.map(({ game: { gameId } }) => gameId);
                            const updatedSelectedGames = selectedGames.filter(
                              game => !seasonGameIdsToDeselect.includes(game.value),
                            );

                            setSelectedGameIds(updatedSelectedGames);
                          }}
                        />
                      </Menu>
                    </div>
                  )}
                </div>
              </div>
              <Grid>
                {season.games.map(({ game }) => {
                  const isChecked = selectedGames?.some(option => option.value === game.gameId);
                  return (
                    <Grid key={game.gameId} item xs={12} md={6}>
                      <div className="flex" data-testid={`game-${game.gameId}`}>
                        <Checkbox
                          id={game.name}
                          label={game.name}
                          withLabel={false}
                          checked={isChecked}
                          onChange={() => {
                            const newGameIds = isChecked
                              ? selectedGames?.filter(option => option.value !== game.gameId)
                              : [...selectedGames, { label: game.name, value: game.gameId }];

                            setSelectedGameIds(newGameIds);
                          }}
                        />
                        {/* // Non-null Assertion is used here as games should always have a gameName at this stage */}
                        <label htmlFor={game.name!} className="pl-2 cursor-pointer">
                          <Typography variant="bodyMedium">{game.name}</Typography>
                          <span className="opacity-60">
                            <Typography variant="bodySmall" as="span" className="flex gap-x-2">
                              <span>{game.date}</span>
                              <span>{game.competitionName}</span>
                            </Typography>
                          </span>
                        </label>
                      </div>
                    </Grid>
                  );
                })}
              </Grid>
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export const GameModal = ({
  setIsModalOpen,
  gamesBySeason,
  selectedGamesAtom,
}: {
  setIsModalOpen: Function;
  gamesBySeason: GamesBySeason[];
  selectedGamesAtom: OptionPromiseAtom;
}) => {
  const { t } = useTranslation(['filters', 'entity']);
  const selectedGames = useAtomValue(selectedGamesAtom);
  const setDeselectedGames = useSetAtom(deselectedGamesAtom);
  const [pendingSelectedGames, setPendingSelectedGames] = useState(selectedGames);

  const allGameValues = gamesBySeason
    .flatMap(({ games }) => games)
    .map(({ game: { gameId, name } }) => convertToDropdownOption(name, gameId));

  const modalId = 'games-modal';
  const handleModalCancel = () => setIsModalOpen(false);

  return (
    <ConfirmationDialog
      id={modalId}
      testId={modalId}
      title={t('game.other', { ns: 'entity' })}
      isOpen
      onConfirm={() => {
        const unselectedGames = allGameValues.filter(
          option => !pendingSelectedGames.map(selectedGame => selectedGame.value).includes(option.value),
        );

        setDeselectedGames(unselectedGames);
        setIsModalOpen(false);
      }}
      onCancel={handleModalCancel}
      cancelLabel={t('cancel')}
      confirmLabel={t('apply')}
    >
      <GameModalList
        gamesBySeason={gamesBySeason}
        selectedGames={pendingSelectedGames}
        setSelectedGameIds={setPendingSelectedGames}
      />
    </ConfirmationDialog>
  );
};
