import { isCreateReportModalOpenAtom } from '@/atoms/report/report';
import { GenericToast } from '@/components/alerts/GenericToast/GenericToast';
import { CreateReportModal } from '@/components/modal/CreateReportModal/CreateReportModal';
import { ButtonIcon } from '@statsbomb/kitbag-components';
import { useSetAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

export const CreateNewReportButton = () => {
  const { t } = useTranslation('report');
  const setIsCreateReportModalOpen = useSetAtom(isCreateReportModalOpenAtom);

  return (
    <>
      <ButtonIcon
        icon="PlusAdd"
        displayText="left"
        variant="secondary"
        onClick={() => setIsCreateReportModalOpen(true)}
        size="small"
      >
        {t('createReportText')}
      </ButtonIcon>
      <CreateReportModal />
      <GenericToast />
    </>
  );
};
