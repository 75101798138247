import { unwrappedTeamDataVisibleColumnsAtom } from '@/atoms/dataLocker/team/table';
import { tableColumnsAtom } from '@/atoms/queries/tableColumns';
import { teamAggsCountAtom, teamAggsDataAtom } from '@/atoms/queries/team/teamAggData';
import { ErrorBoundary } from '@sentry/react';
import { useAtom, useAtomValue } from 'jotai';
import { Suspense } from 'react';
import { GeneralErrorBoundaryFallback } from '../errorBoundary/ErrorBoundaryFallback';
import { AggsTable } from './AggsTable';
import { TablePagination } from './TablePagination';

const TeamAggsTableWithData = () => {
  const [{ data, isPending, status }] = useAtom(teamAggsDataAtom);
  const tableColumns = useAtomValue(tableColumnsAtom);
  const visibleColumns = useAtomValue(unwrappedTeamDataVisibleColumnsAtom);

  if (status === 'error') return <AggsTable hasError entity="team" />;

  return (
    <AggsTable
      data={data}
      isLoading={isPending}
      availableColumns={tableColumns}
      visibleColumns={visibleColumns}
      entity="team"
    />
  );
};

export const TeamAggsTableWithPagination = () => (
  <ErrorBoundary fallback={<GeneralErrorBoundaryFallback />}>
    <Suspense fallback={<AggsTable isLoading entity="team" />}>
      <TeamAggsTableWithData />
    </Suspense>
    <TablePagination dataLengthAtom={teamAggsCountAtom} />
  </ErrorBoundary>
);
