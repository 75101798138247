import { selectedPositionsFilterParamsAtom } from '@/atoms/filters/highLevel/positions';
import { unwrappedPlayerSelectedGamesForQueryAtom } from '@/atoms/filters/player/playerGames';
import { playerEventMetricFiltersDebounceObject } from '@/atoms/queries/player/playerEventData';
import { optionsToValueStrings, toObject } from '@/utils/object';
import { useAtomValue, useSetAtom } from 'jotai';
import { useEffect } from 'react';
import { DEFAULT_DEBOUNCE_MS } from '@/consts/api';
import { playerRadarFilterDebounceObject } from '@/atoms/queries/player/playerRadar';
import { playerPositionsFilterDebounceObject } from '@/atoms/queries/players';

export const useSetPlayerEventDataDebouncedFilterParams = () => {
  const playerGameIds = optionsToValueStrings(useAtomValue(unwrappedPlayerSelectedGamesForQueryAtom));
  const selectedPositionsFilterParams = useAtomValue(selectedPositionsFilterParamsAtom);

  const { debouncedValueAtom: playerPositionsFiltersAtom, timeoutDelayAtom: playerPositionsFiltersTimeoutDelayAtom } =
    playerPositionsFilterDebounceObject;
  const setPlayerPositionsFiltersTimeoutDelay = useSetAtom(playerPositionsFiltersTimeoutDelayAtom);
  const setPlayerPositionsFilters = useSetAtom(playerPositionsFiltersAtom);

  const { debouncedValueAtom: playerRadarFilterAtom, timeoutDelayAtom: playerRadarFilterTimeoutDelayAtom } =
    playerRadarFilterDebounceObject;
  const setPlayerRadarFilterTimeoutDelay = useSetAtom(playerRadarFilterTimeoutDelayAtom);
  const setPlayerRadarFilter = useSetAtom(playerRadarFilterAtom);

  const {
    debouncedValueAtom: playerEventMetricFiltersAtom,
    timeoutDelayAtom: playerEventMetricFiltersTimeoutDelayAtom,
  } = playerEventMetricFiltersDebounceObject;
  const setPlayerEventMetricFilters = useSetAtom(playerEventMetricFiltersAtom);
  const setPlayerEventMetricFiltersTimeoutDelay = useSetAtom(playerEventMetricFiltersTimeoutDelayAtom);

  const updateTimeouts = (timeout: number) => {
    setPlayerPositionsFiltersTimeoutDelay(timeout);
    setPlayerRadarFilterTimeoutDelay(timeout);
    setPlayerEventMetricFiltersTimeoutDelay(timeout);
  };

  useEffect(() => {
    // If there's no games, set the timeout to 0 as this won't trigger an API call.
    if (!playerGameIds.length) updateTimeouts(0);
    setPlayerPositionsFilters({
      eq: { game_id: playerGameIds },
    });
    setPlayerEventMetricFilters({
      eq: {
        ...toObject('event.attributes.player_position', selectedPositionsFilterParams),
        'event.game_id': playerGameIds,
      },
    });
    setPlayerRadarFilter({
      eq: {
        ...toObject('position', selectedPositionsFilterParams),
        game_id: playerGameIds,
      },
    });
    // If there were games this time, set the timeout so that we debounce subsequent calls
    if (playerGameIds.length) updateTimeouts(DEFAULT_DEBOUNCE_MS);
  }, [JSON.stringify(playerGameIds), JSON.stringify(selectedPositionsFilterParams)]);
};
