import { useGetPitchRotation } from '@/hooks/useGetPitchRotation';
import { usePitchCrop } from '@/hooks/usePitchCrop';
import { FallBackProps } from '@/types/visualisation';
import { FallbackLayer, PitchViz } from '@statsbomb/kitbag-datavis';
import { useTranslation } from 'react-i18next';

export const FallbackNoData = ({ isPreview = false }: FallBackProps) => {
  const { t } = useTranslation('status');
  const pitchRotation = useGetPitchRotation(isPreview);
  const visibleArea = usePitchCrop(isPreview);

  return (
    <PitchViz
      rotationName={pitchRotation}
      pitchFocusZoneName={visibleArea}
      overlay={
        <FallbackLayer
          rotationName={pitchRotation}
          pitchFocusZoneName={visibleArea}
          fallbackText={[t('general.noData.title'), t('general.noData.description')]}
        />
      }
    />
  );
};
