import { selectedReportIdAtom } from '@/atoms/report/report';
import { useSetAtom } from 'jotai';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

export const useSetReportId = () => {
  const { reportId } = useParams();
  const setSelectedReportId = useSetAtom(selectedReportIdAtom);

  useEffect(() => {
    setSelectedReportId(reportId);
  }, [reportId]);
};
