import { combineMetricData } from '@/utils/metrics';
import { atom } from 'jotai';
import { teamMetricDistributionsAtom, teamMetricsAtom } from '../queries/team/teamRadar';

export const teamMetricDataAtom = atom(async get => {
  const configTotalMetrics = await get(teamMetricsAtom);
  const metricDistros = await get(teamMetricDistributionsAtom);

  return combineMetricData(configTotalMetrics, metricDistros);
});
