import { Playlist, VideoEvent } from '@statsbomb/video-client-js';
import { WideFirstColumnLayout } from '@/components/layout/WideFirstColumnLayout';
import { VideoProvider } from '@/components/Providers';
import { useAtomValue } from 'jotai';
import { useSetMetricKey } from '@/hooks/useMetricKey';
import { useSetGameIdForVideos } from '@/hooks/useSetGameIdForVideos';
import { teamEventVideoIdsAtom } from '@/atoms/queries/team/teamEventData';
import { useSetTeamId } from '@/hooks/useTeamId';

export const TeamGamesVideoPage = () => {
  useSetMetricKey();
  useSetTeamId();
  useSetGameIdForVideos();

  const teamEventVideoIds = useAtomValue(teamEventVideoIdsAtom);

  return (
    <div className="h-full video-column-order">
      <VideoProvider>
        <WideFirstColumnLayout>
          <Playlist />
          <VideoEvent autoPlay events={teamEventVideoIds} />
        </WideFirstColumnLayout>
      </VideoProvider>
    </div>
  );
};
