import { themeAtom } from '@/atoms/theme';
import { Button, ButtonGroup, Typography, iqTheme } from '@statsbomb/kitbag-components';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

export const ThemeToggle = () => {
  const { t } = useTranslation('preferences');
  const [theme, setTheme] = useAtom(themeAtom);
  const isLightTheme = theme === 'light';

  const handleTheme = (newTheme: keyof typeof iqTheme) => {
    if (newTheme !== theme) {
      setTheme(newTheme);
    }
  };

  return (
    <div>
      <Typography variant="headingMedium">{t('display.theme.label')}</Typography>
      <ButtonGroup>
        <Button onClick={() => handleTheme('light')} variant={isLightTheme ? 'primary' : 'ghost'}>
          {t('display.theme.light')}
        </Button>
        <Button onClick={() => handleTheme('dark')} variant={isLightTheme ? 'ghost' : 'primary'}>
          {t('display.theme.dark')}
        </Button>
      </ButtonGroup>
    </div>
  );
};
