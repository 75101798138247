import { METRIC_KEY } from '@/consts/searchKeys';
import { useSortObjectArray } from '@/hooks/useSortObjectArray';
import { useUpdateQueryString } from '@/hooks/useUpdateQueryString';
import { PromiseAtom } from '@/types/atom';
import { StringOption } from '@/types/generic';
import { MetricData } from '@/types/metric';
import { snakeToCamel } from '@/utils/queries';
import { Dropdown } from '@statsbomb/kitbag-components';
import { useAtomValue } from 'jotai';
import { Suspense, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

const MetricKeyDropdownBase = ({ metricData = [] }: { metricData?: MetricData[] }) => {
  const [searchParams] = useSearchParams();
  const { t } = useTranslation(['filters', 'metrics']);

  const navigate = useNavigate();
  const selectedMetricKey = searchParams.get(METRIC_KEY);
  const createQueryString = useUpdateQueryString(searchParams);

  const translatedOptions = metricData.map(metric => ({
    label: t(`${snakeToCamel(metric.key)}.name`, { ns: 'metrics' }),
    value: metric.key,
  }));
  const sortedOptions = useSortObjectArray('label', translatedOptions);

  useEffect(() => {
    /* 
      if we don't have a metric key i.e we first navigate to a page that requires metricKey set
      select the first metric from the options
    */
    if (!selectedMetricKey && metricData.length) {
      navigate(`?${createQueryString([{ key: METRIC_KEY, value: metricData[0].key }])}`, {
        replace: true,
      });
    }
  }, [metricData.length]);

  return (
    <div className="min-w-[150px]">
      <Dropdown
        id="metric-key-dropdown"
        labelPosition="none"
        onChange={(option: StringOption) => {
          navigate(`?${createQueryString([{ key: METRIC_KEY, value: String(option.value) }])}`);
        }}
        label={t('metricKey')}
        options={sortedOptions}
        value={!selectedMetricKey ? null : sortedOptions.find(({ value }) => value === selectedMetricKey)}
        variant="tertiary"
      />
    </div>
  );
};

const MetricKeyDropdownWithData = ({ metricDataAtom }: { metricDataAtom: PromiseAtom<MetricData[]> }) => {
  const metricData = useAtomValue(metricDataAtom);
  return <MetricKeyDropdownBase metricData={metricData} />;
};

export const MetricKeyDropdown = ({ metricDataAtom }: { metricDataAtom: PromiseAtom<MetricData[]> }) => (
  <Suspense fallback={<MetricKeyDropdownBase />}>
    <MetricKeyDropdownWithData metricDataAtom={metricDataAtom} />
  </Suspense>
);
