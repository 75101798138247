import { metricKeyAtom } from '@/atoms/metric';
import { snakeToCamel } from '@/utils/queries';
import { Typography } from '@statsbomb/kitbag-components';
import { useAtomValue } from 'jotai';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const MetricName = () => {
  const { t } = useTranslation('metrics');
  const metricKey = useAtomValue(metricKeyAtom);

  return (
    <Typography as="span" variant="headingMedium" data-testid="metric-key">
      {metricKey ? t(`${snakeToCamel(metricKey)}.name`, { ns: 'metrics' }) : ''}
    </Typography>
  );
};
