import { filterAtomDefaults } from '@/consts/defaults/filterAtomDefaults';
import { atom } from 'jotai';

export const selectedBodyPartsAtom = atom(filterAtomDefaults.bodyParts);
export const selectedPassHeightAtom = atom(filterAtomDefaults.passHeights);
export const selectedKeeperActionsAtom = atom(filterAtomDefaults.keeperActions);

export const keeperActionFilterParamsAtom = atom(get => {
  const selectedKeeperActions = get(selectedKeeperActionsAtom);
  return selectedKeeperActions.reduce(
    (acc, keeperAction) => ({
      ...acc,
      [`event.attributes.${keeperAction}`]: true,
    }),
    {},
  );
});
