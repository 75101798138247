import { ErrorBoundary } from '@sentry/react';
import { Flag } from '@statsbomb/kitbag-components';
import React from 'react';

type KCCountryCode = React.ComponentProps<typeof Flag>['countryCode'];

// We do not have a flag for these country codes yet and do not want to display the warning in the console
const EXCLUDED_FLAGS = ['SAM', 'NCAM', 'GAEA'];

export const FlagIcon = ({
  countryCode,
  size = 'regular',
}: {
  countryCode: Uppercase<KCCountryCode> | (typeof EXCLUDED_FLAGS)[number];
  size?: 'regular' | 'small' | 'tiny';
}) => {
  if (EXCLUDED_FLAGS.includes(countryCode)) return null;

  /* the data coming back from the api is in UPPERCASE so doing the lowercase here.
  Don't think we should necessarily change the BE to be lowercase just because the FE
  has changed it to lowercase */
  const countryCodeAsFlagType = countryCode.toLowerCase() as KCCountryCode;
  return (
    <ErrorBoundary fallback={<span>{countryCode}</span>}>
      <span className="[&>div]:flex">
        <Flag countryCode={countryCodeAsFlagType} size={size} />
      </span>
    </ErrorBoundary>
  );
};
