import { csvDownloadDataAtom } from '@/atoms/exporting';
import { toCsvFilename } from '@/components/export/Export.helper';
import { useSetAtom } from 'jotai';
import { useEffect } from 'react';

export const useCsvDownload = (fileName: string, url?: string) => {
  const setCsvDownloadData = useSetAtom(csvDownloadDataAtom);
  useEffect(() => {
    if (url) {
      setCsvDownloadData({ url, fileName: toCsvFilename(fileName) });
    }
  }, [url, fileName]);
};
