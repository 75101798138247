import { teamIdAtom } from '@/atoms/team/team';
import { useSetAtom } from 'jotai';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

export const useSetTeamId = () => {
  const { teamId } = useParams();
  if (!teamId) throw new Error('Missing param in URL: teamId');
  const setTeamId = useSetAtom(teamIdAtom);
  useEffect(() => setTeamId(Number(teamId)), [teamId]);
};
