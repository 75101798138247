import { useTranslation } from 'react-i18next';
import { MAX_MINUTES, MIN_MINUTES } from '@/consts/filters/minMinutes';
import { maxMinutesSelectedAtom, minMinutesSelectedAtom } from '@/atoms/filters/dataLocker/minMinutes';
import { SliderMultiple } from '../sliders/SliderMultiple';

export const MinutesPlayedFilter = () => {
  const { t } = useTranslation('metrics');

  return (
    <SliderMultiple
      title={t('minutesPlayed.name')}
      minValue={MIN_MINUTES}
      maxValue={MAX_MINUTES}
      minAtom={minMinutesSelectedAtom}
      maxAtom={maxMinutesSelectedAtom}
      step={100}
    />
  );
};
