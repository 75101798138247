import { AtomsToHydrate } from '@/types/userConfigs';
import { atom } from 'jotai';

export const atomsToHydrateAtom = atom<null | AtomsToHydrate>(null);

export const setAtomsToHydrateAtom = atom(null, (_, set, atomsToHydrate: AtomsToHydrate) => {
  atomsToHydrate.forEach(([atomToSet, value]) => {
    set(atomToSet, value);
  });
});
