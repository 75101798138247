import { atomWithDebounce } from '@/atoms/debounce';
import {
  maxAgeBirthDateToQueryAtom,
  maxAgeSelectedAtom,
  minAgeBirthDateToQueryAtom,
  minAgeSelectedAtom,
} from '@/atoms/filters/dataLocker/ageRange';
import { maxHeightSelectedAtom, minHeightSelectedAtom } from '@/atoms/filters/dataLocker/heightRange';
import { maxMinutesSelectedAtom, minMinutesSelectedAtom } from '@/atoms/filters/dataLocker/minMinutes';
import { selectedPreferredFootAtom } from '@/atoms/filters/dataLocker/preferredFoot';
import { selectedEndDateAtom, selectedStartDateAtom } from '@/atoms/filters/highLevel';
import { competitionIdsToFilterByAtom } from '@/atoms/filters/highLevel/competitions';
import { selectedTeamIdsAtom } from '@/atoms/filters/highLevel/teams';
import { seasonIdsToFilterByAtom } from '@/atoms/filters/highLevel/seasons';
import { selectedPlayerIdsAtom } from '@/atoms/filters/highLevel/players';
import { selectedPositionsFilterParamsAtom } from '@/atoms/filters/highLevel/positions';
import { pageAndOrderParamsAtom } from '@/atoms/general';
import { fetchClientAtom } from '@/atoms/queries/client';
import { playerName } from '@/atoms/queries/players';
import { MAX_AGE, MIN_AGE } from '@/consts/filters/ageRange';
import { MAX_HEIGHT, MIN_HEIGHT } from '@/consts/filters/heightRange';
import { MAX_MINUTES, MIN_MINUTES } from '@/consts/filters/minMinutes';
import { playersNormalisedAggregatesUrl } from '@/query/url';
import { Nullable } from '@/types/generic';
import { NestedObject } from '@/types/object';
import { convertFilterParamsToString, hasEmptyEqFilters } from '@/utils/api';
import { addLink, flattenAndAddPrefix, optionsToValueStrings, toObject } from '@/utils/object';
import { objSnakeToCamel } from '@/utils/queries';
import { MultiPlayerAggregate } from '@statsbomb/parachute-types';
import { atom } from 'jotai';
import { atomWithQuery, atomWithSuspenseQuery } from 'jotai-tanstack-query';
import { unwrap } from 'jotai/utils';
import { unwrappedPlayerSelectedGamesForQueryAtom } from '@/atoms/filters/player/playerGames';
import { rawPlayerRadarDataAtom } from './playerRadar';

const playerAggsAgeFilterParamsAtom = atom(get => {
  const minAgeSelected = get(minAgeSelectedAtom);
  const maxAgeSelected = get(maxAgeSelectedAtom);

  const minAgeDate =
    minAgeSelected !== MIN_AGE ? toObject('output.player.date_of_birth', get(minAgeBirthDateToQueryAtom)) : {};
  const maxAgeDate =
    maxAgeSelected !== MAX_AGE ? toObject('output.player.date_of_birth', get(maxAgeBirthDateToQueryAtom)) : {};

  return { minAgeDate, maxAgeDate };
});

const playerAggsHeightFilterParamsAtom = atom(get => {
  const minHeightSelected = get(minHeightSelectedAtom);
  const maxHeightSelected = get(maxHeightSelectedAtom);

  const minHeight = minHeightSelected !== MIN_HEIGHT ? toObject('output.player.height', minHeightSelected) : {};
  const maxHeight = maxHeightSelected !== MAX_HEIGHT ? toObject('output.player.height', maxHeightSelected) : {};

  return { minHeight, maxHeight };
});

const playerAggsMinutesFilterParamsAtom = atom(get => {
  const minMinutesSelected = get(minMinutesSelectedAtom);
  const maxMinutesSelected = get(maxMinutesSelectedAtom);

  const minMinutes =
    minMinutesSelected !== MIN_MINUTES ? toObject('output.aggregates.minutes_played', minMinutesSelected) : {};
  const maxMinutes =
    maxMinutesSelected !== MAX_MINUTES ? toObject('output.aggregates.minutes_played', maxMinutesSelected) : {};

  return { minMinutes, maxMinutes };
});

export const playerAggsFilterParamsAtom = atom(get => ({
  lte: {
    ...toObject('input.game_date', get(selectedEndDateAtom)),
    ...get(playerAggsAgeFilterParamsAtom).minAgeDate,
    ...get(playerAggsHeightFilterParamsAtom).maxHeight,
    ...get(playerAggsMinutesFilterParamsAtom).maxMinutes,
  },
  gte: {
    ...toObject('input.game_date', get(selectedStartDateAtom)),
    ...get(playerAggsAgeFilterParamsAtom).maxAgeDate,
    ...get(playerAggsHeightFilterParamsAtom).minHeight,
    ...get(playerAggsMinutesFilterParamsAtom).minMinutes,
  },
  eq: {
    ...toObject('input.competition_id', get(competitionIdsToFilterByAtom)),
    ...toObject('output.player.player_id', get(selectedPlayerIdsAtom)),
    ...toObject('input.team_id', get(selectedTeamIdsAtom)),
    ...toObject('input.season_id', get(seasonIdsToFilterByAtom)),
    ...toObject('output.player.preferred_foot', get(selectedPreferredFootAtom)),
    ...toObject('input.position', get(selectedPositionsFilterParamsAtom)),
  },
}));

export const playerGameStatsFilterParamsAtom = atom(get => ({
  eq: {
    'input.game_id': optionsToValueStrings(get(unwrappedPlayerSelectedGamesForQueryAtom)),
  },
}));

export const playerAggsFilterParamsDebounceObject = atomWithDebounce<Nullable<NestedObject>>(null);
const { debouncedValueAtom: debouncedPlayerAggsFilterParamsAtom } = playerAggsFilterParamsDebounceObject;

export const playerAggsDataAtom = atomWithQuery(get => {
  const queryKey = ['playerAggs', get(pageAndOrderParamsAtom), get(debouncedPlayerAggsFilterParamsAtom)] as const;
  const queryFn = async ({
    queryKey: [, pageAndOrderParams, playerAggsFilterParams],
  }: {
    queryKey: typeof queryKey;
  }) => {
    if (hasEmptyEqFilters(playerAggsFilterParams)) return [];
    const { fetch } = get(fetchClientAtom);
    const data: MultiPlayerAggregate[] = await fetch(
      playersNormalisedAggregatesUrl({
        ...pageAndOrderParams,
        ...playerAggsFilterParams,
      }),
    );
    return data.map(data =>
      addLink(
        flattenAndAddPrefix(objSnakeToCamel(data), '.', false),
        playerName(data.player),
        data.player.player_id,
        'playerLink',
      ),
    );
  };
  const enabled = get(debouncedPlayerAggsFilterParamsAtom) !== null;
  return { queryKey, queryFn, enabled };
});

const rawPlayerAggsCountAtom = atomWithSuspenseQuery(get => {
  const queryKey = ['playerAggsCount', get(debouncedPlayerAggsFilterParamsAtom)] as const;
  const queryFn = async ({ queryKey: [, playerAggsFilterParams] }: { queryKey: typeof queryKey }) => {
    if (!playerAggsFilterParams) return null;
    const { fetch } = get(fetchClientAtom);
    return (await fetch(
      `/players/normalised-aggregates/count${convertFilterParamsToString({
        ...playerAggsFilterParams,
      })}`,
    )) as Promise<number>;
  };
  return { queryKey, queryFn };
});
export const playerAggsCountAtom = atom(async get => (await get(rawPlayerAggsCountAtom)).data);

export const playerAggsMinutesPlayedAtom = atom(async get => {
  const rawPlayerRadarData = (await get(rawPlayerRadarDataAtom)).data;
  return rawPlayerRadarData.minutes_played || 0;
});

export const unwrappedPlayerAggsMinutesPlayedAtom = unwrap(playerAggsMinutesPlayedAtom, prev => prev || 0);
