import {
  teamMatchDataDefaultColumnsAtom,
  teamMatchDataVisibleColumnsAtom,
  teamMatchDataVisibleColumnsUserSelectedAtom,
} from '@/atoms/dataLocker/team/match/table';
import { tableColumnEntitiesAtom } from '@/atoms/queries/tableColumns';
import { teamGameAggsCountAtom } from '@/atoms/queries/team/teamGameAggData';
import { unwrappedSelectedTeamNameAtom } from '@/atoms/team/info';
import { TableDataControls } from '@/components/controls/TableDataControls';
import { PageTitle } from '@/components/head/PageTitle';
import { LayoutWithFilters } from '@/components/layout/LayoutWithFilters';
import { TablePagination } from '@/components/tables/TablePagination';
import { TableScopeProvider } from '@/components/tables/TableScopeProvider';
import { TeamHighLevelFilters } from '@/components/team/filters/TeamHighLevelFilters';
import { TeamGameAggsTable } from '@/components/team/tables/TeamGameAggsTable';
import { useEntityPageTitle } from '@/hooks/useEntityPageTitle';
import { useTableColumns } from '@/hooks/useTableColumns';
import { Grid } from '@statsbomb/kitbag-components';
import { useAtomValue } from 'jotai';
import { ScopeProvider } from 'jotai-scope';
import { useTranslation } from 'react-i18next';
import { useCsvDownload } from '@/hooks/exporting/useCsvDownload';
import { teamGamesTotalAggregatesUrl } from '@/query/url';
import { teamIdAtom } from '@/atoms/team/team';
import { teamMatchStatsFilterParamsAtom } from '@/atoms/queries/team/teamAggData';

const TableWithControls = ({ pageTitle }: { pageTitle: string }) => {
  const { t } = useTranslation('games');
  useTableColumns(['competition', 'game', 'team_total_metric']);
  const teamId = useAtomValue(teamIdAtom);
  const teamMatchStatsFilterParams = useAtomValue(teamMatchStatsFilterParamsAtom);
  useCsvDownload(pageTitle, teamGamesTotalAggregatesUrl(teamId, teamMatchStatsFilterParams));

  return (
    <ScopeProvider atoms={[teamMatchDataVisibleColumnsUserSelectedAtom]}>
      <TableDataControls
        visibleColumnsAtom={teamMatchDataVisibleColumnsAtom}
        defaultColumnsAtom={teamMatchDataDefaultColumnsAtom}
        heading={t('latestPerformances')}
      />
      <TeamGameAggsTable />
    </ScopeProvider>
  );
};

export const TeamGamesPage = () => {
  const { t } = useTranslation('navigation');
  const teamName = useAtomValue(unwrappedSelectedTeamNameAtom);
  const pageTitle = useEntityPageTitle(t('secondary.games'), teamName);

  return (
    <ScopeProvider atoms={[tableColumnEntitiesAtom]}>
      <PageTitle>{pageTitle}</PageTitle>
      <LayoutWithFilters filters={<TeamHighLevelFilters />}>
        <Grid>
          <Grid item>
            <TableScopeProvider>
              <TableWithControls pageTitle={pageTitle} />
              <TablePagination dataLengthAtom={teamGameAggsCountAtom} />
            </TableScopeProvider>
          </Grid>
        </Grid>
      </LayoutWithFilters>
    </ScopeProvider>
  );
};
