import { createUnwrappedPitchEventsAtom, getMarkersToggleDisabled, getPitchMarkersEvents } from '@/utils/atoms/vis';
import { atom } from 'jotai';
import { teamEventMarkersForMetricAtom } from '../queries/team/teamEventData';

export const teamPitchEventsAtom = atom(async get => getPitchMarkersEvents(get, teamEventMarkersForMetricAtom));

export const teamMarkersToggleDisabledAtom = atom(async get =>
  getMarkersToggleDisabled(get, teamEventMarkersForMetricAtom),
);

export const teamUnwrappedPitchEventsAtom = createUnwrappedPitchEventsAtom(teamPitchEventsAtom);
