import { sectionAtom } from '@/atoms/general';
import { Section } from '@/types/generic';
import { useAtom } from 'jotai';
import { useEffect } from 'react';

export const useSection = (newSection: Section) => {
  const [section, setSection] = useAtom(sectionAtom);

  useEffect(() => {
    setSection(newSection);
  }, [newSection]);

  return section === newSection;
};
