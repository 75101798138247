import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useTranslatedRadarTemplate } from '@/hooks/useTranslatedRadarTemplate';
import { ColumnControlsWrapper } from '../controls/ColumnControlsWrapper';
import { VisualisationMenu } from '../visualisations/VisualisationMenu';

export const RadarHeader = ({ children }: { children?: ReactNode }) => {
  const { t } = useTranslation('visualisation');
  const secondaryTitle = useTranslatedRadarTemplate();

  return (
    <ColumnControlsWrapper headerComponent={children}>
      <VisualisationMenu
        saveToReportProps={{ visType: 'radar', dataType: 'aggregate' }}
        exportModalProps={{
          visName: t('radar'),
          secondaryTitle,
        }}
      />
    </ColumnControlsWrapper>
  );
};
