import { PlayerRadarChart } from '@/components/player/radar/PlayerRadarChart';
import { PlayerEventMap } from '@/components/player/vis/PlayerEventMap';
import { PlayerHeatMap } from '@/components/player/vis/PlayerHeatMap';
import { PlayerPositions } from '@/components/player/vis/PlayerPositions';
import { TeamRadarChart } from '@/components/team/radar/TeamRadarChart';
import { TeamEventMap } from '@/components/team/vis/TeamEventMap';
import { TeamHeatMap } from '@/components/team/vis/TeamHeatMap';
import { PitchVisComponents, VisComponents } from '@/types/visualisation';

/* 
  We may need to review how we build these components as there is
  some duplication using the MetricKeyFallbackWrapper component.
  As we make more visualisations across different entities
  (Player, Team, Keeper, etc) we should consider if they should
  be DRY'd out into there own components.
*/

export const playerPitchVisComponents: PitchVisComponents = {
  heatMap: PlayerHeatMap,
  eventMap: PlayerEventMap,
  passSonar: PlayerEventMap,
  shotMap: PlayerEventMap,
};

export const playerVisComponents: VisComponents = {
  ...playerPitchVisComponents,
  radar: PlayerRadarChart,
  positionMap: PlayerPositions,
};

export const teamPitchVisComponents: PitchVisComponents = {
  heatMap: TeamHeatMap,
  eventMap: TeamEventMap,
  passSonar: TeamEventMap,
  shotMap: TeamEventMap,
};

export const teamVisComponents: VisComponents = {
  ...teamPitchVisComponents,
  radar: TeamRadarChart,
};
