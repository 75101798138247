import { useTranslation } from 'react-i18next';
import { MAX_AGE, MIN_AGE } from '@/consts/filters/ageRange';
import { maxAgeSelectedAtom, minAgeSelectedAtom } from '@/atoms/filters/dataLocker/ageRange';
import { SliderMultiple } from '../sliders/SliderMultiple';

export const AgeRangeFilter = () => {
  const { t } = useTranslation('filters');

  return (
    <SliderMultiple
      title={t('age')}
      minValue={MIN_AGE}
      maxValue={MAX_AGE}
      minAtom={minAgeSelectedAtom}
      maxAtom={maxAgeSelectedAtom}
    />
  );
};
