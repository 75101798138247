import { PrimitiveAtom, useAtom } from 'jotai';
import { convertOptionsToValues, toggleValue } from '@/utils/array';
import { StringOption } from '@/types/generic';
import { CheckboxGroupBase } from './CheckboxGroupBase';

export const CheckboxGroup = ({
  selectedValuesAtom,
  header,
  options,
}: {
  selectedValuesAtom: PrimitiveAtom<string[]>;
  header: string;
  options: StringOption[];
}) => {
  const [selectedValues, setSelectedValues] = useAtom(selectedValuesAtom);

  const isAllSelected = selectedValues.length === options.length;
  const optionValues = convertOptionsToValues(options);

  const handleChange = (value: string) => {
    const newList = toggleValue(value, selectedValues);
    setSelectedValues(newList);
  };

  const handleChangeAll = () => setSelectedValues(isAllSelected ? [] : optionValues);

  return (
    <CheckboxGroupBase
      options={options}
      selectedValues={selectedValues}
      header={header}
      onChange={handleChange}
      onChangeAll={handleChangeAll}
    />
  );
};
