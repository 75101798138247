import { allTeamsOptionsAtom } from '@/atoms/queries/teams';
import { userDefaultTeamIdAtom } from '@/atoms/user';
import { settingsConfigAtom } from '@/atoms/queries/userConfigs';
import { useOptimisticUserConfigUpdate } from '@/hooks/useOptimisticUserConfigUpdate';
import { SelectorProps } from '@/types/filters';
import { Option } from '@/types/generic';
import { UserDefaultTeamOption } from '@/types/settings';
import { UserConfigPutMutationParams } from '@/types/userConfigs';
import { Dropdown } from '@statsbomb/kitbag-components';
import { useAtomValue } from 'jotai';
import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { GenderIcon } from '../icons/GenderIcon';
import { MenuList, OptionWithFlagAndGender } from './FilterDropdown';

const UserDefaultTeamSelectorBase = ({
  options,
  isLoading,
  userDefaultTeamId,
  configId,
}: SelectorProps & { userDefaultTeamId?: number; configId?: string }) => {
  const { t } = useTranslation(['preferences', 'filters']);
  const { updateUserConfigOptimistically, localValue } = useOptimisticUserConfigUpdate(
    userDefaultTeamId,
    'updateDefaultTeam',
  );

  const userSelectedOption = options?.find(option => option.value === localValue) as UserDefaultTeamOption;

  const saveTeam = async (newTeam: Option) => {
    const mutationParams = {
      configId,
      configType: 'settings',
      configDefinition: { teamId: newTeam.value },
    } as UserConfigPutMutationParams;

    await updateUserConfigOptimistically(mutationParams, newTeam.value);
  };

  return (
    <div className="select-with-gender-overlay relative">
      <Dropdown
        components={{ MenuList, Option: OptionWithFlagAndGender }}
        value={userSelectedOption}
        id="team-select"
        onChange={saveTeam}
        label={t('data.defaultTeam.label')}
        labelPosition="top"
        isSearchable
        options={options}
        placeholder={t('dropdownPlaceholder', { ns: 'filters' })}
        isLoading={isLoading}
      />
      {!isLoading && (
        <div id="gender-icon">
          <GenderIcon gender={userSelectedOption?.optional.gender} />
        </div>
      )}
    </div>
  );
};

const UserDefaultTeamSelectorWithData = () => {
  const settingsConfig = useAtomValue(settingsConfigAtom);
  const userDefaultTeamId = useAtomValue(userDefaultTeamIdAtom);
  const teamOptions = useAtomValue(allTeamsOptionsAtom);

  return (
    <UserDefaultTeamSelectorBase
      options={teamOptions}
      configId={settingsConfig?.configId}
      userDefaultTeamId={userDefaultTeamId}
    />
  );
};

export const UserDefaultTeamSelector = () => (
  <Suspense fallback={<UserDefaultTeamSelectorBase isLoading />}>
    <UserDefaultTeamSelectorWithData />
  </Suspense>
);
