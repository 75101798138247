import { DEFAULT_SELECTION_LAYER_ATTRIBUTES } from '@/consts/selection';
import { activeRectAtom, resultRectAtom, selectedVisEventsAtom } from '@/atoms/vis/selection';
import { selectedVideoIdsBaseAtom } from '@/atoms/video';
import { useSetAtom } from 'jotai';

export const useResetSelectionLayer = () => {
  const setActiveRect = useSetAtom(activeRectAtom);
  const setResultRect = useSetAtom(resultRectAtom);
  const setSelectedEvents = useSetAtom(selectedVisEventsAtom);
  const setSelectedVideoIdsBase = useSetAtom(selectedVideoIdsBaseAtom);

  const resetSelectionLayer = () => {
    setSelectedEvents(null);
    setSelectedVideoIdsBase(null);
    setActiveRect(DEFAULT_SELECTION_LAYER_ATTRIBUTES);
    setResultRect(DEFAULT_SELECTION_LAYER_ATTRIBUTES);
  };

  return resetSelectionLayer;
};
