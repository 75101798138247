import { useStatus } from '@/hooks/useStatus';
import { StatusTranslationKey } from '@/types/status';
import { Card, CardBody } from '@statsbomb/kitbag-components';
import { ContentStateWrapper } from './ContentStateWrapper';
import { CardHeightWrapper } from '../card/CardHeightWrapper';

export const ContentState = ({ status }: { status: StatusTranslationKey }) => {
  const statusProps = useStatus('general', status);

  return (
    <ContentStateWrapper id={`${status}-state`}>
      <Card>
        <CardHeightWrapper>
          <CardBody {...statusProps} />
        </CardHeightWrapper>
      </Card>
    </ContentStateWrapper>
  );
};
