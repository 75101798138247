import { RadarTemplate } from '@/types/radar';
import { Position } from '@/types/player';

export const POSITION_TEMPLATE_MAP: Record<Position, number> = {
  gk: 6,

  lb: 5,
  lcb: 3,
  cb: 3,
  rcb: 3,
  rb: 5,

  lwb: 5,
  ldm: 2,
  cdm: 2,
  rdm: 2,
  rwb: 5,

  lm: 4,
  lcm: 2,
  cm: 2,
  rcm: 2,
  rm: 4,

  lw: 4,
  lam: 4,
  cam: 4,
  ram: 4,
  rw: 4,

  lcf: 1,
  cf: 1,
  rcf: 1,
};

export const PLAYER_RADAR_TEMPLATES: RadarTemplate[] = [
  {
    id: 1,
    labelKey: 'striker',
    metrics: [
      { name: 'npXgPer90', min: 0.14505713, max: 0.54307973 },
      { name: 'npShotsPer90', min: 1.355039, max: 3.5762923 },
      { name: 'shotObvPer90', min: -0.108406946, max: 0.16846159 },
      { name: 'passObvPer90', min: -0.09619579, max: 0.058441564 },
      { name: 'opXaPer90', min: 0.02581491, max: 0.21042155 },
      { name: 'pressuresPer90', min: 7.5337906, max: 23.561357 },
      { name: 'successfulAerialsPer90', min: 0.40867272, max: 6.1400585 },
      { name: 'turnoversPer90', min: 5.245992, max: 2.0483892 },
      { name: 'dribbleAndCarryObvPer90', min: -0.061220214, max: 0.14260457 },
      { name: 'touchesInsideBoxPer90', min: 3.3493412, max: 8.6796465 },
      { name: 'npXgPerShot', min: 0.07468059, max: 0.1997024 },
      // Pressures should be possession adjusted
    ],
  },
  {
    id: 2,
    labelKey: 'midfielder',
    metrics: [
      { name: 'obvPer90', min: 0.0129878, max: 0.29202682 },
      { name: 'deepProgressionsPer90', min: 2.3469543, max: 8.98575 },
      { name: 'passObvPer90', min: -0.025013508, max: 0.15662402 },
      { name: 'opXaPer90', min: 0.007804508, max: 0.15188773 },
      { name: 'dribbleAndCarryObvPer90', min: 0.026795035, max: 0.13025156 },
      { name: 'foulsWonPer90', min: 0.51834166, max: 2.594492 },
      { name: 'turnoversPer90', min: 2.96199, max: 0.48565084 },
      { name: 'pressuresPer90', min: 9.201789, max: 27.271767 },
      { name: 'tacklesAndInterceptionsPer90', min: 1.6250682, max: 6.420852 },
      { name: 'challengeRatio', min: 46.432817, max: 77.348393 },
      { name: 'defensiveActionObvPer90', min: -0.012746575, max: 0.08495106 },
      // Pressures and TacklesAndInterceptions should be possession adjusted
    ],
  },
  {
    id: 3,
    labelKey: 'centreBack',
    metrics: [
      { name: 'aerialRatio', min: 46.730083, max: 76.75386 },
      { name: 'successfulAerialsPer90', min: 1.1064066, max: 4.146533 },
      { name: 'obvPer90', min: 0.026067743, max: 0.26586694 },
      { name: 'passObvPer90', min: 0.014259459, max: 0.122260146 },
      { name: 'dribbleAndCarryObvPer90', min: 0.017530281, max: 0.08547187 },
      { name: 'foulsPer90', min: 1.7505201, max: 0.42817554 },
      { name: 'clearancesPer90', min: 2.3000357, max: 7.006515 },
      { name: 'shotBlocksPerNpShotConceded', min: 0.027073996, max: 0.09796031 },
      { name: 'challengeRatio', min: 57.13436, max: 86.86088 },
      { name: 'defensiveActionObvPer90', min: -0.031702943, max: 0.12626669 },
      // Missing: Being Pressured Change in Pass %, clearances should be possession adjusted
    ],
  },
  {
    id: 4,
    labelKey: 'attackingMidfielder',
    metrics: [
      { name: 'npXgPer90', min: 0.056607656, max: 0.35733584 },
      { name: 'npShotsPer90', min: 0.7890611, max: 3.164096 },
      { name: 'shotObvPer90', min: -0.0790421, max: 0.120219335 },
      { name: 'pressuresPer90', min: 8.22879, max: 25.60377 },
      { name: 'passObvPer90', min: -0.037286326, max: 0.17626053 },
      { name: 'opXaPer90', min: 0.03932271, max: 0.24644235 },
      { name: 'dribbleAndCarryObvPer90', min: 0.019074406, max: 0.1997373 },
      { name: 'foulsWonPer90', min: 0.6772711, max: 3.1206758 },
      { name: 'turnoversPer90', min: 5.2127824, max: 1.7117997 },
      { name: 'touchesInsideBoxPer90', min: 1.5990915, max: 6.6907268 },
      { name: 'npXgPerShot', min: 0.04550766, max: 0.1593568 },
      // Pressures should be possession adjusted
    ],
  },
  {
    id: 5,
    labelKey: 'fullBack',
    metrics: [
      { name: 'tacklesAndInterceptionsPer90', min: 1.5945218, max: 6.3597593 },
      { name: 'deepProgressionsPer90', min: 2.627467, max: 7.0221624 },
      { name: 'passObvPer90', min: 0.051077887, max: 0.25726104 },
      { name: 'opXaPer90', min: 0.015308842, max: 0.15939206 },
      { name: 'dribbleAndCarryObvPer90', min: 0.031427428, max: 0.13951632 },
      { name: 'turnoversPer90', min: 2.9139059, max: 0.521441 },
      { name: 'aerialRatio', min: 28.002378, max: 68.72771 },
      { name: 'pressuresPer90', min: 6.282792, max: 19.209778 },
      { name: 'foulsPer90', min: 1.930803, max: 0.4840476 },
      { name: 'challengeRatio', min: 52.97265, max: 82.4019 },
      { name: 'defensiveActionObvPer90', min: -0.02698042, max: 0.09467277 },
      // Pressures and TacklesAndInterceptions should be possession adjusted
    ],
  },
  {
    id: 6,
    labelKey: 'goalkeeper',
    metrics: [
      { name: 'goalkeeperObvPer90', min: -0.38215876, max: 0.13574341 },
      { name: 'longPassingRatio', min: 34.54221, max: 59.21522 },
      { name: 'gsaaRatio', min: -7.9294145, max: 6.175462 },
      { name: 'npPostShotXgConcededPer90' },
      { name: 'passObvPer90' },
      { name: 'aveGkAggressiveDistance' },
      { name: 'avePassLength', min: 18, max: 50 },
      // This is quite different from existing IQ as we don't have passes into danger, xclaims etc.
    ],
  },
];

export const TEAM_RADAR_TEMPLATES: RadarTemplate[] = [
  {
    id: 1,
    labelKey: 'attacking',
    metrics: [
      { name: 'npCumulativeXgPer90', min: 0.82025224, max: 1.8265312 },
      { name: 'npCumulativeXgPerShot', min: 0.075500004, max: 0.121773206 },
      { name: 'npShotsPer90', min: 9.586843, max: 17.435 },
      { name: 'counterAttackShotsPer90', min: 0.6205263, max: 1.6508514 },
      { name: 'setPieceShotsCumulativeXgPer90', min: 0.18130311, max: 0.38962084 },
      { name: 'clearShotsPer90', min: 3.80182, max: 7.57147 },
      { name: 'aveGkPassLength', min: 57.45718, max: 31.499866 },
      { name: 'opBoxCrossRatio', min: 47.23, max: 28.61 },
      // Missing: High Press Shots
    ],
  },
  {
    id: 2,
    labelKey: 'defending',
    metrics: [
      { name: 'npCumulativeXgConcededPer90', min: 1.6264125, max: 0.8183849 },
      { name: 'npCumulativeXgPerShotConceded', min: 0.11606099, max: 0.076090254 },
      { name: 'npShotsConcededPer90', min: 16.43158, max: 9.108882 },
      { name: 'counterAttackShotsConcededPer90', min: 1.6052631, max: 0.60342103 },
      { name: 'clearShotsConcededPer90', min: 8, max: 3.5 },
      { name: 'setPieceShotsCumulativeXgConcededPer90', min: 0.3938947, max: 0.17195721 },
      { name: 'passingRatioConceded', min: 82.64537, max: 74.32554 },
      { name: 'aggression', min: 0.145, max: 0.216 },
      { name: 'aveDefensiveActionDistance', min: 45, max: 60 },
      // Missing: Passes/Defensive Action
    ],
  },
];
