export default {
  VITE_AUTH0_DOMAIN: import.meta.env.VITE_AUTH0_DOMAIN,
  VITE_AUTH0_CLIENT_ID: import.meta.env.VITE_AUTH0_CLIENT_ID,
  VITE_PARACHUTE_API_ENDPOINT: import.meta.env.VITE_PARACHUTE_API_ENDPOINT,
  VITE_PARACHUTE_REST_API_ENDPOINT: import.meta.env.VITE_PARACHUTE_REST_API_ENDPOINT,
  VITE_NODE_ENV: import.meta.env.VITE_NODE_ENV,
  VITE_SENTRY_DEBUG: import.meta.env.VITE_SENTRY_DEBUG,
  VITE_SENTRY_DSN: import.meta.env.VITE_SENTRY_DSN,
  VITE_ENABLE_TANSTACK_DEVTOOLS: import.meta.env.VITE_ENABLE_TANSTACK_DEVTOOLS,
  DEV: import.meta.env.DEV,
};
