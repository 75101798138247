import { SetStateAction, useRef } from 'react';
import { useAtomValue } from 'jotai';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { FlyoutProvider, Typography } from '@statsbomb/kitbag-components';
import { tableColumnsEditorActiveTabAtom } from '@/atoms/table';
import { TableColumnsEditorTabs } from '@/types/table';
import { MenuItemWithIcon } from '@/components/menu/MenuItemWithIcon';
import { MoreOptionsMenu } from '@/components/menu/MoreOptionsMenu/MoreOptionsMenu';
import { SortableContent } from './SortableContent';

export const ColumnSort = ({
  visibleColumns,
  setVisibleColumns,
  defaultColumns,
}: {
  visibleColumns: string[];
  setVisibleColumns: (value: SetStateAction<string[]>) => void;
  defaultColumns: string[];
}) => {
  const flyoutContextRef = useRef(null);
  const { t } = useTranslation(['table', 'general']);
  const activeTab = useAtomValue(tableColumnsEditorActiveTabAtom);

  return (
    <div
      className={classNames('bg-canvas-secondary-main overflow-y-auto w-full md:!w-2/5', {
        'hidden md:block': activeTab !== TableColumnsEditorTabs.COLUMN_SORT,
      })}
    >
      <div
        className="flex items-center justify-between p-4 sticky top-0 z-[1] bg-canvas-secondary-main"
        ref={flyoutContextRef}
      >
        <Typography variant="headingMedium" as="h2" className="tabular-nums">
          {t('selectedColumnsCount', { count: visibleColumns.length })}
        </Typography>
        <FlyoutProvider value={flyoutContextRef}>
          <MoreOptionsMenu testId="column-sort-menu">
            <MenuItemWithIcon
              icon="Erase"
              text={t('clearAll', { ns: 'general' })}
              onClick={() => setVisibleColumns([])}
              disabled={!visibleColumns.length}
            />
            <MenuItemWithIcon
              onClick={() => setVisibleColumns(defaultColumns)}
              icon="Reset"
              text={t('resetToDefault')}
            />
          </MoreOptionsMenu>
        </FlyoutProvider>
      </div>
      <SortableContent visibleColumns={visibleColumns} setVisibleColumns={setVisibleColumns} />
    </div>
  );
};
