import { ReactNode } from 'react';

export const ColumnTopBar = ({ children }: { children: ReactNode }) => (
  <div
    className="mb-4 -mt-4 -mx-4 px-4 border-canvas-primary-main
      border-b border-opacity-50 py-2 content-center h-12"
  >
    <div className="flex items-center gap-2 flex-wrap">{children}</div>
  </div>
);
