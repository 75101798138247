import { atom } from 'jotai';
import { unwrap } from 'jotai/utils';
import { formatPlayerName } from '@/utils/player';
import { rawFilteredPlayerInfoAtom } from '../queries/players';

export const selectedPlayerNameAtom = atom(async get => {
  const playerInfo = await get(rawFilteredPlayerInfoAtom);
  const [playerInfoData] = playerInfo.data;

  if (!playerInfoData) return '';
  const { name, nickname } = playerInfoData;

  return formatPlayerName(name, nickname);
});

export const unwrappedSelectedPlayerNameAtom = unwrap(selectedPlayerNameAtom, prev => prev || '');
