import { useAtom } from 'jotai';
import { orderByAtom, orderDirectionAtom } from '@/atoms/table';
import { SORT_DIRECTION } from '@/types/ordering';

export const useSortTable = () => {
  const [orderBy, setOrderTableBy] = useAtom(orderByAtom);
  const [orderDirection, setOrderDirection] = useAtom(orderDirectionAtom);

  const handleSort = (newOrderBy: string, isLowerBetter: boolean = false) => {
    const isDifferentColumn = newOrderBy !== orderBy;
    const initialOrderDirection = isLowerBetter ? SORT_DIRECTION.ASC : SORT_DIRECTION.DESC;
    const newOrderDirection = orderDirection === SORT_DIRECTION.DESC ? SORT_DIRECTION.ASC : SORT_DIRECTION.DESC;

    const direction = isDifferentColumn ? initialOrderDirection : newOrderDirection;

    setOrderDirection(direction);
    setOrderTableBy(newOrderBy);
  };

  const getSortedState = (key: string) => (orderBy === key ? orderDirection : 'default');

  return { orderBy, orderDirection, handleSort, getSortedState };
};
