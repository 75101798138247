import { loadableTeamSelectedGamesForQueryAtom } from '@/atoms/filters/team/teamGames';
import { loadableTeamRadarDataAtom } from '@/atoms/queries/team/teamRadar';
import { RadarChart } from '@/components/radar/RadarChart';
import { SBID } from '@/types/generic';

export const TeamRadarChart = ({
  id,
  isWidget,
  isInteractive,
}: {
  id: SBID;
  isWidget?: boolean;
  isInteractive?: boolean;
}) => (
  <RadarChart
    id={id}
    loadableSelectedGamesAtom={loadableTeamSelectedGamesForQueryAtom}
    loadableRadarDataAtom={loadableTeamRadarDataAtom}
    isWidget={isWidget}
    isInteractive={isInteractive}
  />
);
