import { closeToastAtom, toastDataAtom } from '@/atoms/toast';
import { Toast } from '@statsbomb/kitbag-components';
import { useAtomValue, useSetAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

export const GenericToast = () => {
  const { t } = useTranslation(['toast', 'general']);
  const toastData = useAtomValue(toastDataAtom);
  const { toastName, type, content, entityName, translationOptions, isOpen } = toastData;
  const closeToast = useSetAtom(closeToastAtom);
  const title = t(`${toastName}.${type}.title`, {
    ns: 'toast',
    /* 
      Doing this as per the i18next translation docs to escape the input before translating
      Built in i18next escaping results in things like & being output as &amp
      Instead, wrapping text as a string literal to escape it
      https://www.i18next.com/translation-function/interpolation#unescape
    */
    entityName: `${entityName}`,
    interpolation: { escapeValue: false },
    ...translationOptions,
  });
  const description = t(`${toastName}.${type}.description`, {
    ns: 'toast',
    tryAgain: t('tryAgain', { ns: 'general' }),
    entityName: `${entityName}`,
    interpolation: { escapeValue: false },
    ...translationOptions,
  });

  return (
    <Toast title={title} isOpen={isOpen} onClose={closeToast} type={type} testId={`${toastName}-${type}`}>
      {description}
      {content}
    </Toast>
  );
};
