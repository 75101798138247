import { FormattedGame, FormattedGameWithTeam } from '@/types/game';
import { Option } from '@/types/generic';
import { Atom, useAtomValue } from 'jotai';

export enum GamesFilterType {
  COMPETITION = 'competition',
  SEASON = 'season',
  TEAM = 'team',
}

export const useGetGamesByFilter = (
  filteredGamesDataAtom: Atom<Promise<FormattedGame[]>>,
  selectedGamesAtom: Atom<Promise<Option[]>>,
  gamesFilterType: GamesFilterType,
) => {
  const filteredGameData = useAtomValue(filteredGamesDataAtom) as FormattedGameWithTeam[];
  const selectedGames = useAtomValue(selectedGamesAtom);
  const selectedGameIds = selectedGames.map(game => game.value);

  return [
    ...new Set(
      filteredGameData
        .filter(game => selectedGameIds.includes(game.game.gameId))
        .map(game => {
          if (gamesFilterType === GamesFilterType.COMPETITION) return game.cycle.competitionId;
          if (gamesFilterType === GamesFilterType.SEASON) return game.cycle.seasonId;
          return game.team.teamId;
        }),
    ),
  ];
};
