import { useAtomValue } from 'jotai';
import { unwrappedSelectedPlayerNameAtom } from '@/atoms/player/info';
import { unwrappedSelectedTeamNameAtom } from '@/atoms/team/info';
import { sectionAtom } from '@/atoms/general';
import { useTranslation } from 'react-i18next';

export const useExportTitle = () => {
  const { t } = useTranslation('navigation');
  const section = useAtomValue(sectionAtom);
  const playerName = useAtomValue(unwrappedSelectedPlayerNameAtom);
  const teamName = useAtomValue(unwrappedSelectedTeamNameAtom);

  if (section === 'player') return playerName;
  if (section === 'team') return teamName;
  return t('primary.dataLocker');
};
