import { useIsPage } from '@/hooks/useIsPage';
import { Suspense } from 'react';
import { Loader } from '@statsbomb/kitbag-components';
import { EntityName } from '../entity/EntityName';
import { MetricName } from '../metric/MetricName';

export const EntityPreviewHeader = () => {
  const isDataLockerPage = useIsPage('/data-locker/');
  const isSquadPage = useIsPage('/squad');

  return (
    <span>
      {isDataLockerPage || isSquadPage ? (
        <Suspense fallback={<Loader size="1rem" />}>
          <EntityName /> - <MetricName />
        </Suspense>
      ) : (
        <MetricName />
      )}
    </span>
  );
};
