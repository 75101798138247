import { widgetIdAtom } from '@/atoms/report/widget';
import { sectionAtom } from '@/atoms/general';
import {
  displayAtomsToHydrate,
  displayAtomsToHydrateCallback,
  filterAtomsToHydrate,
  filterAtomsToHydrateCallback,
} from '@/consts/atomHydration';
import { entityIdAtoms } from '@/consts/widget';
import {
  AtomsToHydrate,
  ConfigDisplaySettingKey,
  ConfigDisplaySettings,
  ConfigFilterKey,
  ConfigFilterSet,
} from '@/types/userConfigs';
import { ReportWidgetHydrate } from '@/types/widget';

export const getFilterAtomsToHydrate = (filters: ConfigFilterSet) =>
  Object.entries(filters).map(([key, value]) => {
    const filterKey = key as ConfigFilterKey;
    return [filterAtomsToHydrate()[filterKey], filterAtomsToHydrateCallback[filterKey](value)];
  }) as AtomsToHydrate;

const getDisplayAtomsToHydrate = (filters: ConfigDisplaySettings) =>
  Object.entries(filters).map(([key, value]) => {
    const filterKey = key as ConfigDisplaySettingKey;
    return [displayAtomsToHydrate()[filterKey], displayAtomsToHydrateCallback[filterKey](value)];
  }) as AtomsToHydrate;

export const getWidgetAtomsToHydrate = ({
  widgetId,
  entity,
  entityId,
  filters,
  displaySettings,
}: ReportWidgetHydrate) =>
  [
    ...getFilterAtomsToHydrate(filters),
    ...getDisplayAtomsToHydrate(displaySettings),
    [widgetIdAtom, widgetId],
    [sectionAtom, entity],
    ...(entityId ? [[entityIdAtoms[entity], entityId]] : []),
  ] as AtomsToHydrate;
