import { filterAtomDefaults } from '@/consts/defaults/filterAtomDefaults';
import { firstDateForAge, lastDateForAge } from '@/utils/date';
import { atom } from 'jotai';

export const minAgeSelectedAtom = atom(filterAtomDefaults.minAge);
export const maxAgeSelectedAtom = atom(filterAtomDefaults.maxAge);

export const minAgeBirthDateToQueryAtom = atom(get => {
  const minAgeSelected = get(minAgeSelectedAtom);
  return firstDateForAge(minAgeSelected);
});

export const maxAgeBirthDateToQueryAtom = atom(get => {
  const maxAgeSelected = get(maxAgeSelectedAtom);
  return lastDateForAge(maxAgeSelected);
});
