import { selectedGameIdForVideosAtom } from '@/atoms/video';
import { GAME_ID } from '@/consts/searchKeys';
import { useSetAtom } from 'jotai';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

export const useSetGameIdForVideos = () => {
  const [searchParams] = useSearchParams();
  const setSelectedGameIdForVideosAtom = useSetAtom(selectedGameIdForVideosAtom);
  const gameId = searchParams.get(GAME_ID);

  useEffect(() => {
    setSelectedGameIdForVideosAtom(Number(gameId));
  }, [gameId]);
};
