import React, { ReactNode, Ref, forwardRef } from 'react';
import { useAtomValue } from 'jotai';
import { isDarkModeAtom } from '@/atoms/theme';
import { Typography } from '@statsbomb/kitbag-components';
import ExportLogoLight from '@/images/logos/sb_logo_with_icon.svg';
import ExportLogoDark from '@/images/logos/sb_logo_with_icon_negative.svg';

const ExportInfo = ({ children }: { children: ReactNode }) => (
  <Typography variant="headingMedium" className="text-end text-nowrap">
    {children}
  </Typography>
);

/**
 * ExportTemplate
 * A component that displays a visualisation in a template as a preview before exporting
 */
const ExportTemplate = forwardRef(
  (
    {
      children,
      title,
      secondaryTitle = '',
      info1 = '',
      info2 = '',
      info3 = '',
      width,
      withLogo,
    }: {
      children: ReactNode;
      title: string;
      secondaryTitle?: string;
      info1?: string;
      info2?: string;
      info3?: string;
      width: string;
      withLogo?: boolean;
    },
    exportRef: Ref<HTMLDivElement>,
  ) => {
    const isDarkMode = useAtomValue(isDarkModeAtom);
    const ExportLogo = isDarkMode ? ExportLogoDark : ExportLogoLight;

    return (
      <div className="flex flex-col gap gap-4 bg-canvas-tertiary-main p-4" style={{ width }} ref={exportRef}>
        <header className="flex flex-col w-full">
          <div className="flex justify-between items-end">
            <Typography variant="headingLarge" className="text-nowrap">
              {title}
            </Typography>
            <ExportInfo>{info1}</ExportInfo>
          </div>
          <div className="flex justify-between items-end">
            <Typography variant="headingRegular" className="text-nowrap">
              {secondaryTitle}
            </Typography>
            <ExportInfo>{info2}</ExportInfo>
          </div>
          <div className="flex justify-end items-end">
            <ExportInfo>{info3}</ExportInfo>
          </div>
        </header>
        <div className="flex gap-4 pointer-events-none">{children}</div>
        {withLogo && (
          <div className="w-[125px] ml-auto">
            <ExportLogo data-testid="export-logo" />
          </div>
        )}
      </div>
    );
  },
);

export default ExportTemplate;
