import { Slider } from '@statsbomb/kitbag-components';

export const SliderMultipleBase = ({
  title,
  min,
  max,
  value,
  onChange,
  step,
}: {
  title: string;
  min: number;
  max: number;
  value: [number, number];
  onChange: (range: [number, number]) => void;
  step?: number;
}) => (
  // TODO (KC-210: Slider component types need resolving. Until then am using ts-ignore to suppress errors)
  <div>
    {/* @ts-ignore */}
    <Slider min={min} max={max} title={title} value={value} onChange={onChange} step={step} />
  </div>
);
