import { atom } from 'jotai';
import { unwrap } from 'jotai/utils';
import { settingsConfigAtom } from '@/atoms/queries/userConfigs';
import { isValidEntityUser } from '@/utils/user';
import { userDetailsAtom } from './queries/user';

export const hasVideoLicenceAtom = atom(async get => {
  const user = await get(userDetailsAtom);

  return user.data?.features?.video;
});

export const userDefaultTeamIdAtom = atom(async get => {
  const individualDefaultTeam = await get(settingsConfigAtom);
  if (individualDefaultTeam) return individualDefaultTeam.definition.teamId;
  return (await get(userDetailsAtom)).data?.entity?.team_id;
});

export const unwrappedUserDefaultTeamIdAtom = unwrap(userDefaultTeamIdAtom, prev => prev || undefined);

export const isValidEntityUserAtom = atom(async get => {
  const { data } = await get(userDetailsAtom);
  return isValidEntityUser(data);
});
