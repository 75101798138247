import { competitionOptionsAtom, selectedCompetitionsAtom } from '@/atoms/filters/highLevel/competitions';
import { Atom, useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';
import { getDistinctOrderedCompetitionOptions } from '@/utils/cycles';
import { KeysToCamelCase, Option } from '@/types/generic';
import { CycleWithRels } from '@statsbomb/parachute-types';
import { useGetFilteredOptions } from '@/hooks/useGetFilteredOptions';
import { GamesFilterType } from '@/hooks/useGetGamesByFilter';
import { FormattedGame } from '@/types/game';
import { RadarFilterSummaryItem } from './RadarFilterSummaryItem';

export const RadarFilterCompetitionSummary = ({
  filteredGamesDataAtom,
  selectedGamesAtom,
  orderedCyclesAtom,
}: {
  orderedCyclesAtom: Atom<Promise<KeysToCamelCase<CycleWithRels>[]>>;
  filteredGamesDataAtom: Atom<Promise<FormattedGame[]>>;
  selectedGamesAtom: Atom<Promise<Option[]>>;
}) => {
  const { t } = useTranslation('entity');
  const orderedCycles = useAtomValue(orderedCyclesAtom);

  const filteredCompetitionOptions = useGetFilteredOptions(
    competitionOptionsAtom,
    filteredGamesDataAtom,
    selectedGamesAtom,
    GamesFilterType.COMPETITION,
  );

  const sortedCompetitionOptions = getDistinctOrderedCompetitionOptions(orderedCycles, filteredCompetitionOptions);

  return (
    <RadarFilterSummaryItem
      label={t('competition.one')}
      options={sortedCompetitionOptions}
      selectedOptions={useAtomValue(selectedCompetitionsAtom)}
      showFlags
    />
  );
};
