import { Dropdown } from '@statsbomb/kitbag-components';
import { useAtom } from 'jotai';
import { selectedPeriodRangeAtom } from '@/atoms/filters/highLevel';
import { PERIOD_RANGE_OPTIONS } from '@/consts/gameRange';
import { useTranslation } from 'react-i18next';
import { getTranslatedOption, getTranslatedOptions } from '@/utils/translations';
import { BaseOption } from '@/types/generic';
import { PeriodRange } from '@/types/game';

export const PeriodRangeSelector = () => {
  const { t } = useTranslation('filters');
  const [selectedPeriodRange, setSelectedPeriodRange] = useAtom(selectedPeriodRangeAtom);

  return (
    <Dropdown
      id="period-range-selector"
      value={getTranslatedOption(selectedPeriodRange)}
      onChange={(periodRange: BaseOption<PeriodRange>) =>
        setSelectedPeriodRange(PERIOD_RANGE_OPTIONS.find(option => String(option.value) === String(periodRange.value))!)
      }
      options={getTranslatedOptions(PERIOD_RANGE_OPTIONS)}
      label={t('period')}
      labelPosition="top"
    />
  );
};
