import { atom } from 'jotai';
import { combineMetricData } from '@/utils/metrics';
import { playerMetricDistributionsAtom, playerMetricsAtom } from '../queries/player/playerRadar';

export const playerMetricDataAtom = atom(async get => {
  const configTotalMetrics = await get(playerMetricsAtom);
  const metricDistros = await get(playerMetricDistributionsAtom);

  return combineMetricData(configTotalMetrics, metricDistros);
});
