import { useApplicationReferenceData } from '@/hooks/dataFetching/useApplicationReferenceData';
import { useFilterStateHistory } from '@/hooks/useFilterStateHistory';
import { useOnBackButtonClick } from '@/hooks/useOnBackButtonClick';
import { useTheme } from 'styled-components';
import { AppBar, TopBarInfo } from '@statsbomb/kitbag-components';
import { ReactNode, Suspense } from 'react';
import { SideNav } from '@/components/navigation/SideNav';
import { Loading } from '../translations/Generic';
import { GenericToast } from '../alerts/GenericToast/GenericToast';

export const AppShellWithData = ({
  topBar,
  subnav,
  sideNav,
  children,
}: {
  topBar: ReactNode;
  subnav?: ReactNode;
  sideNav: ReactNode;
  children: ReactNode;
}) => {
  useApplicationReferenceData();
  useFilterStateHistory();
  useOnBackButtonClick();
  const theme = useTheme();
  const isTouch = theme.mqMatch('md');

  return (
    <>
      <div className="flex h-full isolate" data-testid="appshell">
        <div className="z-10">{sideNav}</div>
        <div className="flex flex-col w-full overflow-x-hidden grow appbar-wrapper ">
          <AppBar version={2}>
            <TopBarInfo isTouch={isTouch}>{topBar}</TopBarInfo>
            {subnav}
          </AppBar>
          {children}
        </div>
      </div>
      <GenericToast />
    </>
  );
};

export const AppShell = ({
  topBar,
  subnav,
  sideNav = <SideNav />,
  children,
}: {
  topBar: ReactNode;
  subnav?: ReactNode;
  sideNav?: ReactNode;
  children: ReactNode;
}) => (
  <Suspense fallback={<Loading />}>
    <AppShellWithData topBar={topBar} subnav={subnav} sideNav={sideNav}>
      {children}
    </AppShellWithData>
  </Suspense>
);
