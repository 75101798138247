import { Search } from '../search/Search';
import { SupportLinks } from './SupportLinks';

export const TopBarBase = ({
  children,
  extraContent,
}: {
  children?: React.ReactNode;
  extraContent?: React.ReactNode;
}) => (
  <div data-testid="top-bar" className="pr-4 md:px-4 bg-canvas-tertiary-main w-full flex h-8 relative">
    <div className="flex items-center w-full gap-2">
      <div className="flex gap-2 items-center flex-1 truncate">{children}</div>
      {extraContent && <div className="flex gap-2 items-center">{extraContent}</div>}
    </div>
  </div>
);

const WrappedSupportLinks = () => (
  <div className="hidden md:block relative">
    <SupportLinks />
  </div>
);

export const TopBar = ({ children }: { children?: React.ReactNode }) => (
  <TopBarBase
    extraContent={
      <>
        <WrappedSupportLinks />
        <Search />
      </>
    }
  >
    {children}
  </TopBarBase>
);

export const EmptyTopBar = ({ children }: { children?: React.ReactNode }) => (
  <TopBarBase extraContent={<WrappedSupportLinks />}>{children}</TopBarBase>
);
