import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAtomValue, useSetAtom } from 'jotai';
import { WidgetFiltersModal } from '@/components/modal/WidgetFiltersModal/WidgetFiltersModal';
import { unwrappedSelectedReportAtom } from '@/atoms/report/reportSaving';
import { ReportWidgets } from '@/components/report/ReportWidgets/ReportWidgets';
import { AppShell } from '@/components/layout/AppShell';
import { PageTitle } from '@/components/head/PageTitle';
import { RenameReportModal } from '@/components/modal/RenameReportModal/RenameReportModal';
import { DeleteReportModal } from '@/components/modal/DeleteReportModal/DeleteReportModal';
import { useNavigate } from 'react-router-dom';
import { ReportTopBar } from '@/components/report/ReportTopBar/ReportTopBar';
import { useSetReportId } from '@/hooks/useSetReportId';
import { toastDataAtom } from '@/atoms/toast';

const Report = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation(['navigation', 'report', 'general']);

  useSetReportId();
  const navigate = useNavigate();
  const selectedReport = useAtomValue(unwrappedSelectedReportAtom);

  const setToastData = useSetAtom(toastDataAtom);

  useEffect(() => {
    if (selectedReport === null) {
      setToastData('nonexistantReport', false);
      navigate(`/${language}/reports`, { replace: true });
    }
  }, [selectedReport]);

  return (
    <>
      <PageTitle>{t('primary.report')}</PageTitle>
      <AppShell topBar={<ReportTopBar />}>
        <ReportWidgets />
        <WidgetFiltersModal />
      </AppShell>
      <RenameReportModal />
      <DeleteReportModal afterDeleteHandler={() => navigate(`/${language}/reports`)} />
    </>
  );
};

export default Report;
