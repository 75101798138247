import { atom } from 'jotai';
import { atomWithSuspenseQuery } from 'jotai-tanstack-query';
import { fetchClientAtom } from '@/atoms/queries/client';
import { objSnakeToCamel } from '@/utils/queries';
import { Visualisation } from '@statsbomb/parachute-types';
import { getVisualisationsForMetric } from '@/utils/configVisualisation';
import { metricKeyAtom } from '../metric';

const rawVisualisationsAtom = atomWithSuspenseQuery(get => ({
  queryKey: ['visualisations'],
  queryFn: async () => {
    const { fetch } = get(fetchClientAtom);

    return (await fetch(`/visualisations`)) as Promise<Visualisation[]>;
  },
}));

const visualisationsAtom = atom(async get => (await get(rawVisualisationsAtom)).data.map(objSnakeToCamel));

export const visualisationConfigsAtom = atom(async get => {
  const configVisualisation = await get(visualisationsAtom);
  const selectedMetric = get(metricKeyAtom) || '';
  const availableVisualisations = getVisualisationsForMetric(configVisualisation, selectedMetric);
  return availableVisualisations;
});
