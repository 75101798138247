import { pitchOrientationAtom } from '@/atoms/visualisation';
import { PITCH_ORIENTATION } from '@/consts/visualisations';
import { snakeToCamel } from '@/utils/queries';
import { RadioButton, RadioGroup } from '@statsbomb/kitbag-components';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

export const PitchOrientationToggle = () => {
  const { t } = useTranslation('visualisation');
  const [pitchOrientation, setPitchOrientation] = useAtom(pitchOrientationAtom);

  return (
    <RadioGroup groupId="pitch-crop-group" groupTitle={t('orientation.pitchOrientation')} size="regular">
      {PITCH_ORIENTATION.map(orientation => (
        <RadioButton
          key={orientation}
          checked={orientation === pitchOrientation}
          onChange={() => setPitchOrientation(orientation)}
          id={`option-${orientation}`}
          label={t(`orientation.${snakeToCamel(orientation.toLocaleLowerCase())}`)}
          value={orientation}
          size="small"
        />
      ))}
    </RadioGroup>
  );
};
