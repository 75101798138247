import { eventDataFilterParamsAtom } from '@/atoms/queries/eventData';
import { TopBar } from '@/components/topBar/TopBar';
import { TopBarHeading } from '@/components/topBar/TopBarHeading';
import { useAutoResetSelectionLayer } from '@/hooks/useAutoResetSelectionLayer';
import { useAtomValue } from 'jotai';
import { ScopeProvider } from 'jotai-scope';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { layoutScopeAtoms } from '@/consts/scope';
import { AppShell } from '../AppShell';
import { SectionWrapper } from '../SectionWrapper';

const DataLockerInner = () => {
  const filterParams = useAtomValue(eventDataFilterParamsAtom);
  useAutoResetSelectionLayer(filterParams);

  return (
    <SectionWrapper section="dataLocker">
      <Outlet />
    </SectionWrapper>
  );
};

export const DataLockerLayout = () => {
  const { t } = useTranslation('navigation');

  return (
    <ScopeProvider atoms={layoutScopeAtoms}>
      <AppShell
        topBar={
          <TopBar>
            <TopBarHeading>{t('primary.dataLocker')}</TopBarHeading>
          </TopBar>
        }
      >
        <DataLockerInner />
      </AppShell>
    </ScopeProvider>
  );
};
