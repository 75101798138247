import { Grid } from '@statsbomb/kitbag-components';
import { useTranslation } from 'react-i18next';
import { LayoutWithFilters } from '@/components/layout/LayoutWithFilters';
import { TableScopeProvider } from '@/components/tables/TableScopeProvider';
import { PageTitle } from '@/components/head/PageTitle';
import { ScopeProvider } from 'jotai-scope';
import { tableColumnEntitiesAtom } from '@/atoms/queries/tableColumns';
import { TeamAggsTableWithPagination } from '@/components/tables/TeamAggsTable';
import { TeamAggsFilters } from '@/components/dataLocker/TeamAggsFilters';
import { TableDataControls } from '@/components/controls/TableDataControls';
import { useCsvDownload } from '@/hooks/exporting/useCsvDownload';
import { useTableColumns } from '@/hooks/useTableColumns';
import { teamsNormalisedAggregatesUrl } from '@/query/url';
import { useAtomValue } from 'jotai';
import { teamAggsFilterParamsAtom } from '@/atoms/queries/team/teamAggData';
import { teamDataVisibleColumnsAtom, teamDataDefaultColumnsAtom } from '@/atoms/dataLocker/team/table';

const TableWithControls = ({ pageTitle }: { pageTitle: string }) => {
  const { t } = useTranslation(['navigation']);
  useTableColumns(['team', 'team_metric']);
  const teamAggsFilterParams = useAtomValue(teamAggsFilterParamsAtom);
  useCsvDownload(pageTitle, teamsNormalisedAggregatesUrl(teamAggsFilterParams));

  return (
    <>
      <TableDataControls
        visibleColumnsAtom={teamDataVisibleColumnsAtom}
        defaultColumnsAtom={teamDataDefaultColumnsAtom}
        heading={t(`viewing.table`, { ns: 'visualisation' })}
      />
      <TableScopeProvider>
        <TeamAggsTableWithPagination />
      </TableScopeProvider>
    </>
  );
};

export const TeamAggsPage = () => {
  const { t } = useTranslation(['navigation', 'entity']);
  const pageTitle = t('pageTitles.dataLocker', {
    entity: t('team.one', { ns: 'entity' }),
  });

  return (
    <ScopeProvider atoms={[tableColumnEntitiesAtom]}>
      <PageTitle>{pageTitle}</PageTitle>
      <LayoutWithFilters filters={<TeamAggsFilters />}>
        <Grid>
          <Grid item>
            <TableWithControls pageTitle={pageTitle} />
          </Grid>
        </Grid>
      </LayoutWithFilters>
    </ScopeProvider>
  );
};
