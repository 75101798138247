import { atomWithDebounce } from '@/atoms/debounce';
import { playerMetricDataAtom } from '@/atoms/player/metric';
import { PLAYER_RADAR_TEMPLATES } from '@/consts/radar';
import { Nullable } from '@/types/generic';
import { NestedObject } from '@/types/object';
import { convertFilterParamsToString, hasEmptyEqFilters } from '@/utils/api';
import { atom } from 'jotai';
import { atomWithSuspenseQuery } from 'jotai-tanstack-query';
import { loadable } from 'jotai/utils';
import { playerIdAtom } from '../../player/player';
import { fetchClientAtom } from '../client';
import { metricDistributionsAtom, metricsAtom } from '../metric';
import { radarDataAtom } from '../radar';

export const playerRadarFilterDebounceObject = atomWithDebounce<Nullable<NestedObject>>(null);
const { debouncedValueAtom: playerRadarFilterAtom } = playerRadarFilterDebounceObject;

export const rawPlayerRadarDataAtom = atomWithSuspenseQuery(get => {
  const queryKey = ['playerRadarData', get(playerIdAtom), get(playerRadarFilterAtom)] as const;

  const queryFn = async ({ queryKey: [, playerId, filterParams] }: { queryKey: typeof queryKey }) => {
    if (!playerId || hasEmptyEqFilters(filterParams)) return {};
    const { fetch } = get(fetchClientAtom);

    return (await fetch(
      `/player/${playerId}/normalised-aggregates${convertFilterParamsToString(filterParams)}`,
    )) as Promise<Record<string, number>>;
  };

  return { queryKey, queryFn };
});

export const loadablePlayerRadarDataAtom = loadable(
  radarDataAtom(rawPlayerRadarDataAtom, playerMetricDataAtom, PLAYER_RADAR_TEMPLATES),
);

export const playerMetricsAtom = atom(async get => (await get(metricsAtom)).filter(({ player }) => player));

export const playerMetricDistributionsAtom = atom(async get =>
  (await get(metricDistributionsAtom)).filter(({ player }) => player),
);
