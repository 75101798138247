import { tableColumnsEditorActiveTabAtom } from '@/atoms/table';
import { CheckboxGroupBase } from '@/components/checkboxGroups/CheckboxGroupBase';
import { ColumnGroup, TableColumnsEditorTabs } from '@/types/table';
import { sortObjectArray, toggleValue } from '@/utils/array';
import { noop } from '@/utils/noop';
import { Button, SearchField, Typography } from '@statsbomb/kitbag-components';
import classNames from 'classnames';
import { useAtomValue } from 'jotai';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const ColumnSelect = ({
  columnGroups,
  visibleColumns,
  setVisibleColumns,
}: {
  columnGroups: ColumnGroup[];
  visibleColumns: string[];
  setVisibleColumns: (newVisibleColumns: string[]) => void;
}) => {
  const { t } = useTranslation(['table', 'general', 'events', 'metrics', 'columnGroups']);
  const activeTab = useAtomValue(tableColumnsEditorActiveTabAtom);
  const [searchValue, setSearchValue] = useState('');
  const {
    i18n: { language },
  } = useTranslation();

  const translatedColumnGroups = columnGroups.map(({ header, options }) => ({
    header: t(header.translationKey, { ns: header.translationNs }),
    options: sortObjectArray(
      'label',
      options.map(({ value, label }) => ({
        value,
        label: t(label.translationKey, { ns: label.translationNs }),
      })),
      language,
    ),
  }));

  const sortedGroupedColumns = sortObjectArray('header', translatedColumnGroups, language);

  const filteredGroupedColumns = sortedGroupedColumns
    .map(group => ({
      ...group,
      options: group.options.filter(option => option.label.toLowerCase().includes(searchValue.toLowerCase().trim())),
    }))
    .filter(({ options }) => options.length);

  const toggleColumn = (columnKey: string) => setVisibleColumns(toggleValue(columnKey, visibleColumns));

  // select all search filtered columns, which are not selected yet
  const filteredColumnValues = filteredGroupedColumns.flatMap(group => group.options.map(option => option.value));
  const selectAllColumns = () => {
    const updatedColumns = filteredColumnValues.filter(columnValue => !visibleColumns.includes(columnValue));
    setVisibleColumns([...visibleColumns, ...updatedColumns]);
  };

  return (
    <div
      className={classNames('overflow-y-auto w-full md:!w-3/5', {
        'hidden md:flex flex-col': activeTab !== TableColumnsEditorTabs.COLUMN_SELECT,
      })}
    >
      {/* z-index is needed so a sticky element appear above other elements with `position: relative;` */}
      <header className="flex justify-between items-center p-4 sticky top-0 bg-canvas-tertiary-main z-[1]">
        <Typography variant="headingMedium">{t('availableColumns')}</Typography>
        <Button variant="secondary" size="small" onClick={selectAllColumns}>
          {t('selectAll', { ns: 'general' })}
        </Button>
      </header>
      <div className="w-full [&>div]:min-w-full px-4 pb-4 pt-0.5">
        <SearchField
          clearTitle={t('clear', { ns: 'general' })}
          searchIconTitle={t('search', { ns: 'general' })}
          onClear={() => setSearchValue('')}
          onClick={noop}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchValue(e.target.value)}
          placeholder={t('searchField')}
          value={searchValue}
        />
      </div>
      {searchValue.trim() && (
        <Typography variant="headingSmall" className="px-4 pb-4">
          {t('searchSummary', { count: filteredColumnValues.length, searchValue })}
        </Typography>
      )}
      <div className="flex flex-col gap-6 px-4 pb-4">
        {filteredGroupedColumns.map(group => (
          <CheckboxGroupBase
            header={group.header}
            key={group.header}
            options={group.options}
            selectedValues={visibleColumns}
            onChange={toggleColumn}
          />
        ))}
      </div>
    </div>
  );
};
