import { userDefaultTeamIdAtom } from '@/atoms/user';
import { useAtomValue } from 'jotai';
import { Suspense } from 'react';
import { Navigate } from 'react-router-dom';
import { Loading } from '../translations/Generic';

const RedirectToTeamWithData = () => {
  const userDefaultTeamId = useAtomValue(userDefaultTeamIdAtom);

  return <Navigate to={`team/${userDefaultTeamId}/radar`} />;
};

export const RedirectToTeam = () => (
  <Suspense fallback={<Loading />}>
    <RedirectToTeamWithData />
  </Suspense>
);
