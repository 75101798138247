import { Icon, MenuItem, MenuItemText } from '@statsbomb/kitbag-components';

export const MenuItemWithIcon = ({
  text,
  icon,
  ...props
}: {
  text: string;
  icon?: string;
  onClick?: () => void;
  testId?: string;
  disabled?: boolean;
  isActive?: boolean;
}) => (
  <MenuItem iconPosition="left" {...props}>
    {icon && <Icon variant={icon} size="small" />}
    <MenuItemText>{text}</MenuItemText>
  </MenuItem>
);
