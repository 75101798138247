import { useTranslation } from 'react-i18next';
import { useResetSelectionLayer } from '@/hooks/useResetSelectionLayer';
import { useSetAtom } from 'jotai';
import { selectedVideoIdsBaseAtom } from '@/atoms/video';
import { useNavigateToPreview } from '@/hooks/useNavigateToPreview';
import { ButtonIcon } from '@statsbomb/kitbag-components';

export const PlayVideoButton = ({ uuid, eventId }: { uuid: string; eventId: string }) => {
  const { t } = useTranslation('video');
  const setSelectedVideoIds = useSetAtom(selectedVideoIdsBaseAtom);
  const resetSelectionLayer = useResetSelectionLayer();

  const navigateToPreview = useNavigateToPreview();

  const handleSelect = () => {
    resetSelectionLayer();
    setSelectedVideoIds([{ eventId, videoEventId: uuid }]);

    navigateToPreview();
  };

  return <ButtonIcon variant="ghost" onClick={handleSelect} icon="Play" size="small" title={t('playVideo')} />;
};
