import { useSetAtom } from 'jotai';
import { resetAllFiltersAtom } from '@/atoms/filters/resetFilters';
import { ResetButton } from './ResetButton';

export const ResetAllFiltersButton = () => {
  const resetHighLevelFilters = useSetAtom(resetAllFiltersAtom);

  return (
    <div className="w-max" data-testid="reset-highlevel-filters">
      <ResetButton handleClick={resetHighLevelFilters} />
    </div>
  );
};
