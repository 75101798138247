import { useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';
import { unwrappedReportConfigsAtom } from '@/atoms/queries/userConfigs';

// Some old reports don't have a name. This hook adds a fallback name to those reports
export const useReportsWithFallback = () => {
  const { t } = useTranslation('general');
  const reports = useAtomValue(unwrappedReportConfigsAtom);

  const reportsWithFallback = reports.map(report => ({
    ...report,
    definition: { ...report.definition, name: report.definition.name || t('untitled') },
  }));

  return reportsWithFallback;
};
