import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { reportNameAtom, isRenameReportModalOpenAtom } from '@/atoms/report/report';
import { reportConfigsAtom } from '@/atoms/queries/userConfigs';
import { Suspense } from 'react';
import { userConfigIdToRenameAtom } from '@/atoms/userConfigs';
import { toastDataAtom } from '@/atoms/toast';
import { useTranslation } from 'react-i18next';
import { patchUserConfigAtom } from '@/atoms/mutations/userConfigs/userConfigs';
import { GenericSaveModal } from '../GenericSaveModal/GenericSaveModal';

const RenameReportModalWithData = () => {
  const { t } = useTranslation(['report', 'general']);
  const reports = useAtomValue(reportConfigsAtom);
  const userConfigIdToRename = useAtomValue(userConfigIdToRenameAtom);
  const [reportName, setReportName] = useAtom(reportNameAtom);
  const [isRenameModalOpen, setIsRenameModalOpen] = useAtom(isRenameReportModalOpenAtom);
  const setToastData = useSetAtom(toastDataAtom);

  const { mutateAsync: patchUserConfig } = useAtomValue(patchUserConfigAtom);

  // Prevent duplicated report names.
  // If we're renaming, we're allowed to set the name to be the same as the report that we're renaming.
  const otherReportNames = reports
    .filter(({ configId, definition }) => configId !== userConfigIdToRename && definition.name !== undefined)
    .map(({ definition }) => definition.name as string);

  const handleConfirm = async () => {
    const newReportName = reportName.trim();
    const { isSuccess } = await patchUserConfig([
      {
        configId: userConfigIdToRename,
        configDefinition: { name: newReportName },
      },
    ]);

    setToastData('renameReport', isSuccess, newReportName);

    setReportName('');
    setIsRenameModalOpen(false);
  };

  const handleCancel = () => {
    setReportName('');
    setIsRenameModalOpen(false);
  };

  return (
    isRenameModalOpen && (
      <GenericSaveModal
        id="rename-report-modal"
        title={t('renameReport')}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
        confirmLabel={t('save', { ns: 'general' })}
        value={reportName}
        onChange={setReportName}
        existingConfigNames={otherReportNames}
      />
    )
  );
};

export const RenameReportModal = () => {
  const isRenameModalOpen = useAtomValue(isRenameReportModalOpenAtom);

  return (
    isRenameModalOpen && (
      <Suspense>
        <RenameReportModalWithData />
      </Suspense>
    )
  );
};
