import { ExportContent } from '@/types/exporting';
import { toJpeg, toPng, toSvg } from 'html-to-image';

export const EXPORT_WIDTH = {
  DEFAULT: 600,
  WIDE: 1000,
};

export const PADDING = 8;
export const Y_PADDING = PADDING * 2; // top and bottom padding
export const X_PADDING = PADDING * 2; // left and right padding
export const VERTICAL_SPACING = 16;

export const EXPORT_TYPE = {
  PNG: toPng,
  SVG: toSvg,
  JPEG: toJpeg,
};

export const DOWNLOAD_FORMAT_OPTIONS = [
  { value: 'PNG', label: 'png' },
  { value: 'SVG', label: 'svg' },
  { value: 'JPEG', label: 'jpeg' },
];

export const MODAL_ID = 'export-visualisation-modal';

export const CONTENT_VALUES = Object.values(ExportContent);
