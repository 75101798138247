import { resultRectAtom, selectedVisEventsAtom } from '@/atoms/vis/selection';
import { SelectionRect } from '@/types/selection';
import { UnwrappedPitchEventsAtom } from '@/types/visualisation';
import { getSelectedMarkersAndArrows } from '@/utils/atoms/vis';
import { useAtomValue, useSetAtom } from 'jotai';
import { useNavigateToPreview } from './useNavigateToPreview';

export const useDragEndSelectEvents = (unwrappedPitchEventsAtom: UnwrappedPitchEventsAtom) => {
  const pitchEvents = useAtomValue(unwrappedPitchEventsAtom);
  const setResultRect = useSetAtom(resultRectAtom);
  const setSelectedVisEvents = useSetAtom(selectedVisEventsAtom);
  const navigateToPreview = useNavigateToPreview();

  return (rect: SelectionRect) => {
    setResultRect(rect);

    const selectedMarkersAndArrows = getSelectedMarkersAndArrows(rect, pitchEvents);
    setSelectedVisEvents(selectedMarkersAndArrows);

    if (selectedMarkersAndArrows?.length > 0) navigateToPreview();
  };
};
