import { Grid } from '@statsbomb/kitbag-components';
import { GRID_ITEM_STYLES, SCROLLABLE_GRID_ITEM_STYLES, SCROLLABLE_GRID_PARENT_STYLES } from '@/consts/grids';
import classNames from 'classnames';
import React, { Children } from 'react';

export const WideFirstColumnLayout = ({ children }: { children: ReadonlyArray<React.ReactNode> & { length: 2 } }) => {
  const childrenArray = Children.toArray(children);
  const [columnOne, columnTwo] = childrenArray;

  return (
    <div className="grid-wrapper">
      <Grid>
        <Grid item xs={12} md={6} lg={4}>
          <div data-column="1" className={classNames(GRID_ITEM_STYLES, SCROLLABLE_GRID_PARENT_STYLES)}>
            <div className={SCROLLABLE_GRID_ITEM_STYLES}>{columnOne}</div>
          </div>
        </Grid>
        <Grid item xs={12} md={6} lg={8}>
          <div data-column="2" className={GRID_ITEM_STYLES}>
            {columnTwo}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
