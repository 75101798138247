import { useEffect, useRef } from 'react';
import { isEqual } from 'lodash/fp';
import { usePrevious } from './usePrevious';

type UseEffectParams = Parameters<typeof useEffect>;
type EffectCallback = UseEffectParams[0];
type DependencyList = UseEffectParams[1];

// useDeepEffect is a custom hook that behaves like useEffect, but it compares deeply nested dependencies.
// This should only be used when you need to compare deeply nested objects/arrays with objects.
// For anything else, useEffect should be used.
export const useDeepEffect = (callback: EffectCallback, dependencies: DependencyList) => {
  const initialRender = useRef(true);
  const prevDeps = usePrevious(dependencies);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      callback();
      return;
    }

    const isSame = prevDeps?.every((obj, index) => {
      if (!dependencies) {
        return false;
      }
      return isEqual(obj, dependencies[index]);
    });

    if (!isSame) {
      callback();
    }
  }, dependencies);
};
