import { useTranslation } from 'react-i18next';
import { Accordion } from '../accordion/Accordion';
import { PassHeightCheckboxGroup } from '../checkboxGroups/PassHeightCheckboxGroup';

export const PassFilters = () => {
  const { t } = useTranslation('events');

  return (
    <Accordion title={t('fields.event.type.pass')} isDefaultExpanded>
      <PassHeightCheckboxGroup />
    </Accordion>
  );
};
