import { exportContentAtom } from '@/atoms/exporting';
import { CONTENT_VALUES } from '@/consts/exporting';
import { ExportContent } from '@/types/exporting';
import { BaseOption } from '@/types/generic';
import { Dropdown } from '@statsbomb/kitbag-components';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

export const ExportContentDropdown = () => {
  const { t } = useTranslation('export');
  const [exportContentOption, setExportContentOption] = useAtom(exportContentAtom);

  const translatedOptions = CONTENT_VALUES.map(value => ({
    value,
    label: t(`imageContentOptions.label.${value}`),
  }));

  const onChange = (option: BaseOption<ExportContent>) => {
    const visName = t(`imageContentOptions.fileName.${option.value}`);
    setExportContentOption(option.value, visName);
  };
  const value = translatedOptions.find(option => option.value === exportContentOption);

  return (
    <Dropdown
      id="export-content-dropdown"
      options={translatedOptions}
      label={t('imageContent')}
      onChange={onChange}
      value={value}
      isSearchable={false}
    />
  );
};
