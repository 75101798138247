import { pageAndOrderParamsAtom } from '@/atoms/general';
import { teamsNormalisedAggregatesUrl } from '@/query/url';
import { objSnakeToCamel } from '@/utils/queries';
import { MultiTeamAggregate } from '@statsbomb/parachute-types';
import { atomWithQuery, atomWithSuspenseQuery } from 'jotai-tanstack-query';
import { addLink, flattenAndAddPrefix, optionsToValueStrings, toObject } from '@/utils/object';
import { competitionIdsToFilterByAtom } from '@/atoms/filters/highLevel/competitions';
import { seasonIdsToFilterByAtom } from '@/atoms/filters/highLevel/seasons';
import { atom } from 'jotai';
import { atomWithDebounce } from '@/atoms/debounce';
import { Nullable } from '@/types/generic';
import { NestedObject } from '@/types/object';
import { selectedEndDateAtom, selectedStartDateAtom } from '@/atoms/filters/highLevel';
import { selectedTeamIdsAtom } from '@/atoms/filters/highLevel/teams';
import { convertFilterParamsToString } from '@/utils/api';
import { teamIdAtom } from '@/atoms/team/team';
import { unwrappedTeamSelectedGamesForQueryAtom } from '@/atoms/filters/team/teamGames';
import { fetchClientAtom } from '../client';

export const teamAggsFilterParamsAtom = atom(get => ({
  lte: {
    ...toObject('input.game_date', get(selectedEndDateAtom)),
  },
  gte: {
    ...toObject('input.game_date', get(selectedStartDateAtom)),
  },
  eq: {
    ...toObject('input.competition_id', get(competitionIdsToFilterByAtom)),
    ...toObject('input.season_id', get(seasonIdsToFilterByAtom)),
    ...toObject('output.team.team_id', get(selectedTeamIdsAtom)),
  },
}));

export const teamSquadFilterParamsAtom = atom(get => ({
  eq: {
    ...toObject('input.team_id', get(teamIdAtom)),
    'input.game_id': optionsToValueStrings(get(unwrappedTeamSelectedGamesForQueryAtom)),
  },
}));

export const teamMatchStatsFilterParamsAtom = atom(get => ({
  eq: {
    'input.game_id': optionsToValueStrings(get(unwrappedTeamSelectedGamesForQueryAtom)),
  },
}));

export const teamAggsFilterParamsDebounceObject = atomWithDebounce<Nullable<NestedObject>>(null);
const { debouncedValueAtom: debouncedTeamAggsFilterParamsAtom } = teamAggsFilterParamsDebounceObject;

export const teamAggsDataAtom = atomWithQuery(get => {
  const queryKey = ['teamAggs', get(pageAndOrderParamsAtom), get(debouncedTeamAggsFilterParamsAtom)] as const;
  const queryFn = async ({ queryKey: [, pageAndOrderParams, teamAggsFilterParams] }: { queryKey: typeof queryKey }) => {
    const { fetch } = get(fetchClientAtom);
    const data: MultiTeamAggregate[] = await fetch(
      teamsNormalisedAggregatesUrl({
        ...pageAndOrderParams,
        ...teamAggsFilterParams,
      }),
    );
    return data.map(data =>
      addLink(flattenAndAddPrefix(objSnakeToCamel(data), '.', false), data.team.name, data.team.team_id, 'teamLink'),
    );
  };
  const enabled = get(debouncedTeamAggsFilterParamsAtom) !== null;
  return { queryKey, queryFn, enabled };
});

const rawTeamAggsCountAtom = atomWithSuspenseQuery(get => {
  const queryKey = ['teamAggsCount', get(debouncedTeamAggsFilterParamsAtom)] as const;
  const queryFn = async ({ queryKey: [, teamAggsFilterParams] }: { queryKey: typeof queryKey }) => {
    if (!teamAggsFilterParams) return null;
    const { fetch } = get(fetchClientAtom);
    return (await fetch(
      `/teams/normalised-aggregates/count${convertFilterParamsToString({
        ...teamAggsFilterParams,
      })}`,
    )) as Promise<number>;
  };
  return { queryKey, queryFn };
});

export const teamAggsCountAtom = atom(async get => (await get(rawTeamAggsCountAtom)).data);
