import { teamMetricDataAtom } from '@/atoms/team/metric';
import { ExpandVideoButton } from '@/components/button/ExpandVideoButton';
import { CardHeader } from '@/components/card/Card';
import { PreviewItemsLayout } from '@/components/layout/PreviewItemsLayout';
import { MetricKeyDropdown } from '@/components/metric/MetricKeyDropdown';
import { TeamVisEventsTable } from '@/components/team/vis/TeamVisEventsTable';
import { VisualisationComponent } from '@/components/visualisations/PitchVisualisation/VisualisationComponent';
import { teamPitchVisComponents } from '@/consts/visualisationComponents';

export const TeamVideoPreview = () => (
  <>
    <CardHeader>
      <MetricKeyDropdown metricDataAtom={teamMetricDataAtom} />
      <ExpandVideoButton />
    </CardHeader>
    <PreviewItemsLayout>
      <VisualisationComponent allowInteraction visualisations={teamPitchVisComponents} />
      <TeamVisEventsTable />
    </PreviewItemsLayout>
  </>
);
