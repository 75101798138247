import { Position } from '@/types/player';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

export const usePositionUIElements = () => {
  const { t } = useTranslation('events');
  const theme = useTheme();
  const positionColours = theme.colours.visualisations.soccer.positions;

  const positionItemsConfig = [
    {
      key: Position.GK,
      label: t(`fields.event.attributes.playerPosition.${Position.GK}`),
      colour: positionColours.goalkeeper.main,
      cx: 10,
      cy: 40,
    },
    {
      key: Position.LB,
      label: t(`fields.event.attributes.playerPosition.${Position.LB}`),
      colour: positionColours.fullback.main,
      cx: 30,
      cy: 10,
    },
    {
      key: Position.LCB,
      label: t(`fields.event.attributes.playerPosition.${Position.LCB}`),
      colour: positionColours.centreBack.main,
      cx: 30,
      cy: 25.5,
    },
    {
      key: Position.CB,
      label: t(`fields.event.attributes.playerPosition.${Position.CB}`),
      colour: positionColours.centreBack.main,
      cx: 30,
      cy: 40,
    },
    {
      key: Position.RCB,
      label: t(`fields.event.attributes.playerPosition.${Position.RCB}`),
      colour: positionColours.centreBack.main,
      cx: 30,
      cy: 54.5,
    },
    {
      key: Position.RB,
      label: t(`fields.event.attributes.playerPosition.${Position.RB}`),
      colour: positionColours.fullback.main,
      cx: 30,
      cy: 70,
    },
    {
      key: Position.LWB,
      label: t(`fields.event.attributes.playerPosition.${Position.LWB}`),
      colour: positionColours.fullback.main,
      cx: 50,
      cy: 10,
    },
    {
      key: Position.LDM,
      label: t(`fields.event.attributes.playerPosition.${Position.LDM}`),
      colour: positionColours.defensiveMidfield.main,
      cx: 50,
      cy: 25.5,
    },
    {
      key: Position.CDM,
      label: t(`fields.event.attributes.playerPosition.${Position.CDM}`),
      colour: positionColours.defensiveMidfield.main,
      cx: 50,
      cy: 40,
    },
    {
      key: Position.RDM,
      label: t(`fields.event.attributes.playerPosition.${Position.RDM}`),
      colour: positionColours.defensiveMidfield.main,
      cx: 50,
      cy: 54.5,
    },
    {
      key: Position.RWB,
      label: t(`fields.event.attributes.playerPosition.${Position.RWB}`),
      colour: positionColours.fullback.main,
      cx: 50,
      cy: 70,
    },
    {
      key: Position.LM,
      label: t(`fields.event.attributes.playerPosition.${Position.LM}`),
      colour: positionColours.widePlayer.main,
      cx: 70,
      cy: 10,
    },
    {
      key: Position.LCM,
      label: t(`fields.event.attributes.playerPosition.${Position.LCM}`),
      colour: positionColours.centreMidfield.main,
      cx: 70,
      cy: 25.5,
    },
    {
      key: Position.CM,
      label: t(`fields.event.attributes.playerPosition.${Position.CM}`),
      colour: positionColours.centreMidfield.main,
      cx: 70,
      cy: 40,
    },
    {
      key: Position.RCM,
      label: t(`fields.event.attributes.playerPosition.${Position.RCM}`),
      colour: positionColours.centreMidfield.main,
      cx: 70,
      cy: 54.5,
    },
    {
      key: Position.RM,
      label: t(`fields.event.attributes.playerPosition.${Position.RM}`),
      colour: positionColours.widePlayer.main,
      cx: 70,
      cy: 70,
    },
    {
      key: Position.LW,
      label: t(`fields.event.attributes.playerPosition.${Position.LW}`),
      colour: positionColours.widePlayer.main,
      cx: 90,
      cy: 10,
    },
    {
      key: Position.LAM,
      label: t(`fields.event.attributes.playerPosition.${Position.LAM}`),
      colour: positionColours.attackingMidfield.main,
      cx: 90,
      cy: 25.5,
    },
    {
      key: Position.CAM,
      label: t(`fields.event.attributes.playerPosition.${Position.CAM}`),
      colour: positionColours.attackingMidfield.main,
      cx: 90,
      cy: 40,
    },
    {
      key: Position.RAM,
      label: t(`fields.event.attributes.playerPosition.${Position.RAM}`),
      colour: positionColours.attackingMidfield.main,
      cx: 90,
      cy: 54.5,
    },
    {
      key: Position.RW,
      label: t(`fields.event.attributes.playerPosition.${Position.RW}`),
      colour: positionColours.widePlayer.main,
      cx: 90,
      cy: 70,
    },
    {
      key: Position.LCF,
      label: t(`fields.event.attributes.playerPosition.${Position.LCF}`),
      colour: positionColours.forward.main,
      cx: 110,
      cy: 25.5,
    },
    {
      key: Position.CF,
      label: t(`fields.event.attributes.playerPosition.${Position.CF}`),
      colour: positionColours.forward.main,
      cx: 110,
      cy: 40,
    },
    {
      key: Position.RCF,
      label: t(`fields.event.attributes.playerPosition.${Position.RCF}`),
      colour: positionColours.forward.main,
      cx: 110,
      cy: 54.5,
    },
  ];

  return positionItemsConfig;
};
