import { clearLastFilterStateHistoryAtom, filterStateHistoryAtom } from '@/atoms/filters/persistedFilters';
import { resetAllFiltersAtom } from '@/atoms/filters/resetFilters';
import { atomsToHydrateAtom } from '@/atoms/hydration';
import { getFilterAtomsToHydrate } from '@/utils/atoms/atomHydration';
import { useAtomValue, useSetAtom } from 'jotai';

// ignoring this file as we can't simulate the onClick of the back button via non e2e tests
/* istanbul ignore file */
export const useOnBackButtonClick = () => {
  const filterStateHistory = useAtomValue(filterStateHistoryAtom);
  const setAtomsToHydrate = useSetAtom(atomsToHydrateAtom);
  const clearLastHistoryItem = useSetAtom(clearLastFilterStateHistoryAtom);
  const resetAllFilters = useSetAtom(resetAllFiltersAtom);

  window.onpopstate = () => {
    // length - 2 as the last item (length - 1) is the current page you are on
    const previousHistoryItem = filterStateHistory[filterStateHistory.length - 2];

    clearLastHistoryItem();
    /* the reason we are doing this timeout is because the `window.onpopstate` fires
    as soon as the back button is pressed and not when we are navigated backwards to the page
    we are going back to. The issue here is that it means that we hydrate our atoms before
    we've gone to the page we want to hydrate them and instead hydrate on the current page which
    isn't what we want.
    
    the timeout is basically to "fake" awaiting that we are on the correct page before we hydrate
    the atoms. */
    setTimeout(() => {
      /* we need to reset all filters before we hydrate as if you have selected any filters
      that aren't in the list of filters to persist, they will stay selected instead of being removed */
      resetAllFilters();
      if (previousHistoryItem) setAtomsToHydrate(getFilterAtomsToHydrate(previousHistoryItem.filtersToPersist));
    }, 100);
  };
};
