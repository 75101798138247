export const KEEPER_ACTIONS = [
  'keeper_claim',
  'keeper_collected',
  'keeper_save_hold',
  'keeper_sweep',
  'keeper_punch',
  'non_shot_saved',
  'non_shot_saved_to_post',
  'shot_faced',
  'shot_saved',
  'shot_saved_off_target',
  'shot_saved_to_post',
  'penalty_saved',
  'penalty_saved_to_post',
];
