import { useTheme } from 'styled-components';

export const useSuccessFailColouring = () => {
  const theme = useTheme();
  const {
    success: { main: success },
    neutral: { main: neutral },
    fail: { main: fail },
  } = theme.colours.visualisations.dataObjects.successFail;

  return {
    success,
    fail,
    neutral,
  };
};
