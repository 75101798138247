import { isMobileFiltersPanelOpenAtom } from '@/atoms/sidebar';
import { ButtonIcon } from '@statsbomb/kitbag-components';
import { useSetAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

export const MobileModalCloseButton = () => {
  const { t } = useTranslation('general');
  const setIsMobileFiltersPanelOpen = useSetAtom(isMobileFiltersPanelOpenAtom);

  return (
    <div className="mobile-modal-close-button">
      <ButtonIcon
        onClick={() => {
          setIsMobileFiltersPanelOpen(false);
        }}
        icon="Close"
        variant="secondary"
        title={t('close')}
      />
    </div>
  );
};
