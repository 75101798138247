import { fetchClientAtom } from '@/atoms/queries/client';
import { convertFilterParamsToString, hasEmptyEqFilters } from '@/utils/api';
import { objSnakeToCamel, objValsSnakeToCamel } from '@/utils/queries';
import { groupTableColumns } from '@/utils/table';
import { TableColumn, TableColumnCategoryKey } from '@statsbomb/parachute-types';
import { atom } from 'jotai';
import { atomWithSuspenseQuery } from 'jotai-tanstack-query';

export const tableColumnEntitiesAtom = atom<TableColumnCategoryKey[]>([]);

const rawTableColumnsAtom = atomWithSuspenseQuery(get => {
  const queryKey = ['table-columns', get(tableColumnEntitiesAtom)] as const;
  const queryFn = async ({ queryKey: [, tableColumnEntities] }: { queryKey: typeof queryKey }) => {
    const tableColumnParams = { 'eq[category_branch.key]': tableColumnEntities };
    if (hasEmptyEqFilters(tableColumnParams)) return [];
    const { fetch } = get(fetchClientAtom);
    return (await fetch(`/table-columns${convertFilterParamsToString(tableColumnParams)}`)) as Promise<TableColumn[]>;
  };
  return { queryKey, queryFn };
});

export const tableColumnsAtom = atom(async get => {
  const { data } = await get(rawTableColumnsAtom);
  return data.map(objSnakeToCamel).map(objValsSnakeToCamel);
});

export const tableColumnGroupsAtom = atom(async get => groupTableColumns(await get(tableColumnsAtom)));
