import { showLegendAtom } from '@/atoms/visualisation';
import { Toggle } from '@statsbomb/kitbag-components';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

export const LegendToggle = () => {
  const { t } = useTranslation('visualisation');
  const [showLegend, setShowLegend] = useAtom(showLegendAtom);

  return (
    <Toggle
      id="show-legend-toggle"
      label={t('showLegend')}
      checked={showLegend}
      size="small"
      onChange={() => setShowLegend(!showLegend)}
    />
  );
};
