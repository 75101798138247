import { useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';
import { Atom, atom } from 'jotai/vanilla';
import { widgetDisplaySettingsAtom } from '@/atoms/report/reportSaving';
import { useGetEntity } from '@/hooks/useGetEntity';
import { WidgetDataType, WidgetVisType } from '@/types/widget';
import { selectedTeamNameAtom } from '@/atoms/team/info';
import { selectedPlayerNameAtom } from '@/atoms/player/info';
import { EntityOrDataLocker } from '@/types/entity';
import { snakeToCamel } from '@/utils/queries';
import { playerSelectedGamesAtom } from '@/atoms/filters/player/playerGames';
import { Option } from '@/types/generic';
import { teamSelectedGamesAtom } from '@/atoms/filters/team/teamGames';
import { filtersToPersistAtom } from '@/atoms/filters/persistedFilters';
import { selectedSeasonsAtom } from '@/atoms/filters/highLevel/seasons';
import { convertOptionsToLabels } from '@/utils/array';
import { reportConfigsAtom, unwrappedGenderAtom, userConfigsAtom } from '@/atoms/queries/userConfigs';
import { UserConfigPatchMutationParams } from '@/types/userConfigs';
import { patchUserConfigAtom } from '@/atoms/mutations/userConfigs/userConfigs';
import { useRadarTemplateName } from './useRadarTemplateName';

const entityNameAtomMap: Record<EntityOrDataLocker, Atom<string | Promise<string | undefined>>> = {
  player: selectedPlayerNameAtom,
  team: selectedTeamNameAtom,
  dataLocker: atom('Data Locker'),
};

const entityGamesAtomMap: Record<EntityOrDataLocker, Atom<string | [] | Promise<string | undefined | Option[]>>> = {
  player: playerSelectedGamesAtom,
  team: teamSelectedGamesAtom,
  dataLocker: atom([]),
};

/* TODO (PPC-738: Get Entity name as params - remove async from the hook) */
export const useSaveItemToReport = () => {
  const { entity, entityId, subEntity } = useGetEntity();
  const { t } = useTranslation(['visualisation', 'metrics', 'games']);

  const userConfigs = useAtomValue(userConfigsAtom);
  const reports = useAtomValue(reportConfigsAtom);
  const { mutateAsync: patchUserConfig } = useAtomValue(patchUserConfigAtom);

  const filters = useAtomValue(filtersToPersistAtom);
  const gender = useAtomValue(unwrappedGenderAtom);
  const displaySettings = useAtomValue(widgetDisplaySettingsAtom);
  const selectedEntityName = useAtomValue(entityNameAtomMap[entity]);

  const selectedTemplateName = useRadarTemplateName();
  const selectedSeasons = useAtomValue(selectedSeasonsAtom);
  const seasonsDescription = convertOptionsToLabels(selectedSeasons).join(', ');
  const selectedGamesCount = useAtomValue(entityGamesAtomMap[entity])?.length || 0;
  const gamesDescription = selectedGamesCount ? t('gamesText', { ns: 'games', text: selectedGamesCount }) : '';
  const description = [selectedTemplateName, seasonsDescription, gamesDescription].filter(Boolean).join(', ');
  const metricKey = filters.metric;

  const saveItemToReport = async (vis: WidgetVisType, dataType: WidgetDataType, reportIds: string[]) => {
    const validMetricKey = metricKey && vis !== 'radar';
    const metric = validMetricKey ? t(`${snakeToCamel(metricKey)}.name`, { ns: 'metrics' }) : '';
    const title = `${selectedEntityName} - ${metric || t(vis)}`;

    // The metricAtom is being updated automatically when the url changes. Although it can be set on the radar page,
    // it is not applicable to a radar, so we want to make sure it doesn't get saved to the config of a radar type widget.
    if (!validMetricKey) {
      delete filters.metric;
    }

    // gender is only relevant in the context of data locker widget
    const genderFilter = entity === 'dataLocker' ? { gender } : {};

    const newWidget = {
      widgetId: crypto.randomUUID(),
      title,
      description,
      filters: {
        ...filters,
        ...genderFilter,
      },
      displaySettings,
      entity,
      entityId,
      subEntity,
      vis,
      dataType,
    };

    await userConfigs.refetch();

    const reportConfigsToPatch: UserConfigPatchMutationParams[] = reportIds.map(reportId => {
      const reportDefinition = reports.find(({ configId }) => configId === reportId)?.definition;
      if (!reportDefinition) {
        throw new Error('Missing report config');
      }

      return {
        configId: reportId,
        configDefinition: {
          widgets: [...reportDefinition.widgets, newWidget],
        },
      };
    });

    const { isSuccess } = await patchUserConfig(reportConfigsToPatch);
    return isSuccess;
  };

  return saveItemToReport;
};
