import { unwrappedSelectedTeamNameAtom } from '@/atoms/team/info';
import { PageTitle } from '@/components/head/PageTitle';
import { TeamLayoutWithFilters } from '@/components/layout/team/TeamLayoutWithFilters';
import { TeamRadar } from '@/components/team/radar/TeamRadar';
import { useEntityPageTitle } from '@/hooks/useEntityPageTitle';
import { Grid } from '@statsbomb/kitbag-components';
import { useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';

export const TeamRadarPage = () => {
  const { t } = useTranslation('navigation');
  const teamName = useAtomValue(unwrappedSelectedTeamNameAtom);
  const pageTitle = useEntityPageTitle(t('secondary.radar'), teamName);

  return (
    <>
      <PageTitle>{pageTitle}</PageTitle>
      <TeamLayoutWithFilters>
        {/* TODO (PPC-294: override to centre content when on preview)  */}
        <div>
          <Grid>
            <Grid item xs={12}>
              <TeamRadar />
            </Grid>
          </Grid>
        </div>
      </TeamLayoutWithFilters>
    </>
  );
};
