import { PrimitiveAtom, useAtom } from 'jotai';
import { SliderMultipleBase } from './SliderMultipleBase';

export const SliderMultiple = ({
  title,
  minValue,
  maxValue,
  minAtom,
  maxAtom,
  value,
  step,
}: {
  title: string;
  minValue: number;
  maxValue: number;
  minAtom: PrimitiveAtom<number>;
  maxAtom: PrimitiveAtom<number>;
  value?: [number, number];
  step?: number;
}) => {
  const [minSelected, setMinSelected] = useAtom(minAtom);
  const [maxSelected, setMaxSelected] = useAtom(maxAtom);

  const onChange = (range: [number, number]) => {
    const [min, max] = range;
    setMinSelected(min);
    setMaxSelected(max);
  };

  return (
    <SliderMultipleBase
      title={title}
      min={minValue}
      max={maxValue}
      value={value || [minSelected, maxSelected]}
      onChange={onChange}
      step={step}
    />
  );
};
