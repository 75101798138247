import { BaseOption, KeysToCamelCase, Nullable, Option } from '@/types/generic';
import { CycleWithRels, TeamWithRels } from '@statsbomb/parachute-types';

export const getTeamIdFromCycle = (
  playerCycles: KeysToCamelCase<CycleWithRels>[],
  playerTeams: TeamWithRels[],
  targetCycleId: number,
): Nullable<number> => {
  const hasMatchingCycle = playerCycles.find(({ cycle }) => cycle.cycleId === targetCycleId);
  if (!hasMatchingCycle) return null;

  const [targetCycleTeamId] = playerTeams
    .filter(playerTeam => playerTeam.cycle_ids.includes(targetCycleId))
    .map(({ team_id: teamId }) => teamId);

  return targetCycleTeamId;
};

export const sortCyclesBySeasonAndComp = (cycles: KeysToCamelCase<CycleWithRels>[]) => {
  const { leagues, otherComps } = cycles.reduce<{
    leagues: KeysToCamelCase<CycleWithRels>[];
    otherComps: KeysToCamelCase<CycleWithRels>[];
  }>(
    (acc, cycle) => {
      if (cycle.competition.typeId === 1) {
        return {
          ...acc,
          leagues: [...acc.leagues, cycle],
        };
      }
      return {
        ...acc,
        otherComps: [...acc.otherComps, cycle],
      };
    },
    { leagues: [], otherComps: [] },
  );

  return [
    ...leagues.sort((a, b) => b.season.name.localeCompare(a.season.name)),
    ...otherComps.sort((a, b) => b.season.name.localeCompare(a.season.name)),
  ];
};

export const getDistinctOrderedCompetitionOptions = (
  orderedCycles: KeysToCamelCase<CycleWithRels>[],
  filteredCompetitionOptions: Option[],
) =>
  orderedCycles.reduce((acc, cycle) => {
    const currOption = filteredCompetitionOptions.find(option => cycle.competition.competitionId === option.value);
    if (!acc.some(item => item.label === cycle.competition.name) && currOption) {
      return [...acc, currOption];
    }
    return acc;
  }, [] as BaseOption<number>[]);

export const getOrderedTeamOptions = (
  cycles: KeysToCamelCase<CycleWithRels>[],
  teamOptions: Option[],
  playerTeams: TeamWithRels[],
) => {
  const teamIds = [
    ...new Set(cycles.map(cycle => getTeamIdFromCycle(cycles, playerTeams, cycle.cycle.cycleId))),
  ].filter(Boolean);

  return [...teamOptions].sort((a, b) => teamIds.indexOf(a.value) - teamIds.indexOf(b.value));
};
