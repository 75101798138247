import { PageSubNav } from './PageSubNav';

export const TeamNav = () => (
  <PageSubNav
    items={[
      {
        translation: { key: 'secondary.overview' },
        route: 'radar',
      },
      {
        translation: { key: 'secondary.pitchVis' },
        route: 'vis',
      },
      {
        translation: { key: 'secondary.squad' },
        route: 'squad',
      },
      {
        translation: { key: 'secondary.games' },
        route: 'matches',
      },
    ]}
  />
);
