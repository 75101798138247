// This is used in a couple of bits of logic to determine whether a cell
// should be clickable
export const MINUTES_PLAYED_COLUMN = 'aggregates.minutesPlayed';

export const AGGS_TABLE_CONFIG = {
  player: {
    param: 'playerId',
    entityKey: 'player.playerId',
  },
  team: {
    param: 'teamId',
    entityKey: 'team.teamId',
  },
};
