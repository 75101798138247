import { VisDisplayMode } from '@/types/visualisation';
import { ButtonIcon, Menu, Flyout, useRect } from '@statsbomb/kitbag-components';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useVisDisplayMode } from '@/hooks/useVisDisplayMode';
import { MenuItemWithIcon } from '../menu/MenuItemWithIcon';

const DisplayModeMenuItem = ({
  text,
  onClick,
  isModeSelected,
}: {
  text: string;
  onClick: () => void;
  isModeSelected: boolean;
}) => <MenuItemWithIcon onClick={onClick} text={text} isActive={isModeSelected} />;

export const VisDisplayModeSwitcher = ({
  defaultDisplayMode = VisDisplayMode.BOTH,
}: {
  defaultDisplayMode?: VisDisplayMode;
}) => {
  const { t } = useTranslation('visualisation');
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [visDisplayMode, setVisDisplayMode] = useVisDisplayMode();
  useEffect(() => {
    if (!visDisplayMode) {
      setVisDisplayMode(defaultDisplayMode);
    }
  }, []);

  const getIsModeSelected = (mode: VisDisplayMode) => visDisplayMode === mode;

  const [relativeTriggerRef, rect] = useRect<HTMLDivElement>(0);

  return (
    <div ref={relativeTriggerRef} data-testid="vis-display-mode-switcher">
      <ButtonIcon icon="Mode" variant="ghost" onClick={() => setIsMenuOpen(true)} />
      {isMenuOpen && rect && (
        <Flyout
          triggerPosition={rect}
          onOutsideClick={() => setIsMenuOpen(false)}
          /* this values is to position the menu similar to how it's
          positioned in the designs, there isn't an actual value coming from designs
          but this seems to look close enough atleast to the design */
          offset={{ left: 172, top: 0 }}
          isPortalClosedOnResize
        >
          <Menu>
            <DisplayModeMenuItem
              text={t('displayTable')}
              isModeSelected={getIsModeSelected(VisDisplayMode.TABLE)}
              onClick={() => {
                setVisDisplayMode(VisDisplayMode.TABLE);
                setIsMenuOpen(false);
              }}
            />
            <DisplayModeMenuItem
              text={t('displayVis')}
              isModeSelected={getIsModeSelected(VisDisplayMode.VIS)}
              onClick={() => {
                setVisDisplayMode(VisDisplayMode.VIS);
                setIsMenuOpen(false);
              }}
            />
            <DisplayModeMenuItem
              text={t('displayBoth')}
              isModeSelected={getIsModeSelected(VisDisplayMode.BOTH)}
              onClick={() => {
                setVisDisplayMode(VisDisplayMode.BOTH);
                setIsMenuOpen(false);
              }}
            />
          </Menu>
        </Flyout>
      )}
    </div>
  );
};
