import { Nullable } from '@/types/generic';
import { SORT_DIRECTION } from '@/types/ordering';
import { sortObjectArray } from '@/utils/array';
import { useTranslation } from 'react-i18next';

export const useSortObjectArray = <T extends Record<string, any>>(
  orderByField: Nullable<string>,
  array: T[],
  sortDirection = SORT_DIRECTION.ASC,
) => {
  const { i18n } = useTranslation();
  return sortObjectArray(orderByField, array, i18n.language, sortDirection);
};
