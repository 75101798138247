import { metricKeyAtom } from '@/atoms/metric';
import { FallbackNoData } from '@/components/visualisations/FallbackNoData';
import { useAtomValue } from 'jotai';
import { ReactNode } from 'react';

export const MetricKeyFallbackWrapper = ({
  children,
  fallback = <FallbackNoData />,
}: {
  children: ReactNode;
  fallback?: JSX.Element;
}) => {
  const metricKey = useAtomValue(metricKeyAtom);

  return metricKey ? children : fallback;
};
