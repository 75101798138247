import { visualisationConfigsAtom } from '@/atoms/queries/visualisation';
import { selectedVisualisationAtom, showArrowsAtom } from '@/atoms/visualisation';
import { Nullable } from '@/types/generic';
import { ConfigVisualisation } from '@/types/visualisation';
import { snakeToCamel } from '@/utils/queries';
import { useAtomValue, useSetAtom } from 'jotai';
import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { RadioButton, RadioGroup } from '@statsbomb/kitbag-components';

export const VisualisationToggleBase = ({
  selectedVisConfig = null,
  metricVisualisations = [],
}: {
  selectedVisConfig?: Nullable<ConfigVisualisation>;
  metricVisualisations?: ConfigVisualisation[];
}) => {
  const { t } = useTranslation('visualisation');
  const [searchParams, setSearchParams] = useSearchParams();
  const params = Object.fromEntries(searchParams);
  const setShowArrows = useSetAtom(showArrowsAtom);
  const setSelectedVis = useSetAtom(selectedVisualisationAtom);

  const translatedMetricVisualisations = metricVisualisations.map(vis => ({
    label: t(snakeToCamel(vis.visualisationKey)),
    value: vis.visualisationKey,
  }));

  const translatedSelectedVis = selectedVisConfig
    ? translatedMetricVisualisations.find(({ value }) => value === selectedVisConfig.visualisationKey)
    : null;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.currentTarget.value;
    setShowArrows(false);
    setSearchParams({ ...params, vis: newValue });
    setSelectedVis(metricVisualisations.find(vis => vis.visualisationKey === newValue)!);
  };

  return (
    <RadioGroup groupId="pitch-type-group" groupTitle="Pitch type" size="regular">
      {translatedMetricVisualisations.map(vis => (
        <RadioButton
          checked={vis.value === translatedSelectedVis?.value}
          onChange={handleChange}
          id={`${vis.value}-option`}
          key={vis.value}
          label={vis.label}
          value={vis.value}
          size="small"
        />
      ))}
    </RadioGroup>
  );
};

const VisualisationToggleWithData = () => {
  const metricVisualisations = useAtomValue(visualisationConfigsAtom);
  const selectedVisConfig = useAtomValue(selectedVisualisationAtom);

  return <VisualisationToggleBase {...{ selectedVisConfig, metricVisualisations }} />;
};

export const VisualisationToggle = () => (
  <Suspense fallback={<VisualisationToggleBase />}>
    <VisualisationToggleWithData />
  </Suspense>
);
