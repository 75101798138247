import { filterAtomDefaults } from '@/consts/defaults/filterAtomDefaults';
import { toObject } from '@/utils/object';
import { pitchZonesToCoords } from '@/utils/pitchZonesToCoords';
import { atom } from 'jotai';

export const selectedPitchFilterStartZonesAtom = atom(filterAtomDefaults.pitchStartZones);
export const selectedPitchFilterEndZonesAtom = atom(filterAtomDefaults.pitchEndZones);

export const selectedPitchFilterCoordsAtom = atom(get => {
  const startZones = get(selectedPitchFilterStartZonesAtom);
  const startCoords = pitchZonesToCoords(startZones);
  const endZones = get(selectedPitchFilterEndZonesAtom);
  const endCoords = pitchZonesToCoords(endZones);

  return {
    ...toObject('event.start', startCoords),
    ...toObject('event.end', endCoords),
  };
});
