import { tableColumnGroupsAtom } from '@/atoms/queries/tableColumns';
import { DefaultColumnsAtom, VisibleColumnsAtom } from '@/types/tableColumns';
import { Button, Modal, ModalFooter, ModalHeader } from '@statsbomb/kitbag-components';
import { useAtom, useAtomValue } from 'jotai';
import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ColumnSelect } from './ColumnSelect';
import { ColumnSort } from './ColumnSort';
import { MobileTableColumnsEditorTabs } from './MobileTableColumnsEditorTabs';

export const EditTableColumnsModal = ({
  visibleColumnsAtom,
  setShowEditColumnsModal,
  defaultColumnsAtom,
}: {
  visibleColumnsAtom: VisibleColumnsAtom;
  setShowEditColumnsModal: Dispatch<SetStateAction<boolean>>;
  defaultColumnsAtom: DefaultColumnsAtom;
}) => {
  const { t } = useTranslation(['table', 'general']);

  const columnGroups = useAtomValue(tableColumnGroupsAtom);
  const [tableVisibleColumns, setTableVisibleColumns] = useAtom(visibleColumnsAtom);
  const [modalVisibleColumns, setModalVisibleColumns] = useState(tableVisibleColumns);
  const defaultColumns = useAtomValue(defaultColumnsAtom);

  const handleCancelModal = () => {
    setModalVisibleColumns(tableVisibleColumns);
    setShowEditColumnsModal(false);
  };

  const handleConfirmModal = () => {
    setTableVisibleColumns(modalVisibleColumns);
    setShowEditColumnsModal(false);
  };

  return (
    <Modal id="modal-edit-columns" isOpen onCancel={handleCancelModal} onConfirm={handleConfirmModal}>
      <ModalHeader id="modal-edit-columns">{t('editColumns')}</ModalHeader>
      <MobileTableColumnsEditorTabs />
      <div className="flex w-[320px] md:w-[684px] h-[420px]">
        <ColumnSelect
          columnGroups={columnGroups}
          visibleColumns={modalVisibleColumns}
          setVisibleColumns={setModalVisibleColumns}
        />
        <ColumnSort
          visibleColumns={modalVisibleColumns}
          setVisibleColumns={setModalVisibleColumns}
          defaultColumns={defaultColumns}
        />
      </div>
      <ModalFooter>
        <Button onClick={handleCancelModal} variant="ghost">
          {t('cancel', { ns: 'general' })}
        </Button>
        <Button onClick={handleConfirmModal} variant="primary" isDisabled={!modalVisibleColumns.length}>
          {t('apply', { ns: 'general' })}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
