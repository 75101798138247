import { PITCH_CROP, PITCH_ORIENTATION } from '@/consts/visualisations';
import { EventMarkerLayer } from '@statsbomb/kitbag-datavis';
import { Visualisation } from '@statsbomb/parachute-types';
import { Atom } from 'jotai';
import { ArrowEvent, MarkerEvent } from './event';
import { KeysToCamelCase, SBID } from './generic';
import { PromiseAtom } from './atom';

export type PitchOrientation = (typeof PITCH_ORIENTATION)[number];
export type PitchCrop = (typeof PITCH_CROP)[number];

export type ConfigVisualisation = KeysToCamelCase<Visualisation>;
export interface VisualisationComponentProps {
  /* need this as I currently don't want to allow interaction on preview pages
  but the way the VisualisationComponent / event map component has been structured
  the preview pages also have the onDragEnd prop passed in.  
*/
  allowInteraction?: boolean;
  isPreview?: boolean;
  rotationName?: string;
  visibleArea?: string;
}

export interface PitchVisComponents {
  heatMap: () => JSX.Element;
  eventMap: (props: VisualisationComponentProps) => JSX.Element;
  passSonar: (props: VisualisationComponentProps) => JSX.Element;
  shotMap: (props: VisualisationComponentProps) => JSX.Element;
}

interface WidgetVisComponent extends VisualisationComponentProps {
  isWidget: boolean;
  id: SBID;
}

export interface VisComponents extends PitchVisComponents {
  radar: (props: WidgetVisComponent) => JSX.Element;
  positionMap?: (props: WidgetVisComponent) => JSX.Element;
}

export enum VisDisplayMode {
  BOTH = 'both',
  VIS = 'vis',
  TABLE = 'table',
}

export type VisualisationMap = Record<keyof PitchVisComponents, (props: VisualisationComponentProps) => JSX.Element>;

export interface FallBackProps {
  isPreview?: boolean;
}

export interface PitchEvents {
  markerEvents: MarkerEvent[];
  arrowEvents: ArrowEvent[];
}

export type PitchEventsPromiseAtom = PromiseAtom<PitchEvents>;
export type UnwrappedPitchEventsAtom = Atom<PitchEvents>;

export interface EventMapBaseProps {
  onMarkerClick?: React.ComponentProps<typeof EventMarkerLayer>['onMarkerClick'];
  selectedItemIds?: Array<SBID>;
  markerEvents: MarkerEvent[];
  arrowEvents: ArrowEvent[];
  allEvents: (MarkerEvent | ArrowEvent)[];
  allowInteraction?: boolean;
  unwrappedPitchEventsAtom: UnwrappedPitchEventsAtom;
  isPreview?: boolean;
}

export interface EventMapProps extends VisualisationComponentProps {
  pitchEventsAtom: PitchEventsPromiseAtom;
  unwrappedPitchEventsAtom: UnwrappedPitchEventsAtom;
}

export interface HeatMapProps {
  pitchEventsAtom: PitchEventsPromiseAtom;
}
