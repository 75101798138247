import React, { ComponentType } from 'react';
import { WithAuthenticationRequiredOptions, withAuthenticationRequired } from '@statsbomb/kitbag-auth';

export const ProtectedRoute = ({
  component,
  ...args
}: WithAuthenticationRequiredOptions & {
  component: ComponentType<object>;
}) => {
  const Component = withAuthenticationRequired(component, args);
  return <Component />;
};
