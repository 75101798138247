import { PLAYER_DATA_LOCKER_DEFAULT_COLUMNS } from '@/consts/dataLocker/player';
import { atom } from 'jotai';
import { unwrap } from 'jotai/utils';
import { getReadWriteVisibleColumnsAtom } from '../utils';

export const playerDataVisibleColumnsUserSelectedAtom = atom<string[] | undefined>(undefined);

export const playerDataDefaultColumnsAtom = atom(async () => [...PLAYER_DATA_LOCKER_DEFAULT_COLUMNS]);

export const playerDataVisibleColumnsAtom = getReadWriteVisibleColumnsAtom(
  playerDataVisibleColumnsUserSelectedAtom,
  playerDataDefaultColumnsAtom,
);

export const unwrappedPlayerDataVisibleColumnsAtom = unwrap(playerDataVisibleColumnsAtom, prev => prev || []);
