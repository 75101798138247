import { useTranslation } from 'react-i18next';
import { Accordion } from '../accordion/Accordion';
import { KeeperCheckboxGroup } from '../checkboxGroups/KeeperCheckboxGroup';

export const KeeperFilters = () => {
  const { t } = useTranslation('events');

  return (
    <Accordion title={t('fields.event.type.goalkeeper')} isDefaultExpanded>
      <KeeperCheckboxGroup />
    </Accordion>
  );
};
