import { DashboardWidgetConfig } from '@/types/widget';
import { atom } from 'jotai';

// TODO (PPC-1527: Temp hard coded values - will be loaded from API in due course)
const DASHBOARD_TEMPLATES = [
  {
    configId: '9582325e-64f0-49a9-82a2-aea4dfdba6b4',
    type: 'dashboard_template',
    definition: {
      entity: 'player',
      name: 'StatsBomb Default Player Template',
      widgetIds: ['883e2ceb-9778-4a88-9d88-ff6397a5c9fc', '67ebd3d9-e8f3-48a2-b384-d21137a1cc94'],
    },
  },
];

const DASHBOARD_TEMPLATE_WIDGETS: DashboardWidgetConfig[] = [
  {
    configId: '883e2ceb-9778-4a88-9d88-ff6397a5c9fc',
    type: 'widget',
    definition: {
      vis: 'radar',
      entity: 'player',
      filters: {},
      displaySettings: {
        isStartPlacement: null,
        pitchCrop: null,
        pitchOrientation: null,
        radarTemplateId: null,
        showArrows: false,
      },
    },
  },
  {
    configId: '67ebd3d9-e8f3-48a2-b384-d21137a1cc94',
    type: 'widget',
    definition: {
      vis: 'positionMap',
      entity: 'player',
      filters: {},
      displaySettings: {
        isStartPlacement: null,
        pitchCrop: null,
        pitchOrientation: null,
        radarTemplateId: null,
        showArrows: false,
      },
    },
  },
];

const dashboardTemplatesAtom = atom(DASHBOARD_TEMPLATES);
const dashboardWidgetsAtom = atom(DASHBOARD_TEMPLATE_WIDGETS);

export const playerDashboardWidgetsAtom = atom<DashboardWidgetConfig[]>(get => {
  const dashboardTemplates = get(dashboardTemplatesAtom);
  const dashboardWidgets = get(dashboardWidgetsAtom);
  const playerDashboardWidgetIds = dashboardTemplates
    .filter(dashboardTemplate => dashboardTemplate.definition.entity === 'player')
    .flatMap(playerDashboardTemplates => playerDashboardTemplates.definition.widgetIds);

  return dashboardWidgets.filter(dashboardTemplateWidget =>
    playerDashboardWidgetIds.includes(dashboardTemplateWidget.configId),
  );
});
