import { deselectedGamesAtom } from '@/atoms/filters/highLevel';
import { GamesBySeasonPromiseAtom } from '@/types/game';
import { useAtom, useAtomValue } from 'jotai';
import { useDeepEffect } from './useDeepEffect';

export const useFilteredDeselectedGames = (gameOptions: GamesBySeasonPromiseAtom) => {
  const games = useAtomValue(gameOptions);

  const allGameValues = games.flatMap(({ games }) => games).map(({ game: { gameId } }) => gameId);
  const [deselectedGames, setDeselectedGames] = useAtom(deselectedGamesAtom);

  useDeepEffect(() => {
    const deselectedGamesInRange = deselectedGames.filter(deselectedOption =>
      allGameValues.includes(deselectedOption.value),
    );
    setDeselectedGames(deselectedGamesInRange);
  }, [games]);
};
