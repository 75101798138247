import { selectedVideoIdsAtom } from '@/atoms/video';
import { useAtomValue } from 'jotai';
import React from 'react';

export const DebugPanel = () => {
  const selectedVideoIds = useAtomValue(selectedVideoIdsAtom);
  return (
    selectedVideoIds && (
      <div className="fixed bottom-0 right-0 z-50 text-xs bg-white p-2 max-w-xs max-h-28 overflow-y-auto">
        Selected Video Event IDs: {selectedVideoIds?.map(video => video.videoEventId).join(', ')}
      </div>
    )
  );
};
