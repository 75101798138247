import { useTranslation } from 'react-i18next';
import { CustomShape } from '@statsbomb/kitbag-datavis';
import { Gender } from '@statsbomb/parachute-types';
import { GameType } from '@/types/filters';
import { snakeToCamel } from '@/utils/queries';
import { PeriodRange } from '@/types/game';
import { PERIOD_RANGE_OPTIONS } from '@/consts/gameRange';

const DISPLAY_LIMIT = 3;
export const useFormatChecklist = (list: string[] | undefined) => {
  const { t } = useTranslation('general');

  if (!list || !list.length) {
    return '';
  }

  if (list.length > DISPLAY_LIMIT) {
    const resultsToDisplay = list.slice(0, DISPLAY_LIMIT);
    return `${resultsToDisplay.join(', ')} ${t('plusMore', { count: list.length - DISPLAY_LIMIT })}`;
  }

  return list.join(', ');
};

export const useFormatPositions = (positions: string[] | undefined) => {
  const { t } = useTranslation('events');
  if (!positions || !positions.length) {
    return '';
  }

  const translatedPositions = positions.map(position => t(`fields.event.attributes.playerPosition.${position}`));

  return translatedPositions.join(', ');
};

export const useFormatGameCount = (gamesCount: number | undefined, deselectedGamesCount: number = 0) => {
  const { t } = useTranslation('games');
  if (!gamesCount || !deselectedGamesCount) {
    return '';
  }

  return t('gamesText', { text: gamesCount });
};

export const useFormatPitchZones = (zones: CustomShape[] | undefined) => {
  const { t } = useTranslation('filters');

  if (!zones || !zones.length) {
    return '';
  }

  if (zones.length === 14 && zones.every(({ id }) => typeof id === 'number' && id >= 27 && id <= 40)) {
    return t('pitchZones.attackingThird');
  }

  if (zones.length === 14 && zones.every(({ id }) => typeof id === 'number' && id >= 1 && id <= 14)) {
    return t('pitchZones.defensiveThird');
  }

  if (zones.length === 20 && zones.every(({ id }) => typeof id === 'number' && id >= 21 && id <= 40)) {
    return t('pitchZones.attackingHalf');
  }

  if (zones.length === 20 && zones.every(({ id }) => typeof id === 'number' && id >= 1 && id <= 20)) {
    return t('pitchZones.defensiveHalf');
  }

  return t('pitchZones.custom');
};

export const useFormatPeriod = (period: Pick<PeriodRange, 'value'> | undefined) => {
  const { t } = useTranslation('filters');

  if (!period || period.value === PERIOD_RANGE_OPTIONS[0].value) {
    return '';
  }

  const fullGamePeriod = PERIOD_RANGE_OPTIONS.find(({ value }) => value === period.value);
  if (!fullGamePeriod) {
    return '';
  }

  return t(
    fullGamePeriod.translation.key,
    fullGamePeriod.translation.count ? { count: fullGamePeriod.translation.count } : {},
  );
};

export const useFormatGameType = (gameType: GameType | undefined) => {
  const { t } = useTranslation('filters');

  if (!gameType || gameType === GameType.HOME_AWAY) {
    return '';
  }

  if (gameType === GameType.HOME) {
    return t('home');
  }

  return t('away');
};

export const useFormatMetric = (metric: string | undefined) => {
  const { t } = useTranslation('metrics');
  const text = metric ? t(`${snakeToCamel(metric)}.name`) : '';

  return text;
};

export const useFormatEvents = (events: string[] | undefined, keyPrefix: string) => {
  const { t } = useTranslation('events');

  const translatedAttributes = events?.map(event => `${t(`${keyPrefix}.${snakeToCamel(event)}`)}`) || [];
  const formattedAttributes = useFormatChecklist(translatedAttributes);

  return formattedAttributes;
};

export const useFormatGender = (gender?: Gender) => {
  const { t } = useTranslation('preferences');

  if (!gender) {
    return '';
  }

  return t(`data.gender.${gender === Gender.M ? 'm' : 'w'}`, { ns: 'preferences' });
};
