import { useTranslation } from 'react-i18next';
import { ButtonIcon } from '@statsbomb/kitbag-components';

export const ResetButton = ({ handleClick }: { handleClick: () => void }) => {
  const { t } = useTranslation('filters');

  return (
    <ButtonIcon displayText="right" icon="Reset" variant="secondary" onClick={() => handleClick()} size="small">
      {t('reset')}
    </ButtonIcon>
  );
};
