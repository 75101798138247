import { formatFileName } from '@/utils/array';
import { useAtomValue } from 'jotai';
import { exportModalDataAtom } from '@/atoms/exporting';
import { useExportTitle } from './useExportTitle';

export const useExportModalInfo = () => {
  const { visName, secondaryTitle, customWidth } =
    useAtomValue(exportModalDataAtom) ||
    /* istanbul ignore next: shouldn't ever get here as we only call this thing if the data is not null */ {};

  const title = useExportTitle();
  const fileName = formatFileName([title, secondaryTitle, visName]);

  return { title, secondaryTitle, fileName, customWidth };
};
