import { unwrappedPlayerDataVisibleColumnsAtom } from '@/atoms/dataLocker/player/table';
import { playerAggsCountAtom, playerAggsDataAtom } from '@/atoms/queries/player/playerAggData';
import { tableColumnsAtom } from '@/atoms/queries/tableColumns';
import { GeneralErrorBoundaryFallback } from '@/components/errorBoundary/ErrorBoundaryFallback';
import { ErrorBoundary } from '@sentry/react';
import { useAtom, useAtomValue } from 'jotai';
import { Suspense } from 'react';
import { AggsTable } from './AggsTable';
import { TablePagination } from './TablePagination';

const PlayerAggsTableWithData = () => {
  const [{ data, isPending, status }] = useAtom(playerAggsDataAtom);
  const tableColumns = useAtomValue(tableColumnsAtom);
  const visibleColumns = useAtomValue(unwrappedPlayerDataVisibleColumnsAtom);

  if (status === 'error') return <AggsTable hasError entity="player" />;

  return (
    <AggsTable
      data={data}
      isLoading={isPending}
      availableColumns={tableColumns}
      visibleColumns={visibleColumns}
      entity="player"
    />
  );
};

export const PlayerAggsTableWithPagination = () => (
  <ErrorBoundary fallback={<GeneralErrorBoundaryFallback />}>
    <Suspense fallback={<AggsTable isLoading entity="player" />}>
      <PlayerAggsTableWithData />
    </Suspense>
    <TablePagination dataLengthAtom={playerAggsCountAtom} />
  </ErrorBoundary>
);
