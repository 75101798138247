import { BaseOption } from '@/types/generic';
import { useTranslation } from 'react-i18next';

export const useExportInfo = (
  selectedOptions: BaseOption<string | number>[],
  translation: 'Competitions' | 'Seasons' | 'Teams' | 'Players' | 'Events',
  limit: number,
  isReversed?: boolean,
) => {
  const { t } = useTranslation('export');

  if (selectedOptions.length <= limit) {
    const labels = selectedOptions.map(option => option.label);
    const orderedLabels = isReversed ? [...labels].reverse() : labels;

    return orderedLabels.join(', ');
  }

  return t(`x${translation}`, { count: selectedOptions.length });
};
