import { selectedKeeperActionsAtom } from '@/atoms/eventData';
import { KEEPER_ACTIONS } from '@/consts/keeper';
import { snakeToCamel } from '@/utils/queries';
import { useTranslation } from 'react-i18next';
import { CheckboxGroup } from './CheckboxGroup';

export const KeeperCheckboxGroup = () => {
  const { t } = useTranslation(['events', 'filters']);
  const header = t('keeperActions', { ns: 'filters' });

  const options = KEEPER_ACTIONS.map(value => ({
    label: t(`keys.event.attributes.${snakeToCamel(value)}`, { ns: 'events' }),
    value,
  }));

  return <CheckboxGroup options={options} selectedValuesAtom={selectedKeeperActionsAtom} header={header} />;
};
