import { Nullable } from '@/types/generic';
import { atom } from 'jotai';

export const widgetIdAtom = atom<Nullable<string>>(null);
export const widgetIdToEditAtom = atom<Nullable<string>>(null);
export const widgetIdFiltersToDisplayAtom = atom<Nullable<string>>(null);

export const widgetDataToDeleteAtom = atom<{ widgetId: string | null; widgetName: string }>({
  widgetId: null,
  widgetName: '',
});
