import { Icon } from '@statsbomb/kitbag-components';
import { useTranslation } from 'react-i18next';

export const GenderIcon = ({ gender }: { gender: string }) => {
  const { t } = useTranslation(['events', 'gender']);
  const translatedGender = t(gender.toLowerCase(), { ns: 'gender' });

  return (
    <div className="relative flex items-center justify-center w-auto">
      <Icon variant="WomensNoLetter" />
      <span title={t('keys.competition.gender')} className="absolute text-xs">
        {translatedGender}
      </span>
    </div>
  );
};
