import { convertFilterParamsToString } from '@/utils/api';
import { Nullable } from '@/types/generic';

export const playersNormalisedAggregatesUrl = <T>(params: T) =>
  `/players/normalised-aggregates${convertFilterParamsToString(params)}`;

export const teamsNormalisedAggregatesUrl = <T>(params: T) =>
  `/teams/normalised-aggregates${convertFilterParamsToString(params)}`;

export const teamGamesTotalAggregatesUrl = <T>(teamId: Nullable<number>, params: T) => {
  if (teamId === null) return undefined;
  return `/team/${teamId}/games/total-aggregates${convertFilterParamsToString(params)}`;
};

export const playerGamesTotalAggregatesUrl = <T>(playerId: Nullable<number>, params: T) => {
  if (playerId === null) return undefined;
  return `/player/${playerId}/games/total-aggregates${convertFilterParamsToString(params)}`;
};

export const eventsUrl = <T>(params: T) => `/events${convertFilterParamsToString(params)}`;

export const playerMetricEventsUrl = <T>(playerId: Nullable<number>, metricKey: string | undefined, params: T) => {
  if (playerId === null || metricKey === undefined) return undefined;
  return `/player/${playerId}/metric/${metricKey}/events${convertFilterParamsToString(params)}`;
};

export const teamMetricEventsUrl = <T>(teamId: Nullable<number>, metricKey: string | undefined, params: T) => {
  if (teamId === null || metricKey === undefined) return undefined;
  return `/team/${teamId}/metric/${metricKey}/events${convertFilterParamsToString(params)}`;
};
