import { OptionPromiseAtom } from '@/types/atom';
import { GamesBySeasonPromiseAtom } from '@/types/game';
import { useAtomValue } from 'jotai';

export const useGetGameCount = (
  selectedGamesAtom: OptionPromiseAtom,
  gamesGroupedBySeasonAtom: GamesBySeasonPromiseAtom,
) => {
  const selectedGames = useAtomValue(selectedGamesAtom);
  const formattedGamesBySeason = useAtomValue(gamesGroupedBySeasonAtom);

  const gameCount = formattedGamesBySeason.reduce((total, season) => total + season.games.length, 0);
  const allSelected = selectedGames?.length === gameCount || selectedGames === null;

  return {
    allSelected,
    gameCount: selectedGames?.length || 0,
  };
};
