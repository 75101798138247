import { unwrappedSelectedPositionOptionsAtom } from '@/atoms/filters/highLevel/positions';
import { unwrappedPlayerPositionsOptionsAtom } from '@/atoms/queries/players';
import { useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';
import { useRawPositionOptions } from '@/hooks/useRawPositionOptions';
import { RadarFilterSummaryItem } from './RadarFilterSummaryItem';

export const RadarFilterPositionSummary = () => {
  const { t } = useTranslation('filters');

  return (
    <RadarFilterSummaryItem
      label={t('position')}
      options={useRawPositionOptions(useAtomValue(unwrappedPlayerPositionsOptionsAtom))}
      selectedOptions={useRawPositionOptions(useAtomValue(unwrappedSelectedPositionOptionsAtom))}
      allSummaryText={t('summary.allPositions')}
    />
  );
};
