export const formatPlayerName = (name: string, nickname: string | null) =>
  !nickname || /^\w\. \w+/.test(nickname) ? name : nickname;

interface playerPositionsByPercent {
  percentage: number;
  position: string;
}

export const positionPercentagesTo90sPlayed = (
  playerPositions: playerPositionsByPercent[],
  playerTotalMinutes: number,
) =>
  playerPositions.reduce((acc, { position, percentage }) => {
    const played90s = ((percentage / 100) * playerTotalMinutes) / 90;
    return {
      ...acc,
      [position]: played90s,
    };
  }, {});
