import { Typography } from '@statsbomb/kitbag-components';

// TODO (PPC-310: Temporary date picker component to use until one is created in Kitbag Components (https://statsbomb.atlassian.net/browse/RC-95))
export const DatePicker = ({
  id,
  label,
  value,
  onChange,
}: {
  id: string;
  label: string;
  value: string;
  onChange: (date: string) => void;
}) => (
  <div className="flex flex-col">
    <Typography variant="bodyRegular" as="label" htmlFor={id} className="!font-semibold">
      {label}
    </Typography>
    <input
      data-testid={id}
      id={id}
      className="date-picker px-2 min-h-[2rem] rounded-sb-full cursor-pointer
          text-canvas-primary-ink bg-canvas-secondary-main hover:bg-canvas-primary-weak dark:hover:bg-canvas-secondary-weak 
          focus:border-none focus-visible:outline-none text-xs"
      type="date"
      value={value}
      onChange={e => onChange(e.target.value)}
    />
  </div>
);
