import { useNavigate, useSearchParams } from 'react-router-dom';
import { filterSetsAtom } from '@/atoms/queries/userConfigs';
import { useAtomValue } from 'jotai';
import { FILTER_SET_ID, METRIC_KEY } from '@/consts/searchKeys';
import { useUpdateQueryString } from './useUpdateQueryString';

export const useNavigateToFilterSetId = () => {
  const filterSets = useAtomValue(filterSetsAtom);
  const [searchParams] = useSearchParams();
  const updateQueryString = useUpdateQueryString(searchParams);
  const navigate = useNavigate();

  return (filterIdToNavigateTo: string) => {
    const selectedFilterSetToLoad = filterSets.find(({ configId }) => configId === filterIdToNavigateTo);
    const savedMetric = selectedFilterSetToLoad?.definition.filters.metric;

    const filterSetIdParam = [{ key: FILTER_SET_ID, value: filterIdToNavigateTo }];
    const keysToAdd = savedMetric ? [...filterSetIdParam, { key: METRIC_KEY, value: savedMetric }] : filterSetIdParam;

    const newQueryString = updateQueryString(keysToAdd);
    navigate(`?${newQueryString}`);
  };
};
