import { showArrowsAtom } from '@/atoms/visualisation';
import { SelectionRect } from '@/types/selection';
import { PitchEvents, PitchEventsPromiseAtom } from '@/types/visualisation';
import { isArrowWithinRect, isPointWithinRect } from '@statsbomb/kitbag-datavis';
import { Getter } from 'jotai';
import { unwrap } from 'jotai/utils';

export const getPitchMarkersEvents = async (get: Getter, eventMarkersAtom: PitchEventsPromiseAtom) => {
  const eventMarkers = await get(eventMarkersAtom);
  const showArrows = get(showArrowsAtom);

  if (showArrows) return eventMarkers;

  return {
    markerEvents: [...eventMarkers.markerEvents, ...eventMarkers.arrowEvents],
    arrowEvents: [],
  };
};

export const getMarkersToggleDisabled = async (get: Getter, eventMarkersAtom: PitchEventsPromiseAtom) => {
  const { markerEvents, arrowEvents } = await get(eventMarkersAtom);
  return !arrowEvents.length && !!markerEvents.length;
};

export const getSelectedMarkersAndArrows = (
  { x, y, width, height }: SelectionRect,
  { markerEvents, arrowEvents }: PitchEvents,
) => {
  const selectedMarkerEvents = markerEvents.filter(({ startX, startY }) =>
    isPointWithinRect(startX, startY, x, y, width, height),
  );

  const selectedArrowEvents = arrowEvents.filter(({ startX, startY, endX, endY }) =>
    isArrowWithinRect(startX, endX, startY, endY, x, y, width, height),
  );

  return [...selectedMarkerEvents, ...selectedArrowEvents];
};

export const createUnwrappedPitchEventsAtom = (pitchEventsAtom: PitchEventsPromiseAtom) =>
  unwrap(pitchEventsAtom, prev => prev || { markerEvents: [], arrowEvents: [] });
