import { useSuccessFailColouring } from '@/hooks/useSuccessFailColouring';
import { ColouredMarkers } from '@statsbomb/kitbag-datavis';
import { useTranslation } from 'react-i18next';

export const EventMapMarkerLegend = () => {
  const { t } = useTranslation('visualisation');
  const successFailColouring = useSuccessFailColouring();

  return (
    <ColouredMarkers
      title={t('markerColouring.passSuccess')}
      markers={[
        { label: t('passSuccess.success'), colour: successFailColouring.success },
        { label: t('passSuccess.fail'), colour: successFailColouring.fail },
        { label: t('passSuccess.neutral'), colour: successFailColouring.neutral },
      ]}
    />
  );
};
