import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './globals.css';
import '@statsbomb/kitbag-datavis/dist/style.css';
import './i18n';
import { BrowserRouter, Route } from 'react-router-dom';
import { ErrorBoundary } from '@sentry/react';
import { HelmetProvider } from 'react-helmet-async';
import { HandleInvalidEntityUser } from '@/components/HandleInvalidEntityUser';
import { Loading } from './components/translations/Generic';
import { Providers } from './components/Providers';
import { PlayerRoutes } from './routes/playerRoutes';
import { DataLockerRoutes } from './routes/dataLockerRoutes';
import { RedirectToLocalised } from './components/routes/RedirectToLocalised';
import { ProtectedRoute } from './components/routes/ProtectedRoute';
import { GeneralErrorBoundaryFallback } from './components/errorBoundary/ErrorBoundaryFallback';
import { SentryRoutes } from './utils/errorMonitoring';
import { TeamRoutes } from './routes/teamRoutes';
import { Head } from './components/head/Head';
import Report from './pages/Report';
import { RedirectToTeam } from './components/routes/RedirectToTeam';
import { Settings } from './pages/Settings';
import { ReportsPage } from './pages/report/ReportsPage';

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <HelmetProvider>
      <Head />
      <ErrorBoundary fallback={<GeneralErrorBoundaryFallback />}>
        <Suspense fallback={<Loading />}>
          <BrowserRouter>
            <Providers>
              <HandleInvalidEntityUser>
                <SentryRoutes>
                  <Route path="/">
                    <Route path="" element={<RedirectToLocalised />} />
                    <Route path=":locale">
                      <Route path="" element={<ProtectedRoute component={RedirectToTeam} />} />
                      <Route path="player/:playerId/*" element={<ProtectedRoute component={PlayerRoutes} />} />
                      <Route path="team/:teamId/*" element={<ProtectedRoute component={TeamRoutes} />} />
                      <Route path="data-locker/*" element={<ProtectedRoute component={DataLockerRoutes} />} />
                      <Route path="report/:reportId" element={<ProtectedRoute component={Report} />} />
                      <Route path="reports" element={<ProtectedRoute component={ReportsPage} />} />
                      <Route path="settings/*" element={<ProtectedRoute component={Settings} />} />
                    </Route>
                  </Route>
                </SentryRoutes>
              </HandleInvalidEntityUser>
            </Providers>
          </BrowserRouter>
        </Suspense>
      </ErrorBoundary>
    </HelmetProvider>
  </React.StrictMode>,
);
