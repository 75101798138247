import { pitchMarkerColouringModeAtom, showArrowsAtom, showLegendAtom } from '@/atoms/visualisation';
import { useRect } from '@statsbomb/kitbag-components';
import { useAtomValue } from 'jotai';
import { Legend } from '@statsbomb/kitbag-datavis';
import { EventMapMarkerLegend } from './EventMapMarkerLegend';
import { EventMapArrowMarkerLegend } from './EventMapArrowMarkerLegend';

export const EventMapLegend = () => {
  const [legendRef] = useRect<HTMLLegendElement>();
  const showLegend = useAtomValue(showLegendAtom);
  const showArrows = useAtomValue(showArrowsAtom);
  const pitchMarkerColouringMode = useAtomValue(pitchMarkerColouringModeAtom);

  if (!showLegend || pitchMarkerColouringMode === 'default') {
    return null;
  }

  return <Legend legendRef={legendRef}>{showArrows ? <EventMapArrowMarkerLegend /> : <EventMapMarkerLegend />}</Legend>;
};
