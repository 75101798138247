import { tableColumnEntitiesAtom } from '@/atoms/queries/tableColumns';
import { TableColumnCategoryKey } from '@statsbomb/parachute-types';
import { useHydrateAtoms } from 'jotai/utils';

/**
 * This hook hydrates an atom which is then used to control which table columns are fetched.
 * Note that because this uses useHydrateAtoms, this will only trigger for the first component
 * which references this within a given scope. Subsequent uses of this hook will not do anything.
 */
export const useTableColumns = (tableColumnEntities: TableColumnCategoryKey[]) =>
  useHydrateAtoms([[tableColumnEntitiesAtom, tableColumnEntities]]);
