import { reportIdToDeleteAtom, reportNameAtom, isRenameReportModalOpenAtom } from '@/atoms/report/report';
import { reportConfigsAtom } from '@/atoms/queries/userConfigs';
import { UserConfigMenu } from '@/components/menu/UserConfigMenu/UserConfigMenu';
import { TopBar } from '@/components/topBar/TopBar';
import { TopBarHeading } from '@/components/topBar/TopBarHeading';
import { UserConfigReportDefinition } from '@/types/userConfigs';
import { ButtonIcon } from '@statsbomb/kitbag-components';
import { useAtomValue, useSetAtom } from 'jotai';
import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

const ReportTopBarBase = ({ reports }: { reports: UserConfigReportDefinition[] }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation(['navigation', 'report', 'general']);
  const navigate = useNavigate();
  const { reportId } = useParams();

  const selectedReportName = reports.find(({ configId }) => configId === reportId)?.definition.name;

  const setReportName = useSetAtom(reportNameAtom);
  const setIsRenamingModalOpen = useSetAtom(isRenameReportModalOpenAtom);
  const setReportIdToDeleteAtom = useSetAtom(reportIdToDeleteAtom);

  return (
    <TopBar>
      <ButtonIcon
        onClick={() => navigate(`/${language}/reports`)}
        size="small"
        icon="ChevronLeft"
        displayText="left"
        variant="ghost"
      >
        {t('reportsTitle', { ns: 'report' })}
      </ButtonIcon>
      <TopBarHeading>{selectedReportName || ''}</TopBarHeading>
      {reportId && (
        <UserConfigMenu
          configId={reportId}
          onRenameClick={(reportName: string) => {
            setReportName(reportName);
            setIsRenamingModalOpen(true);
          }}
          onDeleteClick={(id: string, name: string) => {
            setReportIdToDeleteAtom(id);
            setReportName(name);
          }}
        />
      )}
    </TopBar>
  );
};

const ReportTopBarWithData = () => {
  const reports = useAtomValue(reportConfigsAtom);
  return <ReportTopBarBase reports={reports} />;
};

export const ReportTopBar = () => (
  <Suspense fallback={<ReportTopBarBase reports={[]} />}>
    <ReportTopBarWithData />
  </Suspense>
);
