import { useUpdateQueryString } from '@/hooks/useUpdateQueryString';
import { useResetSelectionLayer } from '@/hooks/useResetSelectionLayer';
import { ButtonIcon } from '@statsbomb/kitbag-components';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

export const CloseCardHeaderButton = ({
  navigationPath,
  paramsToDelete,
}: {
  navigationPath: string;
  paramsToDelete?: string[];
}) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const updateQueryString = useUpdateQueryString(searchParams);
  const resetSelectionLayer = useResetSelectionLayer();
  const { t } = useTranslation('general');
  const btnLabel = t('close');

  return (
    <ButtonIcon
      variant="ghost"
      onClick={() => {
        const newQueryString = updateQueryString([], paramsToDelete);
        resetSelectionLayer();

        navigate(`${navigationPath}?${newQueryString}`);
      }}
      icon="Close"
      title={btnLabel}
    >
      {btnLabel}
    </ButtonIcon>
  );
};
