import { selectedRadarTemplateKeyAtom } from '@/atoms/radar';
import { useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';

// Hook to return the translation for the currently selected radar template
export const useTranslatedRadarTemplate = () => {
  const { t } = useTranslation('radar');
  const selectedRadarTemplateKey = useAtomValue(selectedRadarTemplateKeyAtom);
  const translatedRadarTemplate = t(`templates.${selectedRadarTemplateKey}`);

  return translatedRadarTemplate;
};
