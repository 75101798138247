import { EVENTS_FILTER_ATOMS } from '@/consts/dataLocker/events';
import { useSetPlayerEventDataDebouncedFilterParams } from './useSetPlayerEventDataDebouncedFilterParams';
import { useSetTeamDataDebouncedFilterParams } from './useSetTeamDataDebounceFilterParams';
import { useSetDataLockerDebouncedFilterParams } from './useSetDataLockerDebouncedFilterParams';

export const useSetWidgetDataDebouncedFilterParams = () => {
  useSetDataLockerDebouncedFilterParams(EVENTS_FILTER_ATOMS);
  useSetPlayerEventDataDebouncedFilterParams();
  useSetTeamDataDebouncedFilterParams();
};
