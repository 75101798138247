import { selectedPlayerNameAtom } from '@/atoms/player/info';
import { selectedTeamNameAtom } from '@/atoms/team/info';
import { useIsPage } from '@/hooks/useIsPage';
import { Typography } from '@statsbomb/kitbag-components';
import { Atom, useAtomValue } from 'jotai';

const DisplayEntityName = ({ selectedEntityNameAtom }: { selectedEntityNameAtom: Atom<Promise<string>> }) => {
  const entityName = useAtomValue(selectedEntityNameAtom);

  return (
    <Typography as="span" variant="headingMedium" data-testid="entity-name">
      {entityName}
    </Typography>
  );
};

export const EntityName = () => {
  const displayTeamName = useIsPage('/data-locker/team');
  const selectedEntityNameAtom = displayTeamName ? selectedTeamNameAtom : selectedPlayerNameAtom;

  return <DisplayEntityName selectedEntityNameAtom={selectedEntityNameAtom} />;
};
