import { eventsDataVisibleColumnsUserSelectedAtom } from '@/atoms/dataLocker/events/table';
import { playerDataVisibleColumnsUserSelectedAtom } from '@/atoms/dataLocker/player/table';
import { selectedFilterSetIdAtom } from '@/atoms/filters/userFilters';
import { sectionAtom } from '@/atoms/general';
import { metricKeyAtom } from '@/atoms/metric';
import { playerIdAtom } from '@/atoms/player/player';
import { renderedRadarDataAtom, selectedRadarTemplateIdAtom } from '@/atoms/radar';
import { isMobileFiltersPanelOpenAtom, isSettingsPanelOpenAtom } from '@/atoms/sidebar';
import { teamIdAtom } from '@/atoms/team/team';
import { selectedVideoIdsBaseAtom } from '@/atoms/video';
import { selectedVisEventsAtom } from '@/atoms/vis/selection';
import {
  isStartPlacementAtom,
  overwrittenVisualisationAtom,
  pitchCropAtom,
  pitchOrientationAtom,
  pitchOrientationReportWidgetAtom,
  pitchOrientationUserPreferenceAtom,
  showArrowsAtom,
} from '@/atoms/visualisation';
import { teamDataVisibleColumnsUserSelectedAtom } from '@/atoms/dataLocker/team/table';
import { filterAtomsToHydrate } from './atomHydration';

const filterAtoms = [...Object.values(filterAtomsToHydrate()), selectedFilterSetIdAtom];

const eventAtoms = [
  selectedVisEventsAtom,
  selectedVideoIdsBaseAtom,
  eventsDataVisibleColumnsUserSelectedAtom,
  metricKeyAtom,
];

export const visConfigAtoms = [
  showArrowsAtom,
  overwrittenVisualisationAtom,
  pitchCropAtom,
  pitchOrientationAtom,
  pitchOrientationReportWidgetAtom,
  pitchOrientationUserPreferenceAtom,
  isStartPlacementAtom,
];

const panelOpenAtoms = [isSettingsPanelOpenAtom, isMobileFiltersPanelOpenAtom];

const radarAtoms = [selectedRadarTemplateIdAtom, renderedRadarDataAtom];

export const layoutScopeAtoms = [
  ...filterAtoms,
  ...eventAtoms,
  ...visConfigAtoms,
  ...panelOpenAtoms,
  ...radarAtoms,
  playerDataVisibleColumnsUserSelectedAtom,
  teamDataVisibleColumnsUserSelectedAtom,
  playerIdAtom,
  teamIdAtom,
  sectionAtom,
];
