import { useTranslation } from 'react-i18next';
import { Accordion } from '../accordion/Accordion';
import { BodyPartCheckboxGroup } from '../checkboxGroups/BodyPartCheckboxGroup';

export const BodyPartFilters = () => {
  const { t } = useTranslation('events');

  return (
    <Accordion title={t('keys.event.attributes.bodyPart')} isDefaultExpanded>
      <BodyPartCheckboxGroup />
    </Accordion>
  );
};
