import { RadarHeader } from '@/components/radar/RadarHeader';
import RadarTemplateDropdown from '@/components/radar/RadarTemplateDropdown';
import { RadarTemplate } from '@/types/radar';

export const RadarCard = ({
  templates,
  label,
  children,
}: {
  templates: RadarTemplate[];
  label: string;
  children: React.ReactNode;
}) => (
  <>
    <RadarHeader>
      <RadarTemplateDropdown templates={templates} label={label} />
    </RadarHeader>
    <div className="mx-auto max-w-[550px]">{children}</div>
  </>
);
