import { ReactNode } from 'react';
import { Typography } from '@statsbomb/kitbag-components';

export const TopBarHeading = ({ children }: { children: ReactNode }) => (
  <div className="flex items-center gap-4 truncate">
    <Typography as="h1" variant="headingLarge" className="truncate">
      {children}
    </Typography>
  </div>
);
