import { RadioButton, RadioGroup, Typography } from '@statsbomb/kitbag-components';
import { useAtom, useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';
import { showArrowsAtom } from '@/atoms/visualisation';
import { Suspense } from 'react';
import { markersToggleDisabledAtom } from '@/atoms/vis/displaySettings';

const PitchMarkerToggleBase = ({ isDisabled = false }: { isDisabled?: boolean }) => {
  const { t } = useTranslation('visualisation');
  const [showArrows, setShowArrows] = useAtom(showArrowsAtom);

  return (
    <RadioGroup groupId="pitch-markings-type" groupTitle={t('markings.title')} size="regular">
      <Typography variant="bodySmall">{t('markings.description')}</Typography>
      <RadioButton
        checked={!showArrows}
        onChange={() => setShowArrows(false)}
        id="show-markers-option"
        label={t('markings.showMarkers')}
        value="false"
        size="small"
        disabled={isDisabled}
      />
      <RadioButton
        checked={showArrows}
        onChange={() => setShowArrows(true)}
        id="show-arrows-option"
        label={t('markings.showArrows')}
        value="true"
        size="small"
        disabled={isDisabled}
      />
    </RadioGroup>
  );
};

const PitchMarkerToggleWithData = () => {
  const isDisabled = useAtomValue(markersToggleDisabledAtom);

  return <PitchMarkerToggleBase isDisabled={isDisabled} />;
};

export const PitchMarkerToggle = () => (
  <Suspense fallback={<PitchMarkerToggleBase isDisabled />}>
    <PitchMarkerToggleWithData />
  </Suspense>
);
