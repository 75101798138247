import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSetAtom } from 'jotai';
import { widgetDataToDeleteAtom, widgetIdToEditAtom } from '@/atoms/report/widget';
import { MenuItemWithIcon } from '../menu/MenuItemWithIcon';
import { MoreOptionsMenu } from '../menu/MoreOptionsMenu/MoreOptionsMenu';
import { DeleteWidgetModal } from '../modal/DeleteWidgetModal/DeleteWidgetModal';
import { EditWidgetTitleModal } from '../modal/EditWidgetTitleModal/EditWidgetTitleModal';

interface WidgetSettingsMenuProps {
  widgetId: string;
  widgetName: string;
}

export const WidgetSettingsMenu = ({ widgetId, widgetName }: WidgetSettingsMenuProps) => {
  const { t } = useTranslation(['general', 'widget']);

  const setWidgetDataToDelete = useSetAtom(widgetDataToDeleteAtom);
  const setWidgetIdToEdit = useSetAtom(widgetIdToEditAtom);

  return (
    <>
      <MoreOptionsMenu title={t('settings', { ns: 'widget' })} size="small">
        <MenuItemWithIcon onClick={() => setWidgetIdToEdit(widgetId)} icon="Edit" text={t('edit')} />
        <MenuItemWithIcon
          onClick={() =>
            setWidgetDataToDelete({
              widgetId,
              widgetName,
            })
          }
          icon="Delete"
          text={t('delete')}
        />
      </MoreOptionsMenu>
      <EditWidgetTitleModal />
      <DeleteWidgetModal />
    </>
  );
};
