import { useSetAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { exportModalDataAtom } from '@/atoms/exporting';
import { SaveToReportMenuItemProps } from '@/types/widget';
import { ExportContent, ExportModalProps } from '@/types/exporting';
import { SaveToReportMenuItem } from '../widget/SaveToReportMenuItem';
import { MenuItemWithIcon } from '../menu/MenuItemWithIcon';
import { MoreOptionsMenu } from '../menu/MoreOptionsMenu/MoreOptionsMenu';
import { SaveWidgetToReportModal } from '../modal/SaveWidgetToReportModal/SaveWidgetToReportModal';

interface VisualisationMenuProps {
  saveToReportProps: SaveToReportMenuItemProps;
  exportModalProps: ExportModalProps;
}

export const VisualisationMenu = ({
  saveToReportProps,
  exportModalProps: { visName, secondaryTitle, customWidth },
}: VisualisationMenuProps) => {
  const { t } = useTranslation('export');
  const setExportModalData = useSetAtom(exportModalDataAtom);

  return (
    <>
      <MoreOptionsMenu>
        <SaveToReportMenuItem {...saveToReportProps} />
        <MenuItemWithIcon
          onClick={() => {
            setExportModalData({
              visName,
              secondaryTitle,
              customWidth,
              content: ExportContent.VIS,
            });
          }}
          icon="Download"
          text={t('export')}
        />
      </MoreOptionsMenu>
      <SaveWidgetToReportModal />
    </>
  );
};
