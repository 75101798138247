import { teamAggsFilterParamsAtom, teamAggsFilterParamsDebounceObject } from '@/atoms/queries/team/teamAggData';
import { MINUTES_PLAYED_COLUMN } from './table';

export const TEAM_DATA_LOCKER_DEFAULT_COLUMNS = [
  'teamLink',
  MINUTES_PLAYED_COLUMN,
  'aggregates.goalsAndOwnGoalsPer90',
  'aggregates.goalsAndOwnGoalsConcededPer90',
  'aggregates.npCumulativeXgPer90',
  'aggregates.npCumulativeXgConcededPer90',
  'aggregates.npShotsPer90',
  'aggregates.npShotsConcededPer90',
  'aggregates.npCumulativeXgPerShot',
  'aggregates.npCumulativeXgPerShotConceded',
  'aggregates.deepProgressionsPer90',
  'aggregates.deepCompletionsPer90',
  'aggregates.passesIntoBoxPer90',
  'aggregates.passObvPer90',
  'aggregates.dribbleAndCarryObvPer90',
  'aggregates.pressuresPer90',
  'aggregates.fhalfPressuresPer90',
  'aggregates.counterpressuresPer90',
  'aggregates.tacklesPer90',
  'aggregates.interceptionsPer90',
  'aggregates.nonAerialDuelsPer90',
  'aggregates.cornersPer90',
  'aggregates.nonThrowInKeyPassesPer90',
  'aggregates.touchesInsideBoxPer90',
  'aggregates.passesPer90',
  'aggregates.shotsPer90',
  'aggregates.shotsOutsideBoxPer90',
  'aggregates.boxCrossRatio',
  'aggregates.gkLongPassingRatio',
] as const;

export const TEAM_AGGS_FILTER_ATOMS = {
  filterParamsAtom: teamAggsFilterParamsAtom,
  filterParamsDebounceObject: teamAggsFilterParamsDebounceObject,
};
