import { Menu } from '@statsbomb/kitbag-components';
import { useTranslation } from 'react-i18next';
import { MenuItemWithIcon } from '../menu/MenuItemWithIcon';

interface GameMenuProps {
  isOpen: boolean;
  onOutsideClick: () => void;
  onClickMatchGoals: () => void;
  onClickSetPieces: () => void;
}

export const GameMenu = ({ isOpen, onOutsideClick, onClickMatchGoals, onClickSetPieces }: GameMenuProps) => {
  const { t } = useTranslation('games');

  return (
    isOpen && (
      <Menu testId="game-menu" onOutsideClick={onOutsideClick}>
        <MenuItemWithIcon onClick={onClickMatchGoals} icon="Play" text={t('gameGoals')} />
        <MenuItemWithIcon onClick={onClickSetPieces} icon="Play" text={t('playerActions')} />
      </Menu>
    )
  );
};
