import { SUPPORTED_LOCALES } from '@/consts/i18n';
import { Dropdown } from '@statsbomb/kitbag-components';
import { useTranslation } from 'react-i18next';
import { StringOption } from '@/types/generic';
import { useNavigate } from 'react-router-dom';

export const LocalisationSwitcher = () => {
  const navigate = useNavigate();
  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation('general');

  const languageOptions = SUPPORTED_LOCALES.map(locale => ({ label: locale.label, value: locale.code }));
  const selectedLanguage = languageOptions.find(locale => locale.value === language);

  return (
    <div className="w-56">
      <Dropdown
        id="locale-dropdown"
        label={t('language')}
        options={languageOptions}
        value={selectedLanguage}
        onChange={(option: StringOption) => {
          const selectedLocale = option.value;

          changeLanguage(selectedLocale);
          navigate(`/${selectedLocale}/settings`, { replace: true });
        }}
      />
    </div>
  );
};
