import { DashboardWidgetVis } from '@/types/widget';
import { entityVisualisationComponents } from '@/consts/widget';
import { useWidgetNavigationPath } from '@/hooks/useWidgetNavigationPath';
import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { DashboardWidget } from './DashboardWidget';
import { DashboardWidgetScopeProvider } from './DashboardWidgetScopeProvider';

const WidgetWrapperWithData = ({ filters, entity, entityId, vis, widgetId }: DashboardWidgetVis) => {
  const { t } = useTranslation('visualisation');
  const title = t(vis);
  const navigationPath = useWidgetNavigationPath(entity, entityId, vis, filters?.metric);
  const Component = entityVisualisationComponents[entity]?.[vis]!;

  return (
    <DashboardWidgetScopeProvider>
      <DashboardWidget title={title} navigationPath={navigationPath}>
        <Component isWidget id={widgetId} />
      </DashboardWidget>
    </DashboardWidgetScopeProvider>
  );
};

export const DashboardWidgetWrapper = (props: DashboardWidgetVis) => (
  <Suspense>
    <WidgetWrapperWithData {...props} />
  </Suspense>
);
