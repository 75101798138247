import { resetAllFiltersAtom } from '@/atoms/filters/resetFilters';
import { selectedFilterSetIdAtom } from '@/atoms/filters/userFilters';
import { PageTitle } from '@/components/head/PageTitle';
import { Button, Typography } from '@statsbomb/kitbag-components';
import { useSetAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export const DataLockerLandingPage = () => {
  const { t } = useTranslation(['dataLocker', 'entity', 'navigation']);
  const resetAllFilters = useSetAtom(resetAllFiltersAtom);
  const setSelectedFilterSetId = useSetAtom(selectedFilterSetIdAtom);

  const onNavigateToDLPage = () => {
    resetAllFilters();
    setSelectedFilterSetId(null);
  };

  return (
    <>
      <PageTitle>{t('primary.dataLocker', { ns: 'navigation' })}</PageTitle>
      <div className="flex w-full h-full flex-col px-2 pb-2 md:justify-center md:items-center">
        <div className="bg-canvas-tertiary-main p-4 flex flex-col gap-4 md:max-w-[400px]">
          <div className=" flex flex-col gap-4 opacity-80">
            <Typography variant="headingMedium">{t('landingPage.heading')}</Typography>
            <Typography variant="bodyMedium">{t('landingPage.paragraph1')}</Typography>
            <Typography variant="bodyMedium">{t('landingPage.paragraph2')}</Typography>
          </div>
          <div className="flex gap-2 flex-wrap">
            <Link className="rounded-sb-full" to="../events">
              <Button as="span" onClick={onNavigateToDLPage}>
                {t('landingPage.link', { entity: t('event.one', { ns: 'entity' }) })}
              </Button>
            </Link>
            <Link className="rounded-sb-full" to="../players">
              <Button as="span" onClick={onNavigateToDLPage}>
                {t('landingPage.link', { entity: t('player.one', { ns: 'entity' }) })}
              </Button>
            </Link>
            <Link className="rounded-sb-full" to="../teams">
              <Button as="span" onClick={onNavigateToDLPage}>
                {t('landingPage.link', { entity: t('team.one', { ns: 'entity' }) })}
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};
