import { EVENTS_FILTER_ATOMS } from '@/consts/dataLocker/events';
import { useSetDataLockerDebouncedFilterParams } from '@/hooks/apiFilterParams/useSetDataLockerDebouncedFilterParams';
import { DataLockerHighLevelFilters } from './filters/DataLockerHighLevelFilters';
import { EventTypeFilters } from '../filters/EventTypeFilters';
import { UserFilters } from '../filters/UserFilters/UserFilters';
import { BodyPartFilters } from '../filters/BodyPartFilters';
import { PitchFilters } from '../filters/PitchFilters';
import { PassFilters } from '../filters/PassFilters';
import { KeeperFilters } from '../filters/KeeperFilters';

export const EventsFilters = () => {
  useSetDataLockerDebouncedFilterParams(EVENTS_FILTER_ATOMS);

  return (
    <div className="flex flex-col">
      <UserFilters />
      <DataLockerHighLevelFilters />
      <EventTypeFilters />
      <PitchFilters />
      <BodyPartFilters />
      <PassFilters />
      <KeeperFilters />
    </div>
  );
};
