import { playerEventsForMetricAtom } from '@/atoms/queries/player/playerEventData';
import { PreviewEvents, PreviewEventsBase } from '@/components/tables/PreviewEvents';
import { useAtomValue } from 'jotai';
import { Suspense } from 'react';

const PlayerPreviewEventsWithData = () => {
  const eventsForMetric = useAtomValue(playerEventsForMetricAtom);
  return <PreviewEvents eventsForMetric={eventsForMetric} />;
};

export const PlayerPreviewEvents = () => (
  <Suspense fallback={<PreviewEventsBase isLoading />}>
    <PlayerPreviewEventsWithData />
  </Suspense>
);
