import React from 'react';
import { useKitbagAuth } from '@statsbomb/kitbag-auth';
import { Navigate } from 'react-router-dom';

interface GroupOnlyRouteProps {
  group: string;
  children: React.ReactNode;
}

export const GroupOnlyRoute = ({ group, children }: GroupOnlyRouteProps) => {
  const { user } = useKitbagAuth();
  if (!user) {
    return null;
  }

  if (group === user?.['https://statsbomb.com/accountID']) {
    return children;
  }
  return <Navigate to="/" replace />;
};
