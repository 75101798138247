import { Playlist, VideoEvent } from '@statsbomb/video-client-js';
import { WideFirstColumnLayout } from '@/components/layout/WideFirstColumnLayout';
import { VideoProvider } from '@/components/Providers';
import { useAtomValue } from 'jotai';
import { playerEventVideoIdsAtom } from '@/atoms/queries/player/playerEventData';
import { useSetMetricKey } from '@/hooks/useMetricKey';
import { useSetPlayerId } from '@/hooks/usePlayerId';
import { useSetGameIdForVideos } from '@/hooks/useSetGameIdForVideos';

export const PlayerGamesVideoPage = () => {
  useSetMetricKey();
  useSetPlayerId();
  useSetGameIdForVideos();

  const playerEventVideoIds = useAtomValue(playerEventVideoIdsAtom);

  return (
    <div className="h-full video-column-order">
      <VideoProvider>
        <WideFirstColumnLayout>
          <Playlist />
          <VideoEvent autoPlay events={playerEventVideoIds} />
        </WideFirstColumnLayout>
      </VideoProvider>
    </div>
  );
};
