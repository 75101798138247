import { toastDataAtom } from '@/atoms/toast';
import { useDeleteUserConfig } from '@/hooks/useDeleteUserConfig';
import { useAtom, useSetAtom } from 'jotai';
import { reportIdToDeleteAtom, reportNameAtom } from '@/atoms/report/report';
import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { noop } from '@/utils/noop';
import { GenericDeleteModal } from '../GenericDeleteModal/GenericDeleteModal';

const DeleteReportModalWithData = ({ afterDeleteHandler }: { afterDeleteHandler: () => void }) => {
  const deleteUserConfig = useDeleteUserConfig();
  const setToastData = useSetAtom(toastDataAtom);

  const [reportIdToDelete, setReportIdToDelete] = useAtom(reportIdToDeleteAtom);
  const [reportName, setReportName] = useAtom(reportNameAtom);

  const { t } = useTranslation('entity');

  return (
    reportIdToDelete && (
      <GenericDeleteModal
        id="delete-report-modal"
        itemToDelete={reportName}
        itemType={t('report.one').toLowerCase()}
        onConfirm={async () => {
          const isSuccess = await deleteUserConfig(reportIdToDelete);
          setToastData('deleteReport', isSuccess, reportName);

          setReportIdToDelete(null);
          setReportName('');

          afterDeleteHandler();
        }}
        onCancel={() => {
          setReportIdToDelete(null);
          setReportName('');
        }}
      />
    )
  );
};

export const DeleteReportModal = ({ afterDeleteHandler = noop }: { afterDeleteHandler?: () => void }) => (
  <Suspense>
    <DeleteReportModalWithData afterDeleteHandler={afterDeleteHandler} />
  </Suspense>
);
