import { filterAtomsToHydrate } from '@/consts/atomHydration';
import { filterAtomDefaults } from '@/consts/defaults/filterAtomDefaults';
import { ConfigGameFilterKey } from '@/types/userConfigs';
import { atom } from 'jotai';

export const resetGameRangeFiltersAtom = atom(null, (_, set) => {
  const filtersToReset: ConfigGameFilterKey[] = ['periodRange', 'startDate', 'endDate', 'deselectedGameIds'];
  filtersToReset.forEach(key => set(filterAtomsToHydrate()[key], filterAtomDefaults[key]));
});

export const resetAllFiltersAtom = atom(null, (_, set) => {
  const filtersToReset = Object.keys(filterAtomDefaults) as (keyof typeof filterAtomDefaults)[];
  filtersToReset.forEach(key => set(filterAtomsToHydrate()[key], filterAtomDefaults[key]));
});
