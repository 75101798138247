import { useSetAtom } from 'jotai';
import { widgetDataToDeleteAtom } from '@/atoms/report/widget';
import { ReportWidgetVis } from '@/types/widget';
import { entityVisualisationComponents } from '@/consts/widget';
import { useWidgetNavigationPath } from '@/hooks/useWidgetNavigationPath';
import { getWidgetAtomsToHydrate } from '@/utils/atoms/atomHydration';
import { atomsToHydrateAtom } from '@/atoms/hydration';
import { getFilterCount } from '@/utils/filters';
import { Suspense } from 'react';
import { ScopeProvider } from 'jotai-scope';
import { ReportWidget } from './ReportWidget';
import { ReportWidgetScopeProvider } from './ReportWidgetScopeProvider';
import { WidgetAtomHydration } from './WidgetAtomHydration';
import { DeleteWidgetModal } from '../modal/DeleteWidgetModal/DeleteWidgetModal';

const WidgetWrapperWithData = ({
  filters,
  displaySettings,
  entity,
  entityId,
  vis,
  widgetId,
  title,
  description,
}: ReportWidgetVis) => {
  const setAtomsToHydrate = useSetAtom(atomsToHydrateAtom);

  const navigationPath = useWidgetNavigationPath(entity, entityId, vis, filters.metric);

  const Component = entityVisualisationComponents[entity]?.[vis]!;

  return (
    <ReportWidgetScopeProvider>
      <WidgetAtomHydration {...{ entity, entityId, widgetId, filters, displaySettings }}>
        <ReportWidget
          id={widgetId}
          title={title}
          description={description}
          navigationPath={navigationPath}
          onClickTitle={() =>
            setAtomsToHydrate(getWidgetAtomsToHydrate({ widgetId, entity, entityId, filters, displaySettings }))
          }
          filtersCount={getFilterCount(filters)}
        >
          <Component isWidget id={widgetId} />
        </ReportWidget>
      </WidgetAtomHydration>
      <DeleteWidgetModal />
    </ReportWidgetScopeProvider>
  );
};

export const ReportWidgetWrapper = (props: ReportWidgetVis) => (
  <Suspense>
    <ScopeProvider atoms={[widgetDataToDeleteAtom]}>
      <WidgetWrapperWithData {...props} />
    </ScopeProvider>
  </Suspense>
);
