import { ErrorBoundary } from '@sentry/react';
import { ReactNode } from 'react';
import { Typography } from '@statsbomb/kitbag-components';
import { ColumnTopBar } from '../columnTopBar/ColumnTopBar';
import { GeneralErrorBoundaryFallback } from '../errorBoundary/ErrorBoundaryFallback';

/**
 * This component should be used to hold the controls which appear at the top
 * of a card within our 50/50 layout
 */
export const ColumnControlsWrapper = ({
  heading,
  headerComponent,
  testId = 'child-components',
  children,
}: {
  heading?: string;
  headerComponent?: ReactNode;
  testId?: string;
  children: ReactNode;
}) => (
  <ErrorBoundary fallback={<GeneralErrorBoundaryFallback />}>
    <ColumnTopBar>
      {heading && (
        <Typography variant="headingRegular" data-testid="control-heading">
          {heading}
        </Typography>
      )}
      {headerComponent}
      <div className="ml-auto flex flex-row gap-2" data-testid={testId}>
        {children}
      </div>
    </ColumnTopBar>
  </ErrorBoundary>
);
