import { atom } from 'jotai';
import { unwrap } from 'jotai/utils';
import { selectedTeamInfoAtom } from '../queries/teams';

export const selectedTeamNameAtom = atom(async get => {
  const selectedTeamInfo = await get(selectedTeamInfoAtom);
  return selectedTeamInfo?.name || '';
});

export const unwrappedSelectedTeamNameAtom = unwrap(selectedTeamNameAtom, prev => prev || '');
