import {
  playerMatchDataDefaultColumnsAtom,
  playerMatchDataVisibleColumnsAtom,
  playerMatchDataVisibleColumnsUserSelectedAtom,
} from '@/atoms/dataLocker/player/match/table';
import { unwrappedSelectedPlayerNameAtom } from '@/atoms/player/info';
import { playerGameAggsCountAtom } from '@/atoms/queries/player/playerGameAggData';
import { tableColumnEntitiesAtom } from '@/atoms/queries/tableColumns';
import { TableDataControls } from '@/components/controls/TableDataControls';
import { PageTitle } from '@/components/head/PageTitle';
import { LayoutWithFilters } from '@/components/layout/LayoutWithFilters';
import { PlayerGamesFilters } from '@/components/player/filters/PlayerGamesFilters';
import { PlayerGameAggsTable } from '@/components/player/tables/PlayerGameAggsTable';
import { TablePagination } from '@/components/tables/TablePagination';
import { TableScopeProvider } from '@/components/tables/TableScopeProvider';
import { useEntityPageTitle } from '@/hooks/useEntityPageTitle';
import { useTableColumns } from '@/hooks/useTableColumns';
import { Grid } from '@statsbomb/kitbag-components';
import { useAtomValue } from 'jotai';
import { ScopeProvider } from 'jotai-scope';
import { useTranslation } from 'react-i18next';
import { useCsvDownload } from '@/hooks/exporting/useCsvDownload';
import { playerGamesTotalAggregatesUrl } from '@/query/url';
import { playerIdAtom } from '@/atoms/player/player';
import { playerGameStatsFilterParamsAtom } from '@/atoms/queries/player/playerAggData';

const TableWithControls = ({ pageTitle }: { pageTitle: string }) => {
  const { t } = useTranslation('games');
  useTableColumns(['competition', 'game', 'player_total_metric']);
  const playerId = useAtomValue(playerIdAtom);
  const playerGameStatsFilterParams = useAtomValue(playerGameStatsFilterParamsAtom);
  useCsvDownload(pageTitle, playerGamesTotalAggregatesUrl(playerId, playerGameStatsFilterParams));

  return (
    <ScopeProvider atoms={[playerMatchDataVisibleColumnsUserSelectedAtom]}>
      <TableDataControls
        visibleColumnsAtom={playerMatchDataVisibleColumnsAtom}
        defaultColumnsAtom={playerMatchDataDefaultColumnsAtom}
        heading={t('latestPerformances')}
      />
      <PlayerGameAggsTable />
    </ScopeProvider>
  );
};

export const PlayerGamesPage = () => {
  useTableColumns(['competition', 'game', 'player_total_metric']);
  const { t } = useTranslation('navigation');
  const playerName = useAtomValue(unwrappedSelectedPlayerNameAtom);
  const pageTitle = useEntityPageTitle(t('secondary.games'), playerName);

  return (
    <ScopeProvider atoms={[tableColumnEntitiesAtom]}>
      <PageTitle>{pageTitle}</PageTitle>
      <LayoutWithFilters filters={<PlayerGamesFilters />}>
        <Grid>
          <Grid item>
            <TableScopeProvider>
              <TableWithControls pageTitle={pageTitle} />
              <TablePagination dataLengthAtom={playerGameAggsCountAtom} />
            </TableScopeProvider>
          </Grid>
        </Grid>
      </LayoutWithFilters>
    </ScopeProvider>
  );
};
