import { pitchOrientationAtom } from '@/atoms/visualisation';
import { useAtomValue } from 'jotai';

export const useGetPitchRotation = (isPreview: boolean) => {
  const pitchOrientation = useAtomValue(pitchOrientationAtom);
  if (isPreview) {
    return 'HORIZONTAL';
  }

  return pitchOrientation;
};
