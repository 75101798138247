import { CellValueToDisplay } from '@/components/tables/CellValueToDisplay';
import { useSortTable } from '@/hooks/useSortTable';
import { NestedObject } from '@/types/object';
import { DataTableColumn } from '@/types/table';
import { getMetricKeyFromColumnKey } from '@/utils/metrics';
import { noop } from '@/utils/noop';
import { alignTableCell, getFormatRule, getHighlightedColumnIndex, isLowerBetter } from '@/utils/table';
import { getTranslationColumns } from '@/utils/translations';
import { ButtonIcon, Icon, Table, Typography } from '@statsbomb/kitbag-components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { MINUTES_PLAYED_COLUMN } from '@/consts/dataLocker/table';
import { StatusTranslationKey } from '@/types/status';
import { GAME_ID, METRIC_KEY } from '@/consts/searchKeys';
import { GameMenu } from '../games/GameMenu';
import { HasVideoAccess } from '../video/HasVideoAccess';
import { PERMANENT_COLUMNS } from './GameAggregatesTable.constants';
import { TableWrapper } from './TableWrapper';
import { ContentState } from '../contentState/ContentState';

const ProcessingAggregates = () => {
  const { t } = useTranslation('games');

  return (
    <div className="flex flex-row gap-x-1">
      <Icon variant="Info" size="small" />
      <Typography variant="bodyMedium" as="span">
        {t('processingAggregates')}
      </Typography>
    </div>
  );
};

interface GameAggregatesTableProps {
  data?: NestedObject[];
  isLoading?: boolean;
  visibleColumns?: string[];
  availableColumns?: DataTableColumn[];
  isVideoColumnVisible?: boolean;
}

export const GameAggregatesTable = ({
  data = [],
  isLoading = false,
  visibleColumns = [],
  availableColumns,
  isVideoColumnVisible,
}: GameAggregatesTableProps) => {
  const { t } = useTranslation(['events', 'games', 'video']);

  /* TODO (PPC-1568: Finish implementing watch buttons) */
  const [openMenuRow, setOpenMenuRow] = useState<number | null>(null);
  const playVideoButtonText = t('playVideo', { ns: 'video' });
  const { orderBy, handleSort, getSortedState } = useSortTable();

  const hasData = data.length > 0;

  const sortedColumnIndex = orderBy ? visibleColumns.findIndex(col => col === orderBy) : -1;
  const highlightedColumnIndex = getHighlightedColumnIndex(sortedColumnIndex, !!isVideoColumnVisible);

  return (
    <TableWrapper>
      <div className={hasData ? 'overflow-x-auto' : 'overflow-x-hidden'}>
        <Table isHeadSticky withBorder={false} highlightedColumn={highlightedColumnIndex}>
          <Table.Head>
            <Table.Row>
              <HasVideoAccess>
                <Table.HeaderCell textAlign="left" size="regular">
                  <span>{t('gameVideo', { ns: 'games' })}</span>
                </Table.HeaderCell>
              </HasVideoAccess>
              {visibleColumns.map((key, index) => {
                const formatRule = getFormatRule(key, availableColumns);
                const { translationKey, translationNs } = getTranslationColumns(key);
                return (
                  <Table.HeaderCell
                    key={key}
                    textAlign={alignTableCell(formatRule)}
                    sortCb={() => handleSort(key, isLowerBetter(formatRule))}
                    sortState={getSortedState(key)}
                    size="regular"
                  >
                    <span data-testid={`column-${index}`}>{t(translationKey, { ns: translationNs })}</span>
                  </Table.HeaderCell>
                );
              })}
            </Table.Row>
          </Table.Head>

          {hasData && (
            <Table.Body>
              {data.map((row, rowIndex) => {
                const rowId = String(row['game.gameId']);
                const columnsToRenderValues = row.hasAggregates ? visibleColumns : PERMANENT_COLUMNS;

                return (
                  <Table.Row key={rowId} className="[&:hover_.invisible]:visible [&:focus-within_.invisible]:visible">
                    {/* TODO (PPC-1568: Finish implementing watch buttons) */}
                    <HasVideoAccess>
                      <Table.DataCell>
                        <ButtonIcon
                          size="small"
                          displayText="right"
                          variant="ghost"
                          icon="ChevronDown"
                          onClick={() => setOpenMenuRow(rowIndex)}
                        >
                          {t('watchVideo', { ns: 'games' })}
                        </ButtonIcon>
                        <GameMenu
                          isOpen={openMenuRow === rowIndex}
                          onOutsideClick={() => setOpenMenuRow(null)}
                          onClickMatchGoals={noop}
                          onClickSetPieces={noop}
                        />
                      </Table.DataCell>
                    </HasVideoAccess>
                    {columnsToRenderValues.map(key => {
                      const formatRule = getFormatRule(key, availableColumns);
                      const value = row[key];

                      /* TODO (PPC-1568: Finish implementing metric PlayButtons) */
                      const gameId = row['game.gameId'] as number;
                      const linkParams = `?${METRIC_KEY}=${getMetricKeyFromColumnKey(key)}&${GAME_ID}=${gameId}`;

                      return (
                        <CellValueToDisplay key={key} value={value} columnRule={formatRule} eventKey={key}>
                          {/* TODO (PPC-1568: Finish implementing metric PlayButtons) */}
                          <HasVideoAccess>
                            {/* Do not show the play button for columns with no video or cells which have an event count of zero */}
                            {key !== MINUTES_PLAYED_COLUMN && formatRule === 'integer' && value !== 0 && (
                              <Link to={`./video${linkParams}`} className="invisible">
                                <ButtonIcon
                                  as="span"
                                  size="small"
                                  variant="ghost"
                                  icon="Play"
                                  title={playVideoButtonText}
                                >
                                  {playVideoButtonText}
                                </ButtonIcon>
                              </Link>
                            )}
                          </HasVideoAccess>
                        </CellValueToDisplay>
                      );
                    })}
                    {!row.hasAggregates &&
                      visibleColumns.map((_, index) =>
                        index !== 0 ? null : (
                          <Table.DataCell key={`${rowId}-processing-aggs`} colSpan={visibleColumns.length}>
                            <ProcessingAggregates />
                          </Table.DataCell>
                        ),
                      )}
                  </Table.Row>
                );
              })}
            </Table.Body>
          )}
        </Table>
      </div>
      {!isLoading && !hasData && <ContentState status={StatusTranslationKey.NO_DATA} />}
      {isLoading && <ContentState status={StatusTranslationKey.LOADING} />}
    </TableWrapper>
  );
};
