import { Environment } from '@/types/environment';
import { initialiseErrorMonitoring } from '@/utils/errorMonitoring';
import env from '@/env';

const viteEnv = env.VITE_NODE_ENV as Environment;
const nodeEnv: Environment = ['development', 'production', 'test'].includes(viteEnv) ? viteEnv : 'development';

export const useErrorMonitoring = () => {
  initialiseErrorMonitoring(nodeEnv);
};
