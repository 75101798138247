import { GameType } from '@/types/filters';
import { TranslatedOption } from '@/types/translations';

export const gameTypeOptions: TranslatedOption<GameType>[] = [
  {
    translation: { key: 'homeAndAway', ns: 'filters' },
    value: GameType.HOME_AWAY,
  },
  {
    translation: { key: 'home', ns: 'filters' },
    value: GameType.HOME,
  },
  {
    translation: { key: 'away', ns: 'filters' },
    value: GameType.AWAY,
  },
];
