import { teamOptionsAtom, selectedTeamsAtom } from '@/atoms/filters/highLevel/teams';
import { Atom, useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';
import { FormattedGame } from '@/types/game';
import { GamesFilterType } from '@/hooks/useGetGamesByFilter';
import { Option } from '@/types/generic';
import { useGetFilteredOptions } from '@/hooks/useGetFilteredOptions';
import { playerCyclesOrderedAtom, playerTeamsAtom } from '@/atoms/queries/players';
import { getOrderedTeamOptions } from '@/utils/cycles';
import { RadarFilterSummaryItem } from './RadarFilterSummaryItem';

export const RadarFilterTeamSummary = ({
  filteredGamesDataAtom,
  selectedGamesAtom,
}: {
  filteredGamesDataAtom: Atom<Promise<FormattedGame[]>>;
  selectedGamesAtom: Atom<Promise<Option[]>>;
}) => {
  const { t } = useTranslation('entity');
  const playerCyclesOrdered = useAtomValue(playerCyclesOrderedAtom);
  const playerTeams = useAtomValue(playerTeamsAtom);

  const filteredTeamOptions = useGetFilteredOptions(
    teamOptionsAtom,
    filteredGamesDataAtom,
    selectedGamesAtom,
    GamesFilterType.TEAM,
  );

  const sortedTeamOptions = getOrderedTeamOptions(playerCyclesOrdered, filteredTeamOptions, playerTeams);

  return (
    <RadarFilterSummaryItem
      label={t('team.one')}
      options={sortedTeamOptions}
      selectedOptions={useAtomValue(selectedTeamsAtom)}
    />
  );
};
