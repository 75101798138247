import { useTranslation } from 'react-i18next';

export const useDecimalPlaces = () => {
  const {
    i18n: { language },
  } = useTranslation();

  const numberFormat = (fractionDigits: number, value: number) =>
    new Intl.NumberFormat(language, {
      minimumFractionDigits: fractionDigits,
      maximumFractionDigits: fractionDigits,
    }).format(value);

  return numberFormat;
};
