import { useTranslation } from 'react-i18next';
import { useAtomValue, useSetAtom } from 'jotai';
import { Suspense } from 'react';
import { Dropdown } from '@statsbomb/kitbag-components';
import { PositionOption, SelectorProps } from '@/types/filters';
import {
  positionOptionsAtom,
  selectedPositionsAtom,
  selectedPositionOptionsAtom,
} from '@/atoms/filters/highLevel/positions';
import { convertOptionsToValues } from '@/utils/array';

const PositionFilterBase = ({ options, isLoading = false }: SelectorProps<PositionOption>) => {
  const { t } = useTranslation(['filters', 'general']);
  const selectedPositions = useAtomValue(selectedPositionOptionsAtom);
  const setSelectedPositions = useSetAtom(selectedPositionsAtom);

  return (
    <Dropdown
      id="position-multi-select"
      value={selectedPositions}
      onChange={(options: PositionOption[]) => setSelectedPositions(convertOptionsToValues(options))}
      label={t('position')}
      labelPosition="top"
      isSearchable
      isMulti
      options={options}
      placeholder={t('dropdownPlaceholder', { ns: 'filters' })}
      isLoading={isLoading}
      selectedChipText={t('selectedChipText', { ns: 'general' })}
      noOptionsText={t('noOptions', { ns: 'general' })}
    />
  );
};

const PositionFilterWithData = () => {
  const positions = useAtomValue(positionOptionsAtom);

  return <PositionFilterBase options={positions} />;
};

export const PositionFilter = () => (
  <Suspense fallback={<PositionFilterBase isLoading />}>
    <PositionFilterWithData />
  </Suspense>
);
