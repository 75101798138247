import { Getter } from 'jotai';
import { Atom } from 'jotai/vanilla';
import { sectionAtom } from '@/atoms/general';
import { HighLevelFilterMapAtom } from '@/types/filters';

export const getOptionsAtomForSection =
  <T>(filterMapAtom: Atom<HighLevelFilterMapAtom<T>>) =>
  async (get: Getter) => {
    const section = get(sectionAtom);
    if (section === null) return [];

    const filterMap = get(filterMapAtom);
    const sectionOptionsAtom = filterMap[section];
    if (!sectionOptionsAtom) {
      // eslint-disable-next-line no-console
      console.error(
        `Unhandled mapping from ${section} section to ${filterMapAtom.debugLabel}.\n${section} is not a handled property.`,
      );
      return [];
    }

    return get(sectionOptionsAtom);
  };
