export enum Position {
  GK = 'gk',
  LB = 'lb',
  LCB = 'lcb',
  CB = 'cb',
  RCB = 'rcb',
  RB = 'rb',
  LWB = 'lwb',
  LDM = 'ldm',
  CDM = 'cdm',
  RDM = 'rdm',
  RWB = 'rwb',
  LM = 'lm',
  LCM = 'lcm',
  CM = 'cm',
  RCM = 'rcm',
  RM = 'rm',
  LW = 'lw',
  LAM = 'lam',
  CAM = 'cam',
  RAM = 'ram',
  RW = 'rw',
  LCF = 'lcf',
  CF = 'cf',
  RCF = 'rcf',
}
