import { Getter, atom } from 'jotai';
import { NestedObjectPromiseAtom, NestedObjectValue } from '@/types/object';
import { sortAscArrayOptions } from '../array';
import { convertToDropdownOption, flattenAndAddPrefix } from '../object';

const optionsAtomCallback = async (
  dataAtom: NestedObjectPromiseAtom,
  sortKeyLabel: string,
  sortKeyValue: string,
  get: Getter,
  optional?: string[],
) => {
  const dataArr = await get(dataAtom);
  const flattenedData = dataArr.map(data => flattenAndAddPrefix(data));

  return sortAscArrayOptions(
    flattenedData.map(dataObject => {
      const options: Record<string, NestedObjectValue> = {};
      const label = dataObject[sortKeyLabel];
      const value = dataObject[sortKeyValue];

      if (optional) {
        optional.forEach(key => {
          const optionKey = key === 'area_code' ? 'countryCode' : key;
          options[optionKey] = dataObject[key];
        });
      }

      return convertToDropdownOption(label, Number(value), { ...options });
    }),
  );
};

export const createOptionsAtom = (
  dataAtom: NestedObjectPromiseAtom,
  sortKeyLabel: string,
  sortKeyValue: string,
  optional?: string[],
) => atom(async get => optionsAtomCallback(dataAtom, sortKeyLabel, sortKeyValue, get, optional));

export const createOptionsAtomDescending = (
  dataAtom: NestedObjectPromiseAtom,
  sortKeyLabel: string,
  sortKeyValue: string,
) => atom(async get => (await optionsAtomCallback(dataAtom, sortKeyLabel, sortKeyValue, get)).reverse());
