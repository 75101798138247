import { StatusTranslationKey, StatusType } from '@/types/status';
import { useTranslation } from 'react-i18next';

export const useStatus = (translationNameSpace: string, status?: StatusTranslationKey) => {
  const { t } = useTranslation('status');

  if (!status) return {};

  return {
    statusType: StatusType[status],
    statusTitle: t(`${translationNameSpace}.${status}.title`),
    statusDescription: t(`${translationNameSpace}.${status}.description`),
  };
};
