import { NestedObject, NestedObjectValue } from '@/types/object';
import { PaginationAndOrderParams } from '@/types/pagination';
import { snakeToCamel } from '@/utils/queries';

export const getSortedGamesToDisplay = (
  gamesWithAggs: {
    [key: string]: NestedObjectValue;
  }[],
  pageAndOrderParams: PaginationAndOrderParams,
) => {
  const orderBy = Object.entries(pageAndOrderParams.order_by);
  const hasOrderBy = orderBy.length !== 0;
  if (!hasOrderBy) {
    return gamesWithAggs;
  }

  const [orderByKeySnake, orderByValue] = orderBy[0];
  const orderByKey = snakeToCamel(orderByKeySnake);
  return [...gamesWithAggs].sort((gameA, gameB) => {
    const gameAValue = gameA[orderByKey];
    const gameBValue = gameB[orderByKey];

    if (gameAValue === null || gameAValue === undefined) return 1;
    if (gameBValue === null || gameBValue === undefined) return -1;

    if (orderByValue === 'DESC NULLS LAST') {
      return gameBValue > gameAValue ? 1 : -1;
    }
    return gameAValue < gameBValue ? -1 : 1;
  });
};

export const convertGameTimeToSeconds = (gameTime: string) => {
  const [hours, minutes, seconds] = gameTime.split(':');

  // checks if are valid numbers
  if (!Number.isFinite(+hours) || !Number.isFinite(+minutes) || !Number.isFinite(+seconds)) return 0;

  const hoursValue = +hours * 60 * 60;
  const minsValue = +minutes * 60;
  const totalSeconds = hoursValue + +minsValue + +seconds;

  return totalSeconds;
};

export const sortGameEventsByMatchTime = (eventData: NestedObject[]) =>
  [...eventData].sort((a, b) => String(a['event.gameTime']).localeCompare(String(b['event.gameTime'])));
