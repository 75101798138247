import { DEFAULT_SELECTION_LAYER_ATTRIBUTES } from '@/consts/selection';
import { VisEvent } from '@/types/event';
import { Nullable } from '@/types/generic';
import { atom } from 'jotai';

export const activeRectAtom = atom(DEFAULT_SELECTION_LAYER_ATTRIBUTES);

export const resultRectAtom = atom(DEFAULT_SELECTION_LAYER_ATTRIBUTES);

export const selectedVisEventsAtom = atom<Nullable<VisEvent[]>>(null);
