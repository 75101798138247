import { filterGamesBySelectedGameRange } from '@/utils/filters';
import { Atom, Getter } from 'jotai';
import { FormattedGame } from '@/types/game';
import { PromiseAtom } from '@/types/atom';
import {
  selectedStartDateAtom,
  selectedEndDateAtom,
  selectedGameRangeAtom,
  selectedPeriodRangeAtom,
} from '@/atoms/filters/highLevel';
import { Option } from '@/types/generic';
import { defaultSeasonsAtom } from '@/atoms/filters/highLevel/seasons';
import { findById } from '../array';

export const getFilteredGames = async (
  get: Getter,
  entityGamesAtom: PromiseAtom<FormattedGame[]>,
  filterFunctionsAtom: Atom<(data: FormattedGame[]) => FormattedGame[]>,
): Promise<FormattedGame[]> => {
  const entityGames = await get(entityGamesAtom);
  const filterFunctions = get(filterFunctionsAtom);
  const startDate = get(selectedStartDateAtom);
  const endDate = get(selectedEndDateAtom);
  const selectedGameRange = get(selectedGameRangeAtom);
  const defaultSeasons = get(defaultSeasonsAtom);

  // Until the default seasons are loaded, we want to behave as if we have no games selected. This
  // will ensure that we don't fire any API calls to fetch event data until we have the default seasons.
  if (!defaultSeasons) return [];

  const selectedPeriodRange = get(selectedPeriodRangeAtom);

  const filteredGames = filterFunctions(entityGames);
  const sortedEntityGames = [...filteredGames].sort(
    // Non-null Assertion is used here as games should always have a gameDate at this stage
    (gameB, gameA) => +new Date(gameB.game.date!) - +new Date(gameA.game.date!),
  );

  return filterGamesBySelectedGameRange(sortedEntityGames, selectedGameRange, selectedPeriodRange, startDate, endDate);
};

export const getGamesBySeason = async (
  get: Getter,
  seasonOptionsAtom: PromiseAtom<Option[]>,
  competitionOptionsAtom: PromiseAtom<Option[]>,
  filteredGamesAtom: PromiseAtom<FormattedGame[]>,
) => {
  const seasons = await get(seasonOptionsAtom);
  const competitions = await get(competitionOptionsAtom);
  const filteredGames = await get(filteredGamesAtom);
  const seasonIds = [...new Set(filteredGames.map(({ cycle }) => cycle.seasonId))];

  return seasonIds
    .map(seasonId => {
      const games = [...filteredGames]
        .filter(({ cycle }) => cycle.seasonId === seasonId)
        // Non-null Assertion is used here as games should always have a gameDate at this stage
        .sort((gameA, gameB) => +new Date(gameB.game.date!) - +new Date(gameA.game.date!));

      const gamesWithCompetitionName = games.map(playerGame => ({
        ...playerGame,
        game: {
          ...playerGame.game,
          competitionName: findById(competitions, playerGame.cycle.competitionId, 'value')?.label,
        },
      }));

      return {
        seasonId,
        seasonName: findById(seasons, games[0].cycle.seasonId, 'value')?.label!,
        games: gamesWithCompetitionName,
      };
    })
    .sort((seasonA, seasonB) => seasonB?.seasonName?.localeCompare(seasonA?.seasonName));
};

export const getSelectedGames = async (
  get: Getter,
  gamesOptionsAtom: PromiseAtom<Option[]>,
  deselectedGamesAtom: Atom<Option[]>,
) => {
  const gameOptionsInRange = await get(gamesOptionsAtom);
  const deselectedGames = get(deselectedGamesAtom);

  if (deselectedGames.length === 0) return gameOptionsInRange;

  return gameOptionsInRange.filter(
    option => !deselectedGames.map(deselectedOption => deselectedOption.value).includes(option.value),
  );
};
