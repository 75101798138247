import { RadarData, RadarTemplate } from '@/types/radar';
import { snakeToCamel } from '@/utils/queries';
import { formatRadarValue } from '@/utils/radar';
import { Atom, atom } from 'jotai';
import { MetricData } from '@/types/metric';
import { SuspenseAtomResult } from '@/types/jotai';
import { PromiseAtom } from '@/types/atom';
import { decimalPlaces } from '@/utils/metrics';
import { radarTemplateIdAtom } from '../radar';

const isRadarData = (item: RadarData | undefined): item is RadarData => !!item;

type RadarDataAtom = SuspenseAtomResult<Record<string, number>>;

export const radarDataAtom = (
  dataAtom: Atom<RadarDataAtom | Promise<RadarDataAtom>>,
  metricDataAtom: PromiseAtom<MetricData[]>,
  templates: RadarTemplate[],
) =>
  atom(async get => {
    const rawRadarData = (await get(dataAtom)).data;

    const metricData = await get(metricDataAtom);
    const selectedRadarTemplateId = await get(radarTemplateIdAtom);

    const selectedRadarTemplate = templates.find(({ id }) => id === selectedRadarTemplateId);
    if (!selectedRadarTemplate) throw new Error('Radar template not found');

    return selectedRadarTemplate.metrics
      .map(({ name, min, max }) => {
        const metric = metricData.find(({ keyNormalised }) => snakeToCamel(keyNormalised) === name);
        if (!metric) return undefined;

        const range = {
          minValue: min || metric.minValue,
          maxValue: max || metric.maxValue,
        };

        const hasValue =
          rawRadarData[metric.keyNormalised] !== null && rawRadarData[metric.keyNormalised] !== undefined;

        return {
          ...metric,
          ...range,
          decimalPlaces: decimalPlaces(range.minValue, range.maxValue),
          value: formatRadarValue(
            hasValue ? rawRadarData[metric.keyNormalised] : range.minValue,
            hasValue ? metric.isPercentage : false,
          ),
        };
      })
      .filter(isRadarData);
  });
