import { defaultUserTeamAndPlayerIdsAtom } from '@/atoms/queries/user';
import { useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';

export interface NavigationItem {
  icon: string;
  path: string;
  label: string;
}

export const useNavigationItems = () => {
  const {
    t,
    i18n: { language: locale },
  } = useTranslation('navigation');
  const { data } = useAtomValue(defaultUserTeamAndPlayerIdsAtom);

  const navigationItems: { primary: NavigationItem[]; footer: NavigationItem[] } = {
    primary: [
      {
        icon: 'Dashboard',
        path: `/${locale}/reports`,
        label: t('primary.report'),
      },
      {
        icon: 'PlayerSoccer',
        path: data ? `/${locale}/player/${data.playerId}/radar` : '#player',
        label: t('primary.player'),
      },
      {
        icon: 'TeamSoccer',
        path: data ? `/${locale}/team/${data.teamId}/radar` : '#team',
        label: t('primary.team'),
      },
      {
        icon: 'IqScout',
        path: `/${locale}/data-locker`,
        label: `${t('primary.dataLocker')}`,
      },
    ],
    footer: [
      {
        icon: 'AccountSettings',
        path: `/${locale}/settings`,
        label: t('primary.settings'),
      },
    ],
  };
  return navigationItems;
};
