import React from 'react';
import { useTranslation } from 'react-i18next';
import { SaveToReportMenuItemProps } from '@/types/widget';
import { useSetAtom } from 'jotai';
import { saveWidgetToReportDataAtom, isSaveWidgetToReportModalOpenAtom } from '@/atoms/report/report';
import { MenuItemWithIcon } from '../menu/MenuItemWithIcon';

export const SaveToReportMenuItem = ({ visType, dataType }: SaveToReportMenuItemProps) => {
  const { t } = useTranslation('widget');

  const setIsSaveWidgetToReportModalOpen = useSetAtom(isSaveWidgetToReportModalOpenAtom);
  const setSaveWidgetToReportData = useSetAtom(saveWidgetToReportDataAtom);

  return (
    <MenuItemWithIcon
      onClick={() => {
        setSaveWidgetToReportData({
          visType,
          dataType,
        });
        setIsSaveWidgetToReportModalOpen(true);
      }}
      icon="PlusAdd"
      text={t('saveToReport', { ns: 'widget' })}
    />
  );
};
