import { useSetAtom } from 'jotai';
import { pageAtom } from '@/atoms/pagination';
import { METRIC_KEY } from '@/consts/searchKeys';
import { useSearchParams } from 'react-router-dom';
import { NestedObject } from '@/types/object';
import { Nullable } from '@/types/generic';
import { useDeepEffect } from './useDeepEffect';

export const useAutoResetPagination = (eventsFilterParams: Nullable<NestedObject>) => {
  const setPageAtom = useSetAtom(pageAtom);
  const [searchParams] = useSearchParams();
  const metricKey = searchParams.get(METRIC_KEY);

  useDeepEffect(() => setPageAtom(1), [eventsFilterParams, metricKey]);
};
