import { TEAM_DATA_LOCKER_DEFAULT_COLUMNS } from '@/consts/dataLocker/team';
import { atom } from 'jotai';
import { unwrap } from 'jotai/utils';
import { getReadWriteVisibleColumnsAtom } from '../utils';

export const teamDataVisibleColumnsUserSelectedAtom = atom<string[] | undefined>(undefined);

export const teamDataDefaultColumnsAtom = atom(async () => [...TEAM_DATA_LOCKER_DEFAULT_COLUMNS]);

export const teamDataVisibleColumnsAtom = getReadWriteVisibleColumnsAtom(
  teamDataVisibleColumnsUserSelectedAtom,
  teamDataDefaultColumnsAtom,
);

export const unwrappedTeamDataVisibleColumnsAtom = unwrap(teamDataVisibleColumnsAtom, prev => prev || []);
