import { loadablePlayerRadarDataAtom } from '@/atoms/queries/player/playerRadar';
import { loadablePlayerSelectedGamesForQueryAtom } from '@/atoms/filters/player/playerGames';
import { RadarChart } from '@/components/radar/RadarChart';
import { SBID } from '@/types/generic';

export const PlayerRadarChart = ({
  id,
  isWidget,
  isInteractive,
}: {
  id: SBID;
  isWidget?: boolean;
  isInteractive?: boolean;
}) => (
  <RadarChart
    id={id}
    loadableSelectedGamesAtom={loadablePlayerSelectedGamesForQueryAtom}
    loadableRadarDataAtom={loadablePlayerRadarDataAtom}
    isWidget={isWidget}
    isInteractive={isInteractive}
  />
);
