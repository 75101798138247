import { pitchCropAtom } from '@/atoms/visualisation';
import { PITCH_CROP } from '@/consts/visualisations';
import { snakeToCamel } from '@/utils/queries';
import { RadioGroup, RadioButton } from '@statsbomb/kitbag-components';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

export const PitchCropOptions = () => {
  const { t } = useTranslation('visualisation');
  const [selectedPitchCrop, setSelectedPitchCrop] = useAtom(pitchCropAtom);
  return (
    <RadioGroup groupId="pitch-crop-group" groupTitle={t('crop.pitchCrop')} size="regular">
      {PITCH_CROP.map(cropName => (
        <RadioButton
          key={cropName}
          checked={cropName === selectedPitchCrop}
          onChange={() => setSelectedPitchCrop(cropName)}
          id={`option-${cropName}`}
          label={t(`crop.${snakeToCamel(cropName.toLocaleLowerCase())}`)}
          value={cropName}
          size="small"
        />
      ))}
    </RadioGroup>
  );
};
