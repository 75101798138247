import { selectedPassHeightAtom } from '@/atoms/eventData';
import { PASS_HEIGHT } from '@/consts/passHeight';
import { useTranslation } from 'react-i18next';
import { snakeToCamel } from '@/utils/queries';
import { CheckboxGroup } from './CheckboxGroup';

export const PassHeightCheckboxGroup = () => {
  const { t } = useTranslation('events');
  const header = t('keys.event.attributes.passHeight');

  const options = PASS_HEIGHT.map(value => ({
    label: t(`fields.event.attributes.passHeight.${snakeToCamel(value)}`),
    value,
  }));

  return <CheckboxGroup options={options} selectedValuesAtom={selectedPassHeightAtom} header={header} />;
};
