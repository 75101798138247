import { atom } from 'jotai';
import { RadarData } from '@/types/radar';
import { playerPositionsOptionsAtom } from '@/atoms/queries/players';
import { PLAYER_RADAR_TEMPLATES, POSITION_TEMPLATE_MAP, TEAM_RADAR_TEMPLATES } from '@/consts/radar';
import { Position } from '@/types/player';
import { unwrap } from 'jotai/utils';
import { sectionAtom } from './general';

export const renderedRadarDataAtom = atom<RadarData[]>([]);
export const unwrappedRenderedRadarDataAtom = unwrap(renderedRadarDataAtom, prev => prev || null);

/* This will probably need to change when the templates come from the DB */
export const selectedRadarTemplateIdAtom = atom<number | null>(null);

export const radarTemplateIdAtom = atom(
  async get => {
    const selectedRadarTemplateId = get(selectedRadarTemplateIdAtom);
    if (selectedRadarTemplateId) return selectedRadarTemplateId;

    const positions = await get(playerPositionsOptionsAtom);
    if (positions.length === 0) return 1;

    return POSITION_TEMPLATE_MAP[positions[0].value as Position];
  },
  (_get, set, id: number) => {
    set(selectedRadarTemplateIdAtom, id);
  },
);

export const unwrappedRadarTemplateIdAtom = unwrap(radarTemplateIdAtom, prev => prev || null);

export const selectedRadarTemplateKeyAtom = atom(get => {
  const section = get(sectionAtom);
  const unwrappedRadarTemplateId = get(unwrappedRadarTemplateIdAtom);

  if (section === 'dataLocker') return null;

  const templates = section === 'player' ? PLAYER_RADAR_TEMPLATES : TEAM_RADAR_TEMPLATES;
  return templates.find(template => template.id === unwrappedRadarTemplateId)?.labelKey;
});
