import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

export const useNavigateToPreview = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const isPreview = pathname.includes('preview');

  return () => !isPreview && navigate(`preview?${searchParams}`);
};
