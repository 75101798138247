import { Grid } from '@statsbomb/kitbag-components';
import { GRID_ITEM_STYLES } from '@/consts/grids';
import React, { ReactNode } from 'react';

export const FullWidthLayout = ({ children }: { children: ReactNode }) => (
  <div className="grid-wrapper grid-full">
    <Grid>
      <Grid item xs={12} md={12} lg={12}>
        <div className={GRID_ITEM_STYLES}>{children}</div>
      </Grid>
    </Grid>
  </div>
);
