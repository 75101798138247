import { useTranslation } from 'react-i18next';
import { Typography } from '@statsbomb/kitbag-components';
import {
  CustomShapeLayer,
  PitchViz,
  GridSelectionLayer,
  BOX_PLUS_CHANNELS_GRID_ZONE_GROUPS,
  GridBumpersLayer,
  BOX_PLUS_CHANNELS_GRID_ZONES,
  GridColoursLayer,
  CustomShape,
} from '@statsbomb/kitbag-datavis';
import { PrimitiveAtom, useAtom, useSetAtom } from 'jotai';
import { selectedPitchFilterEndZonesAtom, selectedPitchFilterStartZonesAtom } from '@/atoms/filters/pitchFilter';
import { Accordion } from '../accordion/Accordion';
import { toggleFilterZoneGroups } from './PitchFilters.helpers';
import { ResetButton } from '../button/ResetButton';

const PitchFilterControl = ({ pitchFilterAtom }: { pitchFilterAtom: PrimitiveAtom<CustomShape[]> }) => {
  const [selectedZones, setSelectedZones] = useAtom(pitchFilterAtom);

  return (
    <GridBumpersLayer
      onBumperClick={(_, { ids }) => setSelectedZones(toggleFilterZoneGroups(ids, selectedZones))}
      gridZoneGroups={BOX_PLUS_CHANNELS_GRID_ZONE_GROUPS}
    >
      <PitchViz
        underlay={
          <>
            <GridColoursLayer zones={BOX_PLUS_CHANNELS_GRID_ZONES} />
            <CustomShapeLayer shapes={selectedZones} shapeRendering="crispEdges" />
            <GridSelectionLayer
              zones={BOX_PLUS_CHANNELS_GRID_ZONES}
              onZoneClick={(_, { id, points }) => {
                if (selectedZones.some(shape => shape.id === id)) {
                  setSelectedZones(selectedZones.filter(shape => shape.id !== id));
                } else {
                  setSelectedZones([{ id, points }, ...selectedZones]);
                }
              }}
            />
          </>
        }
      />
    </GridBumpersLayer>
  );
};

const PitchFilterGroup = ({
  pitchFilterAtom,
  label,
}: {
  pitchFilterAtom: PrimitiveAtom<CustomShape[]>;
  label: string;
}) => {
  const setSelectedZones = useSetAtom(pitchFilterAtom);
  const { t } = useTranslation(['filters', 'general']);

  return (
    <div className="flex flex-col gap-3">
      <div className="flex items-center gap-3">
        <div className="flex-grow">
          <Typography variant="headingMedium">{t(label, { ns: 'general' })}</Typography>
        </div>
        <span data-testid={`pitch-filter-reset-${label}`}>
          <ResetButton handleClick={() => setSelectedZones([])} />
        </span>
      </div>
      <span data-testid={`pitch-filter-${label}`}>
        <PitchFilterControl pitchFilterAtom={pitchFilterAtom} />
      </span>
    </div>
  );
};

export const PitchFilters = () => {
  const { t } = useTranslation('filters');

  return (
    <Accordion title={t('pitch')} isDefaultExpanded>
      <div className="flex flex-col gap-8">
        <PitchFilterGroup pitchFilterAtom={selectedPitchFilterStartZonesAtom} label="start" />
        <PitchFilterGroup pitchFilterAtom={selectedPitchFilterEndZonesAtom} label="end" />
      </div>
    </Accordion>
  );
};
