import { defaultSeasonsAtom } from '@/atoms/filters/highLevel/seasons';
import { eventDataAtom, eventsDataCountAtom } from '@/atoms/queries/eventData';
import { EventsTableBase, EventsTableWithPagination } from '@/components/tables/EventsTable';
import { useAtomValue } from 'jotai';

export const DataLockerEventsTable = () => {
  if (!useAtomValue(defaultSeasonsAtom)) return <EventsTableBase isLoading />;

  return <EventsTableWithPagination eventsAtom={eventDataAtom} dataLengthAtom={eventsDataCountAtom} />;
};
