import { Alert, Typography } from '@statsbomb/kitbag-components';

export const InfoBar = ({
  testId,
  mainText,
  additionalText,
}: {
  testId: string;
  mainText: string;
  additionalText?: string;
}) => (
  <Alert testId={testId}>
    <div className="flex flex-col gap-x-5 bg-transparent [&>svg]:text-messaging-info-main leading-3">
      <Typography as="span" variant="bodyMedium" className="flex items-center">
        {mainText}
      </Typography>
      {additionalText && (
        <Typography as="span" variant="headingSmall">
          {`${additionalText}`}
        </Typography>
      )}
    </div>
  </Alert>
);
