import { ITEMS_PER_PAGE } from '@/consts/interface';
import { TableColumn } from '@statsbomb/parachute-types';
import { KeysToCamelCase } from './generic';

export type DataTableColumn = KeysToCamelCase<TableColumn>;

export type ColumnGroup = {
  header: {
    translationKey: string;
    translationNs: string;
  };
  options: {
    value: string;
    label: {
      translationKey: string;
      translationNs: string;
    };
  }[];
};

export type ItemsPerPage = (typeof ITEMS_PER_PAGE)[number];

export enum TableColumnsEditorTabs {
  COLUMN_SELECT = 'column-select',
  COLUMN_SORT = 'column-sort',
}
