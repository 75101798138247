import { useAtomValue } from 'jotai';
import { userConfigsAtom } from '@/atoms/queries/userConfigs';
import { deleteUserConfigAtom } from '@/atoms/mutations/userConfigs/userConfigs';

export const useDeleteUserConfig = () => {
  const userConfigs = useAtomValue(userConfigsAtom);
  const { mutateAsync: deleteUserConfig } = useAtomValue(deleteUserConfigAtom);

  const handleDeleteUserConfig = async (configIdToDelete: string) => {
    const { isSuccess } = await deleteUserConfig(configIdToDelete);

    if (isSuccess) {
      await userConfigs.refetch();
    }

    return isSuccess;
  };

  return handleDeleteUserConfig;
};
